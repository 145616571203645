import TrackingHandler from '../../../libs/helpers/tracking';

export const CARIS_WIDGET__TOGGLE = (state) => {
  state.data.expanded = !state.data.expanded;
  state.expanded = !state.expanded;
  TrackingHandler.track({
    data: {
      event: state.data.expanded ? 'identifyCar_open' : 'identifyCar_close',
    },
  });
};

export const CARIS_WIDGET__CLOSE = (state) => {
  state.data.expanded = false;
  TrackingHandler.track({
    data: {
      event: 'identifyCar_close',
    },
  });
};

export const CARIS_WIDGET__OPEN = (state) => {
  state.data.expanded = true;
  TrackingHandler.track({
    data: {
      event: 'identifyCar_open',
    },
  });
};

export const CARIS_WIDGET__SET_AUTOEXPAND = (state, value) => {
  state.autoExpand = value;
};

export const CARIS_FILTER_TRIGGER__SELECT = (state) => {
  state.filters = !state.filters;
};

export const CARIS_CARIS_BAR__LOAD_MANUFACTURERS = (state, manufacturers) => {
  state.manufacturers = manufacturers;
};

export const CARIS_CARIS_BAR__LOAD_MODELS = (state, models) => {
  state.models = models;
};

export const CARIS_CARIS_BAR__LOAD_TYPES = (state, types) => {
  state.types = types;
};

export const CARIS_CARIS_BAR__SET_CARIS_MANUFACTURER = (
  state,
  manufacturer
) => {
  state.filteredCar = {
    ...state.filteredCar,
    manufacturer,
    model: {},
    type: {},
  };
};

export const CARIS_CARIS_BAR__SET_CARIS_MODEL = (state, model) => {
  state.filteredCar = {
    ...state.filteredCar,
    model,
    type: {},
  };
};

export const CARIS_CARIS_BAR__SET_CARIS_TYPE = (state, type) => {
  state.filteredCar.type = type;
};

export const CARIS_CARIS_BAR__UNSELECT = (state) => {
  state.data.carDisplayName = '';
  state.data.vehicle = null;
};

export const CARIS_FILTER_SELECT__FETCH = (state, { fetching }) => {
  state.fetching = fetching;
};

export const CARIS_FILTER_SELECT__STEP = (state, { filterStep }) => {
  state.filterStep = filterStep;
};

export const CARIS_MY_GARAGE__SET_GARAGE_VEHICLES = (state, { vehicles }) => {
  state.garageVehicles = [...vehicles];
};

export const CARIS_NEW_VEHICLE__ADD_SELECTABLE_VEHICLES = (
  state,
  { vehicle }
) => {
  state.selectableVehicles = [...state.selectableVehicles, vehicle];
};

export const CARIS_NEW_VEHICLE__RESET_SELECTABLE_VEHICLES = (state) => {
  state.selectableVehicles = [];
};

export const CARIS_NEW_VEHICLE__SELECT_VEHICLE = (state, { vehicle }) => {
  state.selectedVehicle = vehicle;
};

export const CARIS_MY_GARAGE__UPDATE_SAVE_TO_GARAGE = (
  state,
  { ktypnr, garageName }
) => {
  state.saveToGarageVehicle = {
    ktypnr,
    garageName,
  };
};

export const CARIS_DIALOG__OPEN = (state) => {
  state.isDialogActive = true;
};

export const CARIS_DIALOG__CLOSE = (state) => {
  state.isDialogActive = false;
};
