import map from 'lodash/map';

export default class UrlHelper {
  static getUrlParameters(url = window.location.href, excludeParams = []) {
    let parameterString = '';
    let result = {};

    if (url) {
      parameterString = url.split('#')[0].split('?')[1] || '';
      result = parameterString.split('&').reduce((params, parameter) => {
        const [key, value] = parameter.split('=');
        if (key && !excludeParams.includes(key)) {
          params[key] = value || '';
        }

        return params;
      }, {});
    }

    return result;
  }

  static assembleUrl({
    url = window.location.href,
    parameters = {},
    shouldMerge = true,
    excludeParams = [],
  }) {
    let fragment;

    if (!url) {
      url = '';
    }

    if (shouldMerge) {
      parameters = Object.assign(
        UrlHelper.getUrlParameters(url, excludeParams),
        parameters
      );
    }

    if (~url.indexOf('#')) {
      fragment = url.split('#')[1];
      url = url.split('#')[0];
    }

    const mappedParameters = map(parameters, (value, key) => {
      if (Array.isArray(value)) {
        return map(value, (valueItem) => {
          return `${key}%5B%5D=${valueItem}`;
        }).join('&');
      }

      return `${key}=${value}`;
    });

    url = url.split('?')[0];
    if (mappedParameters.length) {
      url += '?' + mappedParameters.join('&');
    }

    return `${url}${fragment ? `#${fragment}` : ''}`;
  }

  static isHomePage() {
    return location.pathname === '/';
  }

  static getCarSelectionURL({ url, parameters }) {
    const obj = {
      parameters,
      excludeParams: ['page'],
    };

    if (UrlHelper.isHomePage()) {
      obj.url = url;
    }

    return UrlHelper.assembleUrl(obj);
  }

  static replaceUrlKey(text, replacement) {
    const html = text.replace(/\{\S+?\}/g, (val) => {
      const replaceKey = val.replace(/{|}/g, '');
      return replacement[replaceKey] || replaceKey;
    });

    return html;
  }

  static clearLocationHash(url = window.location.href) {
    history.replaceState(null, null, (url || '').split('#')[0]);
  }
}
