<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <div class="plp-description-21__description">
      <p v-if="isDescTruncated && !restOfDescriptionVisible">
        {{ truncatedDescription }}

        <span
          class="plp-description-21__show-more"
          @click="toggleDescription()"
        >
          {{ $t('plp-description-21.show_more') }}
          <ChevronRightSvg class="plp-description-21__down-arrow-icon" />
        </span>
      </p>
      <p v-else>
        {{ description }}

        <span
          v-if="isDescTruncated"
          class="plp-description-21__show-less"
          @click="toggleDescription()"
        >
          {{ $t('plp-description-21.show_less') }}
          <ChevronRightSvg class="plp-description-21__up-arrow-icon" />
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import ChevronRightSvg from 'Components/00-generated/ChevronRightSvg';

export default {
  name: 'PlpDescription21',
  components: { ChevronRightSvg },
  mixins: [globalMixin],
  props: {
    description: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      restOfDescriptionVisible: false,
    };
  },
  computed: {
    isDescTruncated() {
      return this.description.length > 230;
    },
    truncatedDescription() {
      return this.description.slice(0, 200);
    },
  },
  methods: {
    toggleDescription() {
      this.restOfDescriptionVisible = !this.restOfDescriptionVisible;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.plp-description-21__description {
  padding: 0 12px 12px;
  display: block;
  font-size: var(--font-size-M);
  line-height: 1.3;

  p {
    word-break: break-all;
  }
}

.plp-description-21__show-more,
.plp-description-21__show-less {
  display: inline-flex;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.plp-description-21__down-arrow-icon,
.plp-description-21__up-arrow-icon {
  color: var(--color-secondary);
  transform: rotate(90deg);
  width: 24px;
  height: 23px;
}

.plp-description-21__up-arrow-icon {
  transform: rotate(270deg);
}
</style>
