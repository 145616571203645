<template>
  <div class="wrapper">
    <ActiveBrandFilterItem
      v-for="filter in mappedActiveFilters"
      :key="filter.value"
      :label="filter.label"
      :value="filter.value"
      :count="filter.count"
      @click="$emit('click', filter)"
    />
  </div>
</template>

<script>
import ActiveBrandFilterItem from './ActiveBrandFilterItem';

export default {
  name: 'ActiveBrandFilter',
  components: {
    ActiveBrandFilterItem,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    activeFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    mappedActiveFilters() {
      let mappedActiveFilters = [];
      for (let [filtersKey, value] of Object.entries(this.activeFilters)) {
        value.forEach((optionsKey) => {
          mappedActiveFilters.push({
            filterAlias: filtersKey,
            optionAlias: optionsKey,
            label: this.getLabel(filtersKey),
            value: this.getValue(filtersKey, optionsKey),
            count: this.getCount(filtersKey, optionsKey),
          });
        });
      }
      return mappedActiveFilters;
    },
  },
  methods: {
    getLabel(filtersKey, filters = this.filters) {
      if (this.hasFiltersKey(filtersKey)) {
        return filters[filtersKey].title;
      }
      this.logErrorMessage('noFiltersKey');
      return '';
    },
    getValue(filtersKey, optionsKey, filters = this.filters) {
      if (
        this.hasFiltersKey(filtersKey) &&
        this.hasOptionsKey(filtersKey, optionsKey)
      ) {
        return filters[filtersKey].options[optionsKey].title;
      }
      this.logErrorMessage('noFiltersOrOptionsKey');
      return '';
    },
    getCount(filtersKey, optionsKey, filters = this.filters) {
      if (
        this.hasFiltersKey(filtersKey) &&
        this.hasOptionsKey(filtersKey, optionsKey)
      ) {
        return filters[filtersKey].options[optionsKey].count;
      }
      this.logErrorMessage('noFiltersOrOptionsKey');
      return '';
    },
    hasFiltersKey(filtersKey, filters = this.filters) {
      return filters[filtersKey] !== undefined;
    },
    hasOptionsKey(filtersKey, optionsKey, filters = this.filters) {
      return filters[filtersKey].options[optionsKey] !== undefined;
    },
    logErrorMessage(message) {
      // eslint-disable-next-line no-console
      console.error('[Error]:', message);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
}

@media #{$_mediaLDown} {
  .wrapper {
    overflow-x: initial;
    scroll-behavior: initial;
    display: block;
    white-space: initial;
  }
}
</style>
