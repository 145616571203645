<i18n src="./translations"></i18n>

<template>
  <div
    v-if="isEnabled"
    :class="[classes]"
    :data-preload="[$options.name]"
    @click="loadBranchesAvailability()"
  >
    <img
      v-if="hasLocationImage"
      width="16"
      height="25"
      class="locationIcon"
      loading="lazy"
      :src="resolveFile('images/branches-availability-link/location.png')"
      :data-src="resolveFile('images/branches-availability-link/location.png')"
    />
    <span class="link">
      {{
        $t(
          labelType === 'primary'
            ? 'branches-availability-link.label'
            : 'branches-availability-link.secondary_label'
        )
      }}
    </span>
    <Dialog
      ref="dialog"
      class="dialog"
      :click-outside="true"
      :title="$t(`branches-availability-link.label`)"
    >
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="!!branchesAvailability"
        class="heading"
        v-html="
          $t('branches-availability-link.heading', {
            '%brandName%': brandName,
            '%productName%': productName,
          })
        "
      />
      <!-- eslint-enable vue/no-v-html -->

      <div
        v-for="branch in branchesAvailability"
        :key="branch.branchName"
        class="branch"
      >
        <div class="branchAvailability">
          <span class="branchName">{{ branch.branchName }}</span>
          <span
            :class="[
              'availability',
              availabilityClasses(branch.availabilityKey),
            ]"
          >
            {{ translations.labels[branch.availabilityKey] }}
          </span>
        </div>
        <div class="branchInfo">
          <p
            v-for="(addressInfo, index) in branch.address"
            :key="index"
            class="address"
          >
            {{ addressInfo }}
          </p>
          <p class="openingWeekdays">
            {{ branch.openingWeekdays }}
          </p>
          <p class="openingWeekend">
            {{ branch.openingWeekend }}
          </p>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import './images/location.png';
import globalMixin from 'Libs/mixins/globalMixin';
import Dialog from 'Components/02-molecules/dialog/Dialog';
import { axios } from 'Services/AjaxService';
import { mapActions, mapState } from 'vuex';

const BRANCHES = [
  {
    branchIdentifier: '1',
    branchName: 'Berlin Weißensee',
    address: ['Nüßlerstraße 35', '13088 Berlin-Weißensee'],
    openingWeekdays: 'Mo. - Fr.: 7:00-20:00 Uhr',
    openingWeekend: 'Sa.: 9:00-18:00 Uhr',
  },
  {
    branchIdentifier: '2',
    branchName: 'Berlin Alt-Mahlsdorf',
    address: ['Alt-Mahlsdorf 41', '12623 Berlin'],
    openingWeekdays: 'Mo. - Fr.: 6:00-20:00 Uhr',
    openingWeekend: 'Sa.: 9:00-18:00 Uhr',
  },
  {
    branchIdentifier: '3',
    branchName: 'Berlin Spandau',
    address: ['Brunsbütteler Damm 137', '13581 Berlin'],
    openingWeekdays: 'Mo. - Fr.: 7:00-20:00 Uhr',
    openingWeekend: 'Sa.: 9:00-18:00 Uhr',
  },
];

export default {
  name: 'BranchesAvailabilityLink',
  components: {
    Dialog,
  },
  mixins: [globalMixin],
  props: {
    product: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    labelType: {
      default: 'primary',
      type: String,
    },
    hasLocationImage: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      translations: {
        labels: {
          available: this.$t('branches-availability-link.available.label'),
          limited: this.$t('branches-availability-link.limited.label'),
          notAvailable: this.$t(
            'branches-availability-link.not-available.label'
          ),
        },
      },
      branches: BRANCHES,
      branchesAvailability: [],
    };
  },
  computed: {
    ...mapState('core', {
      salesChannelId: ({ salesChannel }) => salesChannel.salesChannelId,
    }),
    isEnabled() {
      const supportedSalesChannels = [
        'www-autoteile24-de',
        'www-kfzteile24-de',
        'www-kfzteile-com',
      ];

      return (
        this.product &&
        !this.product.isDropShipment &&
        supportedSalesChannels.includes(this.salesChannelId)
      );
    },
    sku() {
      return this.product.sku;
    },
    brandName() {
      return this.product.brandName;
    },
    productName() {
      return this.product.name;
    },
    productUrl() {
      return this.frontFacingPath('stock.ajax.product.branches').replace(
        '{SKU}',
        this.sku
      );
    },
  },
  methods: {
    ...mapActions('dataLayer', ['trackBranchesAvailabilityCheck']),
    async loadBranchesAvailability() {
      const { data } = await axios.get(this.productUrl);
      if (data) {
        this.branchesAvailability = data
          .filter((branch) => branch.branchIdentifier > 0)
          .map((branch) => ({
            ...branch,
            ...this.branches.find(
              (item) => item.branchIdentifier === branch.branchIdentifier
            ),
          }));

        this.trackBranchesAvailabilityCheck({
          productName: this.productName,
          brandName: this.brandName,
          sku: this.sku,
        });
      }

      if (this.branchesAvailability && !!this.branchesAvailability.length) {
        this.$refs.dialog.show();
      }
    },
    availabilityClasses(availabilityKey) {
      if (availabilityKey === 'available' || availabilityKey === 'limited') {
        return 'available';
      }
      return 'not_available';
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.branchesAvailabilityLink {
  line-height: 17px;
  font-size: var(--font-size-SM);
  display: flex;
  padding: 13px 2px 6px;
  margin-top: var(--space-2);
}

.link {
  color: var(--color-nero);
  text-decoration: underline;
  cursor: pointer;
  // margin: var(--space-1--half) 0;
}

.locationIcon {
  height: 25px;
  width: 16px;
  margin-right: var(--space-0--half);
}

.heading {
  font-size: var(--font-size-SM);
  line-height: 17px;

  ::v-deep span {
    font-weight: bold;
  }
}

.branch {
  border-bottom: 1px solid var(--color-alto-sec);
  padding-top: 18px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-S) !important;

  .branchAvailability {
    padding-left: 5%;

    .branchName {
      font-size: var(--font-size-SM);
      font-weight: bold;
    }

    .availability {
      padding-top: 12px;
      padding-left: 0;
      display: flex;
      align-items: center;

      &::before {
        flex-shrink: 0;
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: var(--color-rolling-stone);
        margin-right: var(--space-0--half);
        margin-top: -2px;
        display: block;
      }

      &.available {
        &::before {
          background: var(--color-japanese-laurel);
        }
      }

      &.not_available {
        &::before {
          background: var(--color-amber);
        }
      }
    }
  }

  .branchInfo {
    margin-right: 34%;

    .address {
      margin-bottom: 0 !important;
    }

    .openingWeekdays {
      margin-bottom: 0 !important;
      margin-top: 22px;
    }

    .openingWeekdays,
    .openingWeekend,
    .address {
      font-size: var(--font-size-S) !important;
    }
  }
}

.branch:nth-last-of-type(1) {
  border-bottom: none;
}

@media #{$_mediaMDown} {
  .branch {
    .branchInfo {
      margin-right: 25%;
    }
  }
}

@media #{$_mediaSDown} {
  .branch {
    .branchInfo {
      margin-right: 3%;
      width: 50%;
    }

    .branchAvailability {
      width: 50%;
      padding-right: 6px;
    }
  }
}
</style>
