export default class TranslationFormat {
  interpolate(message, values) {
    if (values) {
      Object.entries(values).forEach(([placeholder, value]) => {
        message = message.replace(new RegExp(placeholder, 'g'), value);
      });
    }

    return [message];
  }
}
