var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PdpTabBase',{staticClass:"pdp-description-tab",class:_vm.classes,attrs:{"headline":_vm.$t('pdp-description-tab.header'),"data-preload":[_vm.$options.name],"is-expanded":_vm.activeTab}},[_c('PdpTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.restrictions && !!_vm.restrictions.length),expression:"restrictions && !!restrictions.length"}],staticClass:"pdp-description-tab__restrictions-table",attrs:{"lines":_vm.restrictions,"columns":[
      { key: 'title', label: '' },
      { key: 'value', label: '' },
    ],"show-headers":false,"headline":_vm.$t('pdp-description-tab.restrictions_header'),"modifier":"primary"}}),_vm._v(" "),_c('PdpTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.itemFeatures && !!_vm.itemFeatures.length),expression:"itemFeatures && !!itemFeatures.length"}],staticClass:"pdp-description-tab__features-table",attrs:{"lines":_vm.itemFeatures,"columns":[
      { key: 'title', label: '' },
      { key: 'value', label: '' },
    ],"show-headers":false,"headline":_vm.$t('pdp-description-tab.features_header')}}),_vm._v(" "),_c('TyreLabels',{attrs:{"attributes":_vm.features,"images":_vm.images}}),_vm._v(" "),(_vm.productBundles && _vm.productBundles.length)?_c('ProductBundles',{attrs:{"items":_vm.productBundles}}):_vm._e(),_vm._v(" "),(_vm.description)?_c('div',{staticClass:"pdp-description-tab__description",domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e(),_vm._v(" "),(_vm.filteredDocuments && _vm.filteredDocuments.length)?[_c('span',{staticClass:"pdp-description-tab__document-header"},[_vm._v("\n      "+_vm._s(_vm.$t('pdp-description-tab.document_header'))+"\n    ")]),_vm._v(" "),_vm._l((_vm.filteredDocuments),function({ url, title },index){return _c('div',{key:index,staticClass:"pdp-description-tab__document"},[_c('Icon',{staticClass:"pdp-description-tab__document-icon",attrs:{"name":"download"}}),_vm._v(" "),_c('MaskedLink',{staticClass:"pdp-description-tab__document-label",attrs:{"redirect-to":url,"text":title,"target":"_blank","rel":"noreferrer noopener"}})],1)})]:_vm._e(),_vm._v(" "),(_vm.hazardousGoodInfo)?[_c('HazardousGood',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHazardousGood),expression:"isHazardousGood"}],staticClass:"pdp-description-tab__hazardous-good",attrs:{"information":_vm.hazardousGoodInformation,"documents":{ items: _vm.documents },"is-collapsible":false,"modifier":"secondary"}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }