import LocalStorage from '../../../libs/helpers/local-storage';

const AUTO_CLOSE_TIME = 10000;

const LOCAL_STORAGE_KEYS = {
  SHOULD_DELAY_TOAST: 'SHOULD_DELAY_TOAST',
  TOAST_MESSAGE_TO_DELAY: 'TOAST_MESSAGE_TO_DELAY',
  TRUE: 'true',
  FALSE: 'false',
};

const localStorage = new LocalStorage();
const shouldDelayToast = () =>
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SHOULD_DELAY_TOAST)) ===
  LOCAL_STORAGE_KEYS.TRUE;
const isToDelayToast = ({ key, text }) => {
  const toastMessageToDelay = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.TOAST_MESSAGE_TO_DELAY)
  );

  return toastMessageToDelay.text === text || toastMessageToDelay.key === key;
};

export const addMessage = (context, message) => {
  if (!(shouldDelayToast() && isToDelayToast(message))) {
    const uniqueKey = `${message.key}.${Math.random()}`;

    const item = {
      ...message,
      uniqueKey,
    };

    context.commit('addMessage', item);

    setTimeout(() => removeMessage(context, uniqueKey), AUTO_CLOSE_TIME);
  }
};

export const addMessages = (context, messages) => {
  messages.forEach((message) => {
    addMessage(context, message);
  });
};

export const removeMessage = (context, uniqueKey) => {
  context.commit('removeMessage', uniqueKey);
};

export const showAjaxError = (context) => {
  const message = {
    type: 'error',
    key: 'ajax_service.error',
    text: context.state.errorMessage,
  };

  addMessage(context, message);
};

export const addDelayedMessage = (context, delayedMessage) => {
  context.commit('addMessage', { text: delayedMessage });
};

export const removeDelayedMessage = (context, delayedMessage) => {
  context.commit('removeDelayedMessage', delayedMessage);
};
