<i18n src="./translations"></i18n>

<template>
  <div
    :class="[classes, dynamicCssClasses, ...dynamicIconClass]"
    :data-preload="[$options.name]"
  >
    <div
      class="inputWrapper"
      :class="{ 'animation-disabled': isAnimationDisabled }"
    >
      <label v-if="label && type !== 'hidden'" class="label" :for="computedId">
        {{ label }}
        <template v-if="!required && hideOptional === false"
          >({{ $t('input.optional') }})</template
        >
      </label>

      <input
        :id="computedId"
        ref="input"
        class="inputEl qa-inputEl"
        :class="[className, gtmClassName]"
        :type="type"
        :name="send ? fullName : ''"
        :disabled="disabled"
        :readonly="readonly"
        :required="required"
        :value="value"
        :size="size"
        v-bind="attrProps"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="$emit('input', $event.target.value)"
        @invalid.prevent="handleInvalidInput"
        @keyup.enter.prevent="onEnter"
        @keydown.tab="onTab"
      />

      <div v-if="icon1" :class="['input-icon', 'input-icon1']">
        <slot name="icon1" />
      </div>

      <button
        v-if="attr && attr.clear_button"
        key="clear_button"
        type="button"
        :class="['input-icon', 'icon--clear']"
        @click="$emit('input', '')"
      >
        <CrossCricleSvg width="20" height="20" />
      </button>

      <template v-if="!icon1">
        <CheckCircleSvg
          style="opacity: 0; width: 20px; height: 20px;"
          class="input-icon validIcon"
        />

        <WarningSvg
          style="opacity: 0; width: 20px; height: 20px;"
          class="input-icon invalidIcon"
        />
      </template>
    </div>

    <template v-if="type !== 'hidden'">
      <div v-if="errors.length > 0" class="error qa-Input__error">
        <template v-for="error in errors">
          {{ error.message }}
        </template>
      </div>

      <div class="message">
        {{ attr && attr.message }}
      </div>
    </template>
  </div>
</template>
<script>
import CheckCircleSvg from 'Components/00-generated/CheckCircleSvg.vue';
import CrossCricleSvg from 'Components/00-generated/CrossCircleSvg.vue';
import WarningSvg from 'Components/00-generated/WarningSvg.vue';
import globalMixin from 'Libs/mixins/globalMixin';
import { formBaseMixin } from 'Libs/mixins/formBaseMixin';

export default {
  name: 'Input',
  components: {
    CheckCircleSvg,
    CrossCricleSvg,
    WarningSvg,
  },
  mixins: [globalMixin, formBaseMixin],
  props: {
    size: {
      type: String,
      default: '',
    },
    /**
     * By default the icon is displayed on the right side but
     * that can be changed by applying `--iconLeft` modifier.
     */
    icon1: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isAnimationDisabled: false,
  }),
  computed: {
    dynamicIconClass() {
      return [this.buildClass(this.icon1, 'icon')];
    },
    attrProps() {
      if (!this.attr) return {};

      const props = { ...this.attr, placeholder: this.placeholder };

      if (this.attr.autocomplete === 'off') {
        props.autocomplete = `new-${this.fullName}`;
      } else if (this.attr.autocomplete === false) {
        props.autocomplete = 'off';
      }

      return props;
    },
  },
  beforeMount() {
    if (this.attr && this.attr.clear_button) {
      this.addModifier('has-clear-button');
    }

    this.updateValueModifiers(this.value);
    this.$watch('value', (newValue) => {
      this.addModifier('dirty');
      this.removeModifier('error');
      this.updateValueModifiers(newValue);
    });
  },
  mounted() {
    this.addAutofillEventListener();
  },
  beforeDestroy() {
    this.removeAutofillEventListener();
  },
  methods: {
    updateValueModifiers(value) {
      if (value) {
        this.addModifier('filled');
        this.addModifier('icon-show');
      } else {
        this.removeModifier('filled');
        this.removeModifier('icon-show');
      }
    },
    addAutofillEventListener() {
      this.$refs.input.addEventListener(
        'animationstart',
        this.checkAutofillState
      );
    },
    removeAutofillEventListener() {
      this.$refs.input.removeEventListener(
        'animationstart',
        this.checkAutofillState
      );
    },
    checkAutofillState(e) {
      const animationName = e.animationName;
      this.isAnimationDisabled = animationName?.includes('onAutoFillStart');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'mixins';
@import 'animations';

.input {
  font-size: var(--font-size-M);

  .inputEl {
    outline: none;
    width: 100%;
    padding: 12px var(--space-2);
    border: 1px solid var(--color-alto);
    border-radius: var(--border-radius-default);
    margin: 0;

    box-shadow: inset 0 0 0 25px var(--color-white);
    background-color: transparent;
    background-clip: content-box;
    transition: all var(--time-S);

    line-height: 14px;
    color: var(--color-mine-shaft);

    overflow: hidden;
    text-overflow: ellipsis;

    ::placeholder {
      text-overflow: ellipsis;
    }

    &::-ms-clear {
      display: none;
      width: 0;
      color: var(--color-white);
      height: 0;
    }

    &:autofill,
    &:-webkit-autofill {
      animation-name: onAutoFillStart;
    }

    &:not(:autofill),
    &:not(:-webkit-autofill) {
      animation-name: onAutoFillCancel;
    }
  }

  &--label {
    position: relative;
    padding-top: 9px;
  }

  &--hidden {
    position: absolute;
    left: -9999px;
    visibility: hidden;
  }

  &--focused:not(&--error),
  &--focused:not(&--disabled) {
    &:not(.input--iconLeft) {
      .label {
        color: var(--color-chathamsblue);
      }
    }

    .inputEl {
      border: 1px solid var(--color-chathamsblue);
    }

    &.input--withButton {
      .inputEl {
        border-right: 0;
      }
    }
  }

  &--yellow {
    .inputEl {
      box-shadow: inset 0 0 0 25px #f4d310;
    }

    .label {
      background: #f4d310;
      overflow: visible;

      &::before {
        content: '';
        display: block;
        background: white;
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 50%;
        z-index: -1;
        right: 0;
        opacity: 0;
        transition: opacity var(--time-S);
      }
    }
  }

  &--filled,
  &--focused,
  &--top,
  .animation-disabled {
    &:not(.input--iconLeft) {
      .label {
        transform: translate3d(0, -30px, 0) scale(0.75, 0.75);
      }

      .label::before {
        opacity: 1;
      }
    }
  }

  &--disabled {
    pointer-events: none;

    .label {
      color: var(--color-boulder);
    }

    .inputEl {
      color: var(--color-silver);
      -webkit-text-fill-color: var(--color-silver);
      opacity: 1;
    }
  }

  &--white {
    .label {
      color: var(--color-white) !important;
      background: transparent;
    }

    .inputEl {
      color: var(--color-white) !important;
      $shadow1: 1px 0 0 2px var(--color-gold-drop);
      $shadow2: 1px 0 0 2px var(--color-endeavour);
      $shadow3: 1px 0 0 2px var(--color-picton-blue);

      @include themeColor(box-shabow, $shadow1, $shadow2, $shadow3);

      height: 28px;
    }
  }

  &--error,
  &--error.input--focused {
    .error {
      color: var(--color-guardsman-red);
      opacity: 1;
      display: block;
    }

    .label {
      color: var(--color-guardsman-red);
    }

    .inputEl {
      border-color: var(--color-guardsman-red);
    }

    &.input--focused:not(.input--disabled) {
      .input {
        border-color: var(--color-guardsman-red);
      }
    }

    .input-icon {
      fill: var(--color-guardsman-red);

      &.icon--clear {
        fill: var(--color-black);
      }
    }

    &.input--withButton {
      .inputEl {
        border-right: 0;
      }
    }
  }

  &--icon,
  &--valid,
  &--error {
    &:not(.input--iconLeft) {
      .inputEl {
        padding-right: 44px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.input--has-clear-button {
        .input {
          padding-right: 70px;
        }

        .icon--clear {
          right: 48px;
        }
      }
    }
  }

  &--valid {
    .validIcon {
      opacity: 1 !important;
    }
  }

  &--error {
    .invalidIcon {
      opacity: 1 !important;
    }
  }

  &--withButton {
    .inputEl {
      border-right: 0;
      border-radius:
        var(--border-radius-default) 0 0
        var(--border-radius-default);
      height: 40px;
    }

    &.input--filled,
    &.input--focused,
    &.input--top {
      &:not(.input--iconLeft) {
        .label {
          transform: translate3d(3px, -21px, 0) scale(0.75, 0.75);
        }

        .label::before {
          opacity: 1;
        }
      }
    }
  }

  &--iconLeft {
    .inputEl {
      padding-left: 35px !important;
    }

    .label {
      margin-left: 30px;
      background: none;
      transition: none;
      line-height: 18px;
    }

    .input-icon1 {
      display: inline-flex;
      left: 12px;
      width: 16px;
      height: 16px;

      & svg {
        width: 16px;
        height: 16px;
      }
    }

    &.input--filled,
    &.input--top {
      .label {
        opacity: 0;
      }
    }
  }

  &--icon-show {
    .inputEl {
      padding-right: 44px;
    }

    .icon--clear {
      visibility: visible;
      z-index: 1000;
      position: absolute;
      fill: black;
    }
  }
}

.inputWrapper {
  position: relative;

  input[type='password'] {
    padding-right: var(--space-8);
    outline: none;
  }
}

.inputEl {
  height: 48px;
}

.label {
  @include verticallyCentered;

  top: 50%;
  left: 0;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 10px;
  background: var(--color-white);

  max-width: calc(100% - 27px);
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: nowrap;
  color: var(--color-rolling-stone);
  transform-origin: left center;

  pointer-events: none;
  opacity: 1;
  transition:
    opacity var(--time-S),
    color var(--time-S),
    transform var(--time-S);
}

.input-icon {
  @include verticallyCentered;

  &,
  button {
    padding: 0;
    right: 16px;
    transition: opacity var(--time-XS);
    height: 20px;
    width: 20px;
    fill: var(--color-black);
    outline: none;
  }
}

.icon--clear {
  visibility: hidden;
  z-index: -1;
  right: 18px;
  cursor: pointer;
  pointer-events: all;
  fill: var(--color-black);
}

.validIcon {
  fill: var(--color-japanese-laurel);
}

.invalidIcon {
  path {
    fill: var(--color-guardsman-red);
  }
}

.validIcon,
.invalidIcon {
  pointer-events: none;
  opacity: 0;

  transition: opacity var(--time-XS) ease-in-out;
}

.message,
.error {
  color: var(--color-mine-shaft);
  font-size: var(--font-size-S);
  line-height: var(--font-size-M);
  padding-left: var(--space-2);
}

.error {
  position: absolute;
  opacity: 0;
  display: none;
  transition: opacity var(--time-S) ease-in-out;
}
</style>
