<i18n src="./translations"></i18n>

<template>
  <div class="imageSlider">
    <slot />

    <div class="imagesWrapper" :class="{ secondary }">
      <div
        v-show="(primary || secondaryMobile) && items && items.length > 1"
        class="controls"
      >
        <button
          class="control prev"
          :title="$t('image-slider.prev')"
          @click="prev"
        >
          <ChevronLeftSvg
            class="controlIcon"
            :class="{ secondary }"
            width="45"
            height="45"
          />
        </button>
        <button
          class="control next"
          :title="$t('image-slider.next')"
          @click="next"
        >
          <ChevronRightSvg
            class="controlIcon"
            :class="{ secondary }"
            width="45"
            height="45"
          />
        </button>
      </div>

      <div
        v-if="extendedItems && extendedItems.length"
        :class="{ images: true, animate }"
        :style="{
          width: `calc(${extendedItems.length * 100}%)`,
          transform: `translate(-${(100 / extendedItems.length) * index}%, 0)`,
        }"
      >
        <button
          v-for="(item, itemIndex) in extendedItems"
          :key="
            item.full.product
              ? `full-${itemIndex}-${item.full.product.src}`
              : `full-${itemIndex}`
          "
          :class="{
            image: true,
            'image--active': index === itemIndex,
          }"
          :style="{ width: `${100 / extendedItems.length}%` }"
          @click="() => $emit('click-image')"
        >
          <component :is="item.full.component" v-bind="item.full" />
        </button>
      </div>
    </div>

    <div
      v-show="viewport === 'desktop' && items.length > 1"
      class="thumbnails-wrapper"
      :class="{ 'secondary-wrapper': secondary }"
    >
      <button
        v-show="secondary"
        class="thumbnail-navigation-wrapper prev"
        :class="{
          visible: prevVisible,
        }"
        :title="$t('image-slider.prev')"
        @click="decreaseThumbnailIndex"
      >
        <ChevronLeftSvg class="thumbnail-navigation" width="24" height="24" />
      </button>

      <template v-if="items && items.length">
        <div
          v-if="primary"
          v-show="showThumbnails && items.length > 1"
          class="thumbnails"
          :class="{ primary }"
        >
          <button
            v-for="(item, itemIndex) in items"
            :key="`thumbnail-${
              item.thumbnail.image
                ? itemIndex + '-' + item.thumbnail.image.src
                : itemIndex
            }`"
            :class="{
              thumbnail: true,
              active: itemIndex + 1 === index,
            }"
            :title="`${$t('image-slider.element')} ${itemIndex + 1}`"
            @click="goto(itemIndex + 1)"
          >
            <component :is="item.thumbnail.component" v-bind="item.thumbnail" />
          </button>
        </div>
        <div v-if="secondary" class="thumbnails-content">
          <div
            v-show="showThumbnails && items.length > 1"
            class="thumbnails"
            :style="{
              width: `calc(${items.length * 100}%)`,
              transform: `translate(-${
                (100 / items.length / 4) * thumbnailIndex
              }%, 0)`,
            }"
          >
            <button
              v-for="(item, itemIndex) in items"
              :key="`thumbnail-${
                item.thumbnail.image
                  ? itemIndex + '-' + item.thumbnail.image.src
                  : itemIndex
              }`"
              :class="{
                thumbnail: true,
                primary,
                secondary,
                active: itemIndex + 1 === index,
              }"
              :title="`${$t('image-slider.element')} ${itemIndex + 1}`"
              @click="goto(itemIndex + 1)"
              @mouseover="goto(itemIndex + 1)"
            >
              <component
                :is="item.thumbnail.component"
                v-bind="item.thumbnail"
              />
            </button>
          </div>
        </div>
      </template>

      <button
        v-show="secondary"
        class="thumbnail-navigation-wrapper next"
        :class="{
          visible: nextVisible,
        }"
        :title="$t('image-slider.next')"
        @click="increaseThumbnailIndex"
      >
        <ChevronRightSvg class="thumbnail-navigation" width="24" height="24" />
      </button>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import globalMixin from 'Libs/mixins/globalMixin';
import ChevronLeftSvg from 'Components/00-generated/ChevronLeftSvg';
import ChevronRightSvg from 'Components/00-generated/ChevronRightSvg';
import ProductImageItem from 'Components/02-molecules/product-image-item/ProductImageItem';
import ProductImageThumbnail from 'Components/02-molecules/product-image-thumbnail/ProductImageThumbnail';
import NoImageSvg from 'Components/00-generated/NoImageSvg';
import { mapState } from 'vuex';
export default {
  name: 'Slider',
  components: {
    ChevronLeftSvg,
    ChevronRightSvg,
    ProductImageItem,
    ProductImageThumbnail,
    NoImageSvg,
  },
  mixins: [globalMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
    showThumbnails: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      index: 1,
      nextIndex: null,
      animate: true,
      thumbnailCount: null,
      thumbnailIndex: 0,
      nextThumbnailIndex: null,
    };
  },
  computed: {
    ...mapState('core', {
      viewport: ({ viewport }) => viewport.range,
    }),
    extendedItems() {
      if (!this.items || !this.items.length) {
        return [];
      }
      const first = this.items[0];
      const last = this.items[this.items.length - 1];
      return [last, ...this.items, first];
    },
    primary() {
      return this.variant === 'primary';
    },
    secondary() {
      return !this.primary;
    },
    secondaryMobile() {
      return this.viewport !== 'desktop' && this.secondary;
    },
    prevVisible() {
      return (
        this.viewport === 'desktop' &&
        this.variant === 'secondary' &&
        this.items.length > 4 &&
        this.thumbnailIndex > 0
      );
    },
    nextVisible() {
      return (
        this.viewport === 'desktop' &&
        this.variant === 'secondary' &&
        this.items.length - this.thumbnailIndex > 4
      );
    },
  },
  watch: {
    nextIndex(newValue) {
      requestAnimationFrame(() => {
        this.animate = true;
        if (newValue !== null) {
          this.index = newValue;
          this.nextIndex = null;
        }
      });
    },
    nextThumbnailIndex(newValue) {
      requestAnimationFrame(() => {
        this.animate = true;
        if (newValue !== null) {
          this.thumbnailIndex = newValue;
          this.nextThumbnailIndex = null;
        }
      });
    },
  },
  mounted() {
    this.updateViewport();
    window.addEventListener('resize', debounce(this.updateViewport, 150));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateViewport);
  },
  methods: {
    next() {
      requestAnimationFrame(() => {
        if (this.index === this.extendedItems.length - 2) {
          this.animate = false;
          this.index = 0;
        }
        this.nextIndex = this.index + 1;
        this.$emit('change-image', this.nextIndex);
      });
    },
    prev() {
      requestAnimationFrame(() => {
        if (this.index === 1) {
          this.animate = false;
          this.index = this.extendedItems.length - 1;
        }
        this.nextIndex = this.index - 1;
        this.$emit('change-image', this.nextIndex);
      });
    },
    goto(index) {
      this.index = index;
      this.$emit('change-image', this.index);
    },
    updateViewport() {
      const TARGET_WIDTH = 100;
      const count = Math.floor(this.$el.clientWidth / TARGET_WIDTH);
      this.thumbnailCount = Math.max(3, Math.min(5, count));
    },
    increaseThumbnailIndex() {
      if (this.nextVisible) {
        requestAnimationFrame(() => {
          this.nextThumbnailIndex = this.thumbnailIndex + 1;
        });
      }
    },
    decreaseThumbnailIndex() {
      if (this.prevVisible) {
        requestAnimationFrame(() => {
          this.nextThumbnailIndex = this.thumbnailIndex - 1;
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.imageSlider {
  position: relative;
}

.imagesWrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  border: 1px solid var(--color-alto);

  &.secondary {
    border: none;
  }
}

.images {
  display: flex;

  &.animate {
    transition: transform 0.3s ease-in-out;
  }
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: zoom-in;
}

.thumbnails-wrapper {
  display: flex;
  min-height: 64px;

  .thumbnail-navigation-wrapper {
    opacity: 0;

    .thumbnail-navigation {
      color: var(--color-secondary);
      background: var(--color-white);
    }

    &.visible {
      opacity: 1;
      cursor: pointer;
    }

    &.prev {
      margin: auto 12px auto 28px;
    }

    &.next {
      margin: auto 28px auto 12px;
    }
  }

  .thumbnails-content {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .thumbnails {
    display: flex;
    width: calc(100% + 16px);
    margin: 0 -8px;
    transition: transform 0.3s ease-in-out;

    &.primary {
      flex-wrap: wrap;
    }

    .secondary {
      padding: 12px 32px;
    }

    .thumbnail {
      padding: 0;
      margin: 16px 8px 0;
      width: calc(33.333% - 16px);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      outline: none;

      &.active {
        border: 1px solid var(--color-tory-blue);
      }

      &.secondary {
        width: 64px;
        height: 64px;
        margin: auto 10px;
        border-radius: var(--border-radius-default);

        &.active {
          border: 1px solid var(--color-secondary);
        }
      }

      &.primary {
        border: 1px solid var(--color-alto);
      }
    }
  }
}

.controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.control {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 16px 0;
  cursor: pointer;
  outline: none;
  position: relative;
  z-index: 1;

  &.prev {
    margin-left: -1px;
  }

  &.next {
    margin-right: -2px;
  }
}

.controlIcon {
  color: var(--color-primary);
  border: 1px solid var(--color-alt-gray);
  background: var(--color-white);

  &.secondary {
    border: none;
    color: #545456;
  }
}

@media (pointer: coarse) {
  .control {
    opacity: 1;
  }
}
</style>
