<i18n src="./translations"></i18n>

<template>
  <div
    ref="filterRow"
    :class="[classes, 'qa-ProductCardList__sectionWrapper--filter']"
    :data-preload="[$options.name]"
  >
    <template v-for="(section, sectionIndex) in computedVisibleSections">
      <span :key="section + '_title'" class="section__header titleText">
        {{ $t('catalog-filter-desktop.' + section.toLowerCase()) }}
      </span>
      <div :key="section + '_select'" class="section grid">
        <div class="itemsContainer">
          <template v-for="filter in getSectionFilters(section)">
            <CatalogDropdownContainer
              v-if="filter !== 'sorting'"
              :key="filter"
              :name="allFilters[filter].name"
              :title="allFilters[filter].title"
              :options="allFilters[filter].options"
              :value="activeFilters[filter]"
              class="section__filter-row item"
              @input="(value) => handleSetFilter(filter, value)"
            />
            <div v-else :key="filter" class="section__filter-row item">
              <SortingDropdown
                :sorting="allFilters.sorting"
                :active-sorting="activeSorting"
                @input="(value) => handleSetFilter('sorting', value)"
              />
            </div>
          </template>
          <div
            v-if="!isShowingMore && sectionIndex === 1 && haveManyFiltetsToShow"
            class="showMore-button"
          >
            <Button
              icon="Plus"
              class="button--secondary show-more"
              :icon-width="13"
              :icon-height="13"
              :has-focus-border="false"
              :title="$t('catalog-filter-desktop.show-more')"
              :on-click="showMore"
            />
          </div>
        </div>
      </div>
    </template>
    <div v-if="!isEmpty(activeFilters)" class="activeSection">
      <span class="section__header titleText">
        {{ $t('catalog-filter-desktop.active') }}
      </span>
      <ActiveFilter
        :active-filters="activeFilters"
        :filters="allFilters"
        @click="handleRemoveFilter"
      />
    </div>
  </div>
</template>

<script>
import SortingDropdown from 'Components/02-molecules/sorting-dropdown/SortingDropdown';
import globalMixin from 'Libs/mixins/globalMixin';
import CatalogDropdownContainer from 'Components/03-organisms/catalog-dropdown-container/CatalogDropdownContainer.vue';
import ActiveFilter from 'Components/02-molecules/active-filter/ActiveFilter.vue';
import Button from 'Components/01-atoms/button/Button.vue';
import isEmpty from 'lodash/isEmpty';
import tail from 'lodash/tail';
import sortBy from 'lodash/sortBy';

export default {
  name: 'CatalogFilterDesktop',
  components: {
    SortingDropdown,
    CatalogDropdownContainer,
    Button,
    ActiveFilter,
  },
  mixins: [globalMixin],
  props: {
    filterSections: {
      type: Object,
      default: () => ({}),
    },
    allFilters: {
      type: Object,
      default: () => ({}),
    },
    activeFilters: {
      type: Object,
      default: () => ({}),
    },
    activeSorting: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isShowingMore: false,
    };
  },
  computed: {
    haveManyFiltetsToShow() {
      const filterSections = sortBy(Object.keys(this.filterSections));

      const totalFiltersCount = tail(filterSections).reduce(
        (totalFilters, sectionName) => {
          totalFilters += this.filterSections[sectionName].length;
          return totalFilters;
        },
        0
      );

      return totalFiltersCount > 5;
    },
    computedVisibleSections() {
      const filterSections = sortBy(Object.keys(this.filterSections));
      if (this.isShowingMore || !this.haveManyFiltetsToShow) {
        return filterSections;
      }

      return filterSections.slice(0, 2);
    },
  },
  methods: {
    getSectionFilters(section) {
      const filters = this.filterSections[section];
      if (this.isShowingMore) {
        return filters;
      }

      return filters.slice(0, 5);
    },
    showMore() {
      this.isShowingMore = true;
    },
    handleSetFilter(selectedFilterName, value) {
      if (selectedFilterName === 'sorting') {
        this.$emit('applyFilters', this.activeFilters, value);
        return;
      }

      this.$emit(
        'applyFilters',
        {
          ...this.activeFilters,
          [selectedFilterName]: value,
        },
        this.activeSorting
      );
    },
    handleRemoveFilter({ filterAlias, optionAlias }) {
      if (!this.activeFilters[filterAlias]) {
        return;
      }

      const newValue = this.activeFilters[filterAlias].filter(
        (v) => v !== optionAlias
      );

      this.$emit(
        'applyFilters',
        {
          ...this.activeFilters,
          [filterAlias]: newValue,
        },
        this.activeSorting
      );
    },
    isEmpty: isEmpty,
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.grid {
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  .itemsContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin: -4px 0 0 -4px;
    max-width: 100%;
    width: 100%;
    padding: 8px 0 4px;
  }

  .showMore-button,
  .item {
    flex: 1 1 100%;
    max-width: 210px;
    padding: 0 4px;
    margin-bottom: 12px;
  }

  button {
    width: 100%;
    max-height: 40px;
    margin: 0;
  }

  &:last-child {
    margin-bottom: 4px;
  }
}

.section__header {
  display: block;
  color: var(--color-nero);
  vertical-align: middle;
  font-size: var(--font-size-SM);
  line-height: 17px;
  font-weight: bold;
  font-family: 'Glacial Indifference';
}

.activeSection {
  border-top: 1px solid var(--color-alt);
  padding-top: 14px;

  & > div {
    margin-top: 8px;
  }

  @media #{$_mediaMUp} {
    margin-bottom: 10px;
  }
}

button.show-more ::v-deep .icon path {
  fill: var(--color-primary);
}
</style>
