import lowerFirst from 'lodash/lowerFirst';
import noop from 'lodash/noop';

let idCounter = 0;

export const formBaseMixin = {
  props: {
    value: {
      type: [String, Boolean, Number],
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    fullName: {
      type: String,
      default: '',
    },
    send: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    attr: {
      type: Object,
      default: () => ({}),
    },
    hideOptional: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    onEnter: {
      type: Function,
      default: noop,
    },
    onTab: {
      type: Function,
      default: noop,
    },
    maxLength: {
      type: Number,
      default: 255,
    },
  },
  data() {
    return {
      computedId: '',
    };
  },
  computed: {
    dynamicCssClasses() {
      return [
        this.buildClass(this.disabled, 'disabled'),
        this.buildClass(this.readonly, 'readonly'),
        this.buildClass(this.label, 'label'),
        this.buildClass(this.type === 'hidden', 'hidden'),
      ]
        .filter((v) => v !== '')
        .join(' ');
    },
  },
  beforeMount() {
    this.computedId = this.id || `${this.$options.name}_${++idCounter}`;
    if (this.errors && this.errors.length > 0) {
      this.addModifier('error');
    }

    this.$watch('errors', (errors) => {
      if (errors && errors.length > 0) {
        this.addModifier('error');
      } else {
        this.removeModifier('error');
      }
    });
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    blur() {
      this.$refs.input.blur();
    },
    setValidStyle() {
      this.addModifier('valid');
      return this;
    },
    removeValidStyle() {
      this.removeModifier('valid');
      return this;
    },
    handleInvalidInput(e) {
      this.addModifier('error');
      this.$emit('invalid', e);
    },
    handleBlur(e) {
      this.$emit('blur', e);
      this.removeModifier('focused');
    },
    handleFocus(e) {
      this.$emit('focus', e);
      this.addModifier('focused');
      this.removeModifier('error');
    },
    buildClass(value, modifier) {
      if (value) {
        return lowerFirst(this.$options.name) + '--' + modifier;
      }
      return '';
    },
  },
};
