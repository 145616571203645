import { render, staticRenderFns } from "./Availability.vue?vue&type=template&id=4ca00155&scoped=true"
import script from "./Availability.vue?vue&type=script&lang=js"
export * from "./Availability.vue?vue&type=script&lang=js"
import style0 from "./Availability.vue?vue&type=style&index=0&id=4ca00155&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ca00155",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F01-atoms%2Favailability%2FAvailability.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports