<template>
  <div class="wrapper">
    <ActiveFilterItem
      v-for="filter in mappedActiveFilters"
      :key="filter.value"
      :label="filter.label"
      :value="filter.value"
      @click="$emit('click', filter)"
    />
  </div>
</template>

<script>
import ActiveFilterItem from './ActiveFilterItem';

export default {
  name: 'ActiveFilter',
  components: {
    ActiveFilterItem,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    activeFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    mappedActiveFilters() {
      let mappedActiveFilters = [];
      for (let [filtersKey, value] of Object.entries(this.activeFilters)) {
        value.forEach((optionsKey) => {
          mappedActiveFilters.push({
            filterAlias: filtersKey,
            optionAlias: optionsKey,
            label: this.getLabel(filtersKey),
            value: this.getValue(filtersKey, optionsKey),
          });
        });
      }
      return mappedActiveFilters;
    },
  },
  methods: {
    getLabel(filtersKey, filters = this.filters) {
      if (this.hasFiltersKey(filtersKey)) {
        return filters[filtersKey].title;
      }
      this.logErrorMessage('noFiltersKey');
      return '';
    },
    getValue(filtersKey, optionsKey, filters = this.filters) {
      if (
        this.hasFiltersKey(filtersKey) &&
        this.hasOptionsKey(filtersKey, optionsKey)
      ) {
        return filters[filtersKey].options[optionsKey].title;
      }
      this.logErrorMessage('noFiltersOrOptionsKey');
      return '';
    },
    hasFiltersKey(filtersKey, filters = this.filters) {
      return filters[filtersKey] !== undefined;
    },
    hasOptionsKey(filtersKey, optionsKey, filters = this.filters) {
      return filters[filtersKey].options[optionsKey] !== undefined;
    },
    logErrorMessage(message) {
      // eslint-disable-next-line no-console
      console.error('[Error]:', message);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  position: relative;
  cursor: pointer;

  // scrollbar-color: transparent;
  // scrollbar-width: thin;

  // &::-webkit-scrollbar {
  //   width: 4px !important;
  //   background-color: transparent;
  //   color: transparent;
  // }
  // &::-webkit-scrollbar-thumb {
  //   // mozilla doesn't have support for border-radius yet
  //   border-radius: 2px;
  //   background-color: transparent;
  // }
  // &::-webkit-scrollbar-track {
  //   background-color: transparent;
  // }
}

@media #{$_mediaLUp} {
  .wrapper {
    flex-wrap: wrap;
    overflow-x: unset;
  }
}
</style>
