import { mapActions, mapState } from 'vuex';
import TrackingHandler from 'Libs/helpers/tracking';
import StringHelper from 'Libs/helpers/string';

const carisSelectionFormMixin = {
  data() {
    return {
      selections: {
        manufacturer: null,
        model: null,
        type: null,
      },
    };
  },
  computed: {
    ...mapState('carSelection', {
      manufacturers: (state) => state.manufacturers,
      models: (state) => state.models,
      types: (state) => state.types,
    }),
  },
  mounted() {
    if (
      this.fetchClusters &&
      (!this.manufacturers ||
        !this.manufacturers.cluster ||
        this.manufacturers.cluster.length === 0)
    ) {
      this.loadCarisData({ step: 0 });
    }
  },
  methods: {
    ...mapActions('carSelection', ['loadCarisData']),
    handleVehicleSelectionChange({ changedSelect, value }) {
      this.selections = { ...this.selections };

      // [Mina] disbaled due to a hotfix
      // if (this.fetchClusters) {
        if (changedSelect === 'manufacturer') {
          this.loadCarisData({ step: 1, option: value });
        } else if (changedSelect === 'model') {
          this.loadCarisData({ step: 2, option: value });
        } else {
          let modelValue;

          const labelArray = [
            ...(value.label ? [value.label] : []),
            ...(value.labels ? value.labels : []),
          ];

          modelValue = StringHelper.removeAllTags(labelArray[0]);
          modelValue = StringHelper.removeDoubleSpaces(modelValue);

          TrackingHandler.track({
            data: {
              event: 'identifyCar_model',
              page: {
                carModel: modelValue,
              },
            }
          });
        }
      // }

      if (changedSelect === 'type' && this.handleTypeChange) {
        this.handleTypeChange();
      }
    },
  },
};

export default carisSelectionFormMixin;
