<template>
  <div class="brand-category" :class="classes" :data-preload="[$options.name]">
    <div class="brand-category__container">
      <CarManufacturerHeader
        :manufacturer="brand"
        :products-count="productsCount"
      />

      <CategoriesRow
        v-if="subcategories.length > 0"
        :menu="{ menuItems: subcategories }"
        class="brand-category__list"
        source="SeoCarPage"
        :show-prod-count="false"
      />

      <CatalogCategoryFiltersWrapper v-if="!isEmpty" />

      <PlpProducts21 />

      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="description"
        class="brand-category__bottom-text"
        v-html="description"
      ></div>
      <!-- eslint-enable vue/no-v-html -->
    </div>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { mapState } from 'vuex';
import CarManufacturerHeader from 'Components/03-organisms/car-manufacturer-header/CarManufacturerHeader';
import CategoriesRow from 'Components/02-molecules/categories-row/CategoriesRow';
import PlpProducts21 from 'Components/03-organisms/plp-products-21/PlpProducts21';
import CatalogCategoryFiltersWrapper from 'Components/03-organisms/catalog-category-filters-wrapper/CatalogCategoryFiltersWrapper';
export default {
  name: 'BrandCategory',
  components: {
    CategoriesRow,
    CarManufacturerHeader,
    PlpProducts21,
    CatalogCategoryFiltersWrapper,
  },
  mixins: [globalMixin],
  props: {
    description: {
      type: String,
      default: '',
    },
    plp: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('plpFilters', {
      productsCount: (data) => data.count || 0,
      isEmpty: (data) => (data.count || 0) === 0,
    }),
    ...mapState('seoBrandCategories', {
      brand: (state) => state.data?.brand || {},
      subcategories: (state) => state.data?.subcategories || [],
      description: (state) => state.data?.description || '',
    }),
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.brand-category {
  width: 100%;

  @media #{$_mediaMDown} {
    margin-top: -33px;
  }
}

.plpProducts21 {
  margin-top: var(--space-3--half);
}

.brand-category__container {
  @media #{$_mediaMDown} {
    margin-top: 13px;
  }
}

.brand-category__header-text,
.brand-category__bottom-text {
  display: flex;

  @media #{$_mediaSDown} {
    display: block;
    margin-top: 0;
  }
}

.brand-category__list {
  margin-bottom: 0;
}

.catalogCategoryFiltersWrapper {
  margin-top: var(--space-3);
}

.brand-category__bottom-text {
  display: block;
  color: var(--color-hippo-darker);
  margin-top: var(--space-4);

  ::v-deep {
    h2,
    h3 {
      font-weight: bold;
      font-size: var(--font-size-ML);
      display: block;
      margin-bottom: var(--space-2);

      @media #{$_mediaMDown} {
        font-size: var(--font-size-MML);
        margin-top: var(--space-5);
      }
    }
  }

  ::v-deep p {
    font-weight: normal;
    font-size: var(--font-size-M);
    display: block;
    margin-bottom: var(--space-3);
    line-height: 30px;

    @media #{$_mediaMDown} {
      font-size: var(--font-size-M);
    }
  }
}
</style>
