export const getLayoutParams = ({ template = { layoutParams: {} } }) => {
  const {
    reducedHeader = false,
    reducedFooter = false,
    showNavigation = true,
    showMenu = true,
    showCaris = true,
    showPageTitle = false,
  } = template.layoutParams || {};

  return {
    reducedHeader,
    reducedFooter,
    showNavigation,
    showMenu,
    showCaris,
    showPageTitle,
  };
};

export const getSalesChannelConfig = ({ salesChannel: { config } }) => {
  return {
    ...config,
    carisHsnTsnSearch: config.carisHsnTsnSearch === 'true',
    carisLicenseSearch: config.carisLicenseSearch === 'true',
    carisNatCodeSearch: config.carisNatCodeSearch === 'true',
    carisRegNrSearch: config.carisRegNrSearch === 'true',
  };
};

export const getUrl = (state) => (urlKey) => {
  return state.fetchedUrls[urlKey];
};

export const isGermanSalesChannel = ({ salesChannel: { countryCode } }) => {
  const GERMAN_COUNTRY_CODE = 'DE';
  return countryCode === GERMAN_COUNTRY_CODE;
};

export const isBusinessCustomer = ({ requestData: { companyId } }) => {
  return typeof companyId === 'string' && companyId !== '';
};

export const customerServicePhoneNumber = ({
  salesChannel: { config },
  requestData: { companyId },
}) => {
  return companyId
    ? config.b2BCustomerServicePhoneNumber
    : config.customerServicePhoneNumber;
};

export const isDeshop = ({ salesChannel: { salesChannelId } }) => {
  const deshopSalesChannelId = 'www-kfzteile24-de';
  return salesChannelId === deshopSalesChannelId;
};

export const getCompanyInformation = ({
  requestData: {
    canOrderBulkyGoods,
    canOrderHazardousGoods,
    canOrderRiskyGoods,
    companyId,
    isDirectDelivery,
  },
}) => {
  return {
    canOrderBulkyGoods,
    canOrderHazardousGoods,
    canOrderRiskyGoods,
    companyId,
    isDirectDelivery,
  };
};

export const isProductPage = ({
  placeholders: {
    content: { component: componentName },
  },
}) => componentName === 'ProductDetails';

export const isMobileViewport = ({ viewport: { range } }) => {
  return range === 'mobile';
};

export const isRebrandingChannel = ({ salesChannel: { salesChannelId } }) => {
  const rebrandingEnabledSalesChannels = [
    'app_android-kfzteile24-de',
    'app_ios-kfzteile24-de',
  ];
  return rebrandingEnabledSalesChannels.includes(salesChannelId);
};

export const isRebrandingPage = ({
  fetchedUrls,
  requestData: { requestUrl },
}) => {
  const checkoutLoginPageUrlKey = 'checkout.checkout.login';
  const rebrandingEnabledLocations = [fetchedUrls[checkoutLoginPageUrlKey]];
  return rebrandingEnabledLocations.some((enabledLocation) =>
    requestUrl.includes(enabledLocation)
  );
};
