<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <form
      class="caris-license-new-header caris-license__form form"
      @submit.prevent="doLicenseRequest"
    >
      <div class="caris-license-new-header_label">
        {{ $t('caris-license-new-header.headline') }}
      </div>
      <div class="caris-license-new-header_license-plate-wrapper">
        <!-- licensePlate is Disabled for FR -->
        <template v-if="countryCode !== 'FR'">
          <div class="caris-license-new-header_license-plate-EU">
            <Icon
              class="caris-license-new-header_eu-icon"
              name="eu_stars"
              :original="true"
              :inline="false"
              :lazy="true"
            />
            <span class="caris-license-new-header_country-code">{{
              plateIdentifier
            }}</span>
          </div>
          <InputBase
            ref="licensePlate"
            :class="[
              'caris-license-new-header_license-plate',
              { NL: countryCode === 'NL' },
            ]"
            :gtm-class-name="'gtm--caris-license-new-header-licensePlate'"
            :errors="errors"
            :placeholder="
              $t('caris-license-new-header.license_plate.placeholder')
            "
            :send="true"
            full-name="plate_nr"
            :hide-optional="true"
            type="text"
            :value="form.licensePlateValue"
            :on-enter="doLicenseRequest"
            :attr="{
              autocomplete: 'off',
              autocorrect: 'off',
              autocapitalize: 'off',
              spellcheck: 'false',
            }"
            @input="handleChange"
          />
        </template>
        <div class="caris-license-new-header_fields">
          <CarisDropdown
            ref="multiCarDropdown"
            :class="[
              'caris-license-new-header_field',
              'caris-license-new-header_field--full',
            ]"
            :disabled="Object.keys(carList).length === 0"
            :car-list="carList"
            :placeholder="
              $t('caris-license-new-header.type_mine.dropdown_label')
            "
          />
        </div>
        <CarisTypeMineSearch
          v-if="isMobile"
          ref="mine-search"
          class="caris-license-new-header_form__mine-search"
        />
        <div v-else>
          <div class="caris-license-new-header_button-wrapper">
            <button
              class="caris-license-new-header_where-to-find-button"
              :class="[qaClassName + 'whereToFindButton']"
              type="button"
            >
              {{ $t('caris-license-new-header.gray_cart') }}
            </button>
          </div>
          <Button
            v-show="action"
            class="caris-license-new-header_form__select-car-button gtm--caris-license-new-header-selectCarButton"
            :class="qaClassName + 'selectCarButton'"
            :title="$t('caris-license-new-header.select_new_car')"
            modifier="tertiary"
            type="submit"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ktypnrMixin } from 'Libs/mixins/ktypnrMixin.js';
import Icon from 'Components/00-generated/Icon';
import Button from 'Components/01-atoms/button/Button.vue';
import InputBase from 'Components/01-atoms/form/input/InputBase.vue';
import CarisDropdown from 'Components/02-molecules/caris-dropdown/CarisDropdown.vue';
import globalMixin from 'Libs/mixins/globalMixin';
import qaClassNameMixin from 'Libs/mixins/qaClassNameMixin';
import CarisTypeMineSearch from 'Components/03-organisms/caris-type-mine-search/CarisTypeMineSearch.vue';
import debounce from 'lodash/debounce';

export default {
  name: 'CarisLicenseNewHeader',
  components: {
    InputBase,
    CarisDropdown,
    Icon,
    Button,
    CarisTypeMineSearch,
  },
  mixins: [globalMixin, ktypnrMixin, qaClassNameMixin],
  props: {
    disabled: {
      type: Boolean,
    },
    action: {
      type: Boolean,
      default: true,
    },
    parentForm: {
      type: Object,
      default: null,
    },
    validateForm: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      value: '',
      carList: {},
      errors: [],

      insideForm: {
        licensePlateValue: null,
      },
      isMobile: false,
      viewportWidth: null,
    };
  },
  computed: {
    ...mapState('core', {
      countryCode({ salesChannel }) {
        return salesChannel.countryCode;
      },
    }),
    form() {
      return this.parentForm || this.insideForm;
    },
    plateIdentifier() {
      return this.countryCode === 'FR' ? 'F' : this.countryCode;
    },
  },
  watch: {
    viewportWidth(newValue) {
      if (newValue <= 768 && this.isCollapsible) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    this.updateViewport();
    window.addEventListener('resize', debounce(this.updateViewport, 150));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateViewport);
  },
  methods: {
    ...mapActions('carSelection', ['performFindCarRequest']),
    validateLicensePlate(value) {
      const plate = value.replace(/[-\s]+/gi, '');
      let result;
      switch (this.countryCode) {
        case 'NL':
          result = plate.length === 6;
          break;
        case 'FR':
          result = plate.length <= 8;
          break;
        case 'DK':
          result = /^[a-zA-Z]{2}[0-9]{5}$/.test(plate) && plate.length === 7;
          break;
        default:
          result = true;
      }
      return result;
    },
    handleChange(value) {
      this.errors = [];

      this.form.licensePlateValue = value;
    },
    doLicenseRequest() {
      this.$emit('select');

      if (!this.validateForm) {
        return;
      }
      // licensePlate is Disabled for FR
      if (this.countryCode === 'FR') {
        return;
      }

      this.carList = {};
      const numberPlate = this.form.licensePlateValue
        .toUpperCase()
        .replace(/[-\s]+/gi, '');

      if (numberPlate && this.validateLicensePlate(numberPlate)) {
        this.identificationMethod = 'plate';

        this.performFindCarRequest({
          numberPlate,
          callbacks: {
            onsingle: this.setKtypnr.bind(this),
            onmultiple: (cars) => {
              this.carList = cars;
              this.$refs.multiCarDropdown.open();
            },
            onerror: (message) => {
              this.identificationMethod = undefined;
              if (this.errors.every((error) => error.message !== message)) {
                this.errors.push({ message: message });
              }
            },
          },
        });
      } else {
        if (numberPlate) {
          this.$refs.licensePlate.handleInvalidInput();
        }
      }
    },
    updateViewport() {
      this.viewportWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.caris-license-new-header_caris-license-plate-search {
  width: 100%;
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  min-width: 312px;
}

.caris-license-new-header_label {
  width: 100%;
  margin: 0 var(--space-0--half) var(--space-1);
  font-size: var(--font-size-MML);
  font-weight: 700;
  color: var(--color-secondary);
  letter-spacing: -0.16px;
}

.caris-license-new-header_license-plate-wrapper {
  width: 100%;
  margin-top: 10px;
  position: relative;
  margin-left: var(--space-0--half);
}

.caris-license-new-header_license-plate-EU {
  background-color: var(--color-chathamsblue);
  border-top-left-radius: var(--border-radius-default);
  border-bottom-left-radius: var(--border-radius-default);
  position: absolute;
  height: 32px;
  width: 30px;
  z-index: 1;
}

.caris-license-new-header_eu-icon {
  display: block;
  margin: var(--space-1) auto var(--space-0--half);
  width: 10px;
  height: 8px;
}

.caris-license-new-header_country-code {
  display: block;
  color: var(--color-white);
  text-align: center;
  font-weight: bold;
  font-size: 10px;
}

.caris-license-new-header_license-plate {
  margin-left: var(--space-2--half);
  padding-top: 0 !important;
  font-size: var(--font-size-SM) !important;
}

.caris-license-new-header_fields {
  display: flex;
  padding: 0 var(--space-1);
}

.caris-license-new-header_field {
  width: 50%;
  padding: 0 var(--space-1) 0;

  &--full {
    width: 100%;
  }
}

.caris-license-new-header_license-plate ::v-deep {
  input[type='text'] {
    height: 32px;
  }

  .input--focused:not(.input--iconLeft) .label {
    transform: translate3d(-5px, -23px, 0) scale(0.75);
  }
}

.caris-license-new-header_button-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  line-height: 20px;
}

.caris-license-new-header_where-to-find-button {
  display: none;
}

.caris-license-new-header_form__select-car-button {
  visibility: visible !important;
  width: 100%;
  font-size: var(--space-1--half);
  margin-top: var(--space-0--half);
  min-height: 35px;
}

.caris-license-new-header_form__mine-search {
  ::v-deep .input.input--label {
    padding-top: 0;
  }
}

@media #{$_mediaMDown} {
  .caris-license-new-header_form__mine-search::v-deep {
    margin-top: var(--space-1--half);
  }
}
</style>
