<i18n src="./translations"></i18n>
<template>
  <client-only>
    <transition
      enter-class="from-right-initial"
      enter-to-class="from-right-to"
      enter-active-class="from-right-active"
      leave-class="to-right-initial"
      leave-to-class="to-right-to"
      leave-active-class="to-right-active"
    >
      <div v-show="open" :class="classes" :data-preload="[$options.name]">
        <div class="header">
          <CatalogFilterMobileTitle
            :title="
              showRoot
                ? $t('catalog-filter-mobile.filter')
                : allFilters[selectedFilterName].title
            "
          />

          <transition
            enter-class="fadeIn-initial"
            enter-to-class="fadeIn-to"
            enter-active-class="fateIn-active"
            leave-class="fadeOut-initial"
            leave-to-class="fadeOut-to"
            leave-active-class="fadeOut-active"
          >
            <Button
              v-if="showClearButton"
              class="header__clear-btn qa-catalogFilters__clear"
              modifiers="['secondary', 'short']"
              :title="$t('catalog-filter-mobile.reset')"
              :on-click="handleClear"
            />
          </transition>
        </div>

        <transition
          enter-class="from-left-initial"
          enter-to-class="from-left-to"
          enter-active-class="from-left-active"
          leave-active-class="stay"
        >
          <div v-show="showRoot" class="body sectionsView">
            <div class="body__content">
              <div v-show="hasActiveFilters" class="active-filters">
                <ActiveFilter
                  :filters="allFilters"
                  :active-filters="activeFilters"
                  @click="handleRemoveFilter"
                />
              </div>

              <CatalogFilterMobileSections
                :filter-sections="sections"
                :all-filters="allFilters"
                :active-filters="activeFilters"
                :active-sorting="activeSorting"
                @click="goToFilter($event)"
              />
            </div>
            <div class="body__footer">
              <Button
                v-if="showSubmitButton"
                class="button--primary submit qa-catalogFilters__submit"
                modifier="full"
                :title="$t('catalog-filter-mobile.showItems')"
                :on-click="handleSubmit"
              />
              <Button
                v-else
                class="button--secondary back qa-catalogFilters__close"
                modifier="full"
                :title="$t('catalog-filter-mobile.close')"
                :on-click="handleClose"
              />
            </div>
          </div>
        </transition>

        <transition
          enter-class="from-right-initial"
          enter-to-class="from-right-to"
          enter-active-class="from-right-active"
          leave-active-class="stay"
        >
          <div v-show="!showRoot" class="body filterView">
            <div class="body__content">
              <CatalogFilterOptionList
                v-if="selectedFilterName !== 'sorting'"
                :key="selectedFilterName"
                modifier="withBorders"
                :value="dirtyFilterValue"
                :options="
                  allFilters[selectedFilterName] &&
                  allFilters[selectedFilterName].options
                "
                @input="handleFilterChange"
              />
              <SortingOptionList
                v-else
                :key="selectedFilterName"
                :active-option="dirtyFilterValue"
                :options="
                  allFilters[selectedFilterName] &&
                  allFilters[selectedFilterName].options
                "
                @input="handleFilterChange"
              />
            </div>
            <div class="body__footer">
              <Button
                v-if="showSaveButton"
                class="button--primary apply qa-catalogFilters__apply"
                modifier="full"
                :title="$t('catalog-filter-mobile.apply')"
                :on-click="handleSaveFilter"
              />
              <Button
                v-else
                class="button--secondary back qa-catalogFilters__back"
                modifiers="full"
                :title="$t('catalog-filter-mobile.back')"
                :on-click="goToSections"
              />
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </client-only>
</template>
<script>
import Button from 'Components/01-atoms/button/Button';
import globalMixin from 'Libs/mixins/globalMixin';
import ClientOnly from 'vue-client-only';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import sortBy from 'lodash/sortBy';
import head from 'lodash/head';
import cloneDeep from 'lodash/cloneDeep';
import CatalogFilterMobileSections from 'Components/01-atoms/catalog-filter-mobile-sections/CatalogFilterMobileSections.vue';
import CatalogFilterOptionList from 'Components/02-molecules/catalog-filter-option-list/CatalogFilterOptionList.vue';
import CatalogFilterMobileTitle from './CatalogFilterMobileTitle.vue';
import ActiveFilter from 'Components/02-molecules/active-filter/ActiveFilter.vue';
import SortingOptionList from 'Components/01-atoms/sorting-option-list/SortingOptionList';
import scrollMixin from 'Libs/mixins/scrollMixin';

export default {
  name: 'CatalogFilterMobile',
  components: {
    Button,
    ClientOnly,
    CatalogFilterMobileTitle,
    CatalogFilterMobileSections,
    CatalogFilterOptionList,
    ActiveFilter,
    SortingOptionList,
  },
  mixins: [globalMixin, scrollMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    sections: {
      type: Object,
      default: () => ({}),
    },
    allFilters: {
      type: Object,
      default: () => ({}),
    },
    storedActiveFilters: {
      type: Object,
      default: () => ({}),
    },
    storedActiveSorting: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showRoot: true,
      selectedFilterName: '',

      activeSorting: {},
      activeFilters: {},

      dirtyFilterValue: null,
    };
  },
  computed: {
    hasActiveFilters() {
      return Object.keys(this.activeFilters).find(
        (filterName) => !isEmpty(this.activeFilters[filterName])
      );
    },
    showSubmitButton() {
      // remove filters with empty values before comparing them
      const mappedActiveFilters = omitBy(this.activeFilters, (values) => {
        return isEmpty(values);
      });

      return (
        !isEqual(mappedActiveFilters, this.storedActiveFilters) ||
        !isEqual(this.activeSorting, this.storedActiveSorting)
      );
    },
    showSaveButton() {
      if (this.selectedFilterName === 'sorting') {
        return !isEqual(this.activeSorting, this.dirtyFilterValue);
      }

      return !isEqual(
        sortBy(this.activeFilters[this.selectedFilterName]),
        sortBy(this.dirtyFilterValue)
      );
    },
    showClearButton() {
      if (this.showRoot) {
        // remove filters with empty values before comparing them
        const mappedActiveFilters = omitBy(this.activeFilters, (values) => {
          return isEmpty(values);
        });

        return (
          !isEmpty(mappedActiveFilters) ||
          !isEqual(this.activeSorting, this.storedActiveSorting)
        );
      }

      if (this.selectedFilterName === 'sorting') {
        return !isEqual(this.activeSorting, this.dirtyFilterValue);
      }

      return !isEmpty(this.dirtyFilterValue);
    },
  },
  mounted() {
    this.$watch(
      'storedActiveFilters',
      (v) => {
        this.activeFilters = cloneDeep(v);
      },
      { immediate: true }
    );
    this.$watch(
      'storedActiveSorting',
      (v) => {
        this.activeSorting = cloneDeep(v);
      },
      { immediate: true }
    );
    this.$watch(
      'open',
      (v) => {
        this.toggleBodyScrolling(!v);
      },
      { immediate: true }
    );
  },
  methods: {
    goToSections() {
      this.showRoot = true;
    },
    goToFilter(name) {
      this.showRoot = false;
      this.selectedFilterName = name;
      if (name === 'sorting') {
        this.dirtyFilterValue = this.activeSorting;
      } else {
        this.dirtyFilterValue = this.activeFilters[name];
      }
    },
    handleFilterChange(value) {
      this.dirtyFilterValue = value;
    },
    handleSaveFilter() {
      if (this.selectedFilterName === 'sorting') {
        this.activeSorting = this.dirtyFilterValue;
        this.goToSections();
        return;
      }

      this.$set(
        this.activeFilters,
        this.selectedFilterName,
        this.dirtyFilterValue
      );
      this.goToSections();
    },
    handleClear() {
      if (this.showRoot) {
        this.activeFilters = {};
        this.activeSorting = head(
          Object.values(this.allFilters.sorting.options)
        );
        return;
      }

      if (this.selectedFilterName === 'sorting') {
        this.dirtyFilterValue = this.activeSorting;
        return;
      }

      this.dirtyFilterValue = [];
    },
    handleRemoveFilter({ filterAlias, optionAlias }) {
      if (!this.activeFilters[filterAlias]) {
        return;
      }

      const newValue = this.activeFilters[filterAlias].filter(
        (v) => v !== optionAlias
      );
      this.$set(this.activeFilters, filterAlias, newValue);
    },
    handleClose() {
      this.$emit('close');
      this.activeSorting = this.storedActiveSorting;
      this.activeFilters = cloneDeep(this.storedActiveFilters);
    },
    handleSubmit() {
      this.$emit('applyFilters', this.activeFilters, this.activeSorting);
    },
  },
};
</script>
<style scoped lang="scss">
@import 'mixins';
@import 'animations';

.from-right-active {
  z-index: 2;
  transition: transform 0.4s ease-in;
  box-shadow: -15px 0 20px -15px var(--color-black--disabled);
}

.to-right-active {
  transition: transform 0.4s ease-in;
  box-shadow: -15px 0 20px -15px var(--color-black--disabled);
}

.from-left-active {
  z-index: 2;
  transition: transform 0.4s ease-in;
  box-shadow: 15px 0 10px -15px var(--color-black--disabled);
}

.stay {
  z-index: 1;
  transition: transform 0.4s ease-in;
}

.catalogFilterMobile {
  height: 100%;

  z-index: 7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: var(--color-white);
}

.header {
  background: var(--color-wild-sand);
  width: 100%;
  height: 50px;
  padding: 0 12px 0 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid var(--color-alt);
}

.active-filters {
  background: var(--color-wild-sand);
  display: flex;
  align-items: center;
  height: 55px;
  border-bottom: 4px solid var(--color-alt);

  ::v-deep {
    & > * {
      padding: 11px 0;
      margin: 0 12px;
    }
  }
}

.body {
  overflow: hidden;

  display: flex;
  flex-direction: column;

  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;

  &__content {
    background: var(--color-white);

    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__footer {
    background: var(--color-wild-sand);
    height: 65px;
    padding: 12px;

    border-top: 1px solid var(--color-alto);

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
