<template>
  <div :class="classes" :data-preload="[$options.name]">
    <div
      v-for="(item, index) in items"
      :key="index"
      :class="['itemWrap', { 'itemWrap--bordered': items.length === 4 }]"
    >
      <UspItem v-bind="item" />
    </div>
  </div>
</template>

<script>
import UspItem from 'Components/01-atoms/usp-item/UspItem';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'CmsUsp',
  components: { UspItem },
  mixins: [globalMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
    updatedAt: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  serverCacheKey: ({ id, updatedAt }) => {
    /* istanbul ignore next */
    return `${id}::${updatedAt}`;
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.cmsUsp {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: stretch;
  padding: var(--space-2) 0;

  &--bordered {
    border-top: 1px solid var(--color-alto);
    position: relative;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      border-top: 1px solid var(--color-alto);
      position: absolute;
      top: -1px;
      left: -100%;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      border-top: 1px solid var(--color-alto);
      position: absolute;
      left: 100%;
      top: -1px;
    }
  }
}

.itemWrap {
  flex: 0 0 25%;

  &--bordered {
    border: solid 1px transparent;

    &:nth-of-type(1) {
      border-left-color: transparent;
      border-top-color: transparent;
    }

    &:nth-of-type(2) {
      border-right-color: transparent;
      border-top-color: transparent;
    }

    &:nth-of-type(3) {
      border-left-color: transparent;
      border-bottom-color: transparent;
    }

    &:nth-of-type(4) {
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
  }
}

@media #{$_mediaMDown} {
  .cmsUsp--bordered {
    border-top-color: transparent;

    &::before {
      border-top-color: transparent;
    }

    &::after {
      border-top-color: transparent;
    }
  }
}

@media #{$_mediaSDown} {
  .cmsUsp {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .itemWrap {
    flex: 1 0 50%;
  }

  .itemWrap--bordered {
    border: solid 1px var(--color-wild-sand);
  }
}
</style>
