<i18n src="Components/01-atoms/form/input/translations"></i18n>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <div :class="[classes, dynamicCssClasses]" :data-preload="[$options.name]">
    <div class="inputWrapper">
      <label :class="['label']" :for="computedId">
        <input
          :id="computedId"
          ref="input"
          type="checkbox"
          :checked="value"
          class="checkboxEl"
          :name="send ? fullName : ''"
          :disabled="disabled"
          :readonly="readonly"
          :required="required"
          v-bind="attr"
          @change="onChange"
          @focus="handleFocus"
          @blur="handleBlur"
        />
        <span
          :class="[
            'checkmark',
            'qa-Checkbox__checkbox',
            { 'checkmark--checked': value },
          ]"
        >
          <CheckedSvg class="checkedSvg" width="12" height="10" />
        </span>
        <span v-if="escapeContent" class="label__value">{{ label }}</span>
        <span v-if="!escapeContent" class="label__value" v-html="label" />
        <template v-if="!required && hideOptional === false"
          >({{ $t('input.optional') }})</template
        >
      </label>
    </div>
    <div v-if="!!text && text !== ''" :class="['info']">
      {{ text }}
    </div>
    <div
      v-for="error in errors"
      :key="error.message"
      :class="['error', 'qa-Input__error']"
    >
      {{ error.message }}
    </div>
  </div>
</template>

<script>
import { formBaseMixin } from 'Libs/mixins/formBaseMixin.js';
import globalMixin from 'Libs/mixins/globalMixin';
import CheckedSvg from 'Components/00-generated/CheckedSvg.vue';

export default {
  name: 'Checkbox',
  components: {
    CheckedSvg,
  },
  mixins: [globalMixin, formBaseMixin],
  props: {
    text: {
      type: String,
      default: '',
    },
    escapeContent: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(event) {
      this.removeModifier('error');
      this.$emit('input', event.target.checked);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

input {
  opacity: 0;
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin-left: -16px;
}

label {
  padding: 0;
  margin: 0;
  background-color: transparent;
  background-clip: content-box;
  transition: all var(--time-S);
  vertical-align: middle;
  line-height: 20px;
  color: var(--color-mine-shaft);
  display: flex;
  align-items: center;
}

.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  border: 2px var(--color-rolling-stone) solid;
  border-radius: 3px;
  transition:
    background-color var(--time-XS) ease-in-out,
    border-color var(--time-XS) ease-in-out,
    box-shadow var(--time-XS) ease-in-out;

  .checkedSvg {
    display: block;
    opacity: 0;
  }

  &--checked {
    background-color: var(--color-primary);
    border-color: var(--color-primary);

    .checkedSvg {
      opacity: 1;
      fill: white;
    }
  }
}

.checkbox--bold-gray {
  label {
    color: var(--color-rolling-stone);
    font-size: var(--font-size-SM);
    font-weight: 700;
  }
}

div.info,
div.error {
  font-size: var(--font-size-S);
  line-height: 16px;
  margin-left: 27px;
  margin-top: 2px;
}

div.info {
  color: var(--color-rolling-stone);
}

.checkbox {
  &--disabled {
    label {
      pointer-events: none;
      color: var(--color-silver);

      .checkmark {
        border: 2px var(--color-silver) solid;

        &.checkmark--checked {
          background-color: var(--color-silver);
          border-color: var(--color-silver);
        }
      }
    }
  }

  &--error {
    color: var(--color-red-ribbon);

    label {
      color: var(--color-red-ribbon);
    }

    .checkmark {
      &.checkmark--checked {
        background-color: var(--color-red-ribbon);
        border-color: var(--color-red-ribbon);
      }
    }
  }
}

.checkbox--theme-spacing {
  &.checkbox {
    margin-bottom: 40px;
    font-size: 15px;

    .checkmark {
      margin-right: 26px;
      color: var(--color-black);
    }

    .label__value {
      color: var(--color-black);
    }

    div.info {
      margin-left: 46px;
      font-size: var(--font-size-SM);
      line-height: 16px;
      color: var(--color-black);
    }

    div.inputWrapper {
      color: var(--color-black);
      font-size: var(--font-size-SM);
    }

    &.checkbox--disabled {
      .label__value {
        opacity: 0.6;
      }

      .info {
        opacity: 0.6;
      }
    }
  }

  @media #{$_mediaMDown} {
    &.checkbox {
      margin-bottom: 18px;
    }
  }
}
</style>
