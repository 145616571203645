export default class DeviceHelper {
  isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }
  isIosSafari() {
    const isSafari = !!navigator.userAgent.match(
      /^((?!Chrome|Android).)*Safari/
    );
    const iOS = this.isIOS();

    return isSafari && iOS;
  }
  isChrome() {
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    return isChrome;
  }
  isFirefox() {
    const isFirefox = /Firefox/.test(navigator.userAgent);
    return isFirefox;
  }
  /* istanbul ignore next */
  isIE11() {
    return !!window.MSInputMethodContext && !!document.documentMode;
  }
}
