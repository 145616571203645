
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="ShippingTruckSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="none" d="M0 0h32v32H0z"/><path fill="#545456" d="M28 14.683l-2.735-7.3h-4.787V5.995H4.743v12.47H4v1.384h2.18a3.873 3.873 0 00-.066.694 3.414 3.414 0 006.517 1.47 3.505 3.505 0 00.254-.78 5.487 5.487 0 00.081-.694h3.962a1.591 1.591 0 011.5-.69h1.438a3.872 3.872 0 00-.07.694 3.421 3.421 0 106.841 0 3.583 3.583 0 00-.07-.694h1.437zm-16.538 6.546a2.045 2.045 0 01-3.981-.69 1.961 1.961 0 01.122-.694 1.666 1.666 0 01.413-.69 2.009 2.009 0 013.037 0 1.7 1.7 0 01.409.69 2.028 2.028 0 010 1.384zm11.75 1.384a2.06 2.06 0 01-2.053-2.074 1.961 1.961 0 01.125-.694 2.035 2.035 0 013.855 0 1.885 1.885 0 01.125.694 2.06 2.06 0 01-2.052 2.074zm3.188-7.889a4.513 4.513 0 01-1.036-.186 8.127 8.127 0 00-2.211-.231h-1.994l-.026-5.535h3.214l2.053 5.535z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'ShippingTruckSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
