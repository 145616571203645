export const WISHLIST_UPDATE = (state, data) => {
  if (data && data.wishlist && state.data) {
    state.data.wishlist = data.wishlist;
    state.data.isWished = true;
  }
};

export const SET_WISHLIST_ID = (state, wishlistId) => {
  state.wishlistId = wishlistId;
};

export const SET_ROWS = (state, rows) => {
  state.rows = rows;
};
