export default class StringHelper {
  static removeTags(str, tags) {
    const tagsArray = typeof tags === 'string' ? [tags] : [...tags];
    const expression = new RegExp(
      `<\\/?(${tagsArray.join('|')})\\s*\\/?>`,
      'g'
    );

    return str.replace(expression, '');
  }

  static removeAllTags(str) {
    return str?.replace(/<\/?[^>]+(>|$)/g, '');
  }

  static removeDoubleSpaces(str) {
    return str?.replace(/ +(?= )/g, '');
  }

  static escapeString(string) {
    return string.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
  }

  static getSafeRegEx(string) {
    return new RegExp(this.escapeString(string), 'gi');
  }
}
