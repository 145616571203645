<i18n src="./translations"></i18n>

<template>
  <PdpTabBase
    class="pdp-hints-tab"
    :headline="$t('pdp-hints-tab.headline')"
    :class="classes"
    :data-preload="[$options.name]"
    :is-expanded="activeTab"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="pdp-hints-tab__content"
      v-html="$t(`pdp-hints-tab.content.${articleType}`)"
    />
  </PdpTabBase>
</template>

<script>
import PdpTabBase from 'Components/01-atoms/pdp-tab-base/PdpTabBase';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'PdpHintsTab',
  components: { PdpTabBase },
  mixins: [globalMixin],
  props: {
    articleType: {
      type: String,
      default: '',
      required: true,
    },
    activeTab: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

::v-deep {
  .pdp-hints-tab__content {
    font-size: var(--font-size-M);
    line-height: var(--font-size-MLL);

    a {
      color: var(--color-secondary);
      text-decoration: underline;
    }

    p + p {
      margin-top: 1em;
    }
  }
}

@media #{$_mediaMDown} {
  ::v-deep {
    .pdp-tab-base__container {
      padding: var(--space-2);
    }
  }
}
</style>
