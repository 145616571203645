import { render, staticRenderFns } from "./BundleProduct.vue?vue&type=template&id=05ee2968&scoped=true"
import script from "./BundleProduct.vue?vue&type=script&lang=js"
export * from "./BundleProduct.vue?vue&type=script&lang=js"
import style0 from "./BundleProduct.vue?vue&type=style&index=0&id=05ee2968&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05ee2968",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F02-molecules%2Fbundle-product%2FBundleProduct.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "Components/03-organisms/product-details-21/translations?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F02-molecules%2Fbundle-product%2FBundleProduct.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports