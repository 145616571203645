<script>
import globalMixin from 'Libs/mixins/globalMixin';

/*
  [ozlui] This component will be redundant when a switch to Vue3 made.
  But for now this component seems like the best way to insert multiple
  nodes into component root.
  Main usage of this component is for rendering elements without a wrapper
  and ability to insert multiple elements into a root of a component.
  (Which can be observed in Icon generation file svg.js)

  Vue3 support for multi-node root: https://v3.vuejs.org/guide/migration/fragments.html
 */

export default {
  name: 'Fragment',
  functional: true,
  mixins: [globalMixin],
  render(h, ctx) {
    return ctx.children;
  },
};
</script>
