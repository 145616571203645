<i18n src="./translations"></i18n>

<template>
  <div
    :class="[classes, { isNewFiltersLayout: showPlpTitle }]"
    :data-preload="[$options.name]"
  >
    <div v-if="showNewTitle && searchTerm" class="searchingTerm">
      {{ $t('page-title.searchResult') }}:
    </div>
    <h1
      :class="[
        'title',
        'qa-PageTitle',
        { 'title--reverse': theme === 'kfzteile-com' },
        { 'title--short': !!searchTerm && searchTerm.length < 4 },
        { 'title--plp': showPlpTitle },
      ]"
    >
      {{ showNewTitle && searchTerm ? `„${searchTerm}”` : title }}
    </h1>

    <div
      v-if="showNewTitle && productsCount > 0"
      :class="['productsCount', { 'productsCount--plp': showPlpTitle }]"
    >
      ({{ productsCount }}
      {{ showPlpTitle ? $t('page-title.parts') : $t('page-title.articles') }}
      <span v-if="carShortName && showPlpTitle">
        {{ $t('page-title.for') }} {{ carShortName }}</span
      >)
    </div>

    <div v-if="subtitle" class="subtitle">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';
import get from 'lodash/get';

export default {
  name: 'PageTitle', // Mostly used as [SearchPageTitle]
  mixins: [globalMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    isSearchPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('core', {
      theme: ({ salesChannel }) => salesChannel.theme,
      searchTerm: ({ requestData }) =>
        get(requestData, 'queryParams.search[0]', ''),
      isNewFilterSectionEnabled: ({ salesChannel: { config } }) =>
        config.isNewFilterSectionEnabled || false,
    }),
    ...mapState('productsList', {
      isPlpOrSearchPage: (state) => {
        const type = get(state, 'data.plp.pageType');
        return ['productListing', 'search'].indexOf(type) !== -1;
      },
      isPlp: (state) => {
        const type = get(state, 'data.plp.pageType');
        return type === 'productListing';
      },
    }),
    ...mapState('plpFilters', {
      productsCount: (state) => get(state, 'count', 0),
    }),
    ...mapState('carSelection', {
      carShortName: (state) => {
        let name =
          !!state.data.vehicle &&
          state.data.vehicle.manufacturer + ' ' + state.data.vehicle.model;
        if (!!state.data.vehicle && !!state.data.vehicle.generation) {
          name += ' ' + state.data.vehicle.generation;
        }
        return name;
      },
    }),
    showNewTitle() {
      return this.isPlpOrSearchPage;
    },
    showPlpTitle() {
      return this.isPlp && this.isNewFilterSectionEnabled;
    },
  },
  mounted() {
    if (this.isSearchPage) {
      this.trackSearchResult({
        searchKeywords: this.searchTerm || this.title,
        numberOfSearchResults: this.productsCount,
      });
    }

    if (this.isPlpOrSearchPage) {
      this.trackResultItemCount(this.productsCount);
    }
  },
  methods: {
    ...mapActions('dataLayer', ['trackSearchResult', 'trackResultItemCount']),
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

$pageTitleBottom: 8px;

.pageTitle {
  width: 100%;
  display: flex;
  align-items: baseline;

  &.isNewFiltersLayout {
    @media #{$_mediaMDown} {
      display: block;
    }
  }

  &:not(.pageTitle--embedded) {
    @include pageWidth;

    padding-top: 8px;
    padding-bottom: 4px;
  }

  &--embedded {
    flex-shrink: 0;
  }

  &--car-title {
    flex-shrink: initial;
    padding: 0 320px 0 0;

    @media #{$_mediaMDown} {
      margin-bottom: var(--space-1);
      padding-right: 0;
    }
  }
}

.searchingTerm {
  flex: 0 2 auto;
  align-self: center;
  margin-bottom: $pageTitleBottom;

  font-size: var(--font-size-M);
  font-weight: bold;
  color: var(--color-nero);
  line-height: 1.3;
  margin-right: 10px;
}

.title {
  color: var(--color-nero);

  flex: 0 0 auto;
  align-self: center;
  margin-top: 0;
  margin-bottom: $pageTitleBottom;

  padding-right: var(--space-1);
  font-size: 18px;
  font-weight: bold;
  line-height: normal;

  text-overflow: ellipsis;
  overflow: hidden;

  &--plp {
    color: var(--color-nero);
    padding-right: var(--space-2);
    font-size: var(--font-size-L);
  }

  &--reverse {
    color: var(--color-nero);
  }

  &--short {
    text-overflow: clip;
  }
}

.productsCount {
  flex: 0 2 90px;
  align-self: center;
  white-space: nowrap;
  margin-bottom: $pageTitleBottom;

  font-size: var(--font-size-M);
  color: var(--color-rolling-stone);
  line-height: normal;

  &--plp {
    color: var(--color-nero);
    margin-bottom: 5px;

    @media #{$_mediaMDown} {
      font-size: var(--font-size-SM);
      margin-bottom: 11px;
    }
  }
}

.subtitle {
  display: none;

  font-size: var(--font-size-SM);
  line-height: normal;
  color: var(--color-nero);

  flex: 0 2 90px;
  align-self: center;
  margin-bottom: $pageTitleBottom;
}

.pageTitle--wrapping {
  flex-wrap: wrap;

  .title {
    flex-shrink: 1;
  }
}

@media #{$_mediaMUp} {
  .pageTitle {
    &:not(.pageTitle--embedded) {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }

  .title {
    font-size: var(--font-size-L);
    margin-bottom: ($pageTitleBottom + 2);
  }

  .subtitle {
    display: block;
  }
}

@media #{$_mediaLUp} {
  .pageTitle {
    &:not(.pageTitle--embedded) {
      padding-top: 28px;
      padding-bottom: 20px;
    }
  }

  .title {
    font-size: 28px;

    &--plp {
      font-size: 30px;
    }
  }
}
</style>
