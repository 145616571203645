var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imageSlider"},[_vm._t("default"),_vm._v(" "),_c('div',{staticClass:"imagesWrapper",class:{ secondary: _vm.secondary }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:((_vm.primary || _vm.secondaryMobile) && _vm.items && _vm.items.length > 1),expression:"(primary || secondaryMobile) && items && items.length > 1"}],staticClass:"controls"},[_c('button',{staticClass:"control prev",attrs:{"title":_vm.$t('image-slider.prev')},on:{"click":_vm.prev}},[_c('ChevronLeftSvg',{staticClass:"controlIcon",class:{ secondary: _vm.secondary },attrs:{"width":"45","height":"45"}})],1),_vm._v(" "),_c('button',{staticClass:"control next",attrs:{"title":_vm.$t('image-slider.next')},on:{"click":_vm.next}},[_c('ChevronRightSvg',{staticClass:"controlIcon",class:{ secondary: _vm.secondary },attrs:{"width":"45","height":"45"}})],1)]),_vm._v(" "),(_vm.extendedItems && _vm.extendedItems.length)?_c('div',{class:{ images: true, animate: _vm.animate },style:({
        width: `calc(${_vm.extendedItems.length * 100}%)`,
        transform: `translate(-${(100 / _vm.extendedItems.length) * _vm.index}%, 0)`,
      })},_vm._l((_vm.extendedItems),function(item,itemIndex){return _c('button',{key:item.full.product
            ? `full-${itemIndex}-${item.full.product.src}`
            : `full-${itemIndex}`,class:{
          image: true,
          'image--active': _vm.index === itemIndex,
        },style:({ width: `${100 / _vm.extendedItems.length}%` }),on:{"click":() => _vm.$emit('click-image')}},[_c(item.full.component,_vm._b({tag:"component"},'component',item.full,false))],1)}),0):_vm._e()]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewport === 'desktop' && _vm.items.length > 1),expression:"viewport === 'desktop' && items.length > 1"}],staticClass:"thumbnails-wrapper",class:{ 'secondary-wrapper': _vm.secondary }},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.secondary),expression:"secondary"}],staticClass:"thumbnail-navigation-wrapper prev",class:{
        visible: _vm.prevVisible,
      },attrs:{"title":_vm.$t('image-slider.prev')},on:{"click":_vm.decreaseThumbnailIndex}},[_c('ChevronLeftSvg',{staticClass:"thumbnail-navigation",attrs:{"width":"24","height":"24"}})],1),_vm._v(" "),(_vm.items && _vm.items.length)?[(_vm.primary)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showThumbnails && _vm.items.length > 1),expression:"showThumbnails && items.length > 1"}],staticClass:"thumbnails",class:{ primary: _vm.primary }},_vm._l((_vm.items),function(item,itemIndex){return _c('button',{key:`thumbnail-${
            item.thumbnail.image
              ? itemIndex + '-' + item.thumbnail.image.src
              : itemIndex
          }`,class:{
            thumbnail: true,
            active: itemIndex + 1 === _vm.index,
          },attrs:{"title":`${_vm.$t('image-slider.element')} ${itemIndex + 1}`},on:{"click":function($event){return _vm.goto(itemIndex + 1)}}},[_c(item.thumbnail.component,_vm._b({tag:"component"},'component',item.thumbnail,false))],1)}),0):_vm._e(),_vm._v(" "),(_vm.secondary)?_c('div',{staticClass:"thumbnails-content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showThumbnails && _vm.items.length > 1),expression:"showThumbnails && items.length > 1"}],staticClass:"thumbnails",style:({
            width: `calc(${_vm.items.length * 100}%)`,
            transform: `translate(-${
              (100 / _vm.items.length / 4) * _vm.thumbnailIndex
            }%, 0)`,
          })},_vm._l((_vm.items),function(item,itemIndex){return _c('button',{key:`thumbnail-${
              item.thumbnail.image
                ? itemIndex + '-' + item.thumbnail.image.src
                : itemIndex
            }`,class:{
              thumbnail: true,
              primary: _vm.primary,
              secondary: _vm.secondary,
              active: itemIndex + 1 === _vm.index,
            },attrs:{"title":`${_vm.$t('image-slider.element')} ${itemIndex + 1}`},on:{"click":function($event){return _vm.goto(itemIndex + 1)},"mouseover":function($event){return _vm.goto(itemIndex + 1)}}},[_c(item.thumbnail.component,_vm._b({tag:"component"},'component',item.thumbnail,false))],1)}),0)]):_vm._e()]:_vm._e(),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.secondary),expression:"secondary"}],staticClass:"thumbnail-navigation-wrapper next",class:{
        visible: _vm.nextVisible,
      },attrs:{"title":_vm.$t('image-slider.next')},on:{"click":_vm.increaseThumbnailIndex}},[_c('ChevronRightSvg',{staticClass:"thumbnail-navigation",attrs:{"width":"24","height":"24"}})],1)],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }