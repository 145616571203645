<i18n src="./translations"></i18n>
<template>
  <div :class="[classes, isSecondary]" :data-preload="[$options.name]">
    <div v-if="variant === 'secondary'" class="header-wrapper">
      <span class="headline">
        {{ $t('pdp-badges.any_questions') }}
      </span>
      <span class="subtitle margin-bottom">
        {{ $t('pdp-badges.we_help') }}
      </span>
      <MaskedLink
        v-if="contactUsInfo.phone && !contactUsInfo.hideSupportPhoneNumber"
        class="phone-number"
        :redirect-to="`tel:${contactUsInfo.phone}`"
        :text="contactUsInfo.formattedPhone"
      />
      <MaskedLink
        v-if="contactUsInfo.email"
        class="email"
        :redirect-to="`mailto:${contactUsInfo.email}`"
        :text="contactUsInfo.email"
      />
      <span
        v-if="contactUsInfo.openingWeekdays && !hideOpeningWeekends"
        class="subtitle"
      >
        {{ contactUsInfo.openingWeekdays }}
      </span>
      <span
        v-if="contactUsInfo.openingWeekend && !hideOpeningWeekends"
        class="subtitle"
      >
        {{ contactUsInfo.openingWeekend }}
      </span>
    </div>

    <div v-if="variant === 'primary'" class="content-primary">
      <div v-if="!isBusinessCustomer" class="freeShipping">
        <ShippingTruck width="22" height="29" :original="true" class="truck" />
        {{
          $t('pdp-badges.free_shipping_min', {
            '%freeDeliveryMin%': freeDeliveryMin,
            '%currency%': currency,
          })
        }}
      </div>
      <div class="badges">
        <div class="badge">
          <ReloadSmall
            width="24"
            height="19"
            :original="true"
            class="badgeIcon"
          />
          <div class="badgeText">
            {{
              $t('pdp-badges.return_guarantee', {
                '%daysToReturn%': daysToReturn,
              })
            }}
          </div>
        </div>

        <div class="badge">
          <ArrowBox
            width="26"
            height="28"
            :original="true"
            class="badgeIcon return"
          />
          <div class="badgeText">
            {{ $t('pdp-badges.free_return') }}
          </div>
        </div>

        <div class="badge">
          <CustomerService
            width="26"
            height="30"
            :original="true"
            class="badgeIcon"
          />
          <div class="badgeText customer-info">
            <p>{{ $t('pdp-badges.customer_service') }}</p>
            <div class="contact-details-customer-service">
              <span class="contact">
                <MaskedLink
                  v-if="
                    contactUsInfo.phone && !contactUsInfo.hideSupportPhoneNumber
                  "
                  :redirect-to="`tel:${contactUsInfo.phone}`"
                  :text="contactUsInfo.formattedPhone"
                />
              </span>
              <span v-if="showAll">{{
                $t('pdp-badges.customer_service_or')
              }}</span>
              <span class="contact">
                <MaskedLink
                  v-if="contactUsInfo.email"
                  :redirect-to="`mailto:${contactUsInfo.email}`"
                  :text="contactUsInfo.email"
                />
              </span>
              <p
                v-if="
                  contactUsInfo.openingTimes &&
                  !contactUsInfo.hideSupportPhoneNumber
                "
              >
                <span v-if="$te('pdp-badges.customer_service_schedule')">
                  {{ $t('pdp-badges.customer_service_schedule') }}
                </span>
                {{ contactUsInfo.openingTimes }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content-secondary">
      <div v-if="!isBusinessCustomer" class="badge freeShipping">
        <ShippingTruck
          width="32"
          height="32"
          :original="true"
          class="badgeIcon"
        />
        <span class="subtitle">
          {{
            $t('pdp-badges.free_shipping', {
              '%freeDeliveryMin%': freeDeliveryMin,
              '%currency%': currency,
            })
          }}
        </span>
      </div>

      <div class="badge">
        <ReloadSmall
          width="32"
          height="32"
          :original="true"
          class="badgeIcon"
        />
        <span class="subtitle">
          {{
            $t('pdp-badges.return_guarantee', {
              '%daysToReturn%': daysToReturn,
            })
          }}
        </span>
      </div>

      <div class="badge">
        <ArrowBox
          width="32"
          height="32"
          :original="true"
          class="badgeIcon return"
        />
        <span class="subtitle">
          {{ $t('pdp-badges.free_return') }}
        </span>
      </div>

      <div class="badge">
        <PaymentOptionsSvg
          width="32"
          height="32"
          :original="true"
          class="badgeIcon"
        />
        <span class="subtitle">
          {{ $t('pdp-badges.convenient_payments_options') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { mapState, mapGetters } from 'vuex';
import ShippingTruck from 'Components/00-generated/ShippingTruckSvg';
import ReloadSmall from 'Components/00-generated/ReloadSmallSvg';
import ArrowBox from 'Components/00-generated/ArrowBoxSvg';
import CustomerService from 'Components/00-generated/CustomerServiceSvg';
import PaymentOptionsSvg from 'Components/00-generated/PaymentOptionsSvg';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';

export default {
  name: 'PdpBadges',
  components: {
    PaymentOptionsSvg,
    ShippingTruck,
    ReloadSmall,
    ArrowBox,
    CustomerService,
    MaskedLink,
  },
  mixins: [globalMixin],
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('core', {
      freeDeliveryMin: ({ freeDeliveryMin }) => freeDeliveryMin,
      currency: ({ currency }) => currency,
      salesChannelConfig: ({ salesChannel }) => salesChannel.config,
      contactUsInfo() {
        return {
          email: this.isBusinessCustomer
            ? this.salesChannelConfig.b2BCustomerServiceEmail
            : this.salesChannelConfig.customerServiceEmail,
          phone: this.isBusinessCustomer
            ? this.salesChannelConfig.b2BCustomerServicePhoneLink
            : this.salesChannelConfig.customerServicePhoneLink,
          formattedPhone: this.isBusinessCustomer
            ? this.salesChannelConfig.b2BCustomerServicePhoneNumber
            : this.salesChannelConfig.customerServicePhoneNumber,
          openingTimes: this.isBusinessCustomer
            ? this.salesChannelConfig.b2BCustomerServiceOpeningHours
            : this.salesChannelConfig.customerServiceOpeningHours,
          hideSupportPhoneNumber:
            this.salesChannelConfig.hideSupportPhoneNumber || false,
        };
      },
      isKfzteile24: ({ salesChannel }) =>
        salesChannel.salesChannelId === 'www-kfzteile24-de',
      salesChannelId: ({ salesChannel }) => salesChannel.salesChannelId,
    }),
    ...mapGetters('core', {
      isBusinessCustomer: 'isBusinessCustomer',
    }),
    daysToReturn() {
      return this.isKfzteile24 ? '365' : '100';
    },
    showAll() {
      const { phone, email, hideSupportPhoneNumber } = this.contactUsInfo;

      return phone && email && !hideSupportPhoneNumber;
    },
    isSecondary() {
      return this.variant === 'secondary' ? 'secondary' : '';
    },
    hideOpeningWeekends() {
      const hideOpeningWeekends = ['www-carpardoo-dk'].includes(
        this.salesChannelId
      );
      return hideOpeningWeekends;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.pdpBadges {
  width: 100%;
  border: 1px solid var(--color-alt);

  &.secondary {
    border-radius: var(--border-radius-default);
  }
}

.header-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: var(--space-2);

  .headline {
    font-size: var(--font-size-MML);
    font-weight: bold;
    color: var(--color-nero);
    margin-bottom: var(--space-0--half);
  }

  .phone-number {
    font-size: var(--font-size-L);
    font-weight: bold;
    margin-bottom: var(--space-0--half);
    cursor: pointer;
    color: var(--color-secondary);
  }

  .email {
    font-size: var(--space-2);
    color: var(--color-nero);
    margin-bottom: var(--space-2);
    cursor: pointer;
    text-decoration: underline;
  }
}

.subtitle {
  font-size: var(--space-2);
  font-weight: 400;
  color: var(--color-rhino-without-horn);

  &.margin-bottom {
    margin-bottom: var(--space-2);
  }
}

.subtitle + .subtitle {
  margin-top: 4px;
}

.pdpBadges--horizontal {
  .header-wrapper {
    flex-wrap: wrap;
    margin-right: 0;
    position: relative;

    @media #{$_mediaLUp} {
      width: 44%;
      border-right: 1px solid var(--color-alto-light);
      margin: 0;

      .span {
        width: 100%;
      }
    }

    .email {
      margin-bottom: 0;
    }

    .subtitle {
      align-self: baseline;
      margin-top: 5%;
    }

    .margin-bottom {
      margin-bottom: var(--space-1--half);
      margin-top: 0;
    }
  }

  .content-secondary {
    width: 58%;
    margin: 0;
    height: 100%;

    @media #{$_mediaLUp} {
      flex-direction: column;
      margin-left: var(--space-2);

      .badge {
        width: 100%;
      }
    }

    .badge {
      height: 50px;
      margin: 0;
      flex-grow: 0;

      &:first-child {
        border-top: none;
      }

      .badgeIcon {
        margin-right: var(--space-1);
      }
    }
  }

  @media (min-width: #{$_widthXXLFrom}) {
    .header-wrapper {
      height: 103px;
      width: 43%;
      border-right: none;
      margin: var(--space-2);
      margin-right: 0;

      &::before,
      &::after {
        content: '';
        position: absolute;
        height: 105px;
        width: 1px;
        left: 46.5%;
        top: 0;
        background: var(--color-alto-light);
      }

      &::after {
        left: 93%;
      }
    }

    .content-secondary {
      margin-left: 0;

      .badge {
        width: 50%;
      }
    }
  }
}

.content-primary {
  .freeShipping {
    padding: 5px 20px;
    display: flex;
    align-items: center;
    color: var(--color-rolling-stone);
    min-height: 30px;
    font-size: var(--font-size-SM);
    height: 40px;
    font-weight: bold;
    border-bottom: 1px solid var(--color-alt);
    width: 100%;
  }

  .truck {
    flex-shrink: 0;
    margin: -5px 11px -5px 0;
    width: 22px;

    path {
      fill: var(--color-white);
    }
  }

  .badges {
    display: flex;
    flex-wrap: wrap;

    .badgeIcon {
      margin-right: 11px;

      &.customerServiceSvg {
        margin-top: -2px;
      }

      &.return {
        margin-left: 3px;
        margin-right: 7px;
      }
    }
  }

  .badge {
    display: flex;
    flex-grow: 1;
    padding: 0 20px;
    min-width: 150px;
    width: 100%;
    color: var(--color-boulder);
    font-weight: bold;
    font-size: var(--font-size-SM);
    align-items: center;
    height: 40px;
    border-bottom: 1px solid var(--color-alt);

    &:last-child {
      border-bottom: 0;
      align-items: unset;
      height: auto;
      padding-bottom: 11px;
      padding-top: 7px;
    }
  }

  .badgeText.customer-info {
    padding-top: 6px;

    .contact-details-customer-service {
      font-size: 11px;
      font-weight: normal;
      color: var(--color-rolling-stone);
      margin-top: 7px;
    }

    .contact {
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 4px;
      display: inline-block;
    }
  }
}

.content-secondary {
  display: flex;
  flex-wrap: wrap;

  .badgeIcon {
    margin-right: 11px;
  }

  .badge {
    display: flex;
    flex-grow: 1;
    margin: 0 var(--space-2);
    min-width: 150px;
    width: 100%;
    color: var(--color-boulder);
    font-weight: bold;
    font-size: var(--font-size-SM);
    align-items: center;
    height: 40px;
    padding-top: var(--space-2);

    &:last-child {
      margin-bottom: var(--space-2);
    }

    &:first-child {
      border-top: 1px solid var(--color-alt);
    }
  }
}

@media #{$_mediaLDown} {
  .badgeText.customer-info .contact a {
    font-size: 11px;
  }
}

@media #{$_mediaSDown} {
  .freeShipping {
    font-size: var(--font-size-S);
  }

  .column + .badges {
    justify-content: center;
  }

  .badge {
    font-size: var(--font-size-S);
  }
}
</style>
