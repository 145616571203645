<i18n src="./translations"></i18n>

<template>
  <div v-show="isCookieEnabled" class="cookies-wrapper">
    <div
      v-show="isDisplayed"
      class="cookies-main-overlay qa-CookiesMain__overlay'"
      :data-preload="[$options.name]"
    >
      <div
        class="cookies-main-wrapper qa-CookiesMain__wrapper"
        :class="[
          {
            'cookies-main-wrapper--rebranding': isRebrandingChannel,
            'raised-cookie': raisedCookie
          },
        ]"
      >
        <div class="cookies-main qa-CookiesMain__main">
          <h4 class="cookies-main-title qa-CookiesMain__title">
            {{ $t('cookies-main.title') }}
          </h4>

          <p class="cookies-main-text qa-CookiesMain__text">
            {{ $t('cookies-main.body') }}
            <a
              :href="frontFacingPath('content.page.privacy')"
              v-text="$t('cookies-main.data-privacy-text')"
            />.
          </p>

          <div class="buttons">
            <Button
              class="cookies-main-agree qa-CookiesMain__btn-agree qa-CookiesBar__button"
              :modifiers="
                isRebrandingChannel ? ['primary', 'rebranding'] : ['primary']
              "
              :title="$t('cookies-main.agree')"
              :on-click="acceptAllCookies"
            />
            <Button
              class="cookies-main-reject qa-CookiesMain__btn-reject qa-CookiesBar__button"
              :modifiers="
                isRebrandingChannel ? ['primary', 'rebranding'] : ['primary']
              "
              :title="$t('cookies-main.reject')"
              :on-click="rejectAllCookies"
            />
            <button
              class="cookies-main-btn-parameters qa-CookiesMain__btn-parameters"
              type="button"
              @click="showCookiesPreferences"
            >
              {{ $t('cookies-main.parameters') }}
            </button>
          </div>
        </div>

        <Dialog
          ref="dialog"
          is-black
          :is-ios-app="raisedCookie"
          :is-rebranding-channel="isRebrandingChannel"
          :modifiers="['theme-secondary']"
          :click-outside="clickOutside"
          :title="$t('cookies-main.parameters')"
          :confirm-button="confirmButton"
          :cancel-button="cancelButton"
          :third-button="thirdButton"
        >
          <p>{{ $t('cookies-main.dialog_description') }}</p>

          <div class="dialog_content">
            <Checkbox
              :label="$t('cookies-main.cookie_necessary_title')"
              :text="$t('cookies-main.cookie_necessary_content')"
              :hide-optional="true"
              :modifiers="['theme-spacing']"
              :send="true"
              :value="true"
            />

            <Checkbox
              v-model="cookieFunctionalEnabled"
              :label="$t('cookies-main.cookie_functional_title')"
              :text="$t('cookies-main.cookie_functional_content')"
              :hide-optional="true"
              :modifiers="['theme-spacing']"
              :send="true"
            />

            <Checkbox
              v-model="cookieMarketingEnabled"
              :label="$t('cookies-main.cookie_marketing_title')"
              :text="$t('cookies-main.cookie_marketing_content')"
              :hide-optional="true"
              :modifiers="['theme-spacing']"
              :send="true"
            />
          </div>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';

import Button from 'Components/01-atoms/button/Button.vue';
import Dialog from 'Components/02-molecules/dialog/Dialog.vue';
import Checkbox from 'Components/01-atoms/form/checkbox/CheckboxBase';
import DeviceHelper from 'Libs/helpers/device';

Vue.use(VueCookies);

const COOKIES = {
  ID: 'cookies_consent',
  FUNCTIONAL: 'functional',
  MARKETING: 'marketing',
};

const EVENT_LIST = [
  'mousemove',
  'mousedown',
  'keydown',
  'touchmove',
  'touchstart',
  'click',
  'wheel',
  'scroll',
  'resize',
];

export default {
  name: 'CookiesMain',
  components: {
    Button,
    Dialog,
    Checkbox,
  },
  mixins: [globalMixin],
  data() {
    return {
      initialWindowHeight: null,
      isDisplayed: false,
      clickOutside: true,
      cookieFunctionalEnabled: false,
      cookieMarketingEnabled: false,
      raisedCookie: false,
    };
  },
  computed: {
    ...mapState('core', {
      isProduction: ({ environment }) => environment === 'production',
      salesChannelId: ({ salesChannel }) => salesChannel.salesChannelId,
    }),
    ...mapState('cookiesMain', [
      'isCookieEnabled',
      'forceOpenCookiesMainBanner',
    ]),
    isCookieSaved() {
      return !!Vue.$cookies.get(COOKIES.ID);
    },
    ...mapGetters('core', ['isRebrandingChannel']),
    confirmButton() {
      return {
        title: this.$t('cookies-main.agree'),
        onClick: () => {
          this.acceptAllCookies();
        },
      };
    },
    cancelButton() {
      return {
        title: this.$t('cookies-main.reject'),
        modifier: 'primary',
        onClick: () => {
          this.rejectAllCookies();
        },
      };
    },
    thirdButton() {
      return {
        title: this.$t('cookies-main.save'),
        onClick: () => {
          this.saveCookiePreferences('cookies_banner_settings_saved');
        },
      };
    },
    isCookiesBannerDisabled() {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      return params && params.cookiesBanner === 'disabled';
    },
    /**
     * Detect if the cookie banner is displayed within the iOS native app.
     **/
    isIosApp() {
      const deviceHelper = new DeviceHelper();
      return deviceHelper.isIOS() && this.salesChannelId === 'app_ios-kfzteile24-de';
    },
  },
  watch: {
    isCookieSaved(isCookieSaved) {
      this.isDisplayed = !isCookieSaved;
    },
    forceOpenCookiesMainBanner(forceOpen) {
      if (forceOpen) {
        this.isDisplayed = true;
      } else if (this.isCookieSaved) {
        this.isDisplayed = false;
      }
    },
  },
  mounted() {
    if (this.isCookiesBannerDisabled) {
      this.acceptAllCookies();
      return;
    }

    this.addEventListeners();
    this.isDisplayed = !this.isCookieSaved || this.forceOpenCookiesMainBanner;

    if (typeof window !== 'undefined' && this.isIosApp && this.isDisplayed) {
      this.initialWindowHeight = window.innerHeight;
      this.$nextTick(() => {
        window.addEventListener('resize', this.onWindowResize);
      });
    }
  },
  destroyed() {
    if (typeof window !== 'undefined' && this.isIosApp) {
      window.removeEventListener('resize', this.onWindowResize);
    }
    this.removeEventListeners();
  },
  methods: {
    ...mapMutations('cookiesMain', {
      openCookieBanner: 'OPEN_COOKIES_BANNER',
    }),
    ...mapActions('cookiesMain', ['enableCookie']),
    ...mapActions('dataLayer', ['trackCookiesBannerConsent']),
    processActivity() {
      if (!this.isCookieEnabled) {
        this.enableCookie();
      } else {
        this.removeEventListeners();
      }
    },
    addEventListeners() {
      EVENT_LIST.forEach((eventName) =>
        document.addEventListener(eventName, this.processActivity)
      );
    },
    removeEventListeners() {
      EVENT_LIST.forEach((eventName) =>
        document.removeEventListener(eventName, this.processActivity)
      );
    },
    getCookieState(name) {
      const cookies = Vue.$cookies.get(COOKIES.ID);
      return !!cookies && !!cookies[name];
    },
    acceptAllCookies() {
      this.cookieFunctionalEnabled = true;
      this.cookieMarketingEnabled = true;
      this.saveCookiePreferences('cookies_banner_accepted_all');
      this.$root.$emit('closeCookieDialog');
    },
    rejectAllCookies() {
      this.cookieFunctionalEnabled = false;
      this.cookieMarketingEnabled = false;
      this.saveCookiePreferences('cookies_banner_rejected_all');
      this.$root.$emit('closeCookieDialog');
      this.$refs.dialog.hide();
    },
    showCookiesPreferences() {
      this.cookieFunctionalEnabled = this.getCookieState(COOKIES.FUNCTIONAL);
      this.cookieMarketingEnabled = this.getCookieState(COOKIES.MARKETING);
      this.$refs.dialog.show();
    },
    saveCookiePreferences(buttonClicked) {
      /*
        Cookie Default state: false
        Cookie Expiration time: 1 year if accepted, or 7 days if rejected
      */
      Vue.$cookies.set(
        COOKIES.ID,
        {
          [COOKIES.FUNCTIONAL]: this.cookieFunctionalEnabled,
          [COOKIES.MARKETING]: this.cookieMarketingEnabled,
        },
        this.cookieFunctionalEnabled && this.cookieMarketingEnabled
          ? '1y'
          : '7d'
      );

      /*
        we need to refresh the watchers, as the state that comes from the
        server is different to the state that comes from the client
      */
      this.openCookieBanner(false);
      this._computedWatchers.isCookieSaved.run();
      this._computedWatchers.forceOpenCookiesMainBanner.run();
      this.$forceUpdate();
      this.isDisplayed = false;

      if (!this.isCookiesBannerDisabled) {
        this.trackCookiesBannerConsent({
          buttonClicked: buttonClicked,
          consent: {
            functional: this.cookieFunctionalEnabled,
            marketing: this.cookieMarketingEnabled,
          },
        });
      }
    },
    onWindowResize() {
      this.raisedCookie = this.initialWindowHeight !== window.innerHeight;
    }
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'utils/fonts';
@import 'mixins';

.raised-cookie {
  bottom: 88px !important;
}

.cookies-main-overlay {
  width: 100%;
  height: 100%;
  z-index: 9998;
  top: 0;
  bottom: 0;
}

.cookies-main-wrapper {
  width: 100%;
  background: var(--color-nero);
  margin: 0 auto;
  color: var(--color-white);
  position: relative;
  z-index: 9998;
  bottom: 0;
  left: 0;

  .cookies-main {
    display: flex;
    flex-wrap: wrap;
    max-width: 1088px;
    margin: 0 auto;
    padding: 24px 24px 32px;

    &-title {
      font-weight: bold;
      width: 100%;
      font-size: var(--font-size-ML);
      line-height: 24px;
    }

    &-text {
      width: calc(71.6% - 100px);
      margin-right: 40px;
      padding: 24px 0 0;
      font-size: var(--font-size-SM);
      line-height: 18px;
      display: block;

      a {
        text-decoration: underline;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: right;
    padding: var(--space-3) 0 0;
  }

  .cookies-main-agree,
  .cookies-main-reject {
    width: 300px;
    margin-top: var(--space-1);
    text-transform: none;
  }

  .cookies-main-btn-parameters {
    width: 300px;
    margin-top: var(--space-2);
    color: var(--color-white);
    font-size: var(--font-size-SM);
    line-height: 18px;
    text-decoration: underline;
    text-transform: none;
  }

  &--rebranding {
    .cookies-main-title {
      @include rebranding-h4;
    }

    .cookies-main-text {
      @include rebranding-para-regular;
    }
  }
}

::v-deep {
  .checkbox--theme-spacing.checkbox {
    border-bottom: 1px solid var(--color-hippo-darker);
    margin-bottom: 16px;

    div.info {
      color: var(--color-white);
      padding: 8px 0 16px;
      margin-left: 30px;
      margin-top: 0;
      line-height: 18px;
    }

    .label__value {
      color: var(--color-white);
      font-size: var(--font-size-M);
    }

    .checkmark {
      border-color: var(--color-alto-light);
      margin-right: 8px;

      &--checked {
        background-color: var(--color-white);
        border-color: var(--color-white);

        .checkedSvg {
          fill: var(--color-nero);
        }
      }
    }
  }

  .checkbox--theme-spacing.checkbox:first-of-type {
    .checkmark {
      &--checked {
        background-color: var(--color-silver-lining);
        border-color: var(--color-silver-lining);

        .checkedSvg {
          fill: var(--color-nero);
        }
      }
    }
  }

  .checkbox--theme-spacing.checkbox:last-of-type {
    border-bottom: 0;

    div.info {
      padding-bottom: 0;
    }
  }

  .dialog.dialog--theme-secondary::v-deep {
    .dialog_content {
      height: 100%;
    }
  }
}

@media #{$_mediaSDown} {
  .cookies-main-wrapper {
    //position: absolute;
    //bottom: 50px;
    left: 0;
  }
}

@media #{$_mediaMDown} {
  .cookies-main-wrapper {
    .cookies-main {
      height: 50%;
      padding: var(--space-2);

      &-title {
        font-size: var(--font-size-ML);
        line-height: var(--space-3);
        margin-top: 0;
      }

      &-text {
        width: 100%;
        max-height: 25vh;
        overflow-y: auto;

        margin: var(--space-2) 0 0 0;
        padding: 0 var(--space-1) var(--space-1) 0;
        font-size: var(--font-size-SM);

        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: var(--color-white);
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--color-alto-sec);
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .cookies-main-agree,
    .cookies-main-reject {
      margin-top: var(--space-1);
      width: 100%;
      order: 1;
    }

    .cookies-main-btn-parameters {
      margin-top: var(--space-2);
      font-size: var(--font-size-M);
      margin-bottom: 12px;
      width: 100%;
      order: 2;
    }
  }
}

@media #{$_mediaLUp} {
  .cookies-main-wrapper {
    .cookies-main {
      padding: var(--space-3) var(--space-3) var(--space-4);

      &-text {
        width: calc(100% - 300px);
      }
    }

    .buttons {
      align-items: center;
    }

    .cookies-main-agree,
    .cookies-main-reject {
      width: 100%;
    }

    .cookies-main-btn-parameters {
      width: 260px;
    }
  }
}
</style>
