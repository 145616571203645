<template>
  <span :class="[classes]" :data-preload="[$options.name]">
    {{ text }}
  </span>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import scrollMixin from 'Libs/mixins/scrollMixin';

export default {
  name: 'BaseFlag',
  mixins: [globalMixin, scrollMixin],
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
@import 'mixins.scss';

.baseFlag {
  display: inline-block;
  padding: 0.3em;
  padding-right: 0.3em;
  margin-left: 7.8em;
  position: relative;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  line-height: 1;
  color: #ffffff;
  font-weight: bold;
  font-size: var(--font-size-SM);

  background: var(--color-tory-blue);

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 0.8em solid transparent;
    left: -0.78em;
    position: absolute;
    top: 0;
    border-top: 1.6em solid var(--color-tory-blue);
  }
}
</style>
