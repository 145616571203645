<i18n src="./translations"></i18n>

<template>
  <div :class="classes" class="plp-disclaimer-21">
    <!-- eslint-disable vue/no-v-html -->
    <h3
      class="plp-disclaimer-21__header"
      v-html="$t(`plp-disclaimer-21.title.${articleType}`)"
    />
    <div
      class="plp-disclaimer-21__content"
      v-html="shouldShowFullDisclaimer ? fullDisclaimer : truncatedDisclaimer"
    />
    <span
      v-if="!shouldShowFullDisclaimer"
      class="plp-disclaimer-21__show-more"
      @click="toggleDisclaimer()"
    >
      {{ $t('plp-disclaimer-21.show_more') }}
      <ChevronRightSvg class="plp-disclaimer-21__down-arrow-icon" />
    </span>
    <span
      v-else
      class="plp-disclaimer-21__show-less"
      @click="toggleDisclaimer()"
    >
      {{ $t('plp-disclaimer-21.show_less') }}
      <ChevronRightSvg class="plp-disclaimer-21__up-arrow-icon" />
    </span>
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import ChevronRightSvg from 'Components/00-generated/ChevronRightSvg';

export default {
  name: 'PlpDisclaimer21',
  components: { ChevronRightSvg },
  mixins: [globalMixin],
  props: {
    articleType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      shouldShowFullDisclaimer: false,
    };
  },
  computed: {
    truncatedDisclaimer() {
      return (
        this.$t(`plp-disclaimer-21.content.${this.articleType}`).slice(0, 200) +
        '...</p>'
      );
    },
    fullDisclaimer() {
      return this.$t(`plp-disclaimer-21.content.${this.articleType}`);
    },
  },
  methods: {
    toggleDisclaimer() {
      this.shouldShowFullDisclaimer = !this.shouldShowFullDisclaimer;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.plp-disclaimer-21 {
  border-radius: 8px;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px var(--color-alto-sec);
  padding: var(--space-3) var(--space-4);
  margin-bottom: 26px;

  @media #{$_mediaSDown} {
    padding: var(--space-2);
    margin-bottom: var(--space-2);
  }
}

.plp-disclaimer-21__header {
  font-weight: bold;
  margin-bottom: var(--space-2);

  @media #{$_mediaSDown} {
    margin-bottom: var(--space-1);
    line-height: 1.4em;
  }
}

.plp-disclaimer-21__content {
  line-height: 1.4em;

  ::v-deep {
    p + p {
      margin-top: 1em;
    }
  }
}

.plp-disclaimer-21__show-more,
.plp-disclaimer-21__show-less {
  display: inline-flex;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  margin-top: var(--space-2);
  font-size: var(--font-size-SM);
}

.plp-disclaimer-21__down-arrow-icon,
.plp-disclaimer-21__up-arrow-icon {
  color: var(--color-secondary);
  transform: rotate(90deg);
  width: 24px;
  height: 23px;
  margin-top: -4px;
}

.plp-disclaimer-21__up-arrow-icon {
  transform: rotate(270deg);
}
</style>
