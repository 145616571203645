<i18n src="./translations"></i18n>

<template>
  <div
    v-show="!isNativeApp"
    :class="[
      classes,
      {
        'reduced-page-header--rebranding': isRebrandingChannel,
      },
    ]"
    :data-preload="[$options.name]"
  >
    <div v-if="announcement" class="company-announcement">
      <strong class="company-announcement__text">
        {{ announcement || '' }}
      </strong>
    </div>
    <nav
      class="navigation-row"
      :class="[
        isHeaderReduced ? 'navigation-row--reduced' : 'navigation-row--spread',
        { 'navigation-row--rebranding': isRebrandingChannel },
      ]"
    >
      <div v-if="!isHeaderReduced" class="navigation-row__burger-button">
        <BurgerButton :is-new-header="true" />
        <span class="navigation-row__label">{{
          $t('reduced-page-header.menu')
        }}</span>
      </div>
      <Logo
        class="logo--newHeader navigation-row__logo"
        :class="{ 'top-spacing': hasManyHeaderLabels }"
        :url="getLogoUrl()"
        :theme="isRebrandingChannel ? 'rebranding' : theme"
        :is-link="true"
      />

      <div v-if="!isHeaderReduced" class="navigation-row__section">
        <div class="navigation-row__section--info">
          <div
            v-show="!isBusinessCustomer && !isAlpha && promiseBanner.visible"
            class="navigation-row__label"
          >
            <ClockSvg
              width="14"
              height="14"
              :original="true"
              class="navigation-row__clock-icon"
            />
            <span>
              {{ $t('reduced-page-header.info.shipping_today') }}
            </span>
          </div>
          <div
            v-show="isBusinessCustomer && !promiseBanner.visible"
            class="navigation-row__label"
          >
            <ShipmentSvg
              width="14"
              height="14"
              :original="true"
              class="navigation-row__shipment-icon"
            />
            <span>
              {{ $t('reduced-page-header.info.shipping_fast') }}
            </span>
          </div>
          <div v-show="isBusinessCustomer" class="navigation-row__label">
            <SparePartsSvg
              width="14"
              height="14"
              :original="true"
              class="navigation-row__spare-parts-icon"
            />
            <span>{{ $t('reduced-page-header.info.stock_count') }}</span>
          </div>

          <div v-if="isGermanSalesChannel" class="navigation-row__label">
            <ReturnSvg
              width="14"
              height="14"
              :original="true"
              class="navigation-row__return-icon"
            />
            <span>{{ $t('reduced-page-header.info.free_return') }}</span>
          </div>

          <div
            v-show="!isBusinessCustomer && !isAlpha"
            class="navigation-row__label"
          >
            <ShipmentSvg
              width="14"
              height="14"
              :original="true"
              class="navigation-row__delivery-icon"
            />
            <span>
              {{
                $t('reduced-page-header.info.free_shipping', {
                  '%freeDeliveryMin%': freeDeliveryMin,
                })
              }}
            </span>
          </div>
        </div>
        <div class="navigation-row__section--actions">
          <SearchBar
            :is-new-header="true"
            class="navigation-row__search-bar navigation-row__search-bar--desktop"
          />

          <div class="navigation-row__navigation-buttons navigation-buttons">
            <HeaderButton
              class="navigation-buttons__button navigation-buttons__button--header-button navigation-buttons__button--small-screen"
              modifier="headerButton"
              v-bind="searchButtonConfig"
            />
            <Placeholder
              v-if="!!placeholders.headerAccountButton"
              class="navigation-buttons__button navigation-buttons__button--account"
              :config="placeholders.headerAccountButton"
            />
            <Placeholder
              v-if="!!placeholders.headerWishlistButton"
              class="navigation-buttons__button navigation-buttons__button--wishlist qa-button--wishlist"
              :config="placeholders.headerWishlistButton"
            />
            <Placeholder
              v-if="!!placeholders.headerCartButton"
              class="navigation-buttons__button navigation-buttons__button--cart"
              :config="placeholders.headerCartButton"
            />
            <Placeholder
              v-if="!!placeholders.menu"
              class="navigation-buttons__button navigation-buttons__button--mobileNavigation"
              :config="placeholders.menu"
            />
          </div>
        </div>
      </div>

      <transition
        enter-class="navigation-row__search-bar-transition--initial"
        enter-to-class="navigation-row__search-bar-transition--target"
        enter-active-class="navigation-row__search-bar-transition--active"
        leave-class="navigation-row__search-bar-transition--target"
        leave-to="navigation-row__search-bar-transition--initial"
        leave-active-class="navigation-row__search-bar-transition--active-leave"
      >
        <div
          v-show="mobileSearchOpen"
          v-if="!isHeaderReduced"
          class="navigation-row__mobile-search-bar-overlay"
          @click.self="toggleSearchBar(false)"
        >
          <SearchBar
            ref="mobileSearch"
            class="navigation-row__search-bar navigation-row__search-bar--mobile"
            :is-new-header="true"
            mobile
          />
        </div>
      </transition>

      <span
        v-if="isHeaderReduced && !isMobileSalesChannel"
        class="navigation-row__title"
      >
        {{ $t('reduced-page-header.title') }}
      </span>
    </nav>
    <div v-show="!isBusinessCustomer" class="free-shipping">
      <div class="free-shipping-content">
        <DeliverySvg
          width="24"
          height="24"
          :original="true"
          class="navigation-row__delivery-icon"
        />
        <span>
          {{
            $t('reduced-page-header.info.free_shipping', {
              '%freeDeliveryMin%': freeDeliveryMin,
            })
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import scrollMixin from 'Libs/mixins/scrollMixin';
import UrlHelper from 'Libs/helpers/url';
import SessionStorage from 'Libs/helpers/session-storage';
import Logo from 'Components/02-molecules/logo/Logo.vue';
import HeaderButton from 'Components/01-atoms/header-button/HeaderButton.vue';
import SearchBar from 'Components/02-molecules/search-bar/SearchBar.vue';
import Placeholder from 'Components/01-atoms/placeholder/Placeholder.vue';
import BurgerButton from 'Components/01-atoms/burger-button/BurgerButton';
import DeliverySvg from 'Components/00-generated/DeliverySvg';
import ShipmentSvg from 'Components/00-generated/ShipmentSvg';
import ReturnSvg from 'Components/00-generated/ReturnSvg';
import SparePartsSvg from 'Components/00-generated/SparePartsSvg';
import ClockSvg from 'Components/00-generated/ClockSvg';
import globalMixin from 'Libs/mixins/globalMixin';
import nativeAppMixin from 'Libs/mixins/nativeAppMixin';

export default {
  name: 'ReducedPageHeader',
  components: {
    Logo,
    HeaderButton,
    SearchBar,
    Placeholder,
    BurgerButton,
    DeliverySvg,
    ShipmentSvg,
    ReturnSvg,
    SparePartsSvg,
    ClockSvg,
  },
  mixins: [globalMixin, scrollMixin, nativeAppMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchButtonConfig: {
        icon: {
          name: 'search_small',
          width: '21',
          height: '21',
        },
        type: 'button',
        label: this.$t('reduced-page-header.search'),
        onClick: () => this.toggleSearchBar(true),
      },
    };
  },
  computed: {
    ...mapState('core', {
      placeholders: (state) => state.placeholders,
      announcement: ({ salesChannel: { config } }) =>
        config.announcement || false,
      theme: ({ salesChannel }) => salesChannel.theme,
      promiseBanner: ({ promiseBanner }) => promiseBanner,
      salesChannelId: ({ salesChannel }) => salesChannel.salesChannelId,
      baseUrl: ({ salesChannel }) => salesChannel.baseUrl,
      freeDeliveryMin: ({ freeDeliveryMin }) => freeDeliveryMin,
    }),
    ...mapState('search', {
      mobileSearchOpen: (state) => state.mobileOpen,
    }),
    ...mapGetters('core', {
      options: 'getLayoutParams',
      isGermanSalesChannel: 'isGermanSalesChannel',
      isDeshop: 'isDeshop',
      isMobileViewport: 'isMobileViewport',
      companyInfo: 'getCompanyInformation',
    }),
    isAlpha() {
      return this.baseUrl && this.baseUrl.includes('alpha');
    },
    isHeaderReduced() {
      return this.isMobileSalesChannel || this.options.reducedHeader;
    },
    isBusinessCustomer() {
      return this.companyInfo?.companyId;
    },
    hasManyHeaderLabels() {
      return (
        !this.isHeaderReduced &&
        this.promiseBanner.visible &&
        this.isGermanSalesChannel &&
        !this.isBusinessCustomer
      );
    },
  },
  mounted() {
    this.sessionStorage = new SessionStorage();
    this.checkDiscountCodeFromUrl();
  },
  methods: {
    ...mapMutations('search', { toggleMobileSearch: 'TOGGLE_MOBILE_SEARCH' }),
    ...mapActions('header', ['addDiscountCode', 'checkDiscountCode']),
    ...mapActions('navigation', ['closeMobileNavigation']),
    toggleSearchBar(state) {
      this.toggleMobileSearch(state);
      this[`${state ? 'disable' : 'enable'}BodyScrolling`].call();

      setTimeout(() => this.$refs.mobileSearch.toggleFocus(state), 400);
    },
    checkDiscountCodeFromUrl() {
      const { prc, pv } = UrlHelper.getUrlParameters();

      if (prc || pv) {
        this.checkDiscountCode({ discountCode: prc || pv });
      }
    },
    getLogoUrl() {
      return this.isMobileSalesChannel
        ? ''
        : this.frontFacingPath('routing.shop.root');
    },
  },
  serverCacheKey: ({ id = false }) => {
    /* istanbul ignore next */
    return id;
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'utils/fonts';
@import 'mixins';
@import 'animations';

strong {
  font-weight: bold;
}

.reducedPageHeader {
  display: block;
  position: relative;
  z-index: 3;

  @include dropShadow;
}

.reduced-page-header--rebranding {
  .navigation-row {
    background-color: var(--color-rebranding-primary);
    color: var(--color-rebranding-white);

    @include rebranding-para-bold;
  }

  .free-shipping {
    .free-shipping-content {
      color: var(--color-rebranding-black-out);

      @include rebranding-para-small-regular;

      .navigation-row__delivery-icon {
        fill: var(--color-rebranding-black-out);
      }
    }
  }
}

.company-announcement {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 35px;
  max-height: 200px;
  background: var(--color-secondary);
  overflow: hidden;

  @include page-size-padding(var(--space-1));

  &__text {
    font-size: 14px;
    font-weight: bold;
    color: var(--color-white);
    line-height: 19px;
    text-align: center;

    @media #{$_mediaMDown} {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.navigation-row {
  position: relative;
  color: var(--color-white);
  margin: 0;
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include page-size-padding(var(--space-2));

  background-color: var(--color-primary);

  &--spread {
    justify-content: space-between;
  }

  &--reduced {
    justify-content: left;
  }

  &--rebranding {
    @include increaseSpecificity('height', unset);
    @include increaseSpecificity(
      'padding',
      var(--space-2) var(--space-3--half)
    );
  }

  @media #{$_mediaMDown} {
    height: 78px;
  }

  @media #{$_mediaSDown} {
    height: 50px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.navigation-row__logo.logo--newHeader {
  flex-shrink: 0;
  max-width: 180px;
  max-height: 180px;
  margin: 0 60px 2px 0;

  @media (max-width: 1050px) {
    max-width: 130px;
    padding-top: 30px;
  }

  @media #{$_mediaMDown} {
    padding-top: 0;
    margin: 0 var(--space-3) 0 0;
  }

  @media (max-width: 650px) {
    margin-right: auto;
  }
}

.navigation-row__section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &--info {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    padding-bottom: 14px;

    .navigation-row__label {
      padding-left: 5%;
      font-size: 14px;
      align-self: flex-end;
      flex-shrink: 0;
      width: fit-content;
      display: flex;

      @media (max-width: 1200px) {
        padding-left: 2%;
      }

      @media (max-width: 850px) {
        &:last-of-type {
          display: none;
        }
      }

      @media #{$_mediaLDown} {
        font-size: 12px;
      }
    }
  }

  @media #{$_mediaMDown} {
    &--info {
      display: none;
    }
  }

  &--actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media all and (-ms-high-contrast: none) {
      max-width: 960px;
    }
  }
}

.navigation-row__burger-button {
  margin-right: var(--space-2);
  font-size: var(--font-size-S);

  .label {
    font-size: var(--font-size-S);
    margin-left: 2px;
    margin-top: -2px;
  }

  @media #{$_mediaXLUp} {
    display: none;
  }

  @media #{$_mediaSDown} {
    margin-right: 16px;
    font-size: var(--font-size-S);
  }
}

.navigation-row__title {
  display: flex;
  flex-grow: 0.8;
  justify-content: center;
  font-size: var(--font-size-L);
  font-weight: bold;
  line-height: 26px;
}

.navigation-row__mobile-search-bar-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.navigation-row__search-bar {
  &--desktop {
    max-width: 470px;
    margin-right: 8%;
  }

  &--mobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 19px 12px;

    background-color: var(--color-primary);
  }

  @media #{$_mediaMDown} {
    margin-right: var(--space-3);
  }
}

::v-deep {
  .navigation-row__search-bar-transition--initial {
    opacity: 0;
    pointer-events: none;

    .navigation-row__search-bar--mobile {
      pointer-events: none;
      transform: translateX(100%);
    }
  }

  .navigation-row__search-bar-transition--target {
    opacity: 1;
    pointer-events: all;

    .navigation-row__search-bar--mobile {
      opacity: 1;
      pointer-events: all;
      transform: translateX(0);
    }
  }

  .navigation-row__search-bar-transition--active {
    transition: opacity var(--time-S) ease-in;

    .navigation-row__search-bar--mobile {
      transition: transform var(--time-S) ease-in;
    }
  }

  .navigation-row__search-bar-transition--active-leave {
    transition: opacity var(--time-S) ease-in;
    opacity: 0;

    .navigation-row__search-bar--mobile {
      transition: transform var(--time-S) ease-in;
      transform: translateX(100%);
    }
  }
}

.free-shipping {
  width: 100%;
  display: none;
  height: var(--space-4);
  box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);

  .free-shipping-content {
    display: flex;
    align-items: center;
    margin: auto;
    color: var(--color-primary);
    font-size: var(--font-size-S);

    .left-spacing {
      margin-left: var(--space-1);
    }

    .navigation-row__delivery-icon {
      fill: var(--color-primary);
    }
  }

  @media (max-width: 650px) {
    display: flex;
  }
}

.navigation-row__shipment-icon,
.navigation-row__return-icon,
.navigation-row__spare-parts-icon,
.navigation-row__clock-icon,
.navigation-row__delivery-icon {
  margin-right: var(--space-0--half);
  fill: var(--color-white);
}

.navigation-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  flex: 0 0 320px;
  flex-basis: 320px;
  align-items: stretch;
  width: 100%;
  max-width: 396px;
  flex-grow: 1;
  flex-shrink: 0;

  @media #{$_mediaSDown} {
    align-items: center;
    flex-grow: 0;
  }

  @media #{$_mediaMDown} {
    flex-basis: 0;
    flex-grow: 0;
  }
}

.navigation-buttons__button {
  &--small-screen {
    display: none;
  }

  &--wishlist,
  &--cart {
    margin-left: var(--space-1);
  }

  &--mobileNavigation {
    display: none;
  }

  &--header-button {
    @media #{$_mediaSDown} {
      width: 32px;
      height: 32px;
      padding: 0;
    }
  }

  @media #{$_mediaMDown} {
    margin-left: 0;
    margin-right: var(--space-2);
  }

  @media #{$_mediaMUp} {
    margin-left: var(--space-1);
  }

  @media #{$_mediaSDown} {
    padding: 4px;
    height: 100%;

    svg {
      max-height: 21px;
    }

    &--wishlist,
    &--account {
      display: none;
    }
  }
}

@media (max-width: 650px) {
  .navigation-buttons__button--small-screen {
    display: flex;
  }

  .navigation-row__search-bar {
    &--desktop {
      display: none;
    }

    &--mobile {
      display: block;
    }
  }
}

@media #{$_mediaSDown} {
  .navigation-buttons__button--small-screen {
    height: var(--space-4);
    margin-right: var(--space-0--half);
  }

  .navigation-row__search-bar--mobile {
    padding: 10px var(--space-1);
  }
}

@media #{$_mediaSDown} {
  .navigation-row__title {
    font-size: 15px;
  }
}

@media (min-width: 1060px) {
  .logo--newHeader.navigation-row__logo.top-spacing {
    margin-top: 14px;
  }
}

@media #{$_mediaL} {
  .navigation-buttons {
    flex-basis: 0;
    flex-grow: 0;
  }

  .navigation-row__search-bar {
    &--desktop {
      max-width: 535px;
      margin-right: var(--space-3);
    }
  }

  .navigation-buttons__button {
    &--account,
    &--wishlist {
      margin-right: var(--space-3);
      margin-left: 0;
      width: 50px;
    }

    &--cart {
      margin-right: 0;
      margin-left: 0;
      width: 55px;
    }
  }

  .navigation-row__burger-button {
    margin-right: var(--space-3);
    margin-top: var(--space-4);
    font-size: var(--font-size-S);
  }

  .navigation-row__logo.logo--newHeader {
    margin: 0 var(--space-3) 0 0;
  }
}
</style>
