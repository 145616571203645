import has from 'lodash/has';
import uniq from 'lodash/uniq';

export const productsListState = ({ data, component } = {}) => {
  if (!data) {
    return false;
  }

  const typeWhiteList = [
    'battery_disclaimer',
    'turbocharger_disclaimer',
    'tyre_pressure_disclaimer',
    'waste_oil_disclaimer',
  ];

  if (has(data, ['plp', 'products'])) {
    const includesProductHasLegalWarning = data.plp.products.find(
      ({ product }) => {
        return !!product.articleType &&
          typeWhiteList.includes(product.articleType)
          ? product.articleType
          : null;
      }
    );

    includesProductHasLegalWarning
      ? (data.plp.legalWarningType =
          includesProductHasLegalWarning.product.articleType)
      : null;

    // Add Classes to PLP/SRP container for A/B testing
    data.plp.containerClasses = uniq(
      data.plp.products.map(({ product }) => {
        const key =
          product.pdpCarBar && product.pdpCarBar.key
            ? product.pdpCarBar.key.toLowerCase()
            : null;

        return key !== null
          ? `go--${!key.includes('car_') ? 'car_' : ''}${key}`
          : null;
      })
    );
  }

  return {
    data,
    component,
  };
};
