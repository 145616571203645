<template>
  <div :class="classes" :data-preload="[$options.name]">
    <button class="buttonWrap" @click="openMobileNavigation">
      <span v-if="!isNewHeader" class="body" />
      <Icon v-else v-bind="iconConfig" />
    </button>
  </div>
</template>

<script>
import Icon from 'Components/00-generated/Icon';

import { mapActions } from 'vuex';
import scrollMixin from 'Libs/mixins/scrollMixin';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'BurgerButton',
  components: { Icon },
  mixins: [globalMixin, scrollMixin],
  props: {
    isNewHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconConfig: {
        name: 'burger_menu',
        width: '21',
        height: '21',
      },
    };
  },
  methods: {
    ...mapActions('navigation', ['mobileMenuOpen', 'selectMenu']),
    openMobileNavigation() {
      if (this.isNewHeader) {
        this.selectMenu();
      }
      this.mobileMenuOpen();
      this.disableBodyScrolling();
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.buttonWrap {
  cursor: pointer;
}

.body {
  display: block;
  width: 100%;
  height: 4px;
  background-color: var(--color-white);
  border-radius: var(--border-radius-default);

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 4px;
    left: 0;
    right: 0;
    background-color: var(--color-white);
    border-radius: var(--border-radius-default);
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.buttonWrap {
  width: 28px;
  height: 20px;
  display: block;
  padding: 0;
  position: relative;

  .icon {
    margin-top: 2px;
  }
}

@media #{$_mediaL} {
  .buttonWrap {
    width: 30px;

    .icon {
      margin-top: 0;
    }
  }
}
</style>
