import { mapGetters } from 'vuex';

const nativeAppMixin = {
    data() {
        return {
            isNativeApp: this.isRebrandingChannel
        };
    },
    mounted() {
        this.isNativeApp = this.updateIsNativeApp();
    },
    computed: {
        ...mapGetters('core', {
            isRebrandingChannel: 'isRebrandingChannel',
        }),
    },
    methods: {
        updateIsNativeApp() {
            // Native iOS and Android would use this params to hide the header and footer when needed
            if (!this.isRebrandingChannel) return false;

            const host = window?.location?.host;
            if (!host?.includes('app-ios-k24-de') && !host?.includes('app-android-k24-de')) return false;

            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });

            return params?.isNativeApp === 'true';
        },
    },
};

export default nativeAppMixin;
