var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.componentType,_vm._b({tag:"component",class:[
    _vm.classes,
    'qa-Button',
    {
      'button--disabled': _vm.disabled,
      'button--hasLoading button--loading': _vm.hasLoading,
      'button--focus-border': _vm.hasFocusBorder,
      'button--gray': _vm.isGray,
      'button--icon': !!_vm.icon,
    },
  ],attrs:{"name":_vm.name,"type":_vm.componentType === 'a' ? false : _vm.type,"disabled":_vm.disabled,"href":_vm.href || false,"target":_vm.componentType === 'a' ? _vm.target || !!_vm.target : false,"title":_vm.title || !!_vm.title,"value":_vm.value || !!_vm.value,"data-preload":[_vm.$options.name]},on:{"click":_vm.onClick,"mouseup":_vm.onMouseup}},'component',_vm.attrs,false),[_c('div',{staticClass:"inner"},[_vm._t("icon"),_vm._v(" "),(!!_vm.hasLoading)?_c('LoadingSvg',{class:['icon', 'icon--loading'],attrs:{"original":true,"inline":true}}):_vm._e(),_vm._v(" "),_vm._t("default",function(){return [(!!_vm.title)?_c('span',{staticClass:"title"},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }