import { render, staticRenderFns } from "./PriceDiscounted.vue?vue&type=template&id=33c2caad&scoped=true"
import script from "./PriceDiscounted.vue?vue&type=script&lang=js"
export * from "./PriceDiscounted.vue?vue&type=script&lang=js"
import style0 from "./PriceDiscounted.vue?vue&type=style&index=0&id=33c2caad&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33c2caad",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F01-atoms%2Fprice-discounted%2FPriceDiscounted.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports