import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import set from 'lodash/set';
import isArray from 'lodash/isArray';

import TrackingHandler from '../../../libs/helpers/tracking';
import UrlHelper from '../../../libs/helpers/url';

const trackJourneyType = (type) => {
  window.localStorage.setItem('journeyType', type);
};

export const trackLogin = () => {
  const userId = get(window.dataLayer, '[0].page.userId', false);
  if (window.location.href.includes('#login') && userId) {
    TrackingHandler.track({
      data: {
        event: 'account_loginSuccessful',
        page: { userId },
      },
    });

    UrlHelper.clearLocationHash();
  }
};

export const trackLoginState = ({ rootState }) => {
  const isLoggedIn = get(rootState, 'carSelection.data.isLoggedIn', false);
  set(window.dataLayer, '[0].page.userLoginState', isLoggedIn);
};

export const trackEnvironment = ({ rootState }) => {
  const environment = get(rootState, 'core.environment', 'unknown');
  set(window.dataLayer, '[0].page.environment', environment);
};

export const trackResultItemCount = (_store, resultItemCount) => {
  const doesResultItemCountExist = get(
    window.dataLayer,
    '[0].page.resultItemCount',
    false
  );
  if (!doesResultItemCountExist) {
    set(window.dataLayer, '[0].page.resultItemCount', resultItemCount);
  }
};

/**
 * This function is triggered from the checkout component
 * of fe-components.
 */
export const trackCheckoutLogin = () => {
  const userId = get(window.dataLayer, '[0].page.userId', false);
  if (window.location.href.includes('#checkoutLogin')) {
    if (userId) {
      TrackingHandler.track({
        data: {
          event: 'checkoutLogin',
          page: { userId },
        },
      });
    }

    UrlHelper.clearLocationHash();
  }
};

export const trackMiniCart2Checkout = () => {
  return TrackingHandler.trackAsync({
    data: {
      event: 'checkout_initiateCheckout',
      checkoutButtonLocation: 'miniCartViewCheckout',
    },
  });
};

export const trackGarageOpen = () => {
  TrackingHandler.track({
    data: {
      event: 'identifyCar_accountGarage',
    },
  });
};

export const trackBreadcrumbClick = (_store, { categoryKey, event }) => {
  TrackingHandler.track({
    data: {
      event,
      categoryKey,
    },
  });

  trackJourneyType('Catalog');
};

export const trackSideNavigationClick = (_store, { categoryKey, event }) => {
  TrackingHandler.track({
    data: {
      event,
      categoryKey,
    },
  });

  trackJourneyType('Catalog');
};

export const trackActiveMinicart = ({ rootState }) => {
  const products = get(rootState, 'miniCart.data.minicart.products', []);

  TrackingHandler.track({
    data: {
      event: 'miniCart_view',
      products: products.map((p) =>
        discardUndefinedKeys({
          id: p.sku,
          name: p.name,
          articleNumber: p.displaySku,
          price: p.grossPriceUnformatted,
          brand: p.brand,
          category: p.category,
          categoryId: p.categoryId,
        })
      ),
    },
  });
};

export const trackCarSelection = async (_store, { vehicle, event }) => {
  return TrackingHandler.trackDefinedAsync({
    data: {
      event: event,
      page: {
        carType: vehicle && vehicle.ktypnr,
        carManufacturer: vehicle && vehicle.manufacturer,
        carSeries: vehicle && calculateCarSeries(vehicle),
        carModel: vehicle && vehicle.type,
      },
    },
  });
};

export const trackCarUnselection = async (_store, { callback }) => {
  TrackingHandler.trackAsync({
    data: {
      event: 'identifyCar_unselect',
    },
  }).then(() => callback && callback());
};

export const trackSelectedCar = async (_store, { vehicle }) => {
  const carDetails = {
    carType: vehicle && vehicle.ktypnr,
    carManufacturer: vehicle && vehicle.manufacturer,
    carSeries: vehicle && calculateCarSeries(vehicle),
    carModel: vehicle && vehicle.type,
  };

  TrackingHandler.assignToDataLayerPage(carDetails);
};

const searchList = {
  ms: ['search_manual'],
  msb: ['search_manualButtonClick', 'search_manual'],
  as: ['search_suggestion'],
  oens: ['search_oenumber'],
  static: ['search_static'],
};

const trackSearch = (searchObject) => {
  trackJourneyType('Search');

  return TrackingHandler.track({
    data: searchObject,
  });
};

export const trackSearchResult = async (
  { rootState },
  { searchKeywords, numberOfSearchResults }
) => {
  const url = window.location.href;

  if (!url.indexOf('#')) {
    // No new Search was intiated
    return;
  }

  let genart = '';
  let searchType = url.substring(url.indexOf('#') + 1);

  if (searchType.includes('static|')) {
    genart = searchType.split('static|')[1];
    searchType = 'static';
  }

  const carDisplayName = get(rootState, 'carSelection.data.carDisplayName', '');
  const carId = get(rootState, 'carSelection.data.vehicle.ktypnr', '');
  const selectedCarInfo =
    carId && carDisplayName
      ? `${carId}, ${carDisplayName}`
      : 'car_not_selected';

  const searchEvent = {
    searchQuery: searchKeywords.replace(/.+[@].+/g, 'email'),
    selectedCarInfo,
    numberOfSearchResults,
  };

  // If found by identifier
  const firstProduct = get(
    rootState,
    'productsList.data.plp.products[0].product',
    {}
  );

  if (firstProduct.resultSourceType) {
    searchEvent.event = 'found_by_identifier';
    trackSearch(searchEvent);
  }

  if (searchType in searchList) {
    searchList[searchType].forEach((event) => {
      searchEvent.event = event;

      // To avoid (not set) in GA
      if (!searchEvent.searchQuery) {
        searchEvent.searchQuery = `(${event})`;
      }

      if (genart !== '') {
        searchEvent.genart = genart;
        searchEvent.searchQuery += ` (genart: ${genart})`;
      }

      trackSearch(searchEvent);
    });

    UrlHelper.clearLocationHash();
  }
};

export const trackPromoClick = async (_store, { position, href, callback }) => {
  const { prc } = UrlHelper.getUrlParameters(href);

  const event = {
    data: {
      event: 'promotionClick',
      ecommerce: {
        promoClick: {
          promotions: [{ name: position }],
        },
      },
    },
  };

  if (prc) {
    event.data.ecommerce.promoClick.promotions[0].id = prc;
  }

  TrackingHandler.trackAsync(event)?.then(() => callback && callback());
};

export const trackCookiesBannerConsent = async (
  _store,
  { buttonClicked, consent: { functional, marketing } }
) => {
  TrackingHandler.track({
    data: {
      event: 'cookies_consent_updated',
      buttonClicked,
      consent: {
        functional,
        marketing,
      },
    },
  });
};

export const trackBannersInView = async ({ rootState }) => {
  const banners = rootState.core.allBanners;
  if (!isArray(banners)) {
    return;
  }

  const withPrcCodeBanners = banners.map((banner) => {
    const dto = {
      name: banner.position,
    };

    const { prc } = UrlHelper.getUrlParameters(banner.link.href);

    if (prc) {
      dto.id = prc;
    }

    return dto;
  });

  const event = set(
    { event: 'promotionView' },
    'ecommerce.promoView.promotions',
    withPrcCodeBanners
  );

  TrackingHandler.track({
    data: event,
  });
};

const PAGE_TYPE = {
  PDP: 'Product Detail Page',
  PLP: 'Product Listing Page',
  CLP: 'Catalog Listing Page',
  SRP: 'Search Page',
};

const SEARCH_RESULTS = 'Search Results';

export const getProductCategoryLevelTwo = (productId) => {
  const pageType = get(window.dataLayer, '[0].page.pageType');
  // if PDP
  if (pageType === PAGE_TYPE.PDP) {
    const productDetailEvent = window.dataLayer.find(
      (d) => d.event === 'productDetail'
    );

    const productDetail =
      productDetailEvent && productDetailEvent.ecommerce
        ? productDetailEvent.ecommerce.detail.actionField
        : null;

    return productDetail ? productDetail.list : pageType;
  }
  // else if PLP or CLP
  if ([PAGE_TYPE.PLP, PAGE_TYPE.CLP].includes(pageType)) {
    const productImpEvent = window.dataLayer.find(
      (d) => d.event === 'productImpressions'
    );

    const productImpressions =
      productImpEvent && productImpEvent.ecommerce
        ? productImpEvent.ecommerce.impressions
        : null;

    const productImp = productImpressions
      ? productImpressions.find((p) => p.id === productId)
      : null;

    return productImp ? productImp.list : pageType;
  }

  if (pageType === PAGE_TYPE.SRP) {
    return SEARCH_RESULTS;
  }
  // pageType is the default for all other cases
  return pageType;
};

export const trackProductClick = async (_store, { product, position }) => {
  const categoryLevel2 = getProductCategoryLevelTwo(product.sku);

  const promises = [
    TrackingHandler.trackDefinedAsync({
      data: {
        event: 'productClick',
        ecommerce: {
          click: {
            actionField: { list: categoryLevel2 },
            products: [
              {
                name: product.name,
                id: product.sku,
                articleNumber: product.displaySku,
                price: product.prices && product.prices.price,
                brand: product.brandName,
                position: position,
                category: categoryLevel2,
              },
            ],
          },
        },
      },
    }),
  ];

  if (product.resultSourceType) {
    promises.push(
      TrackingHandler.trackDefinedAsync({
        data: {
          event: 'productClick_found_by_identifier',
          type_of_identifier: product.resultSourceType,
          id: product.sku,
        },
      })
    );
  }

  return Promise.all(promises);
};

export const trackAutoExpand = async () => {
  TrackingHandler.track({
    data: {
      event: 'identifyCar_autoExpand',
    },
  });
};

function discardUndefinedKeys(obj) {
  return pickBy(obj, (value) => value !== undefined);
}

export const calculateCarSeries = (vehicle) => {
  let series = vehicle.model;
  const bodyCodes = vehicle.bodyCodes;
  if (bodyCodes && bodyCodes.length > 0) {
    series += '(' + bodyCodes.sort().join(', ') + ')';
  }
  return series;
};

export const trackBranchesAvailabilityCheck = (
  _store,
  { productName, brandName, sku }
) => {
  return TrackingHandler.track({
    data: {
      event: 'checkAvailabilityInBranch',
      products: [
        {
          name: productName,
          brand: brandName,
          id: sku,
        },
      ],
    },
  });
};

export const billingAddress = (_store, customerBillingAddress) => {
  if (customerBillingAddress) {
    TrackingHandler.track({
      data: {
        event: 'billingAddress',
        customerBillingAddress,
      },
    });
  }
};

export const trackAlternativeProductOpened = (product, alternativeProduct) => {
  return TrackingHandler.trackAsync({
    data: {
      event: 'alternativeProducts_modalOpened',
      product: product,
      alternativeProduct: alternativeProduct,
    },
  });
};

export const trackAlternativeProductConfirm = (product, alternativeProduct) => {
  return TrackingHandler.trackAsync({
    data: {
      event: 'alternativeProducts_addToCart',
      product: product,
      alternativeProduct: alternativeProduct,
    },
  });
};

export const trackAlternativeProductCancel = (product, alternativeProduct) => {
  return TrackingHandler.trackAsync({
    data: {
      event: 'alternativeProducts_modalDismissed',
      product: product,
      alternativeProduct: alternativeProduct,
    },
  });
};

export const trackAlternativeProductDetailPageOpened = (
  product,
  alternativeProduct
) => {
  return TrackingHandler.trackAsync({
    data: {
      event: 'alternativeProducts_detailPageOpened',
      product: product,
      alternativeProduct: alternativeProduct,
    },
  });
};
