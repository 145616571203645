<i18n src="./translations"></i18n>

<template>
  <div
    v-show="!isNativeApp"
    :class="[classes, { 'page-footer--rebranding': isRebrandingChannel }]"
    :data-preload="[$options.name]"
  >
    <div class="page-footer-wrapper">
      <div v-if="!isReducedFooter">
        <div class="row links footer-top">
          <LinksList
            v-if="navigation.cars"
            class="column"
            columns="inline"
            separator="|"
            :title="navigation.cars.headline"
            :items="navigation.cars.links"
          />

          <LinksList
            v-if="navigation.products"
            class="column"
            columns="inline"
            separator="|"
            :title="navigation.products.headline"
            :items="navigation.products.links"
          />
        </div>
        <div class="row links footer-middle">
          <LinksList
            v-for="(linkList, index) in navigation.footer.links"
            :key="index"
            class="column one links__about"
            :title="linkList.headline"
            :items="linkList.links"
          >
          </LinksList>

          <div
            v-if="!!paymentMethods.length || !!deliveryMethods.length"
            class="column two services"
          >
            <FooterServices
              v-if="!!paymentMethods.length"
              type="payment"
              :title="$t('page-footer.accepted_payments')"
              :providers="paymentMethods"
            />

            <FooterServices
              v-show="showDeliveryMethods"
              type="delivery"
              :title="$t('page-footer.shipping_providers')"
              :providers="deliveryMethods"
            />
          </div>
        </div>

        <div
          v-if="
            getShowAppLinks ||
            getShowSocials ||
            (getShowChannels && navigation.channels)
          "
          class="row footer-bottom"
        >
          <DeshopSocial
            class="deshop-social"
            :show-app-links="getShowAppLinks"
            :show-socials="getShowSocials"
          />

          <div
            v-if="getShowChannels && navigation.channels"
            class="channelsWrapper"
          >
            <LinksList
              type="channels"
              :items="navigation.channels.links"
              :collapsable="false"
            />
          </div>
        </div>
      </div>

      <div v-if="isReducedFooter" class="infoWrapper row">
        <div class="phoneContainer">
          <div class="phone">
            {{ $t('page-footer.have_questions') }}
            <a :href="`tel:${contactUsInfo.phone}`">{{
              contactUsInfo.formattedPhone
            }}</a>
          </div>

          <div class="times">
            {{ contactUsInfo.openingTimes }}
          </div>
        </div>

        <div class="links">
          <a
            v-for="(link, index) in navigation.about.links"
            :key="index"
            class="link"
            :href="link.url"
            >{{ link.title }}</a
          >
        </div>
      </div>

      <div class="copyrightWrapper">
        <div class="copyright">
          {{ $t('page-footer.copyright', { '%url%': salesChannelUrl }) }}
        </div>

        <img
          class="logo"
          alt="Tec Alliance"
          :src="resolveFile('images/page-footer/tec_alliance.jpg')"
          loading="lazy"
          width="159"
          height="53"
        />
      </div>

      <p v-if="!isReducedFooter" class="disclaimer">
        {{ $t('page-footer.disclaimer') }}
      </p>
    </div>
  </div>
</template>

<script>
import './images/tec_alliance.jpg';
import { mapGetters, mapState } from 'vuex';
import capitalize from 'lodash/capitalize';
import FooterServices from 'Components/02-molecules/footer-services/FooterServices';
import LinksList from 'Components/02-molecules/links-list/LinksList';
import DeshopSocial from 'Components/02-molecules/deshop-social/DeshopSocial';
import globalMixin from 'Libs/mixins/globalMixin';
import nativeAppMixin from 'Libs/mixins/nativeAppMixin';

export default {
  name: 'PageFooter',
  components: {
    FooterServices,
    LinksList,
    DeshopSocial,
  },
  mixins: [globalMixin, nativeAppMixin],
  props: {
    showAppLinks: {
      type: Boolean,
      required: false,
      default: true,
    },
    showChannels: {
      type: Boolean,
      required: false,
      default: true,
    },
    showSocials: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapState('core', {
      navigation: ({ navigation }) => navigation,
      companyId: (state) => state.requestData.companyId,
      shopName: ({ salesChannel }) =>
        salesChannel.displayName &&
        capitalize(salesChannel.displayName.replace('www.', '')),
      salesChannelUrl: ({ salesChannel }) => salesChannel.baseUrl,
      salesChannelConfig: ({ salesChannel }) => salesChannel.config,
      contactUsInfo() {
        return {
          email: this.isBusinessCustomer
            ? this.salesChannelConfig.b2BCustomerServiceEmail
            : this.salesChannelConfig.customerServiceEmail,
          phone: this.isBusinessCustomer
            ? this.salesChannelConfig.b2BCustomerServicePhoneLink
            : this.salesChannelConfig.customerServicePhoneLink,
          formattedPhone: this.isBusinessCustomer
            ? this.salesChannelConfig.b2BCustomerServicePhoneNumber
            : this.salesChannelConfig.customerServicePhoneNumber,
          openingTimes: this.isBusinessCustomer
            ? this.salesChannelConfig.b2BCustomerServiceOpeningHours
            : this.salesChannelConfig.customerServiceOpeningHours,
          hideSupportPhoneNumber:
            this.salesChannelConfig.hideSupportPhoneNumber || false,
        };
      },
      paymentMethods({ paymentMethods }) {
        const methods = paymentMethods.map((icon) => ({
          icon,
          title: this.$t(`page-footer.payment.${icon}`),
        }));

        return this.isBusinessCustomer ? [methods[0]] : methods;
      },
      deliveryMethods({ deliveryMethods }) {
        return deliveryMethods.map((icon) => ({
          icon,
          title: this.$t(`page-footer.delivery.${icon}`),
        }));
      },
      getShowSocials() {
        return this.showSocials && this.navigation.footer.show_socials;
      },
      getShowAppLinks() {
        return this.showAppLinks && this.navigation.footer.show_app_links;
      },
      getShowChannels() {
        return this.showChannels && this.navigation.footer.show_channels;
      },
    }),
    ...mapGetters('core', {
      options: 'getLayoutParams',
      isBusinessCustomer: 'isBusinessCustomer',
    }),
    showDeliveryMethods() {
      return !!this.deliveryMethods.length && !this.isBusinessCustomer;
    },
    contactUsHeadline() {
      return this.contactUsInfo.hideSupportPhoneNumber
        ? this.$t('page-footer.hotline.customer_service')
        : this.$t('page-footer.hotline.headline');
    },
    isReducedFooter() {
      return this.isMobileSalesChannel || this.options.reducedFooter;
    },
  },
  serverCacheKey: ({ id = false }) => {
    /* istanbul ignore next */
    return id;
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'utils/fonts';
@import 'mixins';

.pageFooter {
  margin-top: var(--space-8);
  padding-top: var(--space-8);
  padding-bottom: var(--space-8);
  background: var(--color-white);
  text-align: center;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  color: var(--color-rebranding-black-out);
  background-color: var(--color-alabaster);

  .page-footer-wrapper {
    @include pageWidth;
    @include pagePadding;
  }

  @media #{$_mediaSDown} {
    @include page-size-padding(var(--space-1--half));
  }
}

.row {
  display: flex;
  flex-wrap: nowrap;

  &.footer-top {
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  &.footer-bottom {
    justify-content: space-between;
    align-items: center;
  }

  &.footer-middle {
    margin: var(--space-8) 0;
  }

  .column {
    width: 100%;
  }

  .column + .column {
    margin-left: var(--space-2);
  }
}

.link {
  display: inline-block;
  white-space: nowrap;
  margin-right: var(--space-1);
}

.page-footer--rebranding {
  background-color: var(--color-rebranding-primary--pressed);
  color: var(--color-rebranding-white);
  margin-top: 0;
  padding-top: var(--space-8);

  .phone {
    @include rebranding-h5;
  }

  .times {
    @include rebranding-para-small-regular;
  }

  .links {
    @include rebranding-lead-regular;

    margin-top: var(--space-1--half);
    margin-bottom: var(--space-3);
  }

  .copyright {
    @include rebranding-para-small-regular;
  }
}

.footerServices {
  & + & {
    margin-top: var(--space-5);
  }
}

.disclaimer {
  max-width: 1000px;
  font-size: var(--font-size-S);
  line-height: 16px;
  text-align: left;
  padding: 0;
}

.copyrightWrapper {
  display: flex;
  padding: var(--space-2) 0 0;
  margin: var(--space-2) 0 0;
  border-top: 1px solid var(--color-alto);
  justify-items: space-between;
}

.copyright {
  flex: 1;
  font-size: var(--font-size-SM);
  line-height: 20px;
  text-align: left;
}

.logo {
  display: block;
  width: 150px;
  height: auto;
}

.infoWrapper {
  padding: var(--space-2) 0 0;
  margin: var(--space-2) 0 0;
  justify-content: space-between;
  width: 100%;
}

.phoneContainer {
  text-align: left;
}

.phone {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.times {
  font-size: var(--font-size-SM);
}

.deshop-social {
  flex-shrink: 0;
}

.deshop-social,
.channelsWrapper {
  margin-bottom: var(--space-1);
}

@media #{$_mediaLDown} {
  .pageFooter {
    margin: var(--space-4) 0 0;
    padding: 0 0 var(--space-5);

    .page-footer-wrapper {
      margin: 0;
      padding: 0;
    }
  }

  .page-footer--rebranding {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);

    .copyrightWrapper {
      margin-top: var(--space-3);
      display: flex;
      flex-direction: column-reverse;

      .logo {
        margin-top: 0;
        margin-bottom: var(--space-2);
      }
    }
  }

  .row {
    flex-direction: column;

    &.footer-middle {
      margin: 0 0 var(--space-2) 0;
    }

    &.footer-bottom {
      display: block;
      text-align: left;
    }

    .column + .column {
      margin-left: 0;
    }
  }

  .disclaimer {
    margin: var(--space-2) var(--space-2) 0;
  }

  .services {
    margin-top: var(--space-5);
  }

  .deshop-social {
    margin: 0 var(--space-2);
  }

  .infoWrapper,
  .copyrightWrapper {
    margin: var(--space-5) 0 0;
    padding: 0 var(--space-2);
    border: none;
    display: block;
    text-align: left;

    .logo {
      margin-top: var(--space-2);
    }
  }

  .infoWrapper {
    margin-top: 0;
  }

  .footerServices {
    padding: 0 var(--space-2);

    & + & {
      margin-top: var(--space-3);
    }
  }

  .channelsWrapper {
    margin: var(--space-5) var(--space-2) 0;
  }
}

@media #{$_mediaMDown} {
  .channelsWrapper {
    margin: var(--space-5) var(--space-2) 0;
  }
}
</style>
