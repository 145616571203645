<template>
  <div :class="[classes, containerClasses]" :data-preload="[$options.name]">
    <PlpDisclaimer21 v-if="legalWarningType" :article-type="legalWarningType" />

    <div class="plpContainer qa-ProductCardList__sectionContainer">
      <Product
        v-for="(item, index) in products"
        :key="`Product-${index}`"
        class="product"
        :product="item.product"
        :position="index + 1"
      />

      <Pagination v-if="pagination && pagination.maxPage > 1" :pagination="pagination" />
    </div>

    <div v-if="hasTopSeries()" class="topSeriesList">
      <span class="topSeriesTitle">
        {{ topSeries.title }}
      </span>

      <div class="grid">
        <div class="row">
          <LinksList
            v-for="(carModel, index) in topSeries.items"
            :key="`LinksList-${index}`"
            class="col xl-4 lg-4 sm-4"
            :title="carModel.manufacturer"
            :items="carModel.listItems"
            :image="carModel.image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import globalMixin from 'Libs/mixins/globalMixin';

import PlpDisclaimer21 from 'Components/02-molecules/plp-disclaimer-21/PlpDisclaimer21';
import Product from 'Components/02-molecules/plp-product-21/PlpProduct21.vue';
import Pagination from 'Components/02-molecules/pagination/Pagination';
import LinksList from 'Components/02-molecules/links-list/LinksList';

export default {
  name: 'PlpProducts21',
  components: {
    PlpDisclaimer21,
    Product,
    Pagination,
    LinksList,
  },
  mixins: [globalMixin],
  computed: {
    ...mapState('productsList', {
      legalWarningType: (state) => state.data.plp.legalWarningType,
      products: (state) => state.data.plp.products,
      pagination: (state) => state.data.plp.pagination,
      topSeries: (state) => state.data.topSeries,
      containerClasses: (state) => state.data.plp.containerClasses,
    }),
  },
  methods: {
    hasTopSeries() {
      return this.topSeries && Object.keys(this.topSeries).length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';

.plpProducts21 {
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
}

.plpContainer {
  width: 100%;
  padding: 0 0 14px;
}

.product {
  width: 100%;

  & + & {
    margin-top: 10px;
  }

  @media #{$_mediaLUp} {
    .plpProducts21 {
      padding: var(--space-4) var(--space-2);
    }

    & + & {
      margin-top: 24px;
    }
  }
}

.topSeriesList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .linksList {
    flex-grow: 0;
  }
}

.topSeriesTitle {
  width: 100%;
  margin: var(--space-4) 0 20px 0;
  text-align: left;
  color: var(--color-chathamsblue);
  font-weight: 700;
  font-size: var(--font-size-M);
  line-height: 20px;
}

@media #{$_mediaSDown} {
  .plpProducts21 {
    padding: 10px 0;
  }
}

@media #{$_mediaMDown} {
  .topSeriesTitle {
    margin: 20px 0;
  }

  .topSeriesList {
    flex-direction: column;
    padding: 0;
  }
}

@media #{$_mediaXLUp} {
  .topSeriesList {
    flex-direction: row;
    padding: 14px var(--space-1--half);
  }
}
</style>
