<template>
  <div
    :class="[
      classes,
      'qa-CarisDropdown',
      { 'carisDropdown--expanded': expanded },
      { 'carisDropdown--disabled': disabled },
    ]"
    :data-preload="[$options.name]"
  >
    <div class="headerWrapper">
      <button
        class="header"
        type="button"
        :tabindex="tabIndex"
        @click="handleToggle"
      >
        <span>{{ placeholder }}</span>
        <ChevronRightSvg class="icon" />
      </button>
    </div>
    <div class="optionsWrapper">
      <ul v-if="carList" class="optionsList">
        <li
          v-for="(item, index) in carList"
          :key="`carList${index}`"
          class="option"
          @click="handleCarSelection(index)"
        >
          <span class="optionLabel">
            {{ item }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ktypnrMixin } from 'Libs/mixins/ktypnrMixin.js';
import ChevronRightSvg from 'Components/00-generated/ChevronRightSvg';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'CarisDropdown',
  components: {
    ChevronRightSvg,
  },
  mixins: [globalMixin, ktypnrMixin],
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    carList: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    tabIndex() {
      return this.modifier && this.modifier.includes('disabled') ? '-1' : '0';
    },
  },
  methods: {
    handleToggle() {
      this.expanded = !this.expanded;
    },
    handleCarSelection(ktypnr) {
      this.setKtypnr(ktypnr);
    },
    open() {
      this.expanded = true;

      setTimeout(() => this.$el.scrollIntoView({ behavior: 'smooth' }), 100);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.carisDropdown {
  position: relative;

  &--disabled {
    display: none;

    .header {
      cursor: initial;
      color: var(--color-gallery);
      border-color: var(--color-gallery);
    }

    .icon {
      fill: var(--color-gallery);
    }
  }

  &--expanded {
    .icon {
      transform: rotate(90deg);
    }

    .optionsWrapper {
      max-height: 1000px;
      display: block;
    }
  }
}

.header {
  border: 1px solid var(--color-alto);
  border-radius: var(--border-radius-default);
  padding: 12px var(--space-2);
  color: var(--color-rolling-stone);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.headerWrapper {
  z-index: 1;
}

.icon {
  fill: var(--color-primary);

  width: 24px;
  flex-shrink: 3;
  flex-grow: 0;
}

.optionsWrapper {
  position: absolute;
  top: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  transition: max-height var(--time-S) ease-in-out;
  padding-right: var(--space-2);
  padding-bottom: var(--space-2);
  display: none;
  max-height: 0;
}

.optionsList {
  border-bottom-left-radius: var(--border-radius-default);
  border-bottom-right-radius: var(--border-radius-default);
  border: solid 1px var(--color-alto);
  background-color: var(--color-white);
  max-height: 200px;
  overflow: auto;
}

.option {
  cursor: pointer;
  list-style: none;
  padding: var(--space-2);

  &:hover {
    background-color: var(--color-alabaster);
  }
}
</style>
