
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="ReturnSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.009 23.943"><path d="M14.081.001c-1.15-.044-1.518 1.104-.406 1.438 2.953 1.417 5.224 4.75 5.344 8.72h-2.154c-1.19.003-1.565 1.254-.75 2.187l5.438 6.22c.237.265.496.378.781.375.286-.004.562-.154.812-.438l5.5-6.188c.705-.796.357-2.189-.812-2.188h-2.031C25.078 4.382 20.686-.018 14.081.001zm-7.406 5c-.286.003-.562.155-.812.438l-5.5 6.188c-.705.797-.357 2.222.812 2.22l2.031-.03c.721 5.742 5.11 10.145 11.72 10.124 1.15.045 1.515-1.104.405-1.438-2.953-1.417-5.226-4.75-5.344-8.719h2.154c1.19-.004 1.565-1.224.75-2.156L7.453 5.41c-.234-.266-.493-.41-.778-.408z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'ReturnSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
