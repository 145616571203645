<i18n src="./translations"></i18n>

<template>
  <div
    :class="[classes, { 'is-kvi': showKviLabel }]"
    :data-preload="$options.name"
  >
    <div v-if="showKviLabel" class="kvi-label">{{ $t('price.kvi') }}</div>

    <span :class="label">{{ label }}</span>

    <span class="price-value">{{ value }}</span>

    <span
      v-if="isBusinessCustomer"
      class="price__value-type"
      :class="`price-business-customer__${isNet ? 'netto' : 'gross'}`"
    >
      {{ isNet ? $t('price.business-net') : '' }}
    </span>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'Price',
  mixins: [globalMixin],
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    isNet: {
      type: Boolean,
      default: false,
    },
    isKvi: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('core', ['isBusinessCustomer']),
    ...mapState('renderingSalesChannelConfig', {
      isKviLabelEnabled: ({ isKviLabelEnabled }) => isKviLabelEnabled,
    }),
    showKviLabel() {
      return this.isKvi && this.isKviLabelEnabled;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.price {
  display: block;
  white-space: nowrap;
  // text-align: right;

  color: var(--color-mine-shaft);

  &--inline {
    display: inline-block;
    color: inherit;
  }

  &--bold {
    font-weight: bold;
  }

  &--big {
    text-align: left;
    font-weight: bold;
    font-size: 40px;
    color: var(--color-chathamsblue);
  }

  &--com {
    color: var(--color-primary);
  }

  &--highlighted {
    color: var(--color-chathamsblue);
  }

  &--outdated {
    color: var(--color-chathamsblue);
    text-decoration: line-through;
  }

  &.is-kvi .price-value {
    color: var(--color-primary);
  }

  .kvi-label {
    position: relative;
    padding: 0 var(--space-1);
    margin-bottom: var(--space-0--half);
    font-size: var(--font-size-S);
    color: var(--color-white);
    background-color: var(--color-primary);
    height: var(--space-3);
    max-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: '';
      display: inline-block;
      width: var(--space-3);
      height: var(--space-3);
      background-color: var(--color-white);
      transform: rotate(45deg);
      margin-left: var(--space-1--half);
      margin-right: calc(var(--space-2--half) * -1);
    }
  }
}

.price__value-type {
  font-size: var(--font-size-M);
  font-weight: normal;
}
</style>
