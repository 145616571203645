<template>
  <div class="productImageThumbnail">
    <AdvancedImage
      :lazy="true"
      :src="image.src"
      :alt="image.alt"
      :config="config"
      modifier="centered"
    />
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import AdvancedImage from 'Components/01-atoms/advanced-image/AdvancedImage';

export default {
  name: 'ProductImageThumbnail',
  components: { AdvancedImage },
  mixins: [globalMixin],
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      config: {
        q: 80,
        w: 100,
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.productImageThumbnail {
  width: 100%;
  height: 70px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
