import * as getters from './getters.js';
import * as mutations from '../core/mutations';
import * as actions from './actions';
import { setCoreState } from './state.js';

export default (state) => ({
  namespaced: true,
  state: setCoreState(state),
  getters,
  mutations,
  actions,
});
