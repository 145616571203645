
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="PaymentOptionsSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="none" d="M0 0h32v32H0z"/><path fill="#545456" d="M22.951 11.217v.969h-3.042v-.969zm-14.894.968h3.85c.042-.323.083-.645.123-.969H8.057v.969zm0 1.913h3.6c.043-.329.085-.651.125-.969h-3.72v.969zm17.95-8.568v9.949a1.53 1.53 0 01-1.531 1.53h-3.313a10.758 10.758 0 01-.22 1.455c-.452 2.142-1.637 5.117-.831 8.409a57.109 57.109 0 00-5.984 1.1c-.273-4.059-2.263-4.938-1.8-8.46.542-4.132.843-6.453 1.2-9.243a1.366 1.366 0 011.329-1.306 1.26 1.26 0 011.25 1.363 56.079 56.079 0 00.323 7.385c.147.539 1.086.636 1.086-.512v-1.725h6.457a.509.509 0 00.509-.51V9.856h-7.058a2.58 2.58 0 00-2.567-2.248 2.7 2.7 0 00-2.639 2.248H6.53v5.108a.51.51 0 00.51.51h4.448l-.2 1.53H6.53A1.53 1.53 0 015 15.479V5.53A1.53 1.53 0 016.53 4h17.95a1.53 1.53 0 011.532 1.53zm-1.531.51a.51.51 0 00-.509-.51H7.04a.51.51 0 00-.51.51v1.08h17.95V6.04zM8.952 21.459a37.37 37.37 0 001.943-.551 9.759 9.759 0 01.09-1.569c.045-.353.091-.7.135-1.027l-3.246 1.152c-.617.224-.821.643-.656 1.145a1.39 1.39 0 001.734.85zm2.031.4l-2.026.662c-.459.165-.611.48-.489.852a1.038 1.038 0 001.292.634 38.726 38.726 0 001.7-.486 7.786 7.786 0 01-.477-1.658z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'PaymentOptionsSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
