export default {
  SET_ORDER_MAP(state, orderMap) {
    state.orderMap = orderMap;
  },
  SET_ORDER_DETAIL(state, details) {
    const order = state.orderMap[details.orderId];
    if (order) {
      order.details = details;
    }
  },
};
