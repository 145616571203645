<i18n src="./translations"></i18n>

<template>
  <PdpTabBase
    class="pdp-description-tab"
    :headline="$t('pdp-description-tab.header')"
    :class="classes"
    :data-preload="[$options.name]"
    :is-expanded="activeTab"
  >
    <PdpTable
      v-show="restrictions && !!restrictions.length"
      class="pdp-description-tab__restrictions-table"
      :lines="restrictions"
      :columns="[
        { key: 'title', label: '' },
        { key: 'value', label: '' },
      ]"
      :show-headers="false"
      :headline="$t('pdp-description-tab.restrictions_header')"
      modifier="primary"
    />

    <PdpTable
      v-show="itemFeatures && !!itemFeatures.length"
      class="pdp-description-tab__features-table"
      :lines="itemFeatures"
      :columns="[
        { key: 'title', label: '' },
        { key: 'value', label: '' },
      ]"
      :show-headers="false"
      :headline="$t('pdp-description-tab.features_header')"
    />

    <TyreLabels :attributes="features" :images="images"></TyreLabels>

    <ProductBundles
      v-if="productBundles && productBundles.length"
      :items="productBundles"
    />

    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="description"
      class="pdp-description-tab__description"
      v-html="description"
    />
    <!-- eslint-disable vue/no-v-html -->

    <template v-if="filteredDocuments && filteredDocuments.length">
      <span class="pdp-description-tab__document-header">
        {{ $t('pdp-description-tab.document_header') }}
      </span>
      <div
        v-for="({ url, title }, index) in filteredDocuments"
        :key="index"
        class="pdp-description-tab__document"
      >
        <Icon class="pdp-description-tab__document-icon" name="download" />
        <MaskedLink
          class="pdp-description-tab__document-label"
          :redirect-to="url"
          :text="title"
          target="_blank"
          rel="noreferrer noopener"
        />
      </div>
    </template>
    <template v-if="hazardousGoodInfo">
      <HazardousGood
        v-show="isHazardousGood"
        class="pdp-description-tab__hazardous-good"
        :information="hazardousGoodInformation"
        :documents="{ items: documents }"
        :is-collapsible="false"
        modifier="secondary"
      />
    </template>
  </PdpTabBase>
</template>

<script>
import PdpTable from 'Components/01-atoms/pdp-table/PdpTable';
import globalMixin from 'Libs/mixins/globalMixin';
import Icon from 'Components/00-generated/Icon';
import HazardousGood from 'Components/02-molecules/hazardous-good/HazardousGood';
import PdpTabBase from 'Components/01-atoms/pdp-tab-base/PdpTabBase';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';
import ProductBundles from 'Components/02-molecules/product-bundles/ProductBundles';
import TyreLabels from 'Components/02-molecules/tyre-labels/TyreLabels';
import { isTypeLabel } from 'Components/02-molecules/tyre-labels/models/tyre-labels';

export default {
  name: 'PdpDescriptionTab',
  components: {
    HazardousGood,
    PdpTable,
    Icon,
    PdpTabBase,
    MaskedLink,
    ProductBundles,
    TyreLabels,
  },
  mixins: [globalMixin],
  props: {
    restrictions: {
      type: Array,
      default: () => [],
    },
    productBundles: {
      type: Array,
      default: () => [],
    },
    features: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: '',
    },
    documents: {
      type: Array,
      default: () => [],
    },
    isHazardousGood: {
      type: Boolean,
      default: false,
    },
    hazardousGoodInformation: {
      type: Object,
      default: () => ({}),
    },
    hazardousGoodInfo: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    itemFeatures() {
      return this.features.filter((feature) => !isTypeLabel(feature?.id));
    },
    filteredDocuments() {
      let documents = [];
      // Here we need to distinguish: this.hazardousGoodInfo > A SC key
      // this.isHazardousGood > If the product itself is a hazardous good
      if (
        this.documents.length &&
        this.hazardousGoodInfo &&
        this.isHazardousGood
      ) {
        documents = this.documents.filter(
          (doc) => doc.title !== 'Sicherheitsdatenblatt'
        );
      } else {
        documents = this.documents;
      }
      return documents;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'mixins';
@import 'variables/breakpoints';

@mixin default-typography {
  font-size: var(--font-size-M);
  line-height: var(--font-size-LLXL);
}

.pdp-description-tab__restrictions-table {
  margin-bottom: 40px;
}

.pdp-description-tab__restrictions-table,
.pdp-description-tab__features-table {
  max-height: none;
}

.pdp-description-tab__description {
  margin-top: 40px;

  @include default-typography;
}

.bundles {
  margin-top: var(--space-5);

  @media #{$_mediaMDown} {
    margin-top: var(--space-4);
  }
}

.pdp-description-tab__document-header {
  display: block;
  font-weight: bold;
  padding-bottom: var(--space-0--half);
  margin-top: var(--space-4);
  border-bottom: 1px solid var(--color-concrete);

  @include default-typography;
}

.pdp-description-tab__document-icon {
  height: 22px;
  width: 22px;
  fill: var(--color-secondary);
}

.pdp-description-tab__document {
  display: inline-flex;
  align-content: center;
  margin-top: var(--space-2);
  height: 22px;

  &:not(:last-child) {
    margin-right: var(--space-3);
  }
}

.pdp-description-tab__document-label {
  margin-left: var(--space-1);
  text-decoration: underline;

  @include default-typography;
}

.pdp-description-tab__hazardous-good {
  margin-top: 40px;

  ::v-deep {
    .icon {
      fill: var(--color-secondary);
    }
  }
}

@media #{$_mediaMDown} {
  ::v-deep {
    .pdp-tab-base__container {
      padding: var(--space-2);
    }
  }

  .pdp-description-tab__document {
    margin-top: var(--space-2);
  }

  .pdp-description-tab__document-header {
    padding-bottom: var(--space-1);
  }

  ::v-deep .pdp-table__table-cell:last-child {
    text-align: right;
  }
}
</style>
