<i18n src="./translations"></i18n>

<template>
  <div
    :class="[
      classes,
      {
        'sortingCategoriesDropdown--opened': isOpened,
      },
    ]"
    :data-preload="[$options.name]"
  >
    <div class="header" @click="toggleContainer">
      <div class="header__name">
        {{
          isOpened
            ? $t('sorting-categories-dropdown.sort-by')
            : `${$t('sorting-categories-dropdown.sorting')}: ${activeTitle}`
        }}
      </div>
      <ChevronRightSvg
        :class="['toggleIcon', 'qa_chevron-down', { 'opened-arrow': isOpened }]"
      />
    </div>

    <div
      v-if="isOpened"
      v-click-outside="{
        handler: handleClose,
        isActive: isOpened,
        events: ['click'],
      }"
      class="container"
    >
      <div class="container__body">
        <SortingCategoriesOptionList
          :is-mobile="false"
          :options="sorting.options"
          :active-option="activeSorting"
          :name="sorting.alias"
          @input="click"
        />
      </div>
    </div>
  </div>
</template>
<script>
import globalMixin from 'Libs/mixins/globalMixin';
import ChevronRightSvg from 'Components/00-generated/ChevronRightSvg';
import { formBaseMixin } from 'Libs/mixins/formBaseMixin.js';
import SortingCategoriesOptionList from 'Components/01-atoms/sorting-categories-option-list/SortingCategoriesOptionList';

export default {
  name: 'SortingCategoriesDropdown',
  components: { SortingCategoriesOptionList, ChevronRightSvg },
  mixins: [globalMixin, formBaseMixin],
  props: {
    sorting: {
      type: Object,
      default: () => ({}),
    },
    activeSorting: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpened: false,
      newActiveTitle: '',
    };
  },
  computed: {
    activeTitle() {
      return this.newActiveTitle || this.activeSorting.title;
    },
  },
  methods: {
    toggleContainer() {
      this.isOpened = !this.isOpened;
      if (!this.isOpened) {
        this.handleClose();
      }
    },
    handleClose() {
      this.isOpened = false;
    },
    click(value) {
      this.$emit('input', value);
      this.newActiveTitle = value.title;
      this.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';
@import 'mixins';

.sortingCategoriesDropdown {
  position: relative;
  padding: var(--space-0--half) 0 7px var(--space-1--half);
  border: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media #{$_mediaMDown} {
    padding: var(--space-0--half) 10px 11px 17px;
  }

  @media (max-width: $_widthSTo) {
    padding: 0;

    &--opened {
      padding: var(--space-0--half) 10px 11px 17px;
    }
  }

  &--opened {
    border-color: var(--color-boulderish);
  }
}

.toggleIcon {
  display: flex;
  color: var(--color-white);
  transform: rotate(90deg);
  transform-origin: center;
  transition:
    opacity var(--time-S) ease-in-out,
    transform var(--time-S) ease-in-out;
  margin-top: var(--space-XXS);
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-right: -4px;
  fill: var(--color-primary);

  @media #{$_mediaMDown} {
    width: 23px;
    height: 26px;
  }

  &.opened-arrow {
    transform: rotate(270deg);
  }
}

.header {
  cursor: pointer;
  height: 100%;
  color: var(--color-nero);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--space-1--half) 0 var(--space-0--half);

  @media #{$_mediaMDown} {
    padding: 0;
  }

  &__name {
    text-transform: capitalize;
    color: var(--color-nero);
    font-size: var(--font-size-M);
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 5px;

    @media #{$_mediaMDown} {
      font-size: var(--font-size-SM);
    }
  }

  &__icon {
    ::v-deep svg {
      color: var(--color-primary);
    }

    &--up {
      transform: rotateZ(180deg);
    }
  }
}

.container {
  z-index: 1;
  background: var(--color-white);
  display: flex;
  width: 100%;
  margin-top: 5px;
  position: absolute;
  left: -1px;
  border: 1px solid var(--color-boulderish);
  border-top: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-sizing: content-box;
  padding-bottom: 9px;

  &__body {
    flex: 1;
    overflow-y: auto;
    overflow: hidden;
  }
}
</style>
