<template>
  <div class="title">
    <transition
      enter-class="fadeIn-initial"
      enter-to-class="fadeIn-to"
      enter-active-class="fateIn-active"
      leave-class="fadeOut-initial"
      leave-to-class="fadeOut-to"
      leave-active-class="fadeOut-active"
    >
      <span v-show="showNew">
        {{ titles[0] }}
      </span>
    </transition>
    <transition
      enter-class="fadeIn-initial"
      enter-to-class="fadeIn-to"
      enter-active-class="fateIn-active"
      leave-class="fadeOut-initial"
      leave-to-class="fadeOut-to"
      leave-active-class="fadeOut-active"
    >
      <span v-show="!showNew">
        {{ titles[1] }}
      </span>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CatalogFilterMobileTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      titles: [this.title, this.title],
      showNew: true,
    };
  },
  watch: {
    title(newTitle, oldTitle) {
      this.showNew = !this.showNew;
      this.titles = this.showNew ? [newTitle, oldTitle] : [oldTitle, newTitle];
    },
  },
};
</script>

<style scoped lang="scss">
@import 'mixins';
@import 'animations';

.title {
  text-transform: capitalize;
  font-weight: bold;
  font-size: var(--font-size-M);
  color: var(--color-nero);
  position: relative;
  white-space: normal;
  word-break: break-word;
  width: 100%;
  align-items: center;
  display: flex;
  height: 50px;

  & * {
    position: absolute;
    left: 0;
    padding-right: 4px;
  }

  span {
    width: 100%;
  }
}
</style>
