<template>
  <ButtonBase
    :modifier="modifier"
    :modifiers="modifiers"
    :disabled="disabled"
    :has-loading="hasLoading"
    :has-focus-border="hasFocusBorder"
    :on-click="onClick"
    :target="target"
    :title="title"
    :name="name"
    :type="type"
    :href="href"
    :icon="icon"
    :value="value"
    :is-gray="isGray"
  >
    <template v-slot:default>
      <slot />
    </template>

    <template v-slot:icon>
      <Icon v-if="!!iconConfig" v-bind="iconConfig" class="icon" />
    </template>
  </ButtonBase>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import Icon from 'Components/00-generated/Icon.vue';
import ButtonBase from './ButtonBase.vue';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';

export default {
  name: 'ButtonWrappper',
  components: { ButtonBase, Icon },
  props: {
    modifier: {
      type: String,
    },
    modifiers: {
      type: Array,
    },
    disabled: {
      type: Boolean,
    },
    hasLoading: {
      type: Boolean,
    },
    hasFocusBorder: {
      type: Boolean,
      default: true,
    },
    onClick: {
      type: Function,
    },
    target: {
      type: String,
    },
    title: {
      type: String,
    },
    name: {
      type: [String, Boolean],
    },
    type: {
      type: [String, Boolean],
    },
    href: {
      type: [String, Boolean],
    },
    icon: {
      type: [String, Object],
    },
    iconHeight: {
      type: [Number, String],
      default: 20,
    },
    iconWidth: {
      type: [Number, String],
      default: 'auto',
    },
    value: {
      type: String,
    },
    isGray: {
      type: Boolean,
    },
  },
  computed: {
    iconConfig() {
      if (!!this.icon) {
        if (isString(this.icon)) {
          return {
            name: this.icon,
            width: this.getIconWidth,
            height: this.iconHeight,
          };
        } else if (isObject(this.icon)) {
          return this.icon;
        }
      }
      return false;
    },
    getIconWidth() {
      return this.iconWidth;
    },
  },
};
</script>
