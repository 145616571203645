import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import UrlHelper from '../../../libs/helpers/url';
import TrackingHandler from '../../../libs/helpers/tracking';

export const openMobileFilter = (context) => {
  context.commit('OPEN_FILTER_MOBILE');
};

export const closeMobileFilter = (context) => {
  context.commit('CLOSE_FILTER_MOBILE');
};

export const submitFilters = async (context, payload = {}) => {
  const { activeFilters, activeSorting } = context.state;
  const { newFilters, newSorting } = payload;

  const parameters = {};
  let trackedElements = [];

  // eslint-disable-next-line guard-for-in
  for (const key in activeFilters) {
    for (const filter of activeFilters[key]) {
      if (!(key in newFilters) || !newFilters[key].includes(filter)) {
        trackedElements.push(
          TrackingHandler.trackAsync({
            data: {
              event: 'filter_removed',
              filterDimension: key,
              filterValue: filter,
            },
          })
        );
      }
    }
  }

  if (!isEmpty(newFilters)) {
    Object.keys(newFilters).forEach((key) => {
      if (Array.isArray(newFilters[key])) {
        newFilters[key].forEach((value, index) => {
          parameters[
            encodeURIComponent(`${key}[${index}]`)
          ] = encodeURIComponent(value);
          if (!(key in activeFilters) || !activeFilters[key].includes(value)) {
            trackedElements.push(
              TrackingHandler.trackAsync({
                data: {
                  event: 'filter_applied',
                  filterDimension: key,
                  filterValue: value,
                },
              })
            );
          }
        });
      }
    });
  }

  parameters.page = '1';
  if (newSorting.dir) {
    parameters.sort_order = newSorting.dir;
  }
  parameters.sort = newSorting.identifier;

  if (!isEmpty(newSorting) && !isEqual(activeSorting, newSorting)) {
    trackedElements.push(
      TrackingHandler.trackAsync({
        data: {
          event: 'sorting_changed',
          sortingCriteria: `${parameters.sort}_${parameters.sort_order}`,
        },
      })
    );
  }

  const { search } = UrlHelper.getUrlParameters();
  if (search) {
    parameters.search = search;
  }

  // wait for all tracking events to fire before reloading
  Promise.all(trackedElements).then(() => {
    const url = UrlHelper.assembleUrl({ parameters, shouldMerge: false });
    window.location.href = url;
  });
};
