<i18n src="./translations"></i18n>
<template>
  <div
    :data-preload="[$options.name]"
    :class="{ 'side-navigation--product-page': isProductPage }"
    class="side-navigation"
  >
    <div v-if="miniBreadcrumbsConfig" class="leftSideGrandparentNav">
      <ChevronLeftSvg class="selectionLeftChevron" />
      <!-- [ozlui] v-text directive is used for eliminating unnecessarily generated spaces. -->
      <MaskedLink
        class="leftSideGrandparentLink"
        :redirect-to="miniBreadcrumbsConfig.url"
        :text="miniBreadcrumbsConfig.title"
      />
    </div>
    <ul ref="navList" class="leftSideCategories">
      <li v-if="categoryParent && categoryParent.title">
        <!-- [ozlui] v-text directive is used for eliminating unnecessarily generated spaces. -->
        <MaskedLink
          class="panelNavTitle"
          :redirect-to="categoryParent.url"
          :text="categoryParent.title"
        />
      </li>
      <li v-else>
        <!-- [ozlui] v-text directive is used for eliminating unnecessarily generated spaces. -->
        <MaskedLink
          class="panelNavTitle"
          :redirect-to="frontFacingPath('content.page.node_id.0')"
          :text="$t('side-navigation.title')"
        />
      </li>
      <li
        v-for="(item, index) in menuItems"
        :key="index"
        :class="[
          'panelNavItem',
          { 'panelNavItem--active': item.isSelected },
          { 'panelNavItem--hasChildren': !item.isLeaf },
        ]"
        @click="!item.isSelected && handleNavItemClick(index, $event)"
      >
        <!-- [ozlui] v-text directive is used for eliminating unnecessarily generated spaces. -->
        <a
          v-if="item.url"
          ref="navItemLink"
          :href="item.url"
          :title="item.title"
          class="navItemButton"
          @click="handleClick(item.title)"
          v-text="item.title"
        />
        <ChevronRightSvg
          v-if="!item.isLeaf && item.url"
          class="selectionChevron"
        />

        <LeftNaviDotSvg
          v-if="item.isLeaf && item.url"
          class="selectionNaviDot"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import ChevronRightSvg from 'Components/00-generated/ChevronRightSvg';
import ChevronLeftSvg from 'Components/00-generated/ChevronLeftSvg';
import LeftNaviDotSvg from 'Components/00-generated/LeftnaviDotSvg.vue';
import globalMixin from 'Libs/mixins/globalMixin';
import { mapState, mapActions } from 'vuex';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';

export default {
  name: 'SideNavigation',
  components: {
    ChevronRightSvg,
    ChevronLeftSvg,
    LeftNaviDotSvg,
    MaskedLink,
  },
  mixins: [globalMixin],
  computed: {
    ...mapState('sideNavigation', {
      menuItems: (state) => state.data.menuItems,
      categoryParent: (state) => state.data.parent,
      categoryLink: (state) => state.data.grandparent,
    }),
    ...mapState('core', {
      requestData: ({ requestData }) => {
        return requestData;
      },
      isHomepage() {
        return new URL(this.requestData.requestUrl).pathname === '/';
      },
    }),
    topCategoriesUrl() {
      return this.frontFacingPath('content.page.node_id.0');
    },
    miniBreadcrumbsConfig() {
      if (this.categoryLink && this.categoryLink.title) {
        return {
          url: this.categoryLink.url,
          title: this.categoryLink.title,
        };
      } else if (!this.categoryLink && !this.categoryParent) {
        if (this.isHomepage) {
          return null;
        }
        return {
          url: '/',
          title: this.$t('side-navigation.mini-breadcrumbs-start-title'),
        };
      } else if (!this.categoryLink && this.categoryParent) {
        return {
          url: this.topCategoriesUrl,
          title: this.$t('side-navigation.title'),
        };
      }
      return null;
    },
  },
  methods: {
    ...mapActions('dataLayer', ['trackSideNavigationClick']),
    handleClick(itemTitle) {
      this.trackSideNavigationClick({
        categoryKey: itemTitle,
        event: 'left_side_click',
      });
    },
    handleNavItemClick(itemIndex, { target }) {
      const itemElement = this.$refs.navItemLink[itemIndex];

      if (itemElement && target !== itemElement) {
        itemElement.click();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.side-navigation {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.leftSideCategories {
  display: flex;
  flex-direction: column;
  margin: 2px 42px 0 0;
  width: 246px;

  @media all and (min-width: 1001px) and (max-width: 1300px) {
    width: 220px;
  }

  .navItemButton {
    padding-left: 2px;
    margin-top: 2px;
  }
}

.leftSideGrandparentNav {
  margin-top: 18px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  .leftSideGrandparentLink {
    font-size: var(--font-size-M);
    font-weight: normal;
    width: 100%;

    @include stretch-element(26px, 'left', 6px);
  }

  .selectionLeftChevron {
    width: 30px;
    height: 30px;
    margin-left: -6px;
    color: var(--color-secondary);
  }

  .leftSideGrandparentTitle {
    margin-left: 6px;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.panelNavTitle {
  font-weight: bold;
  font-size: var(--font-size-MML);
  color: var(--color-nero);
  padding-left: var(--space-XXS);
  margin-bottom: var(--space-1--half);
  display: block;
  word-break: break-word;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.panelNavItem {
  display: flex;
  align-items: start;
  flex-direction: row-reverse;
  margin: 0 0 var(--space-0) -7px;

  &--active {
    font-weight: bold;

    .navItemButton {
      pointer-events: none;
      text-decoration: none;
      word-break: break-word;
      white-space: normal !important;
      overflow: visible !important;
    }

    .selectionChevron {
      display: none;
    }

    .selectionNaviDot {
      fill: var(--color-nero);
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &:hover:not(&--active) {
    cursor: pointer;
  }
}

.navItemButton {
  width: 230px;
  line-height: var(--font-size-LLXL);
  font-size: var(--font-size-M);
  color: var(--color-nero);
  word-break: break-word;
  white-space: normal !important;
  overflow: visible !important;
  margin-bottom: var(--space-0--half);

  @include text-ellipsis;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.selectionChevron {
  width: 30px;
  height: 30px;
  color: var(--color-secondary);
}

.selectionNaviDot {
  width: 30px;
  height: 30px;
  fill: var(--color-secondary);
}
</style>
