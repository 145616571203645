<i18n src="./translations"></i18n>

<template>
  <form
    :class="[classes, 'form']"
    autocomplete="off"
    :data-preload="[$options.name]"
    @submit.prevent="handleSubmit"
  >
    <div class="caris-nat-code-header__label">
      {{ $t('caris-nat-code-header.nat_code.headline') }}
    </div>

    <div class="caris-nat-code-header__mode">
      <div class="caris-nat-code-header__fields">
        <div class="caris-nat-code-header__field field--full">
          <InputBase
            ref="nat"
            :placeholder="$t('caris-nat-code-header.nat_label')"
            full-name="nat"
            :value="form.natCodeValue"
            :errors="errors"
            minlength="2"
            :max-length="6"
            type="text"
            :send="true"
            :attr="{
              autocomplete: 'off',
              autocorrect: 'off',
              autocapitalize: 'off',
              spellcheck: 'false',
              pattern: '\\d*',
            }"
            @input="handleChange"
          />
        </div>
      </div>

      <Dialog
        ref="dialog"
        :click-outside="true"
        :title="$t('caris-nat-code-header.nat_code.headline')"
      >
        <div class="caris-nat-code-header__explanation-text">
          {{ $t('caris-nat-code-header.nat_code.text') }}
        </div>
        <img
          class="caris-nat-code-header__explanation-image"
          :data-src="resolveFile('images/caris-nat-code-form/nat_code.jpg')"
          :src="resolveFile('images/caris-nat-code-form/nat_code.jpg')"
          :title="$t('caris-nat-code-header.nat_code.headline')"
          loading="lazy"
        />
      </Dialog>
    </div>

    <div
      class="caris-nat-code-header__where-to-find-button qa-whereToFindButton"
      @click="showDialog"
    >
      {{ $t('caris-nat-code-header.where_to_find') }}
    </div>

    <Button
      v-show="action"
      class="caris-nat-code-header__form__select-car-button selectCarButton"
      :title="$t('caris-nat-code-header.select_new_car')"
      modifier="tertiary"
      type="submit"
    />
  </form>
</template>

<script>
import './images/nat_code.jpg';
import { ktypnrMixin } from 'Libs/mixins/ktypnrMixin.js';
import InputBase from 'Components/01-atoms/form/input/InputBase.vue';
import Dialog from 'Components/02-molecules/dialog/Dialog.vue';
import globalMixin from 'Libs/mixins/globalMixin';
import Button from 'Components/01-atoms/button/Button.vue';

export default {
  name: 'CarisNatCodeHeader',
  components: {
    InputBase,
    Dialog,
    Button,
  },
  mixins: [globalMixin, ktypnrMixin],
  props: {
    action: {
      type: Boolean,
      default: true,
    },
    parentForm: {
      type: Object,
      default: null,
    },
    validateForm: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      natValue: '',
      errors: [],

      insideForm: {
        natCodeValue: null,
      },
    };
  },
  computed: {
    form() {
      return this.parentForm || this.insideForm;
    },
  },
  methods: {
    handleChange(value) {
      this.errors = [];
      this.natValue =
        value.length <= 6 ? value.replace(/\D*/gi, '') : this.natValue;

      this.form.natCodeValue = this.natValue;
    },
    async handleSubmit() {
      if (this.validateForm) {
        const natValue = this.form.natCodeValue;
        if (natValue.length > 6 || natValue.length < 2) {
          this.$refs.nat.handleInvalidInput();
          this.$refs.nat.focus();
          return;
        }
        this.$emit('select');
      } else {
        this.$emit('select');
      }
    },
    showDialog() {
      this.$refs.dialog.show();
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.caris-nat-code-header__form {
  width: 100%;
  position: relative;

  ::v-deep {
    .inputEl {
      height: 32px;
    }
  }
}

.caris-nat-code-header__button-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: var(--space-0--half);
  line-height: 20px;
}

.caris-nat-code-header__help {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.caris-nat-code-header__field {
  width: 50%;

  &--full {
    width: 100%;
  }
}

.caris-nat-code-header__form__select-car-button {
  width: 100%;
}

.caris-nat-code-header__label {
  width: 100%;
  font-size: var(--font-size-MML);
  font-weight: 700;
  color: var(--color-secondary);
  letter-spacing: -0.16px;
  margin-bottom: 10px;
}

.caris-nat-code-header__where-to-find-button {
  font-size: var(--font-size-SM);
  color: var(--color-white);
  text-decoration: underline;
  margin: 0 var(--space-0) var(--space-1);
  cursor: pointer;
  padding-left: 0;
}

.caris-nat-code-header__button-wrapper {
  padding: var(--space-1) 0 0 0;
  display: flex;
  flex-wrap: wrap;
  min-height: 20px;
}

.caris-nat-code-header__explanation_text {
  line-height: 20px;
  font-size: var(--font-size-SM);
}

.caris-nat-code-header__explanation_image {
  margin-top: var(--space-2);
  width: 100%;
  max-width: 440px;
}
</style>
