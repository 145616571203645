
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="ChevronLeftSvg" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'ChevronLeftSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
