import { render, staticRenderFns } from "./HazardousGood.vue?vue&type=template&id=1be4b3b3&scoped=true"
import script from "./HazardousGood.vue?vue&type=script&lang=js"
export * from "./HazardousGood.vue?vue&type=script&lang=js"
import style0 from "./HazardousGood.vue?vue&type=style&index=0&id=1be4b3b3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1be4b3b3",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F02-molecules%2Fhazardous-good%2FHazardousGood.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports