import has from 'lodash/has';
import RedisService from '../../../services/RedisService';

export const fetchUrls = async ({ commit, state }, { urlKeys = [] }) => {
  const { salesChannelId, defaultLocale } = state.salesChannel;

  const urlMappings = await RedisService.fetchUrls({
    urlKeys,
    salesChannelId,
    locale: defaultLocale,
  });

  return commit('addUrlMappings', { urlMappings });
};

export const handleAjaxResponse = (
  { commit, dispatch },
  { data, messages }
) => {
  if (data) {
    if (has(data, 'minicart')) {
      commit('miniCart/MINICART_UPDATE', data, { root: true });
    }
    if (has(data, 'wishlist')) {
      commit('wishlist/WISHLIST_UPDATE', data, { root: true });
    }
  }
  if (messages) {
    dispatch('messages/addMessages', messages, { root: true });
  }
  // TODO: add tracking handling
};
