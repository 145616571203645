export const setWishlistState = ({ data, component } = {}) => {
  if (!data) {
    return false;
  }

  const defaultData = { count: 0, isWished: false };

  return {
    data: {
      ...data,
      wishlist: data.wishlist ? data.wishlist : defaultData,
      isWished: data.isWished ? data.isWished : defaultData.isWished,
    },
    component,
    wishlistId: '',
    rows: [],
  };
};
