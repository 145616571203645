var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.classes},[_c('div',{staticClass:"inner"},[(_vm.pagination.hasPreviousPage)?_c('span',{staticClass:"pageSelector arrow",on:{"click":function($event){return _vm.handleAdjacentPageClick(_vm.pagination.currentPage - 1)}}},[_c('Icon',{staticClass:"chevron",attrs:{"name":"chevron_left"}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm.paginationList),function(page,index){return _c('MaskedLink',{key:`page-${index}`,staticClass:"link pageSelector",class:{
        'pageSelector--separator': page === '...',
        'pageSelector--active': page.current === true,
        'pageSelector--extra':
          page === _vm.pagination.currentPage - 2 ||
          page === _vm.pagination.currentPage + 2,
      },attrs:{"text":(page.page || page).toString(),"redirect-to":_vm.createUrl(page instanceof Object ? page.page : page)}})}),_vm._v(" "),(_vm.pagination.hasNextPage)?_c('span',{staticClass:"pageSelector arrow",on:{"click":function($event){return _vm.handleAdjacentPageClick(_vm.pagination.currentPage + 1)}}},[_c('Icon',{staticClass:"chevron",attrs:{"name":"chevron_right"}})],1):_vm._e()],2),_vm._v(" "),(_vm.pagination.maxPage > _vm.pagesMaxForWindowSize)?_c('div',{staticClass:"info",domProps:{"innerHTML":_vm._s(
      _vm.$t('pagination.results', {
        '%count%': Math.trunc(_vm.pagination.maxPage),
      })
    )}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }