<i18n src="./translations"></i18n>

<template>
  <div v-if="itemTyreLabels && !!itemTyreLabels.length" class="tyre-labels">
    <div
      class="tyre-labels-header"
      :class="{ 'is-minimal': isMinimal }"
      @click="isTyreLabelsDialogOpen = true"
    >
      {{ $t('tyre-labels.header') }}
      <Icon name="info-outline" class="info-icon" width="20" />
    </div>

    <!-- START: Full version -->
    <PdpTable
      v-if="!isMinimal"
      class="tyre-labels-full"
      :lines="itemTyreLabels"
      :columns="[
        { key: 'title', label: '' },
        { key: 'value', label: '' },
      ]"
      :show-headers="false"
    >
      <template #title="{ line }">
        <div class="label-item">
          <Icon :name="line.icon" width="24" height="24" />

          <div class="title">
            {{ line.title }}

            <div v-if="line.sublabel" class="sublabel">
              {{ line.sublabel.title }}
            </div>
          </div>
        </div>
      </template>

      <template #value="{ line }">
        <div class="eprel-label" :class="`label label-${line.value}`">
          {{ line.value }}
        </div>

        <div v-if="line.sublabel" class="sublabel label-number">
          {{ line.sublabel.value }}
        </div>
      </template>
    </PdpTable>
    <!-- END: Full version -->

    <!-- START: Minimal version -->
    <div v-if="isMinimal" class="tyre-labels-minimal">
      <div v-for="label in itemTyreLabels" :key="label.id" class="label-item">
        <Icon :name="label.icon" width="28" />

        <div class="eprel-label" :class="`label label-${label.value}`">
          {{ label.value }}
        </div>

        <div v-if="label.sublabel" class="label-number sublabel">
          &nbsp;{{ label.sublabel.value }}
        </div>
      </div>
    </div>
    <!-- END: Minimal version -->

    <SimpleDialog
      :title="$t('tyre-labels.header')"
      :active="isTyreLabelsDialogOpen"
      @close="isTyreLabelsDialogOpen = false"
    >
      <div class="tyre-labels-dialog">
        <img :src="eprelImg.src" :alt="eprelImg.alt" />
      </div>
    </SimpleDialog>
  </div>
</template>

<script>
import { tyreLabelAttrs } from './models/labels';
import { isTypeLabel } from './models/tyre-labels';
import PdpTable from 'Components/01-atoms/pdp-table/PdpTable';
import Icon from 'Components/00-generated/Icon';
import SimpleDialog from 'Components/01-atoms/simple-dialog/SimpleDialog';

export default {
  name: 'TyreLabels',
  components: { PdpTable, Icon, SimpleDialog },
  props: {
    isMinimal: {
      type: Boolean,
      default: false,
    },
    attributes: {
      type: Array,
      default: () => [],
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isTyreLabelsDialogOpen: false,
    };
  },
  computed: {
    eprelImg() {
      return this.images[this.images.length - 1];
    },
    itemTyreLabels() {
      let labels = [];

      this.attributes.forEach((attribute) => {
        if (!isTypeLabel(attribute.id) || !attribute.value) return;

        const labelAttr = tyreLabelAttrs[attribute.id];
        labels.push({
          ...attribute,
          icon: labelAttr?.icon,
          order: labelAttr?.order,
        });
      });

      const dba = labels.find(
        (label) => label.id === 'external_rolling_noise_dba_1606'
      );

      labels = labels
        .map((label) =>
          label.id === 'rolling_noise_emission_class_3989'
            ? {
                ...label,
                sublabel: dba,
              }
            : label
        )
        .filter(
          (label) =>
            label.id !== 'external_rolling_noise_dba_1606' && label !== null
        );

      return labels.sort((a, b) => a.order - b.order);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'mixins';
@import 'variables/breakpoints';

.tyre-labels {
  &-header {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: var(--font-size-M);
    line-height: var(--font-size-MLL);
    color: var(--color-nero);
    margin-top: var(--space-2);
    margin-bottom: 0;

    &.is-minimal {
      margin-top: 0;
    }

    .info-icon {
      cursor: pointer;
      margin-left: 5px;
    }
  }

  &-full {
    .label-item {
      display: flex;
      align-items: center;
      gap: var(--space-1);
      white-space: nowrap;

      .title {
        width: 100%;
      }

      svg {
        min-width: 24px;
      }
    }

    .sublabel {
      margin-top: var(--space-0--half);
      padding-top: var(--space-0--half);
      border-top: 1px solid var(--color-alt);

      @media #{$_mediaLUp} {
        margin-right: -50px;
      }

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  &-minimal {
    display: flex;
    gap: var(--space-1--half);
    margin-top: var(--space-1);

    .label-item {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;
    }
  }

  &-dialog {
    width: 100%;
    text-align: center;

    img {
      max-width: 100%;
      max-height: 70vh;
    }
  }
}

.eprel-label {
  display: inline-flex;
  align-items: center;

  &.label {
    color: var(--color-white);
    background-color: var(--color-hippo-darker);
    justify-content: center;
    border-radius: var(--border-radius-default);
    font-weight: 900;
    text-transform: uppercase;
    width: var(--space-2--half);
    height: var(--space-2--half);

    &-number {
      width: 100%;
    }

    &-A {
      background-color: #049600;
    }

    &-B {
      background-color: #4eae47;
    }

    &-C {
      background-color: #b8d137;
    }

    &-D {
      background-color: #ffe245;
    }

    &-E {
      background-color: #ffad00;
    }

    &-F {
      background-color: #f0631d;
    }

    &-G {
      background-color: #d9232a;
    }
  }
}

@media #{$_mediaMDown} {
  ::v-deep .pdp-table__table-cell:last-child {
    text-align: right;
  }
}
</style>
