<i18n src="./translations"></i18n>

<template>
  <section
    class="pdp-attributes"
    :class="classes"
    :data-preload="[$options.name]"
  >
    <PdpVehicleNotification
      v-if="!product.isDropShipment"
      class="pdp-attributes__vehicle-notification is-mobile"
      :gtm-class-name="'gtm--pdp-vehicle-notification'"
      :vehicle-compatibility="productVehicleCompatibility"
      :vehicle-name="vehicleName"
      :related-items-link="relatedItemsLink"
    />

    <div class="pdp-attributes__content">
      <PriceInfo21
        class="pdp-attributes__information pdp-attributes__price-info"
        :product="product"
      />

      <PdpVehicleNotification
        v-if="!product.isDropShipment"
        class="pdp-attributes__vehicle-notification"
        :gtm-class-name="'gtm--pdp-vehicle-notification'"
        :vehicle-compatibility="productVehicleCompatibility"
        :vehicle-name="vehicleName"
        :related-items-link="relatedItemsLink"
      />

      <PdpActions
        class="pdp-attributes__actions"
        :product="product"
        :add-to-cart-button-props="addToCartButtonProps"
        @cart-click="handleAddToCart"
        @wishlist-click="handleAddToWishlist"
        @quantity-change="handleQuantityChange"
      />
    </div>

    <Dialog
      ref="unavailableProductDialog"
      class="pdp-attributes__unavailable-product-dialog"
      :click-outside="true"
      :title="$t('pdp-attributes.availability.delayed.title')"
    >
      <div>
        {{
          $t('pdp-attributes.availability.delayed.message', {
            '%customerServicePhoneNumber%': customerServicePhoneNumber,
          })
        }}
      </div>
    </Dialog>

    <AlternativeProductsDialog
      ref="alternativeProductsDialog"
      :parent-product="product"
      :handle-cancel="handleAlternativeProductCancel"
      :handle-confirm="handleAlternativeProductConfirm"
    />
  </section>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import PdpVehicleNotification from 'Components/02-molecules/pdp-vehicle-notification/PdpVehicleNotification';
import PriceInfo21 from 'Components/02-molecules/price-info-21/PriceInfo21';
import { mapActions, mapGetters, mapState } from 'vuex';
import PdpActions from 'Components/02-molecules/pdp-actions/PdpActions';
import Dialog from 'Components/02-molecules/dialog/Dialog';
import AlternativeProductsDialog from 'Components/03-organisms/alternative-products-dialog/AlternativeProductsDialog';

export default {
  name: 'PdpAttributes',
  components: {
    PriceInfo21,
    PdpVehicleNotification,
    PdpActions,
    Dialog,
    AlternativeProductsDialog,
  },
  mixins: [globalMixin],
  props: {
    product: {
      type: Object,
      required: true,
      default: () => ({
        default: {
          productId: '',
          quantityOptions: [],
          unitPrices: [
            {
              label: '',
              price: '',
            },
          ],
          quantityThreshold: 0,
          quantityAmount: 0,
          isBuyable: false,
          availability: '',
          priceTexts: {
            depositPrice: 0,
            tax: 0,
            pricePerUnit: { value: 0, unit: 0 },
            discountedPricePerUnit: {
              unit: 'Liter',
              value: '7,07 €',
            },
          },
          sku: '',
          productName: '',
          brandName: '',
          isDropShipment: false,
        },
      }),
    },
    relatedItemsLink: {
      type: String,
      default: '',
    },
    pdpCarBar: {
      type: Object,
      default: () => ({
        key: 'UNIVERSAL_PRODUCT',
      }),
    },
    summaryHasExtraInformation: {
      type: Boolean,
      default: true,
    },
    summaryHasTaxInformation: {
      type: Boolean,
      default: true,
    },
    isListingPage: { default: false, type: Boolean },
    isNewWishlist: { default: false, type: Boolean },
  },
  data: () => ({
    isAddToCartInProgress: false,
    selectedQuantity: 1,
    productIsReplaced: false,
    showOldVer: false,
  }),
  computed: {
    ...mapState('core', {
      viewport: ({ viewport }) => viewport.range,
      companyId: (state) => state.requestData.companyId,
    }),
    ...mapGetters('core', ['customerServicePhoneNumber']),
    ...mapState('carSelection', {
      vehicleName: ({ data: { vehicle = {} } }) => {
        const { manufacturer, model, type, bodyCodes } = vehicle;
        const selectionName = `${manufacturer} ${model} ${type}`;

        if (bodyCodes && bodyCodes.length > 0) {
          return `${selectionName} (${[...bodyCodes].sort().join(', ')})`;
        }
        return selectionName;
      },
    }),
    isBusinessCustomer() {
      return typeof this.companyId === 'string' && this.companyId !== '';
    },
    productExplanatoryInformation() {
      return this.product.priceTexts;
    },
    productVehicleCompatibility() {
      return this.pdpCarBar?.key || 'UNIVERSAL_PRODUCT';
    },
    productSKU() {
      return this.product.sku;
    },
    productBrandName() {
      return this.product.brandName;
    },
    productName() {
      return this.product.productName;
    },
    addToCartButtonProps() {
      if (this.isAddToCartInProgress) {
        return {
          class: 'button--japaneseLaurel',
          title: this.$t('pdp-attributes.cart.added'),
          icon: 'added_to_cart_check',
          disabled: true,
        };
      }

      return {
        class: [
          this.viewport && this.viewport !== 'desktop' && 'button--noHover',
          'qa-addToCartButton',
        ],
        icon: 'cart',
        title: this.$t('pdp-attributes.cart.add'),
      };
    },
  },
  mounted() {
    this.selectedQuantity = this.product.quantityAmount;
  },
  methods: {
    ...mapActions('miniCart', ['addToCart']),
    ...mapActions('wishlist', ['addToWishlist']),
    async handleAddToCart(
      quantity,
      skipAlternativeProductsCheck = false,
      productToAdd = null
    ) {
      if (!productToAdd) {
        productToAdd = this.product;
      }

      const productPriceEqualsZero = productToAdd.prices.price === 0;

      const availabilityStatus = [
        'DELAYED',
        'AVAILABLE_SOON',
        'AVAILABLE_ON_REQUEST',
        'OUT_OF_STOCK',
        'NOT_AVAILABLE',
        'NOTAVAILABLE',
        '2TO4WEEKS',
      ];

      //Check if it's not instantly available
      //Check if the product has cross references.
      if (
        !skipAlternativeProductsCheck &&
        productToAdd.availability.toUpperCase() !== 'INSTANT' &&
        productToAdd.hasCrossReferences
      ) {
        const alternativeProduct = await this.$refs.alternativeProductsDialog.fetchAlternativeProduct(
          productToAdd.productId,
          this.selectedQuantity
        );
        if (alternativeProduct) {
          //Pop the alternative product dialog if there is an alternative.
          this.$refs.alternativeProductsDialog.showDialog(alternativeProduct);
          return;
        }
      }

      if (
        productPriceEqualsZero ||
        availabilityStatus.includes(productToAdd.availability.toUpperCase())
      ) {
        if (!skipAlternativeProductsCheck) {
          this.$refs.unavailableProductDialog.show();
        }
        return;
      }

      this.isAddToCartInProgress = true;

      try {
        await this.addToCart({
          product: productToAdd,
          quantity: this.selectedQuantity,
        });
      } finally {
        setTimeout(() => {
          this.isAddToCartInProgress = false;
          this.productIsReplaced = false;
        }, 2000);
      }
    },
    handleAddToWishlist() {
      this.isNewWishlist
        ? this.$emit('wish-click', true)
        : this.addToWishlist({
            product: this.product,
            quantity: this.selectedQuantity,
            list: this.isListingPage
              ? 'Product Listing Page'
              : 'Product Detail Page',
          });
    },
    handleQuantityChange(quantity) {
      this.selectedQuantity = quantity;
    },
    handleAlternativeProductConfirm(alternativeProduct) {
      this.productIsReplaced = true;
      this.handleAddToCart(this.selectedQuantity, true, alternativeProduct);
    },
    handleAlternativeProductCancel() {
      if (this.productIsReplaced) {
        return;
      }

      this.handleAddToCart(this.selectedQuantity, true);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.pdp-attributes {
  max-width: 400px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    overflow: hidden;
    border-left: var(--space-1--half) solid var(--color-white);
    border-top: var(--space-1--half) solid transparent;
    border-bottom: var(--space-1--half) solid transparent;
  }

  &__vehicle-notification {
    &:not(.is-mobile) {
      @media #{$_mediaMDown} {
        display: none;
      }
    }

    &.is-mobile {
      margin-bottom: var(--space-1--half);

      @media #{$_mediaLUp} {
        display: none;
      }
    }
  }
}

.pdp-attributes__content {
  padding: var(--space-2) var(--space-6) var(--space-3);
  border-bottom-right-radius: var(--border-radius-default);
  border-bottom-left-radius: var(--border-radius-default);
}

.pdp-attributes__branch-availability-link {
  padding: 0;
  margin-top: var(--space-0--half);
  font-size: var(--font-size-SM);
}

.pdp-attributes__actions {
  margin-top: var(--space-2);
}

@media #{$_mediaMDown} {
  .pdp-attributes {
    max-width: unset;
    width: 100%;

    &::after {
      display: none;
    }
  }

  .pdp-attributes__content {
    padding: var(--space-2);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@media #{$_mediaLUp} {
  .pdp-attributes__content {
    min-height: 352px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media #{$_mediaXLUp} {
  .pdp-attributes__content {
    min-height: 395px;
  }
}
</style>
