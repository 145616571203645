var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.classes,attrs:{"data-preload":[_vm.$options.name]},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.navigateOptions('down')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.navigateOptions('up')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.clearCompletionTerms.apply(null, arguments)}]}},[(_vm.isClickOutsideActive)?_c('div',{staticClass:"overlay",on:{"click":function($event){return _vm.closeCompletionsElement()}}}):_vm._e(),_vm._v(" "),_c('form',{ref:"form",staticClass:"form",attrs:{"method":"GET","action":_vm.searchUrl,"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.resolveSearch.apply(null, arguments)}}},[_c('div',{staticClass:"wrapper"},[_c('InputBase',{ref:"textInput",staticClass:"searchInput qa-searchInput",attrs:{"modifiers":_vm.searchModifiers,"type":"text","full-name":"search","send":true,"icon1":_vm.mobile && _vm.isNewHeader,"value":_vm.inputDisplay,"placeholder":_vm.$t('search-bar.search_term'),"attr":{ clear_button: true }},on:{"invalid":_vm.handleValidity,"focus":_vm.searchBarOnFocusHandler,"input":_vm.searchBarOnChangeHandler},scopedSlots:_vm._u([(_vm.mobile && _vm.isNewHeader)?{key:"icon1",fn:function(){return [_c('button',{on:{"click":_vm.handleButtonClick}},[_c('ChevronLeftSvg',{staticClass:"iconLeft",attrs:{"height":"30","width":"30"}})],1)]},proxy:true}:null],null,true)}),_vm._v(" "),_c('Button',{class:[
          'searchButton qa-searchButton',
          { 'searchButton--hidden': _vm.mobile && _vm.isNewHeader },
        ],attrs:{"modifiers":['tertiary', 'lowercase', 'slim', 'inputLeft'],"title":_vm.onlyShowSeachIcon ? '' : _vm.$t('search-bar.search'),"type":"button","on-click":_vm.handleButtonClick,"icon":_vm.onlyShowSeachIcon ? _vm.icon : ''}})],1)]),_vm._v(" "),_c('CompletionTerms',{class:{
      'secondary-theme': _vm.isCategorySearchEnabled,
      'is-suggested-term': _vm.isCategorySearchEnabled && _vm.searchTerm.length !== 0,
    },attrs:{"items":_vm.completionTerms,"selection-index":_vm.selectionIndex,"on-click-handler":_vm.optionOnClick,"fresh":_vm.fresh,"search-title":_vm.searchTitle}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }