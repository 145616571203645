<template>
  <span :style="{ '--text': `'${text}'` }"></span>
</template>

<script>
export default {
  name: 'SeoHide',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
::after {
  content: var(--text);
}
</style>
