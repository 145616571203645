<template>
  <div
    class="productImageSlider"
    :class="{ 'product-image-slider--product-page': isProductPage }"
  >
    <div v-show="viewport !== 'desktop'" class="teaser">
      <Slider
        class="teaserImage"
        :items="items"
        :show-thumbnails="false"
        :variant="variant"
        @click-image="openLayer"
        @change-image="onChangeSliderImage"
      >
        <div
          v-if="variant === 'primary' && brand && brand.src"
          class="sliderBrand"
        >
          <AdvancedImage
            :src="brand.src"
            :alt="brand.alt"
            :config="brandConfig"
          />
        </div>
      </Slider>

      <button v-show="images.length > 1" class="button" @click="openLayer">
        <ul class="productImageSlider-pagination">
          <li
            v-for="(dot, i) in images"
            :key="`${i}-${dot.src}`"
            :data-index="i"
            :class="{
              'productImageSlider-pagination-dot': true,
              'productImageSlider-pagination-dot--highlighted':
                currentSliderImage === i + 1,
            }"
          />
        </ul>
      </button>
    </div>

    <div v-show="viewport === 'desktop'" class="slider">
      <Slider
        :items="items"
        :variant="variant"
        @click-image="openLayer"
        @change-image="onChangeSliderImage"
      >
        <div
          v-if="variant === 'primary' && brand && brand.src"
          class="sliderBrand"
        >
          <AdvancedImage
            :src="brand.src"
            :alt="brand.alt"
            :config="brandConfig"
          />
        </div>
        <button
          v-show="items && variant === 'secondary' && images.length > 1"
          class="button secondary"
          @click="openLayer"
        >
          <ul class="productImageSlider-pagination">
            <li
              v-for="(dot, i) in images"
              :key="`${i}-${dot.src}`"
              :data-index="i"
              :class="{
                'productImageSlider-pagination-dot': true,
                'productImageSlider-pagination-dot--secondary': true,
                'productImageSlider-pagination-dot--secondary--highlighted':
                  currentSliderImage === i + 1,
              }"
            />
          </ul>
        </button>
      </Slider>
    </div>
    <Dialog ref="productImageDialog" :click-outside="true" :title="title">
      <Slider ref="productImageDialogSlider" :items="items" :variant="variant">
      </Slider>
    </Dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';

import AdvancedImage from 'Components/01-atoms/advanced-image/AdvancedImage';
import Slider from 'Components/02-molecules/slider/Slider';
import Dialog from 'Components/02-molecules/dialog/Dialog';

export default {
  name: 'ProductImageSlider',
  components: { Slider, Dialog, AdvancedImage },
  mixins: [globalMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    images: {
      type: Array,
      default: () => [],
    },
    brand: {
      type: Object,
      default: () => ({}),
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      currentSliderImage: 1,
      brandConfig: {
        q: 60,
        w: 100,
      },
    };
  },
  computed: {
    ...mapState('core', {
      viewport: ({ viewport }) => viewport.range,
    }),
    ...mapGetters('core', ['isProductPage']),
    items() {
      let images = [];

      if (this.images && this.images.length) {
        images = this.images.map((image) => {
          return {
            full: {
              component: 'ProductImageItem',
              product: image,
              isSecondary: this.variant === 'secondary',
            },
            thumbnail: {
              component: 'ProductImageThumbnail',
              image,
            },
          };
        });
      } else {
        images = [
          {
            full: {
              component: 'NoImageSvg',
              width: 270,
              height: 400,
              // TODO: Add Lazy
            },
            thumbnail: {
              component: 'ProductImageThumbnail',
              image: {
                src: '',
              },
            },
          },
        ];
      }

      return images;
    },
  },
  methods: {
    onChangeSliderImage(nextImage) {
      this.currentSliderImage = nextImage;
    },
    async openLayer() {
      this.$refs.productImageDialog.show();
      await this.$nextTick();
      this.$refs.productImageDialogSlider.goto(this.currentSliderImage);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.productImageSlider {
  &-pagination {
    list-style-type: none;
    padding: 10px 0;

    &-dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      border-radius: 50%;
      background-color: var(--color-alt-gray);

      &--secondary {
        width: 6px;
        height: 6px;
        margin: 0 3px;
        background-color: var(--color-silver-lining);

        &--highlighted {
          background-color: var(--color-hippo-darker);
        }
      }

      &--highlighted {
        background-color: var(--color-nero);
      }
    }
  }
}

.product-image-slider--product-page {
  ::v-deep {
    .controlIcon {
      color: var(--color-secondary);
    }

    .productImageSlider-pagination-dot--highlighted,
    .productImageSlider-pagination-dot--secondary--highlighted {
      background-color: var(--color-secondary);
    }

    .thumbnail.active {
      border-color: var(--color-secondary);
    }
  }
}

.sliderBrand {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 2;

  & ::v-deep img {
    display: block;
    max-height: 60px;
    max-width: 100px;
  }
}

.teaser {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.teaserImage {
  display: flex;
  flex-direction: column;
  width: 100%;

  & ::v-deep img {
    display: block;
    max-width: 100%;
  }
}

.brand {
  display: inline-block;
}

.product,
.button {
  padding: 0;
  cursor: pointer;
  outline: none;

  &.secondary {
    position: absolute;
    bottom: 68px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
}

.slider {
  position: relative;
}

.noImage {
  width: auto;
}

@media #{$_mediaLDown} {
  .sliderBrand,
  .brand {
    & ::v-deep img {
      max-height: 30px;
      max-width: 70px;
    }
  }
}
</style>
