// Rendering SC flags
const isPlp21EnabledConfig = {
  'www-k24-at': {
    enabled: true,
    enabledEnvironments: ['storybook', 'integration-5', 'integration-2',
      'integration-3',
      'staging',
      'production'],
  },
  'www-autoteile24-de': {
    enabled: true,
    enabledEnvironments: ['storybook', 'integration-2', 'integration-3', 'integration-5', 'staging', 'production'],
  },
  'www-kfzteile-com': {
    enabled: true,
    enabledEnvironments: [
      'storybook',
      'integration-2',
      'integration-3',
      'qa-1',
      'staging',
      'production',
    ],
  },
  'www-kfzteile24-de': {
    enabled: true,
    enabledEnvironments: [
      'storybook',
      'integration-2',
      'integration-3',
      'staging',
      'production'
    ],
  },
  'www-carpardoo-fr': {
    enabled: true,
    enabledEnvironments: [
      'storybook',
      'integration-2',
      'integration-3',
      'staging',
      'production',
    ],
  },
  'www-carpardoo-nl': {
    enabled: true,
    enabledEnvironments: [
      'storybook',
      'integration-2',
      'integration-3',
      'staging',
      'production',
    ],
  },
  'www-carpardoo-dk': {
    enabled: true,
    enabledEnvironments: [
      'storybook',
      'integration-2',
      'integration-3',
      'qa-1',
      'staging',
      'production',
    ],
  },
};

const isU2dAktionEnabledConfig = {
  'www-k24-at': {
    enabled: true,
  },
  'www-autoteile24-de': {
    enabled: true,
  },
  'www-kfzteile-com': {
    enabled: true,
  },
  'www-kfzteile24-de': {
    enabled: true,
  },
  'www-carpardoo-fr': {
    enabled: true,
  },
  'www-carpardoo-nl': {
    enabled: true,
  },
  'www-carpardoo-dk': {
    enabled: true,
  }
};

const isKviLabelEnabledConfig = {
  'www-k24-at': {
    enabled: false,
  },
  'www-autoteile24-de': {
    enabled: true,
  },
  'www-kfzteile-com': {
    enabled: true,
  },
  'www-kfzteile24-de': {
    enabled: true,
  },
  'www-carpardoo-fr': {
    enabled: false,
  },
  'www-carpardoo-nl': {
    enabled: false,
  },
  'www-carpardoo-dk': {
    enabled: false,
  }
};

export const renderingSalesChannelConfig = (
  salesChannelId,
  currentEnvironment
) => {
  return {
    isPlp21Enabled: processConfig(
      isPlp21EnabledConfig[salesChannelId],
      currentEnvironment
    ),
    isU2dAktionEnabled: processConfig(
      isU2dAktionEnabledConfig[salesChannelId],
      currentEnvironment
    ),
    isKviLabelEnabled: processConfig(
      isKviLabelEnabledConfig[salesChannelId],
      currentEnvironment
    ),
    isTestNewKey: true,
    isTestNewKey2: false,
  };
};

const processConfig = (config, currentEnvironment = '') => {
  if (!!config) {
    if (!!config.enabledEnvironments && !!currentEnvironment) {
      return (
        config.enabled &&
        validateEnvironment(config.enabledEnvironments, currentEnvironment)
      );
    }
    return config.enabled || false;
  }
  return false;
};

const validateEnvironment = (enabledEnvironments, currentEnvironment) => {
  return enabledEnvironments.includes(currentEnvironment);
};
