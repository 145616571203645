import { render, staticRenderFns } from "./CarisDropdown.vue?vue&type=template&id=2e0dd039&scoped=true"
import script from "./CarisDropdown.vue?vue&type=script&lang=js"
export * from "./CarisDropdown.vue?vue&type=script&lang=js"
import style0 from "./CarisDropdown.vue?vue&type=style&index=0&id=2e0dd039&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e0dd039",
  null
  
)

export default component.exports