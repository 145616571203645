<template>
  <div
    :class="[classes, { categoryContainer: !isPdp }]"
    class="qa-CategoryButton__link-container"
    :title="title"
    @click="handleRedirection()"
  >
    <div
      class="imageContainerWrapper"
      :class="{ 'imageContainerWrapper--isPdp': isPdp }"
    >
      <AdvancedImage
        class="imageContainer qa-CategoryButton__image-container"
        :src="imageUrl"
        :alt="title || ''"
        :config="{ q: 80, w: 80 }"
        :is-category="true"
      />

      <a
        v-if="isPdp"
        class="title"
        :href="url"
        :title="title"
        @click.stop="handleRedirection()"
        v-text="title"
      />
    </div>

    <template v-if="!isPdp">
      <span class="qa-CategoryButton__title titleWrapper title">
        {{ title }}
      </span>
    </template>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import AdvancedImage from 'Components/01-atoms/advanced-image/AdvancedImage';
import { mapGetters } from 'vuex';

export default {
  name: 'CategoryItem',
  components: {
    AdvancedImage,
  },
  mixins: [globalMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    isPdp: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('core', ['isGermanSalesChannel']),
  },
  methods: {
    handleRedirection() {
      // Todo [Mina] Clean it up
      // if (this.isGermanSalesChannel) {
      //   location.assign(this.url);
      // }

      // if (this.source === 'SeoCarPage') {
      //   window.location.href = this.url;
      // }

      window.location.href = this.url;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';
@import 'mixins';

.categoryItem {
  display: flex;
  height: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
  border: 1px solid var(--color-silver-lining);
  border-radius: var(--border-radius-default);
  cursor: pointer;

  &:hover {
    border-color: var(--color-black);
  }

  @media #{$_mediaMDown} {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--color-silver-lining);

    &:hover {
      border-color: var(--color-silver-lining);
    }
  }

  .imageContainerWrapper {
    .imageContainer {
      width: 80px;
      height: 68px;
      margin: 0 auto;
      padding: 16px;
      box-sizing: content-box;
      float: right;
      display: block;
    }

    &--isPdp {
      display: flex;
      align-items: center;
      height: 80px;
      background-color: #ffffff;
      margin-bottom: 0;
      border-radius: var(--border-radius-default);

      .imageContainer {
        max-width: 80px;
        max-height: 80px;
        margin: 0;
        padding: var(--font-size-M);
      }

      .title {
        font-size: var(--font-size-M);
        padding: 0;
        width: 200px;
        height: auto;
        align-self: center;
        color: var(--color-nero);
      }
    }
  }

  .title {
    font-weight: bold;
    color: var(--color-nero);
    font-size: var(--font-size-M);
    height: 18px;
    word-break: break-word;
    hyphens: auto;
  }
}

.categoryContainer {
  .title {
    text-overflow: ellipsis;
    display: block;
    padding: var(--space-2);
    height: 100%;
    width: 195px;
  }

  @media #{$_mediaMDown} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    height: 100px;

    .imageContainerWrapper {
      box-shadow: none !important;
      height: 100%;
      margin-bottom: 0;
      padding: 16px;

      .imageContainer {
        justify-content: center;
        display: flex;
        box-shadow: none;
        width: 80px;
        height: 68px;
        padding: 0;
        box-sizing: border-box;
      }
    }

    .titleWrapper {
      font-weight: 700;
      font-size: var(--font-size-M);
      padding: 16px;
      margin: 0 !important;
      width: 100%;
    }
  }
}
</style>
