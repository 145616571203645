<i18n src="./translations"></i18n>

<template>
  <div class="product-bundles">
    <div class="product-bundles__header">
      {{ $t('product-bundles.headline') }}
    </div>

    <Accordion
      v-for="(bundle, index) in bundlesList"
      :key="bundle.productId"
      ref="plpBundles"
      :data-id="bundle.productId"
      class="product-bundles__accordion"
      :disabled="bundle.isLoading"
      @clicked="loadBundleProduct(index)"
    >
      <template slot="header">
        <div class="product-bundles__header-item">
          <div>
            <strong class="product-bundles__art-name">
              {{ bundle.genartName }}
            </strong>

            <p class="product-bundles__art-nr">
              {{ $t('product-bundles.article_number') }}
              {{ bundle.articleNumber }}
            </p>
          </div>

          <LoadingSpinner
            v-show="bundle.isLoading"
            class="product-bundles__accordion-spinner"
            modifier="greyTrace"
            :dimensions="{ width: '20', height: '20' }"
          />

          <ChevronRightSvg
            v-show="!bundle.isLoading"
            class="product-bundles__toggle-icon qa_chevron-down"
            :class="{ 'product-bundles__opened-arrow': bundle.expanded }"
          />
        </div>
      </template>

      <template slot="body">
        <div class="product-bundles__bundle-product">
          <BundleProduct
            v-if="loadedProducts[bundle.productId]"
            :product="loadedProducts[bundle.productId]"
            :amount="bundle.amount"
          />
        </div>
      </template>
    </Accordion>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import imageMixin from 'Libs/mixins/imageMixin.js';

import Accordion from 'Components/02-molecules/accordion/Accordion.vue';
import BundleProduct from 'Components/02-molecules/bundle-product/BundleProduct.vue';
import LoadingSpinner from 'Components/01-atoms/loading-spinner/LoadingSpinner';
import ChevronRightSvg from 'Components/00-generated/ChevronRightSvg';
import { axios } from 'Services/AjaxService';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'ProductBundles',
  components: {
    Accordion,
    BundleProduct,
    LoadingSpinner,
    ChevronRightSvg,
  },
  mixins: [globalMixin, imageMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      bundlesList: [],
      loadedProducts: {},
    };
  },

  mounted() {
    this.bundlesList = cloneDeep(this.items);
  },
  methods: {
    bundleProductApi(productId) {
      return this.frontFacingPath('search.ajax.product').replace(
        '{productId}',
        productId
      );
    },
    async loadBundleProduct(bundleIndex) {
      const bundle = this.bundlesList[bundleIndex];

      if (!this.loadedProducts[bundle.productId]) {
        this.$set(bundle, 'isLoading', true);

        const { data: product } = await axios.get(
          this.bundleProductApi(bundle.productId)
        );

        this.$set(this.loadedProducts, bundle.productId, product);

        this.$set(bundle, 'isLoading', false);
        this.$set(bundle, 'expanded', true);
      } else {
        this.$set(bundle, 'expanded', !bundle.expanded);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.product-bundles {
  &__header {
    display: flex;
    width: 100%;
    color: var(--color-nero);
    font-weight: bold;
    font-size: 18px;
    margin: var(--space-3) 0 var(--space-1--half);
  }

  &__header-item {
    display: flex;
    width: 100%;
    cursor: pointer;
    border-top: 1px solid var(--color-alto-light);
    padding: var(--space-2) 0;
  }

  &__accordion-header {
    color: var(--color-black);
  }

  &__art-nr {
    font-weight: normal;
    font-size: 14px;
    color: var(--color-rhino-without-horn);
  }

  &__art-name {
    font-weight: bold;
    font-size: 16px;
    color: var(--color-nero);
    margin-bottom: 4px;
  }

  &__accordion-spinner {
    display: inline-block;
    margin-left: auto;
    position: relative;
    top: 2px;
  }

  &__toggle-icon {
    display: flex;
    color: var(--color-white);
    transform: rotate(90deg);
    transform-origin: center;
    transition:
      opacity var(--time-S) ease-in-out,
      transform var(--time-S) ease-in-out;
    margin-top: -6px;
    margin-right: -6px;
    width: 34px;
    height: 34px;
    flex-shrink: 0;
    margin-left: auto;
    fill: var(--color-secondary);

    @media #{$_mediaMDown} {
      width: 23px;
      height: 26px;
    }

    &.product-bundles__opened-arrow {
      transform: rotate(270deg);
    }
  }

  &__bundle-product {
    margin: var(--space-2) 0 0;
  }
}

::v-deep {
  .plpBundlesProduct21 .pdpInformation21 {
    .pdp-information-21 {
      &__oe-numbers,
      &__car-types {
        margin-left: -31%;
        position: relative;
        z-index: 10;
      }

      &__car-types {
        margin-top: -2px;
      }

      &__headline {
        display: none;
      }
    }
  }

  tbody:last-child {
    display: none;
  }

  .pdp-information-21__headline + .pdp-information-21__wrapper {
    border-bottom: 0;
    margin-top: 0;
    padding-right: 0;
    padding-left: 12px;

    tr {
      box-sizing: border-box;
      border-bottom: 1px solid var(--color-alt);
      height: 39px;
      line-height: 23px;
      padding: 4%;
    }

    td {
      text-align: left;
      padding: 8px 8px 8px 20px;
    }

    tr {
      height: auto;

      &:last-child {
        border: 0;
      }
    }
  }

  .arrowDropDownSvg {
    display: none;
  }

  .headline {
    padding: 0;
    color: var(--color-nero);
    font-weight: normal;
    height: auto;
  }

  .body {
    padding: 0;
    margin-top: -11px;
  }
}
</style>
