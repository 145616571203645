<i18n src="./translations"></i18n>

<template>
  <form
    class="login-form"
    :data-preload="[$options.name]"
    @submit.prevent="handleLogin"
  >
    <span
      v-if="wrongCredentials"
      class="login-form-error qa-LoginForm_wrong-credentials"
    >
      {{ $t('login-form.check_password_email') }}
    </span>

    <span
      v-if="requestFailed"
      class="login-form-error qa-LoginForm__failed_request"
    >
      {{ $t('login-form.error_message') }}
    </span>

    <h1 v-if="title" class="title">
      {{ title }}
    </h1>

    <div class="login-form-content qa-LoginForm__fields">
      <Input
        class="email qa-LoginForm__input_email"
        name="email"
        type="email"
        :form="{
          vars: {
            id: 'qa-id_LoginForm__input_email',
            full_name: 'email',
            required: true,
            value: email,
            label: $t('login-form.inputs.email'),
          },
        }"
        @input="(value) => (email = value)"
      />
      <Input
        class="password qa-LoginForm__input_password"
        name="password"
        type="password"
        :form="{
          vars: {
            id: 'qa-id_LoginForm__input_password',
            full_name: 'password',
            required: true,
            value: password,
            label: $t('login-form.inputs.password'),
          },
        }"
        @input="(value) => (password = value)"
      />
    </div>

    <a
      class="forgot-password qa-LoginForm__forgot-password_button"
      href="/customer/forgot-password"
    >
      {{ $t('login-form.forgot_password') }}
    </a>

    <div class="buttons">
      <Button
        :title="$t('login-form.login-button.text')"
        modifier="primary"
        class="login-button qa-LoginForm__submit_button"
        type="submit"
        name="login[submit]"
        :disabled="isLoading || disabled"
        :has-loading="isLoading"
      />
      <template v-if="shouldRegister">
        <span class="divider">
          {{ $t('login-form.or') }}
        </span>
        <Button
          :title="$t('login-form.login.registration')"
          modifier="secondary"
          class="register-button qa-LoginForm__register_button"
          :href="frontFacingPath('customer.shop.registration')"
          :disabled="isLoading || disabled"
        />
      </template>
    </div>
  </form>
</template>

<script>
import { axios } from 'Services/AjaxService';
import globalMixin from 'Libs/mixins/globalMixin';
import { formMixin } from 'Libs/mixins/formMixin';
import Input from 'Components/01-atoms/form/input/Input.vue';
import Button from 'Components/01-atoms/button/Button.vue';
import { mapState } from 'vuex';

export default {
  name: 'LoginForm',
  components: { Input, Button },
  mixins: [globalMixin, formMixin],
  props: {
    title: {
      type: String,
      default: null,
    },
    shouldRegister: {
      type: Boolean,
      default: false,
    },
    redirectionUrl: {
      type: String,
      default: '/',
    },
    isNoRedirect: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      password: '',
      wrongCredentials: false,
      requestFailed: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('core', {
      salesChannelId: ({ salesChannel }) => salesChannel.salesChannelId,
    }),
  },
  methods: {
    async handleLogin() {
      this.isLoading = true;
      try {
        const response = await this.signIn();

        if (response.redirect) {
          /**
           * Request was successful and redirect
           * to homepage is needed in order
           * to have the logged in properties.
           */
          window.location.href = '/';
        } else {
          this.isLoading = false;
          /**
           * If response object does not contain redirect property,
           * it means that the request is incorrect.
           **/
          if (response?.messages) this.wrongCredentials = true;
        }
      } catch (error) {
        this.isLoading = false;
        this.requestFailed = true;
      }
    },
    async signIn() {
      return await axios.post(
        '/popup/login',
        `email=${this.email}&password=${
          this.password
        }&guestOrNew=false&redirectUrl=${encodeURIComponent(
          window.location.pathname
        )}`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-sales-channel-id': this.salesChannelId,
          },
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
//noinspection CssUnknownTarget
@import 'variables/breakpoints';

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--space-2);
  align-items: stretch !important;
}

.title {
  width: 100%;
  font-weight: 700;
  color: var(--color-mine-shaft);
  font-size: var(--font-size-MML);
  line-height: var(--font-size-LLXL);
}

.login-form-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
}

.email,
.password {
  padding-top: 0;
}

.forgot-password {
  margin-left: auto;
  color: var(--color-mine-shaft);
  line-height: var(--font-size-LLXL);
  text-decoration: underline;
  cursor: pointer;
}

.login-button,
.register-button {
  width: 100%;
  display: flex;
  text-transform: capitalize !important;
  align-items: center;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.divider {
  margin: var(--space-2) 0;
  align-self: center;
}

.login-form-error {
  margin-bottom: var(--space-3);
  color: var(--color-red);
  line-height: var(--font-size-ML);
}

@media #{$_mediaSDown} {
  .login-form {
    width: 100% !important;
  }
}
</style>
