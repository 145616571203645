import MemoryStorage from './memory-storage';

let instance;

export default class LocalStorage {
  constructor() {
    if (!instance) {
      instance = this;
    }

    this.storage = this._islocalStorageAvailable()
      ? window.localStorage
      : new MemoryStorage();

    return instance;
  }

  setItem(key, value) {
    if (typeof key === 'undefined' || typeof value === 'undefined') {
      return;
    }
    this.storage.setItem(key, value);
  }

  getItem(key) {
    if (typeof key === 'undefined') {
      return null;
    }

    let value = this.storage.getItem(key);

    return value || null;
  }

  hasItem(key) {
    if (typeof key === 'undefined') {
      return false;
    }

    return this.getItem(key) !== null;
  }

  removeItem(key) {
    if (typeof key === 'undefined') {
      return;
    }

    this.storage.removeItem(key);
  }

  _islocalStorageAvailable() {
    try {
      let storage = window.localStorage;

      storage.setItem('test', 'test');
      storage.removeItem('test');

      return true;
    } catch (e) {
      return false;
    }
  }
}
