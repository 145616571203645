var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.classes,attrs:{"data-preload":[_vm.$options.name]}},[(_vm.priceTexts.isExchangePart && _vm.priceTexts.exchangePartValue !== 0)?_c('div',{staticClass:"priceText qa-PriceInfo__exchangePart"},[_vm._v("\n    "+_vm._s(_vm.$t('price-texts.exchange_part', {
        '%gross_exchange_part_value%': _vm.priceTexts.exchangePartValue,
      }))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.priceTexts.description)?_c('div',{staticClass:"priceText qa-PriceInfo__description"},[_vm._v("\n    "+_vm._s(_vm.priceTexts.description)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.showBulkyPrice)?_c('div',{staticClass:"priceText qa-PriceInfo__bulkyPrice"},[_vm._v("\n    "+_vm._s(_vm.$t('price-texts.bulky_price', {
        '%formattedBulkyPrice%': _vm.priceTexts.bulkyPrice,
      }))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.showRiskyPrice)?_c('div',{staticClass:"priceText qa-PriceInfo__bulkyPrice"},[_vm._v("\n    "+_vm._s(_vm.$t('price-texts.risky_price', {
        '%formattedRiskyPrice%': _vm.priceTexts.riskyPrice,
      }))+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }