<i18n src="./translations"></i18n>

<template>
  <div
    v-if="isDisplayed && getPercentage > 0"
    class="price-savings"
    :class="{ is21: is21 }"
  >
    <span v-if="is21">
      {{ $t('price-savings.savings') }} {{ `${getPercentage} %` }}
    </span>
    <span v-else>{{ `-${getPercentage} %` }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import has from 'lodash/has';

export default {
  name: 'U2Discount',
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    is21: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('core', ['isBusinessCustomer']),
    isDisplayed() {
      if (this.isBusinessCustomer) {
        return false;
      }
      const product = this.product;
      const priceTexts = product?.priceTexts;

      const hasDiscountPercentage =
        has(product, ['priceTexts', 'discountPercentage']) &&
        priceTexts?.discountPercentage !== 0;

      const hasU2dPercentage =
        has(product, ['priceTexts', 'upToDiscountPercentage']) &&
        priceTexts?.upToDiscountPercentage > 0;

      const hasPrices =
        has(product, ['prices', 'price']) &&
        has(product, ['prices', 'rrp']) &&
        product.prices.price > product.prices.rrp;

      const hasNoDiscountAndNoU2d = !hasDiscountPercentage && !hasU2dPercentage;

      return !hasNoDiscountAndNoU2d && !hasPrices;
    },
    getPercentage() {
      const priceTexts = this.product?.priceTexts;
      let percentage = priceTexts?.discountPercentage || 0;

      if (!this.is21) {
        return percentage;
      }

      const discountedSalesValueGross =
        this.getPriceValue(priceTexts?.discountedSalesValueGross) || 0;
      const rrp = this.getPriceValue(priceTexts?.recommendedRetailPrice) || 0;

      if (discountedSalesValueGross === null || rrp === null) {
        return percentage;
      }

      const upToDiscountPercentage = priceTexts?.upToDiscountPercentage || 0;

      if (upToDiscountPercentage > 0 && rrp > 0) {
        percentage = Math.round(
          100 * ((rrp - discountedSalesValueGross) / rrp)
        );
      }

      return percentage;
    },
  },
  methods: {
    getPriceValue(priceText) {
      if (typeof priceText !== 'string') {
        return null;
      }
      const matches = priceText.match(/(\d[\d.,]*)/);
      if (!matches) {
        return null;
      }
      const match = matches[1];
      if (!match) {
        return null;
      }
      const numstr = match.replace(',', '.');
      const num = parseFloat(numstr);
      if (isNaN(num)) {
        return null;
      }
      return num;
    },
  },
};
</script>

<style lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.price-savings {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-default);

  &.is21 {
    font-size: var(--font-size-SM);
    font-weight: normal;
    line-height: var(--font-size-MML);
    color: var(--color-monza);
    background-color: transparent;
    border: 1px solid var(--color-monza);
    padding: var(--space-XXS) var(--space-0--half);

    .PriceInfo21--slide & {
      position: absolute;
      z-index: 1;
      top: var(--space-2);
      right: var(--space-1--half);
    }
  }

  &:not(.is21) {
    font-size: var(--font-size-SM);
    font-weight: bold;
    line-height: 17px;
    height: 24px;
    color: var(--color-white);
    background-color: var(--color-monza);
    padding: 0 5px;

    @media #{$_mediaMDown} {
      font-size: 11px;
    }

    .priceInfo--slide & {
      position: absolute;
      z-index: 1;
      top: var(--space-2);
      right: 12px;
    }
  }
}
</style>
