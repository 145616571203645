<template>
  <div
    class="car-categories-overview"
    :class="classes"
    :data-preload="[$options.name]"
  >
    <div class="car-categories-overview__container">
      <CarManufacturerHeader :manufacturer="manufacturer" :series="series" />

      <CategoriesRow
        :menu="{ menuItems: categories }"
        class="car-categories-overview__list"
        source="SeoCarPage"
        :show-prod-count="false"
      />

      <!-- eslint-disable vue/no-v-html -->
      <p
        v-if="description"
        class="car-categories-overview__bottom-text"
        v-html="description"
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import CarManufacturerHeader from 'Components/03-organisms/car-manufacturer-header/CarManufacturerHeader';
import CategoriesRow from 'Components/02-molecules/categories-row/CategoriesRow';

export default {
  name: 'CarCategoriesOverview',
  components: {
    CategoriesRow,
    CarManufacturerHeader,
  },
  mixins: [globalMixin],
  props: {
    description: {
      type: String,
      default: '',
    },
    manufacturer: {
      type: Object,
      default: () => ({}),
    },
    categories: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.car-categories-overview {
  width: 100%;

  @media #{$_mediaMDown} {
    margin-top: -33px;
  }
}

.car-categories-overview__container {
  @media #{$_mediaMDown} {
    margin-top: 13px;
  }
}

.car-categories-overview__header-text,
.car-categories-overview__bottom-text {
  display: flex;

  @media #{$_mediaSDown} {
    display: block;
    margin-top: 0;
  }
}

.car-categories-overview__list {
  margin-bottom: -56px;

  @media #{$_mediaMDown} {
    margin-bottom: 0;
  }
}

.car-categories-overview__bottom-text {
  display: block;
  color: var(--color-hippo-darker);
  margin-top: var(--space-7--half);

  ::v-deep h2 {
    font-weight: bold;
    font-size: var(--font-size-LXL);
    display: block;
    margin-bottom: var(--space-2);
    color: var(--color-nero);
    line-height: 32px;

    @media #{$_mediaMDown} {
      font-size: var(--font-size-MML);
      margin-top: var(--space-5);
    }
  }

  ::v-deep p {
    font-weight: normal;
    font-size: var(--font-size-M);
    display: block;
    margin-bottom: var(--space-3);
    line-height: 30px;
    color: var(--color-nero);

    @media #{$_mediaMDown} {
      font-size: var(--font-size-M);
    }
  }
}
</style>
