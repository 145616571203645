import { setNavigationState } from './state';
import * as mutations from './mutations';
import * as actions from './actions';

export default (placeholder) => ({
  state: setNavigationState(placeholder),
  mutations,
  actions,
  namespaced: true,
});
