<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <transition-group name="list" tag="div">
      <ToastItem
        v-for="message in messages"
        :key="message.uniqueKey || message.key"
        :message="message"
        :modifier="message.type"
        :style="message.style"
        @close="removeMessage($event)"
      />
    </transition-group>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import ToastItem from '../toast-item/ToastItem';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'Toast',
  components: { ToastItem },
  mixins: [globalMixin],
  computed: {
    ...mapState('messages', {
      messages: ({ items }) => items,
      delayedMessages: ({ delayedMessages }) => delayedMessages,
    }),
  },
  mounted() {
    this.translateErrorMessage(this.$t('toast.errorMessage'));
    if (this.delayedMessages) {
      this.delayedMessages.forEach((message) => {
        this.addMessage(message);
      });
    }
  },
  methods: {
    ...mapMutations('messages', ['translateErrorMessage']),
    ...mapActions('messages', ['removeMessage', 'addMessage']),
  },
};
</script>

<style scoped lang="scss">
@import 'mixins';

.list-enter-active,
.list-leave-active {
  transition:
    opacity var(--time-S) ease-in-out,
    max-height var(--time-S) ease-in-out,
    margin var(--time-S) ease-in-out;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  max-height: 0;
  margin: 0 8px;
}

.toast {
  width: 100%;
}

@media print {
  .toast {
    display: none;
  }
}
</style>
