<template>
  <div :class="[classes]" :data-preload="[$options.name]">
    <div class="headline" @click="expandHandler">
      <ArrowDropDownSvg
        v-show="!mobileOnly || viewport === 'mobile'"
        :class="['headlineIcon', { rotateArrow: expanded && !disabled }]"
        width="7"
        height="8"
      />
      <slot name="header" />
    </div>

    <TransitionExpand :expand="expanded && !disabled" :max="max">
      <div class="body">
        <slot name="body" />
      </div>
    </TransitionExpand>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';

import ArrowDropDownSvg from 'Components/00-generated/ArrowDropDownSvg';
import TransitionExpand from 'Components/01-atoms/transition-expand/TransitionExpand';

export default {
  name: 'Accordion',
  components: {
    TransitionExpand,
    ArrowDropDownSvg,
  },
  mixins: [globalMixin],
  props: {
    mobileOnly: {
      type: Boolean,
      default: false,
    },
    max: {
      type: String,
      default: '9999',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    ...mapState('core', {
      viewport: ({ viewport }) => viewport.range,
    }),
  },
  methods: {
    expandHandler() {
      const mediumMax = window.matchMedia(`(max-width: ${this.max}px)`).matches;

      if (mediumMax || this.max === '9999') {
        this.expanded = !this.expanded;
      }

      this.$emit('clicked', this.expanded);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.headline {
  display: flex;
  align-items: center;
  min-height: 55px;
  height: 0;

  font-weight: bold;
  font-size: var(--font-size-M);
  line-height: 18px;

  padding: 11px 12px;
  cursor: pointer;

  @include themeColor(
    color,
    var(--color-tory-blue),
    var(--color-endeavour),
    var(--color-chathamsblue)
  );
}

.headlineIcon {
  flex-shrink: 0;
  margin-right: 8px;

  transform: rotate(0);
  transform-origin: center;
  transition: transform var(--time-S) ease-in-out;
}

.rotateArrow {
  transform: rotate(-180deg);
}

.body {
  overflow: hidden;
  padding: 0 var(--space-2) var(--space-2);
}

@media (min-width: $_widthMFrom) {
  .headline {
    padding: var(--space-2);
    font-size: var(--font-size-M);
    line-height: 20px;
  }
}
</style>
