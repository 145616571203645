<template>
  <transition
    enter-active-class="expandEnterActive"
    leave-active-class="expandLeaveActive"
    enter-class="expandEnter"
    leave-to-class="expandLeaveTo"
    :data-preload="[$options.name]"
    @afterEnter="afterEnter"
    @enter="enter"
    @leave="leave"
  >
    <div v-show="expand || expandState" :class="classes">
      <slot />
    </div>
  </transition>
</template>

<script>
import debounce from 'lodash/debounce';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'TransitionExpand',
  mixins: [globalMixin],
  props: {
    expand: {
      type: Boolean,
      default: true,
    },
    initialExpandState: Boolean,
    min: {
      type: String,
      default: '0',
    },
    max: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      expandState: true,
    };
  },
  beforeMount() {
    this.expandState =
      this.initialExpandState || !this.matchMedia(this.min, this.max);
    window.addEventListener('resize', debounce(this.onResize, 150));
  },
  methods: {
    matchMedia(min, max) {
      return window.matchMedia(
        `(min-width: ${min}px) and (max-width: ${max}px)`
      ).matches;
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
    enter(element) {
      const { width } = getComputedStyle(element);

      element.style.width = width;
      element.style.position = 'relative';
      element.style.height = 'auto';

      const { height } = getComputedStyle(element);

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    leave(element) {
      const { height } = getComputedStyle(element);
      element.style.height = height;

      setTimeout(() => {
        element.style.height = 0;
      });
    },
    onResize() {
      this.expandState = !this.matchMedia(this.min, this.max);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.expandEnterActive,
.expandLeaveActive {
  transition: height var(--time-S) ease-in-out;
  overflow: hidden;
}

.expandEnter,
.expandLeaveTo {
  height: 0;
}
</style>
