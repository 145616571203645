<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <div v-if="productsCount === 0">
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="no-items"
        v-html="
          $t('catalog-category-filters-wrapper.results_count', {
            '%count%': 0,
          })
        "
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>

    <div v-if="productsCount > 0">
      <div v-if="isSearchPage" class="title">
        <PageTitle is-search-page :modifiers="['embedded', 'wrapping']" />
      </div>

      <div class="filters">
        <CatalogCategoryFilters
          v-if="shouldShowFilters"
          :filter-sections="sections"
          :all-filters="allFilters"
          :active-filters="storedActiveFilters"
          :active-sorting="storedActiveSorting"
          @applyFilters="handleApplyFilters"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CatalogCategoryFilters from 'Components/03-organisms/catalog-category-filters/CatalogCategoryFilters';
import globalMixin from 'Libs/mixins/globalMixin';
import get from 'lodash/get';
import size from 'lodash/size';
import PageTitle from 'Components/03-organisms/page-title/PageTitle';

export default {
  name: 'CatalogCategoryFiltersWrapper',
  components: {
    CatalogCategoryFilters,
    PageTitle,
  },
  mixins: [globalMixin],
  computed: {
    ...mapState('core', {
      isSearchPage: (state) => {
        const type = get(state, 'placeholders.content.data.plp.pageType');
        return type === 'search';
      },
    }),
    ...mapState('plpFilters', {
      productsCount: (data) => data.count,
      open: (data) => data.openMobileFilter,
      sections: (data) => data.filterSections || {},
      allFilters(data) {
        if (!data.filters) {
          return {};
        }

        data.filters.sorting.title = this.$t(
          'catalog-category-filters-wrapper.sort'
        );
        Object.values(data.filters.sorting.options).forEach((option) => {
          if (option.dir) {
            const directionLabel = this.$t(
              'catalog-category-filters-wrapper.' + option.dir
            );
            option.title = option.title.includes(directionLabel)
              ? option.title
              : `${directionLabel} ${option.title}`;
          } else {
            option.title = `${this.$t(
              'catalog-category-filters-wrapper.relevance'
            )}`;
          }
        });

        return data.filters;
      },
      storedActiveFilters: (data) => data.activeFilters || {},
      storedActiveSorting: (data) => data.activeSorting,
    }),
    hasFilters() {
      return size(this.allFilters) > 0;
    },
    hasActiveFilters() {
      return size(this.storedActiveFilters) !== 0;
    },
    shouldShowFilters() {
      if (this.hasFilters) {
        if (this.productsCount < 2 && !this.hasActiveFilters) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('plpFilters', [
      'openMobileFilter',
      'closeMobileFilter',
      'submitFilters',
    ]),
    handleClose() {
      this.$emit('close');
      this.closeMobileFilter();
    },
    handleApplyFilters(dirtyActiveFilters, dirtySorting) {
      this.submitFilters({
        newFilters: dirtyActiveFilters,
        newSorting: dirtySorting,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'mixins';

.catalogCategoryFiltersWrapper {
  display: block;

  .no-items {
    padding-top: 15px;
  }

  .title {
    padding-bottom: 12px;

    @media #{$_mediaMUp} {
      padding-top: 6px;
    }

    @media #{$_mediaMDown} {
      padding: var(--space-2) 0;
    }

    @media #{$_mediaLUp} {
      padding-top: 10px;
      padding-bottom: 26px;
      flex-wrap: nowrap;
    }
  }

  @media #{$_mediaMUp} {
    margin-bottom: var(--space-1);
  }
}
</style>
