import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const buildFiltersState = ({ plpFilters, content }) => {
  if (!plpFilters || !plpFilters.data) {
    return false;
  }

  const initialData = fromDto(plpFilters, content);

  return {
    component: plpFilters.component,
    ...initialData,

    openMobileFilter: false,
  };
};

function fromDto(plpFilters, content) {
  const { availability, facets, searchContext, count } = plpFilters.data || {};
  const allFilters = {};
  const filterSections = { FIRST: ['sorting'] };
  const activeFilters = {};

  //  Create filters map
  (facets || []).filter(hasValidOptions).forEach((filter) => {
    const newFilter = { ...filter, options: {} };

    // map options
    (filter.options || []).filter(isOptionValid).forEach((o) => {
      newFilter.options[o.alias] = o;
      if (o.isActive) {
        activeFilters[filter.alias] = activeFilters[filter.alias] || [];
        activeFilters[filter.alias].push(o.alias);
      }
    });

    // create sections
    if (newFilter.alias === 'brand') {
      filterSections.FIRST.push(newFilter.alias);
    } else if (newFilter.category) {
      filterSections[newFilter.category] =
        filterSections[newFilter.category] || [];
      filterSections[newFilter.category].push(filter.alias);
    }

    allFilters[filter.alias] = newFilter;
  });

  if (availability && availability.options && availability.options.findIndex((f) => f.isActive && f.alias === 'instant') !== -1) {
    activeFilters.availability = ['instant'];
  }

  // Build sorting obj
  allFilters.sorting = buildSortingDropdown(
    get(content, 'data.plp.sortingConfig', [])
  );

  // Compute activeSorting
  const { sortField, sortOrder } = searchContext || {};
  const allSortingOptions = Object.values(allFilters.sorting.options);
  const activeSorting =
    allSortingOptions.find(
      (o) => o.identifier === sortField && o.dir === sortOrder
    ) || allSortingOptions[0];

  return {
    filters: allFilters,
    filterSections,
    activeFilters,
    activeSorting,
    searchContext,
    availability,
    count: count || 0,
  };
}

function hasValidOptions(filter) {
  if (!filter) {
    return false;
  }

  const hasValidOptions = filter.options.reduce(
    (foundValid, o) => foundValid || isOptionValid(o),
    false
  );

  return hasValidOptions;
}

function isOptionValid(o) {
  return o.alias && o.title;
}

function buildSortingDropdown(sortingConfig) {
  const options = {};

  sortingConfig.forEach(({ label, identifier, directions }) => {
    if (isEmpty(directions)) {
      options[identifier] = {
        title: label,
        alias: identifier,
        identifier,
      };
      return;
    }

    directions.forEach((dir) => {
      const alias = identifier + dir;
      options[alias] = {
        alias,
        title: label,
        identifier,
        dir,
      };
    });
  });

  return {
    alias: 'sorting',
    options,
  };
}

export { buildFiltersState };
