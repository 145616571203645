
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="CheckedSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 10"><g fill-rule="evenodd"><path d="M0 5.192l1.4-1.346L5 7.308 12.6 0 14 1.346 5 10"/><path fill="none" d="M-5-7h24v24H-5z"/></g></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'CheckedSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
