<template>
  <div
    class="link content"
    :class="classes"
    :data-preload="[$options.name]"
    @click="handleLinkClick()"
  >
    <Icon :name="icon" class="icon" width="30" height="40" />
    <Subtitle class="caption" :title="label" />
  </div>
</template>

<script>
import Subtitle from '../subtitle/Subtitle';
import Icon from 'Components/00-generated/Icon';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'UspItem',
  components: { Subtitle, Icon },
  mixins: [globalMixin],
  props: {
    href: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleLinkClick() {
      location.assign(this.href);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.uspItem {
  min-height: 100px;
}

.link {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: var(--space-1) var(--space-2);
}

.caption {
  text-align: center;
  line-height: 19px;
  width: 80%;
  min-width: 125px;
}

.icon {
  width: 30px;
  height: 40px;
  margin-bottom: var(--space-1);
  flex-shrink: 0;

  ::v-deep {
    path,
    g {
      fill: var(--color-secondary);
    }
  }
}

@media #{$_mediaMDown} {
  .caption {
    font-size: var(--font-size-S);
    line-height: 14px;
  }
}
</style>
