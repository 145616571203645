
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="ReloadSmallSvg" xmlns="http://www.w3.org/2000/svg" id="_01_Atoms_Icons_rueckgabe" viewBox="0 0 32 32"><defs/><path id="bounding_box" fill="none" d="M0 0h32v32H0z"/><g id="icon" fill="#545456" transform="translate(6.667 6.667)"><path id="lower_arrow" d="M18.351.474a9.225 9.225 0 01-9.066 7.3A9.311 9.311 0 012.894 5.2l-1.57 1.569A.775.775 0 01.778 7 .783.783 0 010 6.221V.776A.782.782 0 01.778 0h5.445A.782.782 0 017 .776a.78.78 0 01-.23.548L5.1 2.99a6.215 6.215 0 004.232 1.676 6.2 6.2 0 005.3-2.964 9.8 9.8 0 00.644-1.423.375.375 0 01.365-.279h2.333a.393.393 0 01.39.388.2.2 0 01-.013.085z" class="cls-2" transform="translate(0 10.889)"/><path id="upper_arrow" d="M18.449 7a.784.784 0 01-.778.778h-5.445A.784.784 0 0111.45 7a.765.765 0 01.23-.546l1.676-1.678a6.222 6.222 0 00-9.539 1.3A9.69 9.69 0 003.173 7.5a.376.376 0 01-.365.281H.39A.394.394 0 010 7.388V7.3A9.278 9.278 0 019.115 0a9.434 9.434 0 016.43 2.576l1.58-1.568a.77.77 0 01.546-.232.784.784 0 01.778.778z" class="cls-2" transform="translate(.218)"/></g></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'ReloadSmallSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
