<template>
  <div class="car-category" :class="classes" :data-preload="[$options.name]">
    <div class="car-category__container">
      <CarManufacturerHeader
        :manufacturer="manufacturer"
        :series="series"
        :products-count="productsCount"
      />

      <CategoriesRow
        v-if="subcategories.length > 0"
        :menu="{ menuItems: subcategories }"
        class="car-category__list"
        source="SeoCarPage"
        :show-prod-count="false"
      />

      <CatalogCategoryFiltersWrapper v-if="!isEmpty" />

      <PlpProducts21 />
    </div>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { mapState } from 'vuex';
import CarManufacturerHeader from 'Components/03-organisms/car-manufacturer-header/CarManufacturerHeader';
import CategoriesRow from 'Components/02-molecules/categories-row/CategoriesRow';
import PlpProducts21 from 'Components/03-organisms/plp-products-21/PlpProducts21';
import CatalogCategoryFiltersWrapper from 'Components/03-organisms/catalog-category-filters-wrapper/CatalogCategoryFiltersWrapper';
export default {
  name: 'CarCategory',
  components: {
    CategoriesRow,
    CarManufacturerHeader,
    PlpProducts21,
    CatalogCategoryFiltersWrapper,
  },
  mixins: [globalMixin],
  props: {
    description: {
      type: String,
      default: '',
    },
    plp: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('plpFilters', {
      productsCount: (data) => data.count || 0,
      isEmpty: (data) => (data.count || 0) === 0,
    }),
    ...mapState('seoCarCategories', {
      manufacturer: (state) => state.data.manufacturer || {},
      series: (state) => state.data.series || {},
      subcategories: (state) => state.data.subcategories || [],
    }),
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.car-category {
  width: 100%;

  @media #{$_mediaMDown} {
    margin-top: -33px;

    ::v-deep .plp-product-21 {
      padding: 19px 0;
    }
  }
}

.plpProducts21 {
  margin-top: var(--space-3--half);
}

.car-category__container {
  @media #{$_mediaMDown} {
    margin-top: 13px;
  }
}

.car-category__header-text,
.car-category__bottom-text {
  display: flex;

  @media #{$_mediaSDown} {
    display: block;
    margin-top: 0;
  }
}

.car-category__list {
  margin-bottom: 0;

  @media #{$_mediaMDown} {
    margin-bottom: 0;
  }
}

.catalogCategoryFiltersWrapper {
  margin-top: var(--space-3);
}

.car-category__bottom-text {
  display: block;
  color: var(--color-hippo-darker);
  margin-top: var(--space-4);

  ::v-deep h2 {
    font-weight: bold;
    font-size: var(--font-size-ML);
    display: block;
    margin-bottom: var(--space-2);

    @media #{$_mediaMDown} {
      font-size: var(--font-size-MML);
      margin-top: var(--space-5);
    }
  }

  ::v-deep p {
    font-weight: normal;
    font-size: var(--font-size-M);
    display: block;
    margin-bottom: var(--space-3);
    line-height: 30px;

    @media #{$_mediaMDown} {
      font-size: var(--font-size-M);
    }
  }
}
</style>
