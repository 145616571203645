<i18n src="./translations"></i18n>

<template>
  <div class="caris-authentication-message">
    <span class="caris-authentication-message__headline">
      {{ $t('caris-authentication-message.headline') }}
    </span>
    <p class="caris-authentication-message__not-signed-in-message">
      {{ $t('caris-authentication-message.not_signed_in_message') }}
    </p>
    <div
      v-if="viewport !== 'mobile'"
      class="caris-authentication-message__links"
    >
      <MaskedLink
        class="caris-authentication-message__link"
        :redirect-to="frontFacingPath('customer.shop.registration')"
        :text="$t('caris-authentication-message.register')"
      />
      <MaskedLink
        class="caris-authentication-message__link"
        :redirect-to="frontFacingPath('customer.shop.login')"
        :text="$t('caris-authentication-message.login')"
      />
    </div>
    <Button
      v-else
      class="caris-authentication-message__login-button"
      :title="$t('caris-authentication-message.to_login')"
      modifier="tertiary"
      type="button"
      :on-click="handleLoginClick"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import globalMixin from 'Libs/mixins/globalMixin';

import Button from 'Components/01-atoms/button/Button';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';

export default {
  name: 'CarisAuthenticationMessage',
  components: { Button, MaskedLink },
  mixins: [globalMixin],
  computed: {
    ...mapState('core', { viewport: ({ viewport }) => viewport.range }),
  },
  methods: {
    handleLoginClick() {
      location.href = this.frontFacingPath('customer.shop.login');
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.caris-authentication-message {
  display: flex;
  flex-direction: column;

  --regular-font-size: var(--font-size-SM);
  --regular-line-height: var(--font-size-ML);
  --headline-font-size: 18px;
  --headline-line-height: 22px;
}

.caris-authentication-message__headline {
  font-size: var(--headline-font-size);
  line-height: var(--headline-line-height);
  color: var(--color-secondary);
  font-weight: bold;
}

.caris-authentication-message__not-signed-in-message {
  font-size: var(--font-size-M);
  line-height: var(--regular-line-height);
  color: var(--color-white);
  margin-top: var(--space-1);
}

.caris-authentication-message__links {
  margin-top: var(--space-2);
  margin-bottom: var(--space-1);
}

.caris-authentication-message__link {
  display: inline-block;
  margin-right: var(--space-1--half);
  color: white;
  text-decoration: underline;
  font-size: var(--font-size-M);
  line-height: var(--regular-line-height);
}

.caris-authentication-message__login-button {
  width: 100%;
  margin-top: var(--space-2);
}

@media (max-width: #{$_widthXXLFrom}) {
  .caris-authentication-message {
    --regular-font-size: var(--font-size-SM);
    --regular-line-height: var(--font-size-M);
    --headline-font-size: var(--font-size-M);
    --headline-line-height: var(--font-size-ML);
  }
}

@media #{$_mediaMDown} {
  .caris-authentication-message {
    --regular-font-size: var(--font-size-S);
    --regular-line-height: var(--font-size-SM);
    --headline-font-size: var(--font-size-SM);
    --headline-line-height: var(--font-size-SM);
  }
}
</style>
