
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="ArrowBoxSvg" xmlns="http://www.w3.org/2000/svg" id="_01_Atoms_Icons_retoure" viewBox="0 0 32 32"><defs/><path id="bounding_box" fill="none" d="M0 0h31.999v31.999H0z"/><g id="icon" fill="#545456" transform="translate(2.666 6.675)"><path id="Fill_1" d="M9.45 18.666L0 13.239v-8.95L7.534 0l9.437 5.4v8.95L9.45 18.666zM11.572 9.2l-1.544.838v6.5l5.4-3.1V7.106l-1.543.838v2.167l-2.313 1.312zM1.543 5.965v6.37l6.942 3.986v-6.3zm2.47-2.183l-1.577.907 6.834 4 1.546-.846-6.8-4.057zm3.518-1.994L6.29 2.5l6.96 4.026 1.312-.714-7.031-4.024zm3.842 12.519v-1.332l.269-.155v1.335l-.268.151zm.455-.258v-1.336l.265-.152V13.9l-.263.152zm.632-.362v-1.336l.265-.152v1.335l-.264.152zm.443-.256V12.1l.265-.151v1.335l-.263.151zm.445-.256V11.84l.27-.156v1.336l-.269.154zm.658-.376v-1.337l.264-.151v1.335l-.262.152z" class="cls-2"/><path id="Fill_1-2" d="M8.248 6.138v2.046l4.122-4.107L8.242 0v2.046S1.4 3.137 0 9.2c2.986-3.171 8.248-3.062 8.248-3.062" class="cls-2" transform="rotate(83.98 12.682 14.09)"/></g></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'ArrowBoxSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
