export const setCarisState = ({ data, component } = {}) => {
  if (!data) {
    return false;
  }

  return {
    data,
    component,
    expanded: data.expanded,
    autoExpand: data.expanded,
    filters: false,
    fetching: false,
    filterStep: 1,
    garageVehicles: [],
    selectableVehicles: [],
    selectedVehicle: null,
    isDialogActive: false,
    saveToGarageVehicle: {
      ktypnr: null,
      garageName: null,
    },
    filteredCar: {
      manufacturer: {},
      model: {},
      type: {},
    },
    manufacturers: {
      clusters: [],
      header: {},
      noResults: false,
    },
    models: {
      clusters: [],
      header: {},
      noResults: false,
    },
    types: {
      clusters: [],
      header: {},
      noResults: false,
    },
  };
};
