<template>
  <div
    class="pdp-table"
    :class="[classes, `pdp-table--${modifier}`]"
    :data-preload="[$options.name]"
  >
    <span v-if="headline" class="pdp-table__headline">
      {{ headline }}
    </span>

    <ul v-if="isCompact" class="pdp-table__list">
      <template v-for="(line, index) of renderedLines">
        <li
          v-if="rowModifier.indices.includes(index)"
          :key="`${index}-slot`"
          class="pdp-table__list-item pdp-table__list-item--slot"
        >
          <slot name="row-modifier-extra" />
        </li>
        <li
          :key="`${index}-list-item`"
          class="pdp-table__list-item"
          :class="[
            rowModifier.indices.includes(index)
              ? `pdp-table__list-item--${rowModifier.modifier}`
              : 'pdp-table__list-item--regular',
            previousSiblingIndices.includes(index) &&
            !rowModifier.indices.includes(index)
              ? 'pdp-table__list-item--prev-sibling-to-modified'
              : '',
          ]"
        >
          <span
            v-for="{ label, key } of columns"
            :key="line[key]"
            class="pdp-table__list-item-cell"
            :class="{
              'pdp-table__list-item-cell--full-row': key === fullRowColumnKey,
            }"
          >
            <span class="pdp-table__list-item-cell-header"> {{ label }}</span>
            <span class="pdp-table__list-item-cell-value">
              {{ line[key] }}
            </span>
          </span>
        </li>
      </template>
    </ul>
    <table v-else class="pdp-table__table">
      <thead v-if="showHeaders" class="pdp-table__table-header">
        <tr class="pdp-table__header-row">
          <th
            v-for="({ label }, columnIndex) of columns"
            :key="label"
            class="pdp-table__table-header-cell"
            :style="cellStyling(columnIndex)"
          >
            {{ label }}
          </th>
        </tr>
      </thead>
      <tbody class="pdp-table__table-body">
        <template v-for="(line, index) of renderedLines">
          <tr
            v-if="rowModifier.indices.includes(index)"
            :key="`${index}-slot-row`"
            class="pdp-table__table-row pdp-table__table-row--slot-row"
            :class="[
              rowModifier.indices.includes(index)
                ? `pdp-table__table-row--${rowModifier.modifier}`
                : 'pdp-table__table-row--regular',
            ]"
          >
            <td
              class="pdp-table__table-cell pdp-table__table-cell--slot-cell"
              :colspan="columns.length"
            >
              <slot name="row-modifier-extra" />
            </td>
          </tr>
          <tr
            :key="index"
            class="pdp-table__table-row"
            :class="[
              rowModifier.indices.includes(index)
                ? `pdp-table__table-row--${rowModifier.modifier}`
                : 'pdp-table__table-row--regular',
              previousSiblingIndices.includes(index) &&
              !rowModifier.indices.includes(index)
                ? 'pdp-table__table-row--prev-sibling-to-modified'
                : '',
            ]"
          >
            <td
              v-for="({ key }, columnIndex) of columns"
              :key="line[key]"
              class="pdp-table__table-cell"
              :style="cellStyling(columnIndex)"
            >
              <slot :name="key" :line="line">
                {{ line[key] }}
              </slot>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <button
      v-if="!showAll && loadLimit && loadLimit < lines.length"
      class="pdp-table__show-all-button"
      type="button"
      @click="toggleShowAll"
    >
      {{ showAllLabel }}
    </button>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'PdpTable',
  mixins: [globalMixin],
  props: {
    headline: {
      type: String,
      default: '',
    },
    lines: {
      type: Array,
      default: () => [],
      required: true,
    },
    columns: {
      type: Array,
      default: () => [],
      required: true,
    },
    showHeaders: {
      type: Boolean,
      default: true,
    },
    modifier: {
      type: String,
      default: 'regular',
    },
    rowModifier: {
      type: Object,
      default: () => ({
        indices: [],
        modifier: '',
      }),
    },
    loadLimit: {
      type: Number,
      default: 0,
    },
    showAllLabel: {
      type: String,
      default: '',
    },
    isCompact: {
      type: Boolean,
      default: false,
    },
    fullRowColumnKey: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    showAll: false,
  }),
  computed: {
    limit() {
      return this.loadLimit && !this.showAll
        ? this.loadLimit
        : this.lines.length;
    },
    renderedLines() {
      return this.lines.slice(0, this.limit);
    },
    previousSiblingIndices() {
      return this.rowModifier.indices.map((index) => index - 1);
    },
  },
  methods: {
    toggleShowAll() {
      this.$emit('show-all-click');
      this.showAll = !this.showAll;
    },

    cellStyling(columnIndex) {
      let ratio;

      if (columnIndex === 0) {
        ratio = 100 / this.columns.length + 10;
      } else {
        if (columnIndex === 1) {
          ratio = 100 / this.columns.length - 10;
        } else {
          ratio = 100 / this.columns.length;
        }
      }

      return `flex-basis: ${ratio}%; min-width: ${ratio}%;`;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

@mixin typography {
  font-size: var(--font-size-M);
  line-height: var(--font-size-MLL);
  color: var(--color-nero);
}

@mixin disable-underline {
  border-bottom: none !important;

  &::after {
    display: none;
  }
}

.pdp-table {
  max-height: 500px;
  overflow-y: auto;

  &--primary {
    .pdp-table__table {
      background-color: var(--color-alto-light);
    }

    .pdp-table__table-row:not(:last-child)::after {
      bottom: 0;
      left: var(--space-2);
      height: 1px;
      width: calc(100% - var(--space-2) * 2);
      content: '.';
      color: transparent;
      background-color: var(--color-alt);
      position: absolute;
    }

    .pdp-table__table-row:first-child {
      .pdp-table__table-cell {
        padding-top: var(--space-1--half);
      }
    }

    .pdp-table__table-row:last-child {
      .pdp-table__table-cell {
        padding-bottom: var(--space-1--half);
      }
    }

    .pdp-table__table-header-cell {
      padding-top: var(--space-1--half);
    }
  }

  &--regular {
    .pdp-table__table-row:not(:last-child) {
      border-bottom: 1px solid var(--color-alt);
    }
  }
}

.pdp-table__headline {
  font-weight: bold;
  margin-bottom: var(--space-2);

  @include typography;
}

.pdp-table__table-body {
  display: block;
}

.pdp-table__list {
  width: 100%;
  border-radius: 8px;
}

.pdp-table__list,
.pdp-table__table {
  overflow-x: auto;
}

.pdp-table__list-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 var(--space-2) var(--space-2) var(--space-2);

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-alt);
  }

  &--slot {
    padding-top: var(--space-2);
    padding-bottom: 0;
    background-color: var(--color-success-green);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    @include disable-underline;
  }

  &--success {
    background-color: var(--color-success-green);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    @include disable-underline;
  }

  &--prev-sibling-to-modified {
    @include disable-underline;
  }
}

.pdp-table__list-item-cell {
  display: block;
  margin-top: var(--space-1--half);

  &--full-row {
    flex-basis: 100%;
  }
}

.pdp-table__list-item-cell-header {
  display: block;
  font-size: var(--font-size-S);
  line-height: var(--font-size-M);
  color: var(--color-rolling-stone);
}

.pdp-table__list-item-cell-value {
  @include typography;
}

.pdp-table__table {
  text-align: left;
  width: 100%;
  table-layout: fixed;
  border-radius: var(--border-radius-default);
}

.pdp-table__table-row {
  box-sizing: border-box;
  position: relative;
  display: flex;

  &:first-child {
    margin-top: var(--space-0--half);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    margin-bottom: var(--space-0--half);
  }

  &--prev-sibling-to-modified {
    @include disable-underline;
  }

  &--success {
    background-color: var(--color-success-green);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    @include disable-underline;

    .pdp-table__table-cell {
      padding-bottom: var(--space-1--half);

      &:first-child {
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }

  &--slot-row {
    height: unset;

    .pdp-table__table-cell.pdp-table__table-cell--slot-cell {
      padding: var(--space-2) var(--space-2) 0;
      border-radius: 8px 8px 0 0;
    }
  }
}

.pdp-table__header-row {
  display: flex;
}

.pdp-table__table-header-cell {
  font-weight: bold;
  padding-bottom: var(--space-1--half);
  overflow: hidden;
  text-overflow: ellipsis;

  @include typography;

  &:first-child {
    padding-left: var(--space-2);
  }

  &:last-child {
    padding-right: var(--space-2);
  }
}

.pdp-table__table-cell {
  max-width: 285px;
  vertical-align: top;
  padding: var(--space-0--half) var(--space-4) var(--space-0--half) 0;

  @include typography;

  &:first-child {
    padding-left: var(--space-2);
  }
}

.pdp-table__show-all-button {
  text-decoration: underline;
  cursor: pointer;
  margin-top: var(--space-1--half);

  @include typography;
}
</style>
