import qs from 'qs';
import AjaxService, { axios } from 'Services/AjaxService';

const WISHLIST_SHARE_LINK_ENDPOINT_KEY = 'checkout.ajax.wishlist.share';
const WISHLIST_ADD_ALL_ENDPOINT_KEY = 'checkout.ajax.wishlist.cart.add_all';
const WISHLIST_EMPTY_ENDPOINT_KEY = 'checkout.ajax.wishlist.clear';

export const addToWishlist = async ({ commit, state, rootState }, data) => {
  const url = rootState.core.fetchedUrls['checkout.ajax.wishlist.add'];

  const payload = {
    sku: data.product.sku,
    carTypeNumber: data.product.carTypeNumber,
    quantity: data.quantity,
    productId: data.product.productId,
    list: data.list,
  };

  const request = await axios.post(url, qs.stringify(payload));

  if (request.data) {
    commit('WISHLIST_UPDATE', request.data);
    state.data.isWished = true;
  }
};

export const getShareLink = async ({ rootState }, { wishlistId }) => {
  const { fetchedUrls } = rootState.core;
  let result;
  try {
    result = await AjaxService.post(
      fetchedUrls[WISHLIST_SHARE_LINK_ENDPOINT_KEY].replace(
        '{idWishlist}',
        wishlistId
      ),
      { wishlistId }
    );
  } catch (e) {
    result = null;
  }

  return { shareLink: result ? result.data.url : null };
};

export const addAllToCart = async ({ rootState, commit }, { wishlistId }) => {
  const { fetchedUrls } = rootState.core;
  let result;
  try {
    result = await AjaxService.post(
      fetchedUrls[WISHLIST_ADD_ALL_ENDPOINT_KEY].replace(
        '{idWishlist}',
        wishlistId
      ),
      { wishlistId }
    );
  } catch (e) {
    result = null;
  }

  if (result && result.data) {
    updateWishlistAndMiniCartStores(result.data, commit);
  }
};

export const emptyWishlist = async ({ rootState, commit }, { wishlistId }) => {
  const { fetchedUrls } = rootState.core;
  let result;
  try {
    result = await AjaxService.post(
      fetchedUrls[WISHLIST_EMPTY_ENDPOINT_KEY].replace(
        '{idWishlist}',
        wishlistId
      ),
      { wishlistId }
    );
  } catch (e) {
    result = null;
  }

  if (result && result.data) {
    updateWishlistAndMiniCartStores(result.data, commit);
    commit('SET_ROWS', []);
  }
};

const updateWishlistAndMiniCartStores = ({ minicart, wishlist }, commit) => {
  if (minicart) {
    commit('miniCart/MINICART_UPDATE', { minicart }, { root: true });
  }
  if (wishlist) {
    commit('wishlist/WISHLIST_UPDATE', { wishlist }, { root: true });
  }
};
