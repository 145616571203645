<i18n src="./translations"></i18n>

<template>
  <div :class="[classes]" :data-preload="[$options.name]">
    <p class="brands-title">{{ $t('brand-filter-container.title') }}:</p>
    <div class="wrapper">
      <ul class="brands-options" :class="{ expanded: isShowingMore }">
        <li
          v-for="(item, index) in brandsList"
          :key="index"
          class="brand-item"
          :class="{
            active: item.isActive,
          }"
          @click="handleClickBrand(item.alias, item.isActive)"
        >
          <AdvancedImage
            v-if="item.imageUrl"
            class="imageContainer"
            :src="item.imageUrl"
            :alt="item.title"
            :is-brand="true"
            :title="item.title"
            :lazy="true"
          />
          <span class="alternate-text">{{ item.title }}</span>
        </li>
        <li
          v-if="brandsList.length > nrOfBrandFiltersDisplayed"
          class="show-more-button"
          @click="isShowingMore = !isShowingMore"
        >
          {{
            !isShowingMore
              ? $t('brand-filter-container.show-more')
              : $t('brand-filter-container.show-less')
          }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import globalMixin from 'Libs/mixins/globalMixin';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import AdvancedImage from 'Components/01-atoms/advanced-image/AdvancedImage';

export default {
  name: 'BrandFilterContainer',
  components: {
    AdvancedImage,
  },
  mixins: [globalMixin],
  props: {
    alias: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localValue: cloneDeep(this.value),
      isShowingMore: false,
      nrOfBrandFiltersDisplayed: 5,
    };
  },
  computed: {
    hasDirtyData() {
      return !isEqual(this.value, this.localValue);
    },
    brandsList() {
      return Object.values(this.options).sort(
        (a, b) => (b.isActive === true) - (a.isActive === true)
      );
    },
  },
  mounted() {
    this.$watch('value', (newValue) => {
      this.localValue = cloneDeep(newValue);
    });
  },
  methods: {
    handleClickBrand(brandAlias, isActive) {
      if (!isActive) {
        this.$emit('input', [...this.value, brandAlias]);
        return;
      }

      this.$emit(
        'input',
        this.value.filter((v) => v !== brandAlias)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'mixins';

.brandFilterContainer {
  background-color: var(--color-wild-sandish);
  padding: 0 var(--space-1--half) 0;

  @media #{$_mediaMDown} {
    padding: 0 var(--space-1--half);
  }

  .wrapper {
    display: flex;
    flex-wrap: nowrap;

    .show-more-button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: 3px;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: var(--color-gold-drop);
      }
    }

    @media #{$_mediaMDown} {
      .show-more-button {
        display: none;
      }
    }
  }

  .alternate-text {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 98px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;

    &:first-child {
      display: block;
    }
  }

  .advancedImage--failed-image {
    & + .alternate-text {
      display: block;
    }

    ::v-deep img {
      display: none;
    }
  }

  .brands-options {
    display: flex;
    flex-wrap: wrap;

    .brand-item {
      display: inline-block;
      flex-wrap: nowrap;
      align-items: center;
      width: 97px;
      min-width: 97px;
      height: 50px;
      background: white;
      border: 1px solid white;
      margin-right: var(--space-1--half);
      margin-left: var(--space-0--half);
      margin-bottom: var(--space-1--half);
      cursor: pointer;
      position: relative;

      &:last-child {
        margin: 0;
      }

      &.active,
      &:hover {
        border: 1px solid var(--color-secondary);
      }
    }

    .brand-item {
      display: none;

      @media #{$_mediaMDown} {
        display: inline-block;
      }

      &:nth-child(-n + 5) {
        display: inline-block;
      }
    }

    &.expanded .brand-item:not(:nth-child(-n + 5)) {
      display: inline-block;
    }
  }

  .brands-title {
    margin-left: var(--space-0--half);
  }

  .brands-title,
  .show-more-button {
    font-size: 14px;
    color: var(--color-nero);
    margin-bottom: 6px;
  }

  .content--narrow ::v-deep .brand-item {
    width: 99px;
  }

  ::v-deep .advancedImage {
    width: 100px;
  }

  ::v-deep .brandImage {
    max-width: 100px;
    max-height: 42px;
    width: auto;
    height: auto;
    margin: 0 auto;
    padding: 2px 6px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    position: relative;
  }

  ::v-deep .imageContainer picture {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
  }

  ::v-deep .label__value {
    width: 180px;
    word-break: break-word;
    white-space: normal;
  }
}

@media #{$_mediaMDown} {
  .brandFilterContainer {
    .brands-options {
      overflow-x: auto;
      flex-wrap: initial;
      -webkit-overflow-scrolling: touch;
      scroll-behavior: smooth;
      white-space: nowrap;
    }

    .brands-title {
      font-size: 12px;
      margin-left: 3px;
    }

    .brand-item {
      &:nth-child(8n) {
        margin-right: 16px;
      }
    }
  }
}
</style>
