const openLink = (link) => {
  const anchorElement = document.createElement('a');

  anchorElement.setAttribute('href', link);
  anchorElement.setAttribute('target', '_blank');
  anchorElement.setAttribute('rel', 'noreferrer noopener');

  anchorElement.click();
  anchorElement.remove();
};

export default openLink;
