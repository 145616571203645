
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="ReloadSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 6v3l4-4-4-4v3c-4.4 0-8 3.6-8 8 0 1.6.5 3 1.2 4.3l1.5-1.5C6.2 14 6 13 6 12c0-3.3 2.7-6 6-6zm6.8 1.7l-1.5 1.5c.4.8.7 1.8.7 2.8 0 3.3-2.7 6-6 6v-3l-4 4 4 4v-3c4.4 0 8-3.6 8-8 0-1.6-.5-3-1.2-4.3z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'ReloadSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
