<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <div class="inner">
      <div class="content qa-ToastItem__content">
        <Icon
          v-if="message.icon"
          class="icon"
          :title="message.icon"
          :name="message.icon"
          width="24"
          height="24"
        />

        <p v-if="!hasHyperlink" class="text">
          {{ message.text }}
        </p>

        <p v-else class="text">
          {{ sterilizedMessageText }}

          <a v-if="getHyperlink" :href="getHyperlink.link">
            {{ getHyperlink.text }}
          </a>
        </p>

        <div class="close">
          <button
            class="closeButton"
            :title="$t('toast-item.close')"
            @click="$emit('close', message.uniqueKey)"
          >
            <CloseSvg class="close-icon" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseSvg from 'Components/00-generated/CloseSvg';
import Icon from 'Components/00-generated/Icon';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'ToastItem',
  components: {
    CloseSvg,
    Icon,
  },
  mixins: [globalMixin],
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasHyperlink() {
      const messageText = this.message?.text;
      return messageText.includes('<a ') && messageText.includes('href="');
    },
    getHyperlink() {
      // TBD [Mina]: Refactor this logic and have a specific prop for hyperlinks in Toasts
      const messageText = this.message?.text;

      const text = messageText
        .split('<a ')[1]
        ?.split('">')[1]
        ?.split('</a>')[0];

      const link = messageText.split('href="')[1]?.split('">')[0];

      /* To avoid XSS attacks, a link must be:
        Navigating within the same domain
        and doesn't include any JS,
      */
      const isValidLink =
        link &&
        link.startsWith(document.location.origin) &&
        !link.includes('javascript');

      if (isValidLink && text) {
        return {
          text,
          link,
        };
      }

      return null;
    },
    sterilizedMessageText() {
      return this.message.text.split('<a ')[0];
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.toastItem {
  @include dropShadow(4px);

  width: calc(100% - 2 * var(--space-1));
  background: var(--color-wild-sand);
  max-height: 200px;
  overflow: hidden;
  margin: var(--space-1);
  border-radius: var(--border-radius-default);

  &--light-blue {
    background: var(--color-rebranding-semantic-light-blue);

    .icon {
      fill: var(--color-rebranding-semantic-blue);
    }

    .text {
      color: var(--color-rebranding-black-out);
      font-weight: normal;
    }

    .close-icon svg {
      fill: var(--color-rebranding-abbey);
    }
  }

  &--green {
    background: var(--color-japanese-laurel);

    .text {
      color: var(--color-white);
    }

    .icon,
    .close-icon {
      fill: var(--color-white);
    }

    .close {
      svg {
        fill: var(--color-white);
      }
    }
  }

  &--error {
    background: var(--color-monza);

    .text {
      color: var(--color-white);
    }

    .icon,
    .close-icon {
      fill: var(--color-white);
    }

    .close {
      svg {
        fill: var(--color-white);
      }
    }
  }
}

.inner {
  position: relative;

  @include pageWidth;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 7px var(--space-8) 7px var(--space-2);
}

.text {
  font-weight: bold;
  line-height: 26px;
  color: var(--color-nero);
  text-align: left;

  a {
    text-decoration: underline;
  }
}

.icon,
.close-icon {
  fill: var(--color-nero);
  margin-right: var(--space-1);
  flex-shrink: 0;
}

.close {
  position: absolute;
  right: var(--space-2);
  top: 50%;
  transform: translate(0, -50%);
}

.closeButton {
  border: none;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 2px;
  appearance: none;
  border-radius: 0;
  background: none;

  svg {
    display: block;
    width: 22px;
    height: 22px;
    margin: 0;
    fill: var(--color-nero);
  }
}

@media #{$_mediaXS} {
  .text {
    text-align: left;
  }
}
</style>
