
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="ClockSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15"><path fill-rule="evenodd" d="M8 4v4.3H4.5v-.8H7V4h1zm3.7 3.3c0-1-.2-1.7-.6-2.5-.4-.7-1-1.3-1.7-1.7-.7-.4-1.6-.6-2.4-.6-1 0-1.8.2-2.6.7C3.7 3.6 3 4 2.7 5c-.5.8-.7 1.6-.7 2.5 0 1 .2 1.7.7 2.4.4.8 1 1.4 1.7 1.8.8.4 1.6.6 2.5.6.7 0 1.6 0 2.3-.5.7-.4 1.3-1 1.8-1.8.5-.7.7-1.5.7-2.4zm2 0c0 1.2-.3 2.4-1 3.4-.5 1-1.4 2-2.4 2.5-1 .6-2.2 1-3.4 1-1.4 0-2.5-.4-3.6-1-1-.6-2-1.4-2.5-2.5-.7-1-1-2.2-1-3.4C0 6 .3 5 1 3.8c.5-1 1.4-1.8 2.4-2.5 1-.6 2.2-1 3.5-1 1 0 2.2.4 3.3 1 1 .7 2 1.5 2.5 2.5.6 1 1 2.2 1 3.5z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'ClockSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
