<template>
  <client-only>
    <div v-show="active" :class="classes" :data-preload="[$options.name]">
      <span v-show="!!searchTitle" class="search-title">{{ searchTitle }}</span>
      <ul class="items">
        <li
          v-for="(item, index) in items"
          :key="`search-suggestion-${index}`"
          :class="['item', { 'item--hover': selectionIndex === index }]"
          @click="onSuggestionClick(index, item)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <span class="itemLink" v-html="highlight(item.label)" />
          <!-- eslint-enable vue/no-v-html -->
        </li>
      </ul>
    </div>
  </client-only>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { mapState } from 'vuex';
import StringHelper from 'Libs/helpers/string';
import ClientOnly from 'vue-client-only';

export default {
  name: 'CompletionTerms',
  components: {
    ClientOnly,
  },
  mixins: [globalMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    searchTitle: {
      type: String,
      default: '',
    },
    fresh: {
      type: Boolean,
      default: true,
    },
    selectionIndex: {
      type: Number,
      default: null,
    },
    onClickHandler: {
      type: Function,
      required: true,
    },
  },
  computed: {
    active() {
      return this.fresh ? false : !!this.items.length;
    },
    ...mapState('search', {
      searchTerm: (state) => state.searchTerm,
    }),
  },
  methods: {
    onSuggestionClick(index, item) {
      if (!this.searchTitle) {
        this.onClickHandler(index);
        return;
      }
      // if no Keywords entered
      // [TODO-Mina] Add GTM
      location.assign(`${item.url}#static|${item.genart}`);
    },
    highlight(label) {
      const cleanLabel = StringHelper.removeTags(label, 'em');
      const searchTerm = StringHelper.getSafeRegEx(this.searchTerm);

      return cleanLabel.replace(searchTerm, '<em>$&</em>');
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.completionTerms {
  background-color: var(--color-white);
  border-bottom-left-radius: var(--border-radius-default);
  border-bottom-right-radius: var(--border-radius-default);

  @include dropShadow;
}

.search-title {
  margin: 8px 0 -8px;
  display: block;
  width: 100%;
  color: var(--color-boulder);
  font-size: 13px;
  padding: var(--space-1) var(--space-2);
}

.items {
  @include resetList;

  padding: var(--space-1) 0;
  position: relative;
}

.item {
  background: var(--color-white);
  transition: background-color var(--time-S) ease-in-out;
  cursor: pointer;
  text-decoration: none;
  font-style: unset;

  color: var(--color-secondary);

  .itemLink {
    padding: var(--space-1) var(--space-2);
    display: block;
  }

  ::v-deep {
    em {
      font-style: unset;

      color: var(--color-black);
    }
  }

  &--hover,
  &:hover {
    background-color: var(--color-gallery);
  }
}

.secondary-theme {
  &.is-suggested-term {
    .itemLink {
      color: var(--color-black);
      font-weight: bold;
    }
  }

  .search-title {
    font-size: 14px;
    color: var(--color-nero);
  }

  .item {
    color: var(--color-nero);

    & ::v-deep em {
      color: var(--color-nero);
      font-weight: normal;
    }
  }
}

.transitionInitial {
  opacity: 0;
  pointer-events: none;
  transform: translateY(-20px);
}

.transitionActive {
  transition: opacity var(--time-S) linear, transform var(--time-S) ease-in;
}

.transitionGoal {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0);
}
</style>
