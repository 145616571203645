import { render, staticRenderFns } from "./CarisHsnTsn.vue?vue&type=template&id=be5b92bc&scoped=true"
import script from "./CarisHsnTsn.vue?vue&type=script&lang=js"
export * from "./CarisHsnTsn.vue?vue&type=script&lang=js"
import style0 from "./CarisHsnTsn.vue?vue&type=style&index=0&id=be5b92bc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be5b92bc",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F02-molecules%2Fcaris-hsn-tsn%2FCarisHsnTsn.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports