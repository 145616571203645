var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.classes,
    { 'cmsBanners--reverse': _vm.theme === 'kfzteile-com' },
    'qa-cmsBanners',
  ],attrs:{"data-preload":[_vm.$options.name]}},[_c('div',{staticClass:"inner"},[_c('span',{staticClass:"headline"},[_vm._v("\n      "+_vm._s(_vm.headline)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"items"},_vm._l((_vm.banners),function(banner,index){return _c('div',{key:index,staticClass:"item qa-bannerItem",on:{"click":function($event){_vm.trackPromoClick({
            href: banner.link.href,
            position: banner.position,
            callback: () => _vm.redirectTo(banner.link.href),
          })}}},[(banner.callToAction)?_c('Button',{staticClass:"call-to-action",attrs:{"modifier":"primary","title":banner.callToAction}}):_vm._e(),_vm._v(" "),_c('AdvacedImage',{attrs:{"lazy":true,"src":banner.images.desktop.src,"alt":banner.images.desktop.alt || banner.images.mobile.alt || '',"reference-img-class":"image","config":{ q: 80 },"sources":[
            {
              media: 'M_DOWN',
              src: banner.images.mobile.src,
            },
          ]}})],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }