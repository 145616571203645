import { axios } from 'Services/AjaxService';
import qs from 'qs';
import { getProductCategoryLevelTwo } from '../data-layer/actions';

export const updateQuantity = async ({ commit, state, rootState }, data) => {
  const updatedProduct = state.data.minicart.products.find((product) => product.productId === data.productId);

  const url = rootState.core.fetchedUrls['checkout.ajax.cart.amount.modify'];
  const payload = {
    productId: data.productId,
    amount: data.quantity || 0,
    carTypeNumber: updatedProduct.carTypeNumber,
    sku: updatedProduct.sku,
    cartId: state.data.minicart.cartId,
    miniCart: true,
  };

  if (data.listOfOrigin) {
    payload.list = data.listOfOrigin;
  }

  const request = await axios.post(url, qs.stringify(payload), {
    hideSuccess: true,
  });

  if (request.data) {
    commit('MINICART_UPDATE', request.data);
  }
};

export const updateWishlist = async ({ commit, state, rootState }, data) => {
  const updatedProduct = state.data.minicart.products.filter((product) => {
    return product.productId === data.productId;
  });

  const url = rootState.core.fetchedUrls['checkout.ajax.cart.wishlist.add'];
  const payload = {
    productId: data.productId,
    amount: !updatedProduct[0].inWishlist
      ? updatedProduct[0].quantityAmount
      : 0,
    carTypeNumber: updatedProduct[0].carTypeNumber,
    sku: updatedProduct[0].sku,
    cartId: state.data.minicart.cartId,
    miniCart: true,
  };

  const request = await axios.post(url, qs.stringify(payload));

  if (request.data) {
    commit('MINICART_UPDATE', request.data);
    commit('wishlist/WISHLIST_UPDATE', request.data, { root: true });
  }
};

export const addToCart = async ({ commit, dispatch, rootState }, data) => {
  const url = rootState.core.fetchedUrls['checkout.ajax.cart.add'];

  const payload = {
    sku: data.product.sku,
    carTypeNumber: data.product.carTypeNumber,
    quantity: data.quantity,
    productId: data.product.productId,
    list: data.list ? data.list : getProductCategoryLevelTwo(data.product.sku),
  };

  const response = await axios.post(url, qs.stringify(payload), {
    hideSuccess: true,
  });

  if (response?.data) {
    commit('MINICART_UPDATE', response.data);
    commit('wishlist/WISHLIST_UPDATE', response.data, { root: true });

    setTimeout(() => {
      dispatch(
        'header/showLatestAddedNotification',
        {
          productId: data.product.productId,
        },
        { root: true }
      );
    }, 500);
  }
};
