<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    :class="[classes, { 'cmsContentText--reverse': theme === 'kfzteile-com' }]"
    :data-preload="[$options.name]"
    v-html="content"
  />
  <!-- eslint-enable vue/no-v-html -->
</template>

<script>
import { mapState } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'CmsContentText',
  mixins: [globalMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
    updatedAt: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('core', {
      theme: ({ salesChannel }) => salesChannel.theme,
    }),
  },
  serverCacheKey: ({ id, updatedAt }) => {
    /* istanbul ignore next */
    return `${id}::${updatedAt}`;
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.cmsContentText {
  padding: var(--space-2) var(--space-1);
  display: block;
  max-width: 100%;

  &--fullWidth {
    width: 100%;
  }

  ::v-deep {
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: 1em !important;
      font-weight: bold;

      & + * {
        margin-top: 0.5em;
      }

      &:first-child {
        margin-top: 0 !important;
      }
    }

    h1 {
      margin-bottom: 0.5em;

      line-height: 1.25em;
      font-size: var(--font-size-XL);

      color: var(--color-secondary);
    }

    &--reverse {
      h1 {
        color: var(--color-primary);
      }
    }

    h2,
    h3,
    h4,
    h5 {
      line-height: 32px;
    }

    h2 {
      font-size: var(--font-size-LXL);
    }

    h3 {
      font-size: var(--font-size-L);
    }

    table,
    p,
    ol,
    ul,
    blockquote {
      margin-bottom: 0.5em;
      font-size: var(--font-size-M);
      line-height: 26px;
    }

    ol,
    ul {
      margin-left: 1.5em;
    }

    ol {
      list-style: decimal;
    }

    ul {
      list-style: disc;
    }

    a {
      text-decoration: underline;

      color: var(--color-nero);
    }

    b,
    strong,
    th {
      font-weight: bold;
      font-size: 17px;
    }

    u {
      text-decoration: underline;
    }

    em,
    i {
      font-style: italic;
    }

    tr {
      & + & {
        th,
        td {
          padding-top: var(--space-1);
        }
      }
    }

    td,
    th {
      padding-left: var(--space-2);
      vertical-align: top;

      &:first-child {
        padding-left: 0;
      }
    }

    .ql-indent-1 {
      margin-left: 1.5em;
    }

    .ql-indent-2 {
      margin-left: 3em;
    }

    .ql-indent-3 {
      margin-left: 4.5em;
    }

    .ql-indent-4 {
      margin-left: 6em;
    }

    .ql-indent-5 {
      margin-left: 7.5em;
    }

    .ql-indent-6 {
      margin-left: 9em;
    }

    .ql-indent-7 {
      margin-left: 10.5em;
    }

    blockquote {
      border-left: 4px solid var(--color-rolling-stone);
      padding-left: calc(1.5em - 3px);
    }
  }
}

.cmsContentText {
  ::v-deep {
    .p {
      max-width: 800px;
    }

    .withHeadings h1,
    .searchTree h1 {
      font-size: 18px;
      color: inherit;
      font-weight: normal;
      line-height: 1;
      margin: 0 0 15px;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 4px;
    }

    .withHeadings h2,
    .searchTree div.searchTreeContent h2 {
      font-size: 14px;
      color: inherit;
      font-weight: bold;
      line-height: 1;
      margin: 0 0 5px;
      border-bottom: none;
      padding-bottom: 0;
    }

    @media only screen and (max-width: 800px) {
      .withHeadings h1,
      .searchTree h1 {
        font-size: 16px;
      }

      .withHeadings h2,
      .searchTree div.searchTreeContent h2 {
        font-size: 11px;
      }

      .withHeadings,
      .searchTree div.searchTreeContent {
        font-size: 11px;
      }
    }

    .columns p {
      max-width: none;
    }

    .columns,
    .columns > .column {
      display: block;
      box-sizing: border-box;
    }

    .columns {
      margin-left: -7.5px;
      margin-right: -7.5px;
    }

    .columns > .column {
      width: auto;
      top: auto;
      background: none;
      padding-left: 7.5px;
      padding-right: 7.5px;
      position: relative;
    }

    @media only screen and (min-width: 720px) {
      > ul.carLinks {
        list-style-position: inside;
      }

      > ul.carLinks > li {
        float: left;
        width: 50%;
        padding-left: 5px;
      }
    }

    @media only screen and (min-width: 641px) {
      .columns::after {
        display: block;
        clear: both;
        content: '';
      }

      .two.columns > .column {
        float: left;
        width: 50%;
      }

      .two.columns > .column.colspan2 {
        width: 100%;
      }

      .three.columns > .column {
        float: left;
        width: 33.3333333333%;
      }

      .three.columns > .column.colspan2 {
        width: 66.666666666%;
      }

      .three.columns > .column.colspan3 {
        width: 100%;
      }
    }

    .seoTextMain h1,
    .seoTextMain h2,
    .seoTextMain h3 {
      font-weight: normal;
      color: #ef7d00;
    }

    .seoTextMain h1 {
      font-size: 150%;
    }

    .seoTextMain h2 {
      font-size: 120%;
    }

    .m_all_30 {
      margin: var(--space-4);
    }

    .row {
      zoom: 1;
    }

    .col {
      display: block;
      float: left;
      margin: 1% 0 1% 1.6%;

      &:first-child {
        margin-left: 0;
      }
    }

    .row::before,
    .row::after {
      content: '';
      display: table;
    }

    .row::after {
      clear: both;
    }

    .span-1-of-2 {
      width: 49.2%;
    }

    .span-1-of-3 {
      width: 32.26%;
    }

    .span-1-of-4 {
      width: 23.8%;
    }

    .span-4-of-12 {
      width: 32.26%;
    }

    .span-6-of-12 {
      width: 49.2%;
    }

    .span-8-of-12 {
      width: 66.13%;
    }

    .span-9-of-12 {
      width: 74.6%;
    }

    .span-12-of-12 {
      width: 100%;
    }

    .m_top_0 {
      margin-top: 0;
    }

    .m_top_20 {
      margin-top: var(--space-3);
    }

    .m_bottom_0 {
      margin-bottom: 0;
    }

    .m_bottom_10 {
      margin-bottom: var(--space-1--half);
    }

    .m_right_15 {
      margin-right: var(--space-2);
    }

    .p_all_5 {
      padding: var(--space-0--half);
    }

    .p_all_10 {
      padding: var(--space-1--half);
    }

    .p_all_20 {
      padding: var(--space-2);
    }

    .box2x2 {
      margin-top: 0;
      background-color: var(--color-white);
      height: 180px;
      border: 1px solid #cccccc;
      border-radius: 2px;
      overflow: hidden;
      position: relative;
      display: inline;
    }

    .box3x3 {
      margin-top: 0;
      background-color: var(--color-white);
      border-radius: 2px;
      overflow: hidden;

      img {
        display: block;
        margin: auto;
        height: auto;
        border: 1px solid #cccccc;
        width: 100%;
        z-index: 2;
      }

      .box3x3 p {
        margin-top: var(--space-0--half);
      }
    }

    .box3x3-new {
      margin-top: var(--space-1--half);
      background-color: var(--color-white);
      height: 185px;
      border: 1px solid #cccccc;
      border-radius: 2px;
      overflow: hidden;
      position: relative;
      display: inline;
      padding-top: var(--space-4);
    }

    .box4x4 {
      margin-top: 0;
      margin-bottom: var(--space-1--half);
      background-color: var(--color-white);
      height: 180px;
      border: 1px solid #cccccc;
      border-radius: 2px;
      overflow: hidden;
      position: relative;

      img {
        position: absolute;
        display: inline;
        margin: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 180px;
        z-index: 2;
      }
    }

    .txt_size_14 {
      font-size: var(--font-size-SM);
      line-height: 1.5;
    }

    .txt_size_16 {
      font-size: var(--font-size-M);
      line-height: 1.5;
    }

    .txt_size_24 {
      font-size: var(--font-size-L);
      line-height: 1.5;
    }

    .txt_size_26 {
      font-size: var(--font-size-LLXL);
      line-height: 1.5;
    }

    .txt_size_36 {
      font-size: var(--font-size-XL);
      line-height: 1.25;
    }

    .font_k24 {
      font-family: glacialindifference, sans-serif;
    }

    .txt_color_bla {
      color: var(--color-mine-shaft);
    }

    .txt_color_blu {
      color: #174287;
    }

    .txt_color_whi {
      color: var(--color-white);
    }

    .txt_color_ora {
      color: #ef7d01;
    }

    .txt_color_gre {
      color: #666666;
    }

    .osram-orange-text {
      color: #f4820f;
    }

    .hero-logo-new {
      float: right;
      width: 25%;
    }

    img.img_res {
      width: 100%;
      height: auto;
      border-radius: 3px;
      border: 1px solid var(--color-concrete);
    }

    .btn_lp {
      display: inline-block;
      padding: 6px 15px;
      margin: calc(2rem - 18px) 0;
      height: 36px;
      line-height: 1.5rem;
      white-space: nowrap;
      font-weight: bold;
      text-decoration: none;
      letter-spacing: inherit;
      text-align: center;
      cursor: pointer;
      border-radius: 3px;
      border: none;
      color: var(--color-white) !important;
      background: linear-gradient(#f59c00, #ef7d00);
    }

    .align_center,
    .center {
      text-align: center;
    }

    .hero-text-box {
      width: 100%;
      height: 300px;
      position: relative;
      text-align: center;
      margin-top: 5px;
      overflow: hidden;
      background-color: var(--color-black);
      margin-bottom: 10px;

      .herologo {
        position: absolute;
        background-color: var(--color-white);
        padding: var(--space-0--half);
        max-width: 150px;
        height: auto;
        top: 0;
        left: 0;
        z-index: 2;
      }

      .heroimg {
        position: absolute;
        opacity: 0.6;
        width: auto;
        min-height: 300px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }

      .hero-text-fill {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-bottom: 2%;
        z-index: 3;
        white-space: nowrap;

        h1 {
          color: var(--color-white);
        }
      }
    }

    .btn-full:link,
    .btn-full:visited {
      background-color: #f4820f;
      border: 1px solid #f4820f;
      color: var(--color-white);
      margin-right: var(--space-2);
    }

    .btn:link,
    .btn:visited {
      display: inline-block;
      padding: var(--space-1--half) var(--space-4);
      font-weight: 400;
      text-decoration: none;
      border-radius: 3px;
      transition: background-color 0.2s, border 0.2s, color 0.2s;
    }

    .labelwhite {
      text-align: center;
      font-size: var(--font-size-MML);
      font-weight: 400;
      width: 100%;
      height: auto;
      margin-bottom: var(--space-1--half);
      content: ' ';
      position: absolute;
      display: inline;
      left: 50%;
      bottom: -10%;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, 0.8);
      z-index: 11;

      p {
        margin: 0;
        font-size: var(--font-size-SM);
      }
    }

    .labelwhite-new {
      text-align: left;
      font-size: 90%;
      font-weight: 400;
      width: 100%;
      height: auto;
      margin-bottom: var(--space-1--half);
      content: ' ';
      position: absolute;
      display: inline;
      left: 50%;
      bottom: 78%;
      transform: translate(-50%, -50%);
      background-color: rgb(255, 255, 255);
      z-index: 11;

      p {
        padding-left: var(--space-1--half);
      }
    }

    .productteaser-new {
      position: absolute;
      display: inline;
      margin: auto;
      max-width: 100%;
      z-index: 2;
    }

    .btn:link,
    .btn:visited {
      display: inline-block;
      padding: var(--space-1--half) var(--space-2);
      font-weight: 400;
      text-decoration: none;
      border-radius: 3px;
      transition: background-color 0.2s, border 0.2s, color 0.2s;
    }

    .btn-full-new:link,
    .btn-full-new:visited {
      background-color: #f4820f;
      border: 1px solid #f4820f;
      color: #ffffff;
      z-index: 900000;
      position: absolute;
      left: 50%;
      min-width: fit-content;
      top: 65%;
    }

    .linkbox {
      text-align: center;
      width: 100%;
      height: 100%;
      content: ' ';
      display: inline;
      position: absolute;
      z-index: 3;
      transition: background-color 0.4s;
    }

    .teaserlogo {
      position: absolute;
      margin-top: 5px;
      margin-left: 5px;
      width: 80px;
      height: auto;
      top: 0;
      left: 0;
      z-index: 6;
    }

    .productteaser {
      position: absolute;
      display: inline;
      margin: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 350px;
      max-height: 180px;
      z-index: 2;
    }

    .toall {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      background-color: var(--color-white);
      color: #555555;
      font-family: 'Arial', sans-serif;
      font-size: var(--font-size-ML);
      font-weight: 400;
      text-rendering: optimizeLegibility;
    }

    h3.accordion {
      background: #f4f4f4; // url(/cms/images/landingpages/acc_bg.png) -60px 0px no-repeat;
      cursor: pointer;
      margin: var(--space-0--half) 0;
      padding-left: var(--space-6);
      text-align: left;
      font-size: var(--font-size-MML);
      border: none;
      outline: none;
      transition: 0.4s;
      overflow: hidden;

      span {
        position: absolute;
        margin-left: -38px;
      }
    }

    h3.accordion + div.panel {
      padding: 0 var(--space-3);
      background-color: var(--color-white);
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
    }

    h3.accordion + div.panel h2 {
      font-size: 16px;
      margin-bottom: 5px;
    }

    h3.accordion + div.panel p:first-of-type {
      font-size: 14px;
      line-height: 21px;
    }

    .fa {
      display: inline-block;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .fa-minus::before {
      content: '\f068';
    }

    .fa-plus::before {
      content: '\f067';
    }

    .circleShapeWhite,
    .bgWhite {
      background-color: var(--color-white);
    }

    .inputText {
      color: var(--color-mine-shaft);
      padding: var(--space-0--half);
      line-height: 1.5;
      font-size: 100%;
    }

    .borderGrey {
      border: 1px solid #cccccc;
    }

    .borderRadius {
      border-radius: 3px;
    }

    .td_18_blu {
      font-size: var(--font-size-MML);
      line-height: 1.5;
      font-family: glacialindifference, sans-serif;
      background-color: #174287;
      color: #ffffff;
      padding-left: var(--space-0--half) !important;
      font-weight: bold;
    }

    .td_18_ora {
      font-size: var(--font-size-MML);
      line-height: 1.5;
      font-family: glacialindifference, sans-serif;
      background-color: #ef7d01;
      color: #ffffff;
      padding-left: var(--space-0--half) !important;
      font-weight: bold;
    }

    .cta {
      padding: 6px 15px;
      font-weight: 700;
      background: linear-gradient(#f59c00, #ef7d00);
      height: 36px;
      line-height: 1.5rem;
      color: var(--color-white);
      border: none;
      border-radius: 3px;
      font-size: var(--font-size-M);
      transition: all ease-out 0.25s;
      cursor: pointer;
    }

    .labeldetails {
      text-align: left;
      font-size: 90%;
      font-weight: 400;
      width: 100%;
      z-index: 5;

      p {
        font-size: var(--font-size-SM);
      }
    }

    p {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
    }

    body,
    .hasToolTip:hover::after {
      font-size: 13px;
    }

    table.custArea {
      border-spacing: 0;
      width: 100%;
      border: 1px solid #e0e0e0;
      margin-bottom: 15px;
    }

    .table_title {
      font-size: 14px;
    }

    .table_th_grey,
    table.custArea > tbody > tr:first-child > th,
    table.custArea.tpms > tbody > tr > th {
      background-color: #e0e0e0;
      text-align: left;
    }

    .table_th_grey_border {
      background-color: #e0e0e0;
      border-radius: 3px 3px 0 0;
    }

    .table_th_left_grey {
      background-color: #e0e0e0;
      border-radius: 3px 0 0;
    }

    .table_th_right_grey {
      background-color: #e0e0e0;
      border-radius: 0 3px 0 0;
    }

    .table_td_left_grey {
      border-left: solid 1px #e0e0e0;
    }

    table.customerService > tbody > tr > th:first-child,
    table.customerService > tbody > tr > td:first-child {
      padding-right: 20px;
    }

    table.customerService > tbody > tr > th {
      border-bottom: solid 1px #333333;
      font-weight: normal;
    }

    table.customerService > tbody > tr:nth-child(2) > td {
      padding-top: 10px;
    }

    table.opening > tbody > tr > td:first-child {
      padding-right: 30px;
    }

    table.baseLine > tbody > tr > td,
    table.payment1 > tbody > tr > td,
    table.createAccount > tbody > tr > td {
      vertical-align: baseline;
    }

    table.custArea.tpms {
      margin-top: 15px;
    }

    table.custArea.tpms th {
      text-align: left;
    }

    table.custArea.tpms tr:first-child > th {
      font-size: 14px;
    }

    table.custArea.tpms td:first-child {
      padding-top: 28px;
      position: relative;
    }

    table.custArea.tpms td:first-child > :first-child {
      font-size: 110%;
      position: absolute;
      top: 8px;
    }

    table.custArea.tpms td:last-child {
      width: 0;
      vertical-align: bottom;
    }

    table.custArea.tpms td:last-child a.button {
      margin-top: 3px;
    }

    @media only screen and (max-width: 900px) {
      #article-PopupContent table.custArea td,
      #article-PopupContent table.custArea th {
        padding: 3px;
      }
    }

    @media only screen and (max-width: 800px) {
      #article-PopupContent table.custArea td,
      #article-PopupContent table.custArea th {
        text-align: left;
      }
    }

    table.custArea.orders {
      width: auto;
    }

    table.custArea.paymentInfos {
      width: auto;
    }

    table.custArea > tbody > tr > th,
    table.custArea > tbody > tr > td {
      padding: 10px;
      font-weight: normal;
    }

    table.custArea.orders > tbody > tr > td {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    table.custArea.paymentInfos > tbody > tr > td.amount {
      text-align: right;
    }

    table.custArea.valueCoupon > tbody > tr:not(:last-child) > td {
      border-bottom: none;
    }

    table.custArea > tbody > tr:not(:last-child) {
      position: relative;
    }

    div.tablesContainer {
      max-width: 510px;
    }

    table.custArea > tbody > tr:not(:last-child) > td {
      border-bottom: 1px solid #e0e0e0;
    }

    table.custArea table {
      border-collapse: collapse;
    }

    table.custArea table td {
      text-align: center;
      vertical-align: center;
    }

    table.custArea table td[colspan] a {
      display: block;
    }

    table.custArea > tbody > tr > th,
    table.custArea > tbody > tr > td {
      padding: 5px 10px;
    }

    table.custArea.garageOffers td:last-child {
      text-align: right;
    }

    table.custArea.jobs {
      margin: 30px 0;
    }

    a.button,
    table.custArea.jobs.max480 a {
      color: inherit;
      text-decoration: none;
    }

    table.custArea.jobs.min480 td:last-child {
      width: 10px;
    }

    table.custArea > tbody > tr:first-child > th,
    table.custArea.tpms > tbody > tr > th {
      background-color: #e0e0e0;
    }

    table.garage td:first-child,
    table.branches td:first-child {
      padding-right: 10px;
      font-weight: bold;
    }

    .gradOrange {
      background: linear-gradient(#f59c00, #ef7d00);
    }

    .button {
      padding: 6px 15px;
      margin-top: var(--space-1);
      border: none;
      line-height: 1.5;
    }

    .white {
      color: #ffffff !important;
    }

    .borderRadius {
      border-radius: 3px;
    }

    #markenLogos #markenSliderContainer_all a {
      height: 200px;
      flex-basis: 33.33%;
      max-width: calc(33.33% - 8px);
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgb(85, 85, 85);
      border-radius: 6px;
      margin: 4px;

      img {
        max-width: 100%;
      }
    }
  }
}
</style>
