var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._b({staticClass:"headerButton--newHeader",class:[
    _vm.classes,
    {
      'headerButton--label': !!_vm.label,
      'headerButton--clicked': _vm.isActive,
    },
  ],attrs:{"tabindex":!_vm.href ? 0 : false,"title":_vm.title || !!_vm.title,"data-preload":[_vm.$options.name]},on:{"click":_vm.handleClick}},'button',_vm.attrs,false),[(!!_vm.iconConfig)?_c('Icon',_vm._b({},'Icon',_vm.iconConfig,false)):_vm._e(),_vm._v(" "),_c('client-only',[(_vm.count)?_c('div',{staticClass:"bubble"},[_c('div',{staticClass:"bubbleInner",class:`qa-${_vm.icon.name}BubbleInner`},[_vm._v("\n        "+_vm._s(_vm.count)+"\n      ")])]):_vm._e()]),_vm._v(" "),(!!_vm.label)?_c('span',{staticClass:"label",class:{ expanded: !_vm.toggle }},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),_c('ChevronRightSvg',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggle),expression:"toggle"}],staticClass:"toggleIcon qa-toggleIcon chevron-down"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }