import isEqual from 'lodash/isEqual';

export const OPEN_DESKTOP_MENU = (state) => {
  state.isDesktopOpen = true;
};
export const LOADING_STATE_MENU_ITEMS = (state, fetching) => {
  state.rootMenuFetching = fetching;
};

export const CLOSE_DESKTOP_MENU = (state) => {
  state.isDesktopOpen = false;
};

export const SELECT_ROOT_MENU = (state, menuItem) => {
  if (!state.data) return;
  state.data.menuItems = state.data?.menuItems?.map((item) => ({
    ...item,
    selected: isEqual(
      !!item.name && item.name,
      !!menuItem.name && menuItem.name
    ),
  }));
};

export const SET_LAST_SELECTED_MENU_ITEM_INDEX = (state, index) => {
  if (index) {
    state.lastSelectedMenuItemIndex = index;
  }
};

export const SET_ACTIVE_MENU_ITEM = (state, menuItem) => {
  if (menuItem && menuItem.path && !menuItem.path.length) {
    menuItem.path = [{ name: menuItem.name }];
  }
  state.activeMenuItem = menuItem;
};

export const SET_ACTIVE_MENU_ITEM_FETCH = (state, { fetching }) => {
  state.activeMenuItem.fetching = fetching;
};

export const SET_ROOT_MENU_ITEMS = (state, { menuItems, topCategories }) => {
  state.data.menuItems = [{ menuItems: topCategories }, ...menuItems].map(
    (category, index) => ({
      ...category,
      topCategories: index === 0,
      selected: index === 0,
      fetching: false,
      menuItems: index === 0 ? category.menuItems : category,
    })
  );
};

export const SET_MENU_ITEM_CHILDREN = (state, { menuItem, children }) => {
  state.activeMenuItem.menuItems = children;
  state.activeMenuItem.fetching = false;

  let item = null;
  let currentMenuItems = state.data.menuItems;

  if (menuItem.path.length === 0) {
    menuItem.path = [{ name: menuItem.name }];
    item = currentMenuItems.find(
      (currentMenuItem) => currentMenuItem.name === menuItem.name
    );
    // TODO : Refactor if(item) condition to remove duplication
    if (item) {
      currentMenuItems = item.menuItems || [];
    }
  } else {
    for (let i = 0; i < menuItem.path.length; i++) {
      const pathItem = menuItem.path[i];
      item = currentMenuItems.find(
        (menuItem) => menuItem.name === pathItem.name
      );
      if (item) {
        currentMenuItems = item.menuItems || [];
      }
    }
  }

  if (item) {
    item.menuItems = children;
    item.fetching = false;
  }
};

export const OPEN_MOBILE_NAV = (state) => {
  state.isMobileOpen = true;
};

export const CLOSE_MOBILE_NAV = (state) => {
  state.isMobileOpen = false;
};
