<template>
  <div id="app" :class="{ mounted }">
    <component :is="resolvedTemplate" :modifier="layoutModifier" />
    <div id="appLoader" :data-urls="printDataUrls()" />
  </div>
</template>

<script>
// https://github.com/Akryum/vue-virtual-scroller/issues/173
import 'regenerator-runtime/runtime.js';
import 'Src/fonts/GlacialIndifference-Bold.woff2';
import 'Src/fonts/GlacialIndifference-Bold.woff';
import 'Src/fonts/GlacialIndifference-Regular.woff2';
import 'Src/fonts/GlacialIndifference-Regular.woff';
import 'Src/fonts/GT-America-Extended-Regular.woff2';
import 'Src/fonts/GT-America-Extended-Bold.woff2';
import 'Src/fonts/GT-America-Extended-Black.woff2';
import 'Src/fonts/Ocran.woff2';
import 'Src/fonts/Ocran.woff';
import 'Src/styles/grid.scss';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import { mapActions, mapState } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';
import DefaultTemplate from 'Components/04-templates/default/DefaultTemplate';

const vueAppMounted = () => {
  window.vueAppReady = true;
  const event = new CustomEvent('VUE_APP__MOUNTED');
  window.dispatchEvent(event);
};

export default {
  name: 'ShopApp',
  components: {
    DefaultTemplate,
    MinimalTemplate: () =>
      import(
        'Components/04-templates/minimal/MinimalTemplate' /* webpackChunkName: "MinimalTemplate" */
      ),
    BackofficeTemplate: () =>
      import(
        'Components/04-templates/backoffice/BackofficeTemplate' /* webpackChunkName: "BackofficeTemplate" */
      ),
    FramelessTemplate: () =>
      import(
        'Components/04-templates/frameless/FramelessTemplate' /* webpackChunkName: "FramelessTemplate" */
      ),
  },
  mixins: [globalMixin],
  data() {
    return {
      mounted: false,
      urls: [
        'car.ajax.car.id',
        'car.ajax.deselect_car',
        'car.ajax.find_car',
        'car.ajax.garage',
        'car.ajax.hsn',
        'car.ajax.hsn',
        'car.ajax.manufacturers',
        'car.ajax.models',
        'car.ajax.select_car',
        'car.ajax.types',
        'checkout.ajax.cart.add',
        'checkout.ajax.cart.amount.modify',
        'checkout.ajax.cart.voucher.add',
        'checkout.ajax.cart.voucher.popup',
        'checkout.ajax.cart.wishlist.add',
        'checkout.ajax.step.address.edit',
        'checkout.ajax.step.address.type',
        'checkout.ajax.wishlist.add',
        'checkout.ajax.wishlist.clear',
        'checkout.ajax.wishlist.share',
        'checkout.ajax.wishlist.cart.add_all',
        'checkout.autocomplete.address.city',
        'checkout.autocomplete.address.postalcode',
        'checkout.autocomplete.address.street',
        'checkout.cart.details',
        'checkout.checkout.details',
        'checkout.checkout.login',
        'checkout.wishlist',
        'content.page.branches',
        'content.page.node_id.0',
        'content.page.privacy',
        'content.page.shipping',
        'customer.helpCenter',
        'customer.shop.address.create',
        'customer.shop.address.update',
        'customer.shop.ajax.newsletter.subscription',
        'customer.shop.ajax.vehicle.minimal.create',
        'customer.shop.customer_edit',
        'customer.shop.invoice',
        'order.shop.invoice',
        'customer.shop.login',
        'customer.shop.logout',
        'customer.shop.registration',
        'customer.shop.vehicles',
        'customer.shop.wishlist',
        'order.ajax.order',
        'order.shop.overview',
        'page.payment',
        'routing.shop.root',
        'search.ajax.bundle.product',
        'search.ajax.menu.navigation',
        'search.ajax.menu.subtree',
        'search.ajax.product',
        'search.ajax.product.car.data',
        'search.ajax.product.oe_numbers',
        'search.search_bar.completion',
        'search.search_bar.search',
        'stock.ajax.product.branches',
        'stock.ajax.availability',
        'search.alternativeproducts.get',
      ],
    };
  },
  computed: mapState('core', {
    isBot: ({ isBot }) => isBot,
    layoutModifier: (state) => state.template.layoutModifier,
    requestUrl: (state) => state.requestData.requestUrl,
    resolvedTemplate: (state) => {
      const template = `${upperFirst(camelCase(state.template.layout))}`;

      if (state.isBot === false && state.isShop === false) {
        if (template === 'DefaultTemplate') {
          return 'BackofficeTemplate';
        }

        return 'FramelessTemplate';
      }

      if (template !== 'MinimalTemplate') {
        return 'DefaultTemplate';
      }

      return template;
    },
  }),
  mounted() {
    // this code will not be available in the storybook (we use a custom template there)
    this.$store.dispatch(
      'messages/addMessages',
      this.$store.state.core.messages
    );
    this.$nextTick(vueAppMounted);
    this.mounted = true;
    this.trackLoginState();
    this.trackEnvironment();

    if (this.isBot) {
      window.seoLinksReady = true;
      const event = new CustomEvent('SEO_LINKS__READY');
      window.dispatchEvent(event);
    }

    window.dataLayer.push(window.dataLayerObject);

    if (this.requestUrl.includes('/shop/checkout/success') && window.Android) {
      const { revenue, id } = window.data.ecommerce.purchase.actionField;

      try {
        if (window.Android.onDataV2) {
          window.Android.onDataV2(revenue, id);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }

      try {
        if (window.Android.onDataV3) {
          window.Android.onDataV3(
            JSON.stringify(window.data.ecommerce.purchase.actionField)
          );
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  },
  methods: {
    ...mapActions('dataLayer', ['trackLoginState', 'trackEnvironment']),
  },
};
</script>
<style scoped lang="scss">
#appLoader {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
</style>
