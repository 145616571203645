<i18n src="./translations"></i18n>
<i18n src="Components/03-organisms/product-details-21/translations"></i18n>

<template>
  <div class="bundle-product" :class="classes" :data-preload="[$options.name]">
    <div class="bundle-product__image-container">
      <BaseFlag
        v-if="showUniversalFlag && !product.pdpCarBar"
        class="bundle-product__universal-flag"
        :text="$t('bundle-product.universal_flag')"
      />

      <div
        class="bundle-product__images"
        :class="{ active: imageHover }"
        @click="productClick()"
      >
        <AdvancedImage
          v-if="product.imageUrls && product.imageUrls.length"
          class="bundle-product__product-image"
          :src="product.imageUrls[0]"
          :config="{ q: 80, w: 180 }"
          @image-click="productClick()"
        />

        <NoImageSvg
          v-else
          class="bundle-product__no-image"
          :lazy="true"
          :inline="false"
          @click="productClick()"
        />
      </div>
    </div>

    <div class="bundle-product__content-container">
      <PdpInformation21
        v-if="isCarSelected && carAttributes.length"
        :headline="$t('bundle-product.car_attributes')"
        :has-hazardous-good-enabled="true"
        :regular="carAttributes"
        :product="product"
        :default-limit="1000"
        class="bundle-product__information is-desktop"
      />

      <PdpInformation21
        v-if="isCarSelected && information.length && carAttributes.length < 6"
        :headline="$t('bundle-product.general_attributes')"
        :has-hazardous-good-enabled="true"
        :product="product"
        :quantity="product.quantityAmount"
        :regular="information"
        :default-limit="1000"
        class="bundle-product__information"
      />

      <PdpInformation21
        v-if="!isCarSelected && (information.length || carAttributes.length)"
        :headline="$t('bundle-product.general_attributes')"
        :has-hazardous-good-enabled="true"
        :product="product"
        :regular="[productQuantity, ...carAttributes, ...information]"
        :default-limit="1000"
        class="bundle-product__information"
      />

      <PlpDescription21
        v-if="
          !attributes.length &&
          product.shortDescription &&
          !!product.shortDescription.length
        "
        :description="product.shortDescription"
        class="bundle-product__information is-desktop"
      />

      <PdpInformation21
        :product-id="product.productId"
        :has-oe-numbers="!!product.oeNumbers.length"
        :oe-numbers="product.oeNumbers"
        :default-limit="1000"
        :product="product"
        class="bundle-product__information"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin.js';
import AdvancedImage from 'Components/01-atoms/advanced-image/AdvancedImage.vue';
import PdpInformation21 from 'Components/02-molecules/pdp-information-21/PdpInformation21.vue';
import NoImageSvg from 'Components/00-generated/NoImageSvg.vue';
import BaseFlag from 'Components/01-atoms/base-flag/BaseFlag';
import PlpDescription21 from 'Components/02-molecules/plp-description-21/PlpDescription21';

export default {
  name: 'BundleProduct',
  components: {
    AdvancedImage,
    PdpInformation21,
    NoImageSvg,
    BaseFlag,
    PlpDescription21,
  },
  mixins: [globalMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    amount: {
      type: Number,
      default: 0,
    },
    position: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      imageHover: false,
      showUniversalFlag: false,
      furtherDetailsExpanded: false,
      isAddToCartInProgress: false,
    };
  },
  computed: {
    isCarSelected() {
      return (
        this.product.pdpCarBar &&
        this.product.pdpCarBar.key &&
        this.product.pdpCarBar.key === 'SELECTED_CAR_OK'
      );
    },
    detailsButtonClass() {
      return ['button--secondary', 'qa-ProductCard__showDetails'];
    },
    attributes() {
      if (!this.product || !this.product.attributes) return [];

      return this.product.attributes.map((attribute) => ({
        label: attribute.title,
        info: attribute.value,
        isTop: attribute.isTop,
      }));
    },
    productQuantity() {
      const pieceTranslation =
        this.amount > 1
          ? this.$t('bundle-product.quantity.pieces')
          : this.$t('bundle-product.quantity.piece');

      return {
        label: this.$t('bundle-product.quantity.amount'),
        info: `${this.amount} ${pieceTranslation}`,
        isTop: true,
      };
    },
    carAttributes() {
      return this.attributes.filter(({ isTop }) => isTop);
    },
    information() {
      return this.attributes.filter(({ isTop }) => !isTop);
    },
  },
  methods: {
    ...mapActions('dataLayer', ['trackProductClick']),
    ...mapActions('wishlist', ['addToWishlist']),
    productClick() {
      this.trackProductClick({
        product: this.product,
        position: this.position,
      }).finally(() => {
        if (this.product.productUrl) {
          window.location.href = this.product.productUrl;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';
@import 'mixins';

.bundle-product {
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;

  @media #{$_mediaSDown} {
    padding-left: 0;

    border: 0;
  }

  @media #{$_mediaLUp} {
    padding: 0 var(--space-2) var(--space-2);
  }

  @media #{$_mediaLDown} {
    .is-desktop {
      display: none;
    }
  }

  &__no-image {
    max-height: 126px;
  }

  &__image-container {
    position: relative;
    width: 25%;
    max-width: 150px;
    padding-right: var(--space-1--half);
  }

  &__content-container {
    padding: var(--space-0--half) 0 var(--space-1--half) 0;
    text-align: left;
    width: 75%;
    max-width: 600px;
  }

  &__product-image {
    max-height: 140px;
    max-width: 100%;
    cursor: pointer;
    height: auto;
    width: auto;

    &:hover,
    &.active {
      ::v-deep .img {
        @media #{$_mediaMUp} {
          transform: scale(1.06);
        }

        @media #{$_mediaMUp} {
          transform: scale(1.06);
        }
      }
    }

    ::v-deep .img {
      transition: transform var(--time-S) ease-in-out;
      max-height: 140px;
    }
  }

  &__images {
    display: flex;
    margin-top: 3px;
    flex-direction: column;
    align-items: center;
  }

  &__universal-flag {
    display: none;
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 1;

    @media #{$_mediaLUp} {
      display: block;
    }
  }

  &__information {
    padding-left: 3px;

    @media #{$_mediaMUp} {
      padding-left: var(--space-1--half);
    }

    @media #{$_mediaLDown} {
      padding-left: 0;
    }

    @media #{$_mediaXLUp} {
      padding-left: 0;
    }
  }

  ::v-deep {
    .pdp-information-21 {
      &__headline {
        display: none;
      }

      &__wrapper {
        padding-left: 0;
      }
    }
  }
}
</style>
