<i18n src="./translations"></i18n>

<template>
  <form
    :class="[classes, 'form']"
    autocomplete="off"
    :data-preload="[$options.name]"
    @submit.prevent="emitClick"
  >
    <div class="divider">
      {{ $t('caris-type-mine-search.or') }}
    </div>
    <div class="label">
      {{ $t('caris-type-mine-search.type_mine.title') }}
      <button type="button" class="helpBttn" @click="showDialog">
        <Icon
          name="help"
          class="help"
          width="16"
          height="16"
          :original="true"
          :inline="false"
          :lazy="true"
        />
      </button>
    </div>

    <div class="mode">
      <div class="fields">
        <div :class="['field', 'field--full']">
          <InputBase
            ref="typeMine"
            :errors="errors"
            :label="$t('caris-type-mine-search.type_mine.input_label')"
            :value="typeMineValue"
            full-name="mine-type"
            type="text"
            :send="true"
            :attr="{
              autocomplete: 'off',
              autocorrect: 'off',
              autocapitalize: 'off',
              spellcheck: 'false',
            }"
            @input="handleChange"
          />
        </div>
        <div class="caris_type_mine_search__where-to-find-button">
          <button
            class="where-to-find-button"
            :class="qaClassName + 'whereToFindButton'"
            type="button"
            @click="showDialog"
          >
            {{ $t('caris-type-mine-search.where_to_find') }}
          </button>
        </div>
      </div>
      <div class="fields">
        <CarisDropdown
          ref="multiCarDropdown"
          :class="['field', 'field--full', 'qa-MultiCarDropdown']"
          :disabled="Object.keys(carList).length === 0"
          :car-list="carList"
          :placeholder="$t('caris-type-mine-search.type_mine.dropdown_label')"
        />
      </div>

      <div class="buttonWrapper">
        <Button
          type="submit"
          :modifiers="['primary', 'full']"
          :title="$t('caris-type-mine-search.find')"
        />
      </div>

      <Dialog
        ref="dialog"
        :click-outside="true"
        :title="$t('caris-type-mine-search.type_mine.title')"
      >
        <img
          class="explanationImage"
          :data-src="resolveFile('images/caris-type-mine-search/type_mine.jpg')"
          :src="resolveFile('images/caris-type-mine-search/type_mine.jpg')"
          :title="$t('caris-type-mine-search.type_mine.title')"
          loading="lazy"
        />
      </Dialog>
    </div>
  </form>
</template>

<script>
import './images/type_mine.jpg';
import { mapActions } from 'vuex';
import { ktypnrMixin } from 'Libs/mixins/ktypnrMixin.js';
import Icon from 'Components/00-generated/Icon';
import InputBase from 'Components/01-atoms/form/input/InputBase.vue';
import Button from 'Components/01-atoms/button/Button.vue';
import CarisDropdown from 'Components/02-molecules/caris-dropdown/CarisDropdown.vue';
import Dialog from 'Components/02-molecules/dialog/Dialog.vue';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'CarisTypeMineSearch',
  components: {
    InputBase,
    Button,
    CarisDropdown,
    Dialog,
    Icon,
  },
  mixins: [globalMixin, ktypnrMixin],
  data() {
    return {
      carList: {},
      typeMineValue: '',
      errors: [],
    };
  },
  methods: {
    ...mapActions('carSelection', ['doTypeMineSearch']),
    handleChange(value) {
      this.errors = [];

      this.typeMineValue = value;
    },
    searchWithTypeMine() {
      this.carList = {};

      this.doTypeMineSearch({
        typeMine: this.typeMineValue,
        callbacks: {
          onsingle: this.setKtypnr.bind(this),
          onmultiple: (cars) => {
            this.carList = cars;
            this.$refs.multiCarDropdown.open();
          },
          onerror: (message) => {
            if (this.errors.every((error) => error.message !== message)) {
              this.errors.push({ message: message });
            }
            this.errors = [...this.errors];
            this.$refs.typeMine.handleInvalidInput();
            this.$refs.typeMine.focus();
          },
        },
      });
    },
    showDialog() {
      this.$refs.dialog.show();
    },
    emitClick(e) {
      this.$emit('submit-click', e);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.form {
  width: 100%;
  position: relative;
  min-width: 312px;
}

.divider {
  padding: 0 var(--space-2);
  text-align: center;

  font-size: var(--font-size-S);
  color: var(--color-rolling-stone);
  line-height: 20px;
  height: 20px;
}

.help {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.fields {
  display: flex;
  padding: 0 var(--space-1);
}

.field {
  width: 50%;
  padding: 0 var(--space-1) 0;

  &--full {
    width: 100%;
  }
}

.label {
  padding: 6px 0 12px;
  color: var(--color-rolling-stone);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 16px;
  text-align: center;
}

.buttonWrapper {
  padding: var(--space-1) var(--space-2);
  display: flex;
  flex-wrap: wrap;
  min-height: 44px;
}

.helpBttn {
  @include square(16px);

  padding: 0;
  margin-left: 12px;
}

.explanationImage {
  margin-top: var(--space-2);
  width: 100%;
  max-width: 440px;
}
</style>
