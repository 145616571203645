<i18n src="./translations"></i18n>

<template>
  <div
    :class="[
      classes,
      {
        'catalogDropdownContainer--opened': isOpened,
        'catalogDropdownContainer--manyItems': Object.keys(options).length > 6,
        'qa-catalogFilter': true,
      },
    ]"
    :data-preload="[$options.name]"
  >
    <div class="header" @click="toggleContainer">
      <div class="header__name" :title="title">
        {{ title }}
      </div>
      <div :class="['header__icon', { 'header__icon--up': isOpened }]">
        <Icon name="arrow_drop_down" width="9" height="9" />
      </div>
    </div>

    <div
      v-if="isOpened"
      v-click-outside="{
        handler: handleSubmit,
        isActive: isOpened,
        events: ['click'],
      }"
      class="container"
    >
      <div class="container__body">
        <CatalogFilterOptionList v-model="localValue" :options="options" />
      </div>
      <div class="container__footer">
        <Button
          v-if="hasDirtyData"
          class="applyButton button--primary qa-catalogFilter__apply"
          :modifiers="['full', 'short']"
          :on-click="handleSubmit"
          :title="$t('catalog-dropdown-container.apply')"
        />
        <Button
          v-else
          class="closeButton button--secondary qa-catalogFilter__close"
          :modifiers="['full', 'short']"
          :title="$t('catalog-dropdown-container.close')"
          :on-click="handleClose"
        />
      </div>
    </div>
  </div>
</template>
<script>
import globalMixin from 'Libs/mixins/globalMixin';
import Icon from 'Components/00-generated/Icon';
import CatalogFilterOptionList from 'Components/02-molecules/catalog-filter-option-list/CatalogFilterOptionList.vue';
import Button from 'Components/01-atoms/button/Button.vue';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'CatalogDropdownContainer',
  components: { Button, Icon, CatalogFilterOptionList },
  mixins: [globalMixin],
  props: {
    alias: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpened: false,
      localValue: cloneDeep(this.value),
    };
  },
  computed: {
    hasDirtyData() {
      return !isEqual(this.value, this.localValue);
    },
  },
  mounted() {
    this.$watch('value', (newValue) => {
      this.localValue = cloneDeep(newValue);
    });
  },
  methods: {
    toggleContainer() {
      this.isOpened = !this.isOpened;
      if (!this.isOpened) {
        this.handleClose();
      }
    },
    handleClose() {
      this.isOpened = false;
      this.localValue = this.value;
    },
    handleSubmit() {
      if (!isEqual(this.localValue, this.value)) {
        this.$emit('input', this.localValue);
      }
      this.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'mixins';

.catalogDropdownContainer {
  position: relative;
  width: 200px;
  height: 40px;

  &--opened {
    .header,
    .container {
      border-color: var(--color-chathamsblue);
    }

    .container {
      overflow: hidden;
    }

    .header__name {
      overflow: hidden;
      text-overflow: initial;
      white-space: normal;
      line-height: 14px;
      word-wrap: break-word;
      max-height: 29px;
    }
  }

  &--manyItems {
    .container {
      height: 308px;
      padding-top: 0;
    }
  }

  ::v-deep .label__value {
    width: 180px;
    word-break: break-word;
    white-space: normal;
  }
}

.header {
  cursor: pointer;
  height: 100%;
  border: 1px solid var(--color-alto);
  border-radius: 3px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 16px;

  &__name {
    color: var(--color-nero);
    font-size: var(--font-size-SM);
    line-height: 24px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -2px;
    padding-right: 5px;
  }

  &__icon {
    ::v-deep svg {
      color: var(--color-primary);
    }

    &--up {
      transform: rotateZ(180deg);
    }
  }
}

.container {
  width: 250px;
  max-height: 360px;
  min-height: 100px;
  padding-top: 10px;

  border: 1px solid var(--color-alto);
  background: var(--color-white);

  display: flex;
  flex-direction: column;

  position: absolute;
  top: 38px;

  z-index: 1;

  &__body {
    flex: 1;
    overflow-y: auto;

    @media all and (-ms-high-contrast: none) {
      min-height: 200px;
    }
  }

  &__footer {
    flex-grow: 0;
    flex-shrink: 0;

    padding: 10px 14px 14px;
  }
}
</style>
