<template>
  <nav :class="classes">
    <ul class="linksList">
      <li
        v-for="(item, index) in links"
        :key="index"
        class="listItem"
        @click="handleListItemClick(index, $event)"
      >
        <Icon class="listItem__icon" :name="item.icon" />
        <MaskedLink
          ref="listItemLink"
          class="listItem__link"
          :redirect-to="item.url"
          :text="item.title"
        />
      </li>
    </ul>
  </nav>
</template>

<script>
import Icon from 'Components/00-generated/Icon';
import globalMixin from 'Libs/mixins/globalMixin';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';

export default {
  name: 'StaticLinks',
  components: {
    Icon,
    MaskedLink,
  },
  mixins: [globalMixin],
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleListItemClick(index, { target }) {
      const listItemLinkComponent = this.$refs.listItemLink[index];

      if (listItemLinkComponent && listItemLinkComponent.$el !== target) {
        listItemLinkComponent.$el.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';
@import 'mixins';

.staticLinks {
  border-bottom: solid 1px var(--color-alt);
  padding-bottom: var(--space-1);
  margin: 0 var(--space-2);
}

.listItem {
  border: none;
  height: 44px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.listItem__link {
  font-weight: 500;
  color: var(--color-nero);
  margin-left: 8px;
}

.listItem__icon {
  align-self: center;
  width: 20px;
  height: 20px;

  fill: var(--color-secondary);
}
</style>
