<i18n src="./translations"></i18n>

<template>
  <ul class="caris-vehicle-table">
    <li
      v-if="viewport !== 'mobile'"
      class="caris-vehicle-table__header"
      :class="{
        'caris-vehicle-table__header--extended': !isSingleVehicle,
      }"
    >
      <span class="caris-vehicle-table__header-cell">
        {{ $t('caris-vehicle-table.header.type') }}
      </span>
      <span class="caris-vehicle-table__header-cell">
        {{ $t('caris-vehicle-table.header.motor') }}
      </span>
      <span class="caris-vehicle-table__header-cell">
        {{ $t('caris-vehicle-table.header.buildYear') }}
      </span>
      <span class="caris-vehicle-table__header-cell">
        {{ $t('caris-vehicle-table.header.motorCode') }}
      </span>
    </li>
    <template v-if="interpolatedVehicles && interpolatedVehicles.length > 0">
      <li
        v-for="vehicle in interpolatedVehicles"
        :key="vehicle.id"
        class="caris-vehicle-table__row gtm--caris-vehicle-details"
        :class="{
          'caris-vehicle-table__row--selected':
            !isSingleVehicle && selectedVehicle.id === vehicle.id,
          'caris-vehicle-table__row--selectable': !isSingleVehicle,
          'caris-vehicle-table__row--collapsed': vehicle.isCollapsed,
        }"
        @click="() => !isSingleVehicle && handleVehicleSelection(vehicle)"
      >
        <RadioBase
          v-if="!isSingleVehicle"
          class="caris-vehicle-table__row-cell caris-vehicle-table__row-cell--radio"
          :children="[
            {
              vars: {
                label: vehicle.interpolated.type,
                value: vehicle.interpolated.type,
              },
            },
          ]"
          :checked="selectedVehicle.id === vehicle.id"
        />
        <button
          class="caris-vehicle-table__expand-button"
          @click.stop="() => handleVehicleToggle(vehicle)"
        >
          <Icon
            v-if="!isSingleVehicle && viewport === 'mobile'"
            class="caris-vehicle-table__expand-icon"
            :class="
              !vehicle.isCollapsed &&
              'caris-vehicle-table__expand-icon--collapsed'
            "
            name="chevron_right"
          />
        </button>
        <template
          v-if="
            viewport === 'mobile' && !isSingleVehicle
              ? !vehicle.isCollapsed
              : true
          "
        >
          <span
            v-if="isSingleVehicle || viewport === 'mobile'"
            class="caris-vehicle-table__row-cell caris-vehicle-table__row-cell--first-with-header"
          >
            <span
              v-if="viewport === 'mobile'"
              class="caris-vehicle-table__header-cell caris-vehicle-table__header-cell--mobile"
            >
              {{ $t('caris-vehicle-table.header.type') }}
            </span>
            {{ vehicle.interpolated.type }}
          </span>
          <span class="caris-vehicle-table__row-cell">
            <span
              v-if="viewport === 'mobile'"
              class="caris-vehicle-table__header-cell caris-vehicle-table__header-cell--mobile"
            >
              {{ $t('caris-vehicle-table.header.motor') }}
            </span>
            {{ vehicle.interpolated.motor }}
          </span>
          <span class="caris-vehicle-table__row-cell">
            <span
              v-if="viewport === 'mobile'"
              class="caris-vehicle-table__header-cell caris-vehicle-table__header-cell--mobile"
            >
              {{ $t('caris-vehicle-table.header.buildYear') }}
            </span>
            {{ vehicle.interpolated.buildYear }}
          </span>
          <span class="caris-vehicle-table__row-cell">
            <span
              v-if="viewport === 'mobile'"
              class="caris-vehicle-table__header-cell caris-vehicle-table__header-cell--mobile"
            >
              {{ $t('caris-vehicle-table.header.motorCode') }}
            </span>
            {{ vehicle.interpolated.motorCode }}
          </span>
        </template>
      </li>
    </template>
  </ul>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import Icon from 'Components/00-generated/Icon';
import RadioBase from 'Components/01-atoms/form/radio/RadioBase.vue';

export default {
  name: 'CarisVehicleTable',
  components: { RadioBase, Icon },
  props: {
    vehicles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      interpolatedVehicles: {
        type: Array,
        default: () => [],
      },
      selectedVehicle: {
        type: Object,
        default: () => {},
      },
    };
  },
  computed: {
    ...mapState('core', {
      viewport: ({ viewport }) => viewport.range,
    }),
    isSingleVehicle() {
      return this.interpolatedVehicles.length === 1;
    },
  },
  watch: {
    vehicles() {
      this.processVehicles();
    },
  },
  mounted() {
    this.processVehicles();

    if (this.vehicles && this.vehicles.length === 1) {
      this.handleVehicleSelection(this.vehicles[0]);
    }
  },
  methods: {
    ...mapMutations('carSelection', {
      selectVehicle: 'CARIS_NEW_VEHICLE__SELECT_VEHICLE',
    }),
    processVehicles() {
      this.interpolatedVehicles = this.vehicles.map((vehicle) => ({
        ...vehicle,
        isCollapsed: true,
        interpolated: this.interpolateVehicle(vehicle),
      }));
    },
    interpolateVehicle(vehicle) {
      const integerToHumanReadableYear = (yearInInteger) => {
        const yearInString = yearInInteger.toString();
        return `${yearInString.slice(-2)}/${yearInString.slice(2, 4)}`;
      };

      const {
        manufacturer,
        model,
        generation,
        bodyCodes,
        motorCodes,
        type,
        ps,
        kw,
        ccm,
        buildFrom,
        buildUntil,
      } = vehicle;

      return {
        type: `${manufacturer} ${model ? model : ''} ${
          generation ? generation : ''
        } ${type ? type : ''} ${
          this.viewport !== 'mobile' && bodyCodes ? bodyCodes.join(', ') : ''
        }`,
        motor: `
          ${ccm ? ccm + 'cm, ' : ''}
          ${ps ? ps + 'PS, ' : ''}
          ${kw ? kw + 'kW' : ''}
          `,
        buildYear:
          buildFrom && buildUntil
            ? this.$t('caris-vehicle-table.table.buildFrom', {
                '%start%': integerToHumanReadableYear(buildFrom),
                '%end%': integerToHumanReadableYear(buildUntil),
              })
            : '-',
        motorCode: motorCodes ? motorCodes.join(', ') : '-',
      };
    },
    handleVehicleSelection(vehicle) {
      this.selectedVehicle = vehicle;
      this.selectVehicle({ vehicle });
    },
    handleVehicleToggle(vehicle) {
      vehicle.isCollapsed = !vehicle.isCollapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';
@import 'mixins';

.caris-vehicle-table {
  --regular-font-size: var(--font-size-M);
  --regular-line-height: var(--font-size-ML);
}

.caris-vehicle-table__header {
  display: flex;
  margin-bottom: 20px;

  &--extended {
    .caris-vehicle-table__header-cell {
      &:first-child {
        width: calc(25% + 20px);
        max-width: 200px;
        padding-left: 40px;
        margin-right: var(--space-2);
      }
    }
  }
}

.caris-vehicle-table__header-cell {
  display: block;
  font-weight: bold;
  text-align: left;
  color: var(--color-nero);
  font-size: var(--regular-font-size);
  line-height: var(--regular-line-height);
  width: 25%;

  &--mobile {
    width: 100%;
    margin-bottom: var(--space-1);
  }
}

.caris-vehicle-table__row {
  display: flex;
  color: var(--color-nero);
  position: relative;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &--selected {
    color: var(--color-primary);
  }

  &--selectable {
    cursor: pointer;
  }

  &--collapsed {
    .caris-vehicle-table__row-cell--radio {
      margin-bottom: 0 !important; // override's expanded version's margin-bottom
    }
  }
}

.caris-vehicle-table__row-cell {
  font-size: var(--regular-font-size);
  line-height: var(--regular-line-height);
  color: inherit;
  text-align: left;
  width: 25%;
  padding-right: var(--space-2);

  &--radio {
    pointer-events: none;
    width: calc(25% + 20px);
    max-width: 200px;
    margin-right: var(--space-2);

    ::v-deep {
      .label {
        padding-left: 0;
        margin-left: 20px;
      }

      .label-text {
        font-weight: normal;
      }
    }
  }
}

.caris-vehicle-table__expand-button {
  position: absolute;
  right: var(--space-2);
  top: var(--space-2);
  cursor: pointer;
}

.caris-vehicle-table__expand-icon {
  transform: rotate(90deg);
  width: 24px;
  height: 24px;

  &--collapsed {
    transform: rotate(-90deg);
  }
}

@media #{$_mediaMDown} {
  .caris-vehicle-table {
    --regular-font-size: var(--font-size-S);
    --regular-line-height: var(--font-size-SM);
  }

  .caris-vehicle-table__row {
    flex-direction: column;
  }

  .caris-vehicle-table__row {
    &--selectable {
      border: 1px solid var(--color-hippo);
      border-radius: var(--border-radius-default);
      padding: var(--space-2);
    }

    &--selected {
      color: var(--color-nero) !important; // overriding desktop version's color
    }

    .caris-vehicle-table__row-cell {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: var(--space-1);
      }

      &--first-with-header {
        margin-top: var(--space-2);
      }
    }
  }
}
</style>
