<template>
  <div :class="[classes, 'qa-FooterServices']" :data-preload="[$options.name]">
    <div
      role="heading"
      aria-level="2"
      class="headline qa-FooterServices__headline"
      @click="expandHandler"
    >
      {{ title }}
    </div>

    <div class="items">
      <div
        v-for="(item, index) in providers"
        :key="index"
        class="item qa-FooterServices__item"
      >
        <Icon
          :name="item.icon || item"
          height="30"
          width="70"
          :alt="item.title ? item.title : item"
          :class="setClasses(item)"
          :original="true"
          :inline="showTitle(item)"
          :lazy="!showTitle(item)"
        />
        <span v-if="showTitle(item)" class="itemTitle">
          {{ item.title }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'Components/00-generated/Icon';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'FooterServices',
  components: {
    Icon,
  },
  mixins: [globalMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    providers: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    expandList: false,
  }),
  methods: {
    showTitle: (item) =>
      [
        'stored_creditcard',
        'prepayment',
        'cash_on_delivery',
        'b2b_cash_on_delivery',
        'collected_invoice',
        'business_to_business_invoice',
      ].includes(item.icon),
    setClasses(item) {
      const classes = [
        [
          'prepayment',
          'cash_on_delivery',
          'b2b_cash_on_delivery',
          'collected_invoice',
          'stored_creditcard',
          'business_to_business_invoice',
        ].includes(item.icon)
          ? 'genericIcon'
          : 'itemIcon',
      ];

      if (item.icon === 'postnord') {
        classes.push('expanded');
      }

      return classes;
    },
    expandHandler() {
      const mediumMax = window.matchMedia('(max-width: 768px)').matches;
      if (mediumMax) {
        this._data.expandList = !this._data.expandList;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.headline {
  display: flex;
  align-items: center;

  font-weight: bold;
  font-size: var(--font-size-MML);
  color: var(--color-mine-shaft);
  margin-bottom: var(--space-1);
}

.headlineIcon {
  margin-right: 8px;

  transform: rotate(0);
  transform-origin: center;
  transition: transform var(--time-S) ease-in-out;

  ::v-deep path {
    fill: var(--color-primary);
  }
}

.rotateArrow {
  transform: rotate(-180deg);
}

.items {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--space-0--half);
  background: var(--color-white);
  margin: var(--space-1) var(--space-1) 0 0;
  min-width: 80px;
  border-radius: 3px;
}

.itemIcon {
  height: var(--space-5) !important;
  object-fit: contain;

  &.expanded {
    max-width: 110px;
    width: 110px !important;
  }

  &.gray {
    filter: grayscale(100%);
    opacity: 0.9;
  }
}

.genericIcon {
  height: var(--space-3) !important;
}

.genericIcon,
.itemTitle {
  fill: var(--color-rolling-stone);
  opacity: 0.9;
}

.itemTitle {
  line-height: 16px;
  font-size: calc(var(--font-size-XS) + 2px);
  color: var(--color-rebranding-black-out);
}

.itemLabel {
  margin-top: 8px;
  font-size: var(--font-size-S);
  color: var(--color-rebranding-black-out);
}

@media #{$_mediaMDown} {
  .headline {
    font-size: var(--font-size-MML);
    cursor: pointer;
  }

  .items {
    padding: 0;
  }
}

@media #{$_mediaLUp} {
  .headlineIcon {
    display: none;
  }

  .items {
    max-height: none !important;
  }
}
</style>
