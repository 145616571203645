export const SET_SEARCH_COMPLETION_TERMS = (state, results = []) => {
  state.searchBar.completions = results;
};
export const UPDATE_SEARCH_TERM = (state, value) => {
  state.searchBar.searchTerm = value;
};

export const OPEN_MOBILE_SEARCH_BAR = (state) => {
  state.searchBar.open = true;
};
export const CLOSE_MOBILE_SEARCH_BAR = (state) => {
  state.searchBar.open = false;
};

export const TOGGLE_MINI_CART = (state) => {
  state.miniCart.open = !state.miniCart.open;
  state.latestAddedProductNotification.open = false;
};

export const OPEN_MINI_CART = (state) => {
  state.miniCart.open = true;
};

export const CLOSE_MINI_CART = (state) => {
  state.miniCart.open = false;
};

export const CLOSE_LATEST_ADDED_NOTIFICATION = (state) => {
  state.latestAddedProductNotification.open = false;
};

export const OPEN_LATEST_ADDED_NOTIFICATION = (state, productId) => {
  CLOSE_MINI_CART(state);
  state.latestAddedProductNotification.open = true;
  state.latestAddedProductNotification.productId = productId;
};

export const OPEN_ACCOUNT_WIDGET = (state) => {
  state.accountWidget.open = true;
};

export const CLOSE_ACCOUNT_WIDGET = (state) => {
  state.accountWidget.open = false;
};

export const TOGGLE_ACCOUNT_WIDGET = (state) => {
  state.accountWidget.open = !state.accountWidget.open;
};

export const OPEN_MOBILE_SIDENAV = (state) => {
  state.mobileSideNav.open = true;
};

export const CLOSE_MOBILE_SIDENAV = (state) => {
  state.mobileSideNav.open = false;
};

export const TOGGLE_MOBILE_SIDENAV = (state) => {
  state.mobileSideNav.open = !state.mobileSideNav.open;
};

export const SET_OVERLAY_ON = (state) => {
  state.overlay.on = true;
};

export const SET_OVERLAY_OFF = (state) => {
  state.overlay.on = false;
};

export const SET_OVERLAY_FADING_ON = (state) => {
  state.overlay.fading = true;
};

export const SET_OVERLAY_FADING_OFF = (state) => {
  state.overlay.fading = false;
};

export const SET_DISCOUNT = (state, discount) => {
  state.discount = discount;
};

export const SHOW_DISCOUNT_POPUP = (state) => {
  state.discount.show = true;
};

export const HIDE_DISCOUNT_POPUP = (state) => {
  state.discount.show = false;
};

export const SHOW_UNAVAILABLE_PRODUCTS_DIALOG = (state, productsData) => {
  state.unavailableProducts.show = true;
  state.unavailableProducts.products = productsData.products;
  state.unavailableProducts.cartId = productsData.cartId;
};

export const HIDE_UNAVAILABLE_PRODUCTS_DIALOG = (state) => {
  state.unavailableProducts.show = false;
};

export const SET_FREE_SHIPPING_LIMIT = (state, limit) => {
  state.freeShippingLimit = limit;
};
