var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"caris-vehicle-table"},[(_vm.viewport !== 'mobile')?_c('li',{staticClass:"caris-vehicle-table__header",class:{
      'caris-vehicle-table__header--extended': !_vm.isSingleVehicle,
    }},[_c('span',{staticClass:"caris-vehicle-table__header-cell"},[_vm._v("\n      "+_vm._s(_vm.$t('caris-vehicle-table.header.type'))+"\n    ")]),_vm._v(" "),_c('span',{staticClass:"caris-vehicle-table__header-cell"},[_vm._v("\n      "+_vm._s(_vm.$t('caris-vehicle-table.header.motor'))+"\n    ")]),_vm._v(" "),_c('span',{staticClass:"caris-vehicle-table__header-cell"},[_vm._v("\n      "+_vm._s(_vm.$t('caris-vehicle-table.header.buildYear'))+"\n    ")]),_vm._v(" "),_c('span',{staticClass:"caris-vehicle-table__header-cell"},[_vm._v("\n      "+_vm._s(_vm.$t('caris-vehicle-table.header.motorCode'))+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.interpolatedVehicles && _vm.interpolatedVehicles.length > 0)?_vm._l((_vm.interpolatedVehicles),function(vehicle){return _c('li',{key:vehicle.id,staticClass:"caris-vehicle-table__row gtm--caris-vehicle-details",class:{
        'caris-vehicle-table__row--selected':
          !_vm.isSingleVehicle && _vm.selectedVehicle.id === vehicle.id,
        'caris-vehicle-table__row--selectable': !_vm.isSingleVehicle,
        'caris-vehicle-table__row--collapsed': vehicle.isCollapsed,
      },on:{"click":() => !_vm.isSingleVehicle && _vm.handleVehicleSelection(vehicle)}},[(!_vm.isSingleVehicle)?_c('RadioBase',{staticClass:"caris-vehicle-table__row-cell caris-vehicle-table__row-cell--radio",attrs:{"children":[
          {
            vars: {
              label: vehicle.interpolated.type,
              value: vehicle.interpolated.type,
            },
          },
        ],"checked":_vm.selectedVehicle.id === vehicle.id}}):_vm._e(),_vm._v(" "),_c('button',{staticClass:"caris-vehicle-table__expand-button",on:{"click":function($event){$event.stopPropagation();return (() => _vm.handleVehicleToggle(vehicle)).apply(null, arguments)}}},[(!_vm.isSingleVehicle && _vm.viewport === 'mobile')?_c('Icon',{staticClass:"caris-vehicle-table__expand-icon",class:!vehicle.isCollapsed &&
            'caris-vehicle-table__expand-icon--collapsed',attrs:{"name":"chevron_right"}}):_vm._e()],1),_vm._v(" "),(
          _vm.viewport === 'mobile' && !_vm.isSingleVehicle
            ? !vehicle.isCollapsed
            : true
        )?[(_vm.isSingleVehicle || _vm.viewport === 'mobile')?_c('span',{staticClass:"caris-vehicle-table__row-cell caris-vehicle-table__row-cell--first-with-header"},[(_vm.viewport === 'mobile')?_c('span',{staticClass:"caris-vehicle-table__header-cell caris-vehicle-table__header-cell--mobile"},[_vm._v("\n            "+_vm._s(_vm.$t('caris-vehicle-table.header.type'))+"\n          ")]):_vm._e(),_vm._v("\n          "+_vm._s(vehicle.interpolated.type)+"\n        ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"caris-vehicle-table__row-cell"},[(_vm.viewport === 'mobile')?_c('span',{staticClass:"caris-vehicle-table__header-cell caris-vehicle-table__header-cell--mobile"},[_vm._v("\n            "+_vm._s(_vm.$t('caris-vehicle-table.header.motor'))+"\n          ")]):_vm._e(),_vm._v("\n          "+_vm._s(vehicle.interpolated.motor)+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"caris-vehicle-table__row-cell"},[(_vm.viewport === 'mobile')?_c('span',{staticClass:"caris-vehicle-table__header-cell caris-vehicle-table__header-cell--mobile"},[_vm._v("\n            "+_vm._s(_vm.$t('caris-vehicle-table.header.buildYear'))+"\n          ")]):_vm._e(),_vm._v("\n          "+_vm._s(vehicle.interpolated.buildYear)+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"caris-vehicle-table__row-cell"},[(_vm.viewport === 'mobile')?_c('span',{staticClass:"caris-vehicle-table__header-cell caris-vehicle-table__header-cell--mobile"},[_vm._v("\n            "+_vm._s(_vm.$t('caris-vehicle-table.header.motorCode'))+"\n          ")]):_vm._e(),_vm._v("\n          "+_vm._s(vehicle.interpolated.motorCode)+"\n        ")])]:_vm._e()],2)}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }