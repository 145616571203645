const imageMixin = {
  methods: {
    imageUrl(source = null, transformations = {}) {
      if (source === null || !source.indexOf('/')) {
        return '';
      }

      const pathComponents = source.split('/');
      const baseName = pathComponents.pop();
      const basePath = pathComponents.join('/');

      const parts = [basePath];

      if (Object.keys(transformations).length) {
        parts.push(
          Object.keys(transformations)
            .sort((a, b) => (a > b ? 1 : -1))
            .reduce((strings, key) => {
              const value = transformations[key];
              return [...strings, `${key}_${value}`];
            }, [])
            .join(',')
        );
      }

      parts.push(baseName);

      return parts.join('/');
    },
  },
};

export default imageMixin;
