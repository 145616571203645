export const searchResults = ({ data, component } = {}) => {
  if (!data) {
    return false;
  }

  return {
    data,
    component,
  };
};
