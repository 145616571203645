export const tyreLabelAttrs = {
    fuel_efficiency_class_1604: { icon: 'fuel_efficiency_class', order: 0 },
    wet_grip_class_1605: { icon: 'wet_grip_class', order: 1 },
    rolling_noise_emission_class_3989: {
        icon: 'noise_emission_class',
        order: 2,
    },
    external_rolling_noise_dba_1606: {
        icon: 'noise_emission_class',
        order: 3,
    },
};
