<i18n src="./translations"></i18n>

<template>
  <SimpleDialog
    modifier="discount-dialog"
    add-kebab-case-modifier
    :data-preload="[$options.name]"
    :active="shouldShow"
    :action="dialogAction"
    position="center"
    @action-click="hideDialog"
    @close="hideDialog"
  >
    <template v-slot:headerPrefix>
      <CheckCircleSvg
        class="discount-dialog__header-icon"
        width="20"
        height="20"
      />
      <span class="discount-dialog__title">{{ headline }}</span>
    </template>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p class="discount-dialog__content" v-html="content" />
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p class="discount-dialog__fine-print" v-html="finePrint" />
  </SimpleDialog>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

import CheckCircleSvg from 'Components/00-generated/CheckCircleSvg';
import SimpleDialog from 'Components/01-atoms/simple-dialog/SimpleDialog.vue';

export default {
  name: 'DiscountDialog',
  components: { SimpleDialog, CheckCircleSvg },
  mixins: [globalMixin],
  props: {
    shouldShow: {
      type: Boolean,
      default: false,
    },
    headline: {
      required: true,
      type: String,
    },
    content: {
      required: true,
      type: String,
    },
    finePrint: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      dialogAction: {
        enabled: true,
        title: this.$t('discount-dialog.action'),
        modifier: 'primary',
      },
    };
  },
  methods: {
    hideDialog() {
      this.$emit('hide-dialog');
    },
  },
};
</script>

<style lang="scss">
@import 'variables/breakpoints.scss';

$modified-dialog-class: '.simple-dialog--discount-dialog';
$dialog-class: '.simple-dialog';

#{$modified-dialog-class} {
  &#{$dialog-class} {
    --dialog-width: 620px;
  }

  #{$dialog-class}__header {
    display: flex;
    align-items: center;

    .discount-dialog__header-icon {
      margin-right: var(--space-1);
    }

    .discount-dialog__title {
      font-size: var(--font-size-L);
      line-height: 1.17;
      letter-spacing: -0.1px;
      font-weight: bold;
      color: var(--color-nero);
    }
  }
  #{$dialog-class}__body {
    margin-top: var(--space-1);

    img {
      margin: var(--space-2) 0 var(--space-1);
    }

    .discount-dialog__content {
      font-size: var(--font-size-M);
      line-height: 1.38;
      color: var(--color-nero);
      margin-bottom: var(--space-1);
    }

    .discount-dialog__fine-print {
      font-size: var(--font-size-S);
      line-height: 1.33;
      color: var(--color-nero);
    }
  }

  #{$dialog-class}__action-button {
    font-size: var(--font-size-M);
    line-height: 1.38;
    font-weight: bold;
    color: var(--color-white);
    height: 48px;
    margin: var(--space-2) 0 0;
    text-transform: uppercase !important;
  }
}

@media #{$_mediaMDown} {
  #{$modified-dialog-class} {
    #{$dialog-class}__header {
      .discount-dialog__title {
        font-size: var(--font-size-M);
        line-height: 1.38;
        letter-spacing: normal;
        padding-right: var(--space-1);
      }
    }

    #{$dialog-class}__body {
      img {
        max-width: 100%;
        height: auto;
      }

      .discount-dialog__content {
        font-size: var(--font-size-SM);
        line-height: 1.43;
      }

      .discount-dialog__fine-print {
        font-size: var(--font-size-S);
        line-height: 1.33;
      }
    }
  }
}
</style>
