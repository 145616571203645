<template>
  <!-- [ozlui] v-text directive is used for eliminating unnecessarily generated spaces. -->
  <!-- eslint-disable vue/no-v-html -->
  <a
    v-if="isLink"
    :class="[classes, { 'masked-link--with-underline': withUnderline }]"
    :target="target"
    :rel="target && target === '_blank' ? 'noopener noreferrer' : rel"
    :href="redirectTo"
    :title="text"
    v-html="text || html"
  />

  <span
    v-else
    :class="[classes, { 'masked-link--with-underline': withUnderline }]"
    :target="target"
    :rel="target && target === '_blank' ? 'noopener noreferrer' : rel"
    @click="handleRedirection()"
    v-html="text || html"
  />
  <!-- eslint-enable vue/no-v-html -->
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { mapState } from 'vuex';

export default {
  name: 'MaskedLink',
  mixins: [globalMixin],
  props: {
    html: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    redirectTo: {
      type: String,
      default: '/',
      required: true,
    },
    clickHandler: {
      type: Function,
      default: null,
    },
    target: {
      type: String,
      default: '_self',
    },
    rel: {
      type: String,
      default: '',
    },
    checkForUser: {
      type: Boolean,
      default: false,
    },
    withUnderline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isUser: false,
    };
  },
  computed: {
    ...mapState('carSelection', {
      isVehicleSelected: ({ data: { vehicle } }) => vehicle && vehicle.ktypnr,
    }),
    isLink() {
      return this.checkForUser && this.isVehicleSelected;
    },
  },
  mounted() {
    this.addKebabCaseClass = true;
  },
  methods: {
    handleRedirection() {
      if (this.clickHandler !== null) {
        this.clickHandler();
      }
      if (this.redirectTo) {
        location.assign(this.redirectTo);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.masked-link {
  cursor: pointer;

  &--with-underline {
    text-decoration: underline;
  }
}
</style>
