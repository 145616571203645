<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="$options.name">
    <div class="price-info-21__prices qa-priceInfo21__prices">
      <div
        v-if="product.unitPrices && product.unitPrices[0]"
        class="price-info-21__price-wrap"
      >
        <Price
          v-if="!showUpToDiscountedPrice"
          :label="product.unitPrices[0].label"
          :is-kvi="product.isKvi"
          :value="getPrice"
          :is-net="isBusinessCustomer"
          class="price-info-21__main-price qa-PriceInfo21__mainPrice"
          :modifiers="priceModifiers"
        />

        <PriceDiscounted
          v-else
          is21
          :price-texts="product.priceTexts"
          :unit-prices="product.unitPrices"
          :prices="product.prices"
          :price-modifiers="priceModifiers"
          :is-kvi="product.isKvi"
        />
      </div>

      <span
        v-if="showRecommendedPrice"
        class="price-info-21__retail-price-container qa-PriceInfo__retailPrice"
      >
        {{ $t('price-info-21.price_text_uvp') }}
        <span
          class="price-info-21__retail-price"
          :class="{
            'is-striked': !isBusinessCustomer,
          }"
          >{{ product.priceTexts.recommendedRetailPrice }}</span
        >
      </span>

      <price-savings :product="product" is21 />

      <div class="price-info-21__price-tax">
        <span
          v-if="product.priceTexts && pricePerUnit && pricePerUnit.value"
          class="price-info-21__unit-price qa-PriceInfo21__unitPrice"
        >
          {{ pricePerUnit.value }}/{{ pricePerUnit.unit }},
        </span>

        <span v-if="showTaxes" class="qa-PriceInfo21__tax">
          {{
            $t('price-info-21.tax_text', {
              '%taxPercentage%': product.priceTexts.tax,
            })
          }},
        </span>

        <MaskedLink
          v-if="!isDirectDelivery"
          class="price-info-21__shipping"
          :text="`${$t('price-info-21.excluding-shipping')}${
            hasDepositPrice ? ',' : ''
          }`"
          :redirect-to="frontFacingPath('content.page.shipping')"
          :with-underline="true"
        />

        <span v-if="hasDepositPrice" class="qa-PriceInfo21__deposit">{{
          $t('price-info-21.deposit_price', {
            '%formattedDepositPrice%': product.priceTexts.depositPrice,
          })
        }}</span>
      </div>

      <div class="price-info-21__availability-wrapper">
        <PdpAvailability
          :class="[
            `qa-availability--${productAvailability} ${
              isDesktop ? 'qa-Availability__Desktop' : ''
            }`,
          ]"
          class="price-info-21__availability"
          :state="productAvailability"
        />
        <BranchesAvailabilityLink :product="product" label-type="secondary" />
      </div>

      <PriceTexts
        v-if="priceTextsEnabled"
        :modifiers="modifiers.includes('slim') ? ['slim'] : []"
        :price-texts="product.priceTexts"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';
import has from 'lodash/has';
import Price from 'Components/01-atoms/price/Price';
import PriceTexts from 'Components/01-atoms/price-texts/PriceTexts';
import PdpAvailability from 'Components/01-atoms/pdp-availability/PdpAvailability';
import BranchesAvailabilityLink from 'Components/01-atoms/branches-availability-link/BranchesAvailabilityLink';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';
import PriceDiscounted from 'Components/01-atoms/price-discounted/PriceDiscounted';
import PriceSavings from 'Components/01-atoms/price-savings/PriceSavings';

export default {
  name: 'PriceInfo21',
  components: {
    Price,
    PdpAvailability,
    BranchesAvailabilityLink,
    PriceTexts,
    MaskedLink,
    PriceDiscounted,
    PriceSavings,
  },
  mixins: [globalMixin],
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    quantity: {
      type: Number,
      default: 1,
    },
    priceTextsEnabled: {
      type: Boolean,
      default: true,
    },
    isPlp: {
      type: Boolean,
      default: false,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isWishedProduct: false,
    };
  },
  computed: {
    ...mapState('renderingSalesChannelConfig', {
      isU2dAktionEnabled: ({ isU2dAktionEnabled }) => isU2dAktionEnabled,
    }),
    ...mapGetters('core', ['isBusinessCustomer', 'getCompanyInformation']),
    hasDepositPrice() {
      return this.product.priceTexts && this.product.priceTexts.depositPrice;
    },
    isDirectDelivery() {
      return (
        (this.isBusinessCustomer &&
          this.getCompanyInformation?.isDirectDelivery) ||
        false
      );
    },
    showTaxes() {
      return (
        this.product.priceTexts &&
        this.product.priceTexts.tax &&
        !this.isBusinessCustomer
      );
    },
    showUpToDiscountedPrice() {
      const priceTexts = this.product?.priceTexts;
      const discountedSalesValueGross = priceTexts?.discountedSalesValueGross;
      const upToDiscountPercentage = priceTexts?.upToDiscountPercentage;

      return (
        discountedSalesValueGross &&
        discountedSalesValueGross !== 0 &&
        upToDiscountPercentage &&
        upToDiscountPercentage > 0 &&
        this.isU2dAktionEnabled
      );
    },
    pricePerUnit() {
      return !this.showUpToDiscountedPrice
        ? this.product.priceTexts.pricePerUnit
        : this.product.priceTexts.discountedPricePerUnit;
    },
    getPrice() {
      // B2B => net price is displayed
      if (
        this.isBusinessCustomer &&
        this.product.unitPrices &&
        this.product.unitPrices.length > 1
      ) {
        return this.product?.unitPrices[1]?.price;
      }

      // B2C
      const discountedSalesValueGross = this.product?.priceTexts
        ?.discountedSalesValueGross;

      return discountedSalesValueGross && discountedSalesValueGross !== 0
        ? discountedSalesValueGross
        : this.product?.unitPrices[0]?.price;
    },
    productAvailability() {
      return this.product.availability;
    },
    priceModifiers() {
      const modifiers = ['big'];
      const product = this.product;

      if (
        has(product, ['salesOffer', 'formerPrice']) ||
        (has(product, ['priceTexts', 'recommendedRetailPrice']) &&
          product.priceTexts.recommendedRetailPrice.length > 0)
      ) {
        modifiers.push('highlighted');
      }

      if (this.theme === 'kfzteile-com') {
        modifiers.push('com');
      }
      return modifiers;
    },
    showRecommendedPrice() {
      const product = this.product;
      const hasRecommendedPrice =
        has(product, ['priceTexts', 'recommendedRetailPrice']) &&
        product.priceTexts.recommendedRetailPrice.length > 0;
      const hasPrices =
        has(product, ['prices', 'price']) && has(product, ['prices', 'rrp']);

      // if (this.isBusinessCustomer) {
      //   return false;
      // }

      if (!hasRecommendedPrice && !hasPrices) {
        return false;
      }

      if (hasPrices && product.prices.price > product.prices.rrp) {
        return false;
      }

      return true;
    },
    ...mapState('core', {
      theme: ({ salesChannel }) => salesChannel.theme,
      // TODO [izerozlu] remove 'salesChannelId' access after the disabling is not needed
      salesChannelId: ({ salesChannel }) => salesChannel.salesChannelId,
    }),
    ...mapState('wishlist', {
      isWished: (state) => state.data.isWished || false,
    }),
    isNewAvailability() {
      if (this.product && this.product.availability) {
        const { availability } = this.product;
        return (
          typeof availability === 'string' || availability instanceof String
        );
      }
      return false;
    },
    availabilityKey() {
      if (this.isNewAvailability) {
        return this.product.availability;
      }
      return this.product.availability ? this.product.availability.key : '';
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.PriceInfo21 {
  padding-left: var(--space-1--half);

  .price-info-21__former-price {
    font-size: var(--font-size-ML);
  }

  .price-info-21__shipping {
    color: var(--color-primary);
  }

  @media #{$_mediaSDown} {
    &--slim {
      .priceTax {
        font-size: 11px;
        line-height: 1.3em;
      }
    }

    .price-info-21__price-tax .qa-PriceInfo21__tax {
      font-size: var(--font-size-S) !important;
    }
  }
}

.PriceInfo21--slide .price-info-21__prices {
  display: flex;
  flex-flow: column;
}

.price-info-21__price-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  @media #{$_mediaSDown} {
    margin-bottom: var(--space-0--half);
  }
}

.price-info-21__wishlist-button {
  display: flex;
  flex-grow: 99;
  justify-content: flex-end;
}

.PriceInfo21--slide .price-info-21__price-wrap {
  flex-wrap: nowrap;
}

.price-info-21__main-price {
  font-size: var(--font-size-LXL);
  color: var(--color-nero);
  margin: var(--space-0--half) var(--space-1--half) 0 0;

  &.price--highlighted,
  &.price--outdated,
  &.price--big {
    color: var(--color-nero);
    font-size: var(--font-size-LXL);
  }

  .PriceInfo21--slide & {
    font-size: var(--font-size-L);
    line-height: 29px;
  }
}

.price-info-21__former-price {
  font-size: var(--font-size-ML);
}

.price-info-21__price-tax {
  font-size: var(--font-size-SM);
  line-height: 1.29em;
  color: var(--color-hippo-darker);
  margin-top: var(--space-0--half);

  .PriceInfo21--slide & {
    font-size: var(--font-size-S);
    line-height: 14px;
    min-height: 28px;
  }
}

.price-info-21__retail-price-container {
  font-size: var(--font-size-M);
  color: var(--color-nero);
  line-height: var(--font-size-MLL);
  margin-top: var(--space-0--half);

  .price-savings {
    margin-left: var(--space-1);
  }
}

.price-info-21__retail-price {
  white-space: nowrap;

  &.is-striked {
    text-decoration: line-through;
  }
}

.price-info-21__availabilit-wrapper {
  margin-bottom: 10px;

  .PriceInfo21--slide & {
    order: -1;
    margin: var(--space-1) 0;
  }
}

.price-info-21__tax-comma {
  margin-left: -3px;
  margin-right: -3px;
}

@media #{$_mediaMDown} {
  .price-info-21__price-wrap {
    flex-flow: row wrap;
    margin-bottom: 6px;

    .price-info-21__main-price {
      width: 100%;
      margin-bottom: 8px;
      font-size: var(--font-size-L);
    }

    .price-info-21__retail-price-container {
      font-size: var(--font-size-SM);
      margin-top: 5px;
    }
  }
}

.priceTexts {
  margin-top: 0;

  @media #{$_mediaMDown} {
    margin-top: var(--space-2);
    font-size: var(--font-size-SM);
  }

  ::v-deep {
    .priceText {
      color: var(--color-black);
      font-size: var(--font-size-SM);
      line-height: 1.29em;
      margin: 14px 0 var(--space-2);

      &:first-child,
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media #{$_mediaMDown} {
  // .price-info-21__prices {
  //   display: flex;
  //   flex-direction: column;
  // }

  .price-info-21__price-tax {
    margin-bottom: 15px;
    // margin-top: var(--space-2--half);
  }

  .price-info-21__availability-wrapper {
    order: initial;
    margin-top: 15px;
    margin-bottom: 0;
  }

  .price-info-21__main-price {
    font-size: var(--font-size-L);
  }
}

@media screen and (max-width: 919px) {
  .price-info-21__wishlist-button {
    &--hidden {
      display: none;
    }
  }
}

::v-deep {
  @media #{$_mediaMDown} {
    .pdp-availability__message {
      line-height: 15px;
      padding-top: 1px;

      .pdp-availability__text {
        display: block;
        margin-bottom: var(--space-XXS);
      }

      .pdp-availability__label {
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }

  .branchesAvailabilityLink {
    padding: 0;
    margin-top: var(--space-XXS);

    img {
      display: none;
    }

    .link {
      margin: 0;
    }
  }
}
</style>
