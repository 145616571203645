
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="DeliverySvg" xmlns="http://www.w3.org/2000/svg" data-name="01_Atoms/Icons/delivery"><path d="M21 11.645l-1.8-4.743h-3.15v-.9H5.699v1.8h2.287v.9H3v.9h4.986v.9H3.9v.9h1.8v2.7h-.494v.9H6.64a2.49 2.49 0 00-.044.451 2.25 2.25 0 004.455.449 3.531 3.531 0 00.053-.451h2.607a1.052 1.052 0 01.984-.449h.946a2.5 2.5 0 00-.046.451 2.25 2.25 0 104.5 0 2.3 2.3 0 00-.046-.451h.946zm-10.88 4.256a1.348 1.348 0 01-2.619-.449 1.261 1.261 0 01.08-.451 1.082 1.082 0 01.272-.449 1.332 1.332 0 012 0 1.1 1.1 0 01.269.449 1.305 1.305 0 010 .9zm7.731.9a1.347 1.347 0 01-1.351-1.348 1.262 1.262 0 01.082-.451 1.344 1.344 0 012.536 0 1.211 1.211 0 01.082.451 1.347 1.347 0 01-1.35 1.347zm2.1-5.129a3 3 0 01-.681-.121 5.407 5.407 0 00-1.455-.15h-1.312l-.017-3.6h2.115l1.351 3.6z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'DeliverySvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
