
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="ShipmentSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.97 23"><g data-name="Ebene 2"><path d="M32 16.53V18a.72.72 0 01-.72.72h-2.19a4.31 4.31 0 11-8.62 0h-9a4.31 4.31 0 01-8.62 0v-5.78H6a.36.36 0 00.36-.36v-.72A.36.36 0 006 11.5H.36a.36.36 0 01-.36-.36v-.72a.36.36 0 01.36-.36h7.1a.36.36 0 00.36-.36V9a.36.36 0 00-.36-.36H1.8a.36.36 0 01-.36-.35v-.74a.36.36 0 01.36-.36h7.1a.36.36 0 00.36-.36v-.72a.36.36 0 00-.36-.36H.36A.36.36 0 010 5.39v-.72a.36.36 0 01.36-.36h2.52V2.16A2.16 2.16 0 015 0h14.75a2.16 2.16 0 012.16 2.16v2.15h2a2.13 2.13 0 011.5.69l4.49 4.43a2.2 2.2 0 01.63 1.57v4.85h.72a.72.72 0 01.75.68zM9.34 18.69a2.16 2.16 0 10-2.15 2.15 2.16 2.16 0 002.15-2.15zm19-7.73l-4.45-4.49h-2v5h6.47zm-1.44 7.73a2.16 2.16 0 10-2.16 2.15 2.16 2.16 0 002.2-2.15z" data-name="Ebene 1"/></g></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'ShipmentSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
