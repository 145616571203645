import { render, staticRenderFns } from "./CarManufacturerHeader.vue?vue&type=template&id=468de9e2&scoped=true"
import script from "./CarManufacturerHeader.vue?vue&type=script&lang=js"
export * from "./CarManufacturerHeader.vue?vue&type=script&lang=js"
import style0 from "./CarManufacturerHeader.vue?vue&type=style&index=0&id=468de9e2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "468de9e2",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F03-organisms%2Fcar-manufacturer-header%2FCarManufacturerHeader.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports