<i18n src="./translations"></i18n>

<template>
  <div class="caris-hsn-tsn" :data-preload="['Button', 'ButtonBase']">
    <p class="caris-hsn-tsn__headline">
      {{ title || $t('caris-hsn-tsn.headline') }}
    </p>
    <form class="caris-hsn-tsn__form form" @submit.prevent="submitForm">
      <InputBase
        ref="hsn-input"
        class="form__field"
        :class="{
          'form__field--invalid': isHsnInvalid,
        }"
        full-name="hsn"
        type="text"
        :class-name="qaClassName + 'hsnInput'"
        :gtm-class-name="gtmClassName ? gtmClassName + 'hsnInput' : ''"
        :placeholder="$t('caris-hsn-tsn.hsn_label')"
        :value="form.hsnValue"
        :send="true"
        :icon1="false"
        :attr="{
          autocomplete: 'off',
          autocorrect: 'off',
          autocapitalize: 'off',
          spellcheck: 'false',
          maxlength: '4',
        }"
        @input="(value) => handleInputChange('hsn', value)"
      />
      <InputBase
        ref="tsn-input"
        class="form__field"
        :class="{ ['form__field--invalid']: isTsnInvalid }"
        full-name="tsn"
        type="text"
        :class-name="qaClassName + 'tsnInput'"
        :gtm-class-name="gtmClassName ? gtmClassName + 'tsnInput' : ''"
        :placeholder="$t('caris-hsn-tsn.tsn_label')"
        :value="form.tsnValue"
        :send="true"
        :icon1="false"
        :attr="{
          autocomplete: 'off',
          autocorrect: 'off',
          autocapitalize: 'off',
          spellcheck: 'false',
          maxlength: '3',
        }"
        @input="(value) => handleInputChange('tsn', value)"
      />
      <button
        class="form__where-to-find-button"
        :class="[
          qaClassName + 'whereToFindButton',
          gtmClassName ? gtmClassName + 'whereToFindButton' : '',
        ]"
        type="button"
        @click="showWhereToFindDialog"
      >
        {{ $t('caris-hsn-tsn.where_to_find') }}
      </button>
      <Button
        v-show="action"
        class="form__select-car-button"
        :class="[
          qaClassName + 'selectCarButton',
          gtmClassName ? gtmClassName + 'selectCarButton' : '',
        ]"
        :title="$t('caris-hsn-tsn.select_car')"
        modifier="tertiary"
        type="submit"
      />
    </form>
    <Dialog
      ref="whereToFindDialog"
      class="hsn-tsn__where-to-find-dialog where-to-find-dialog"
      :click-outside="true"
      :title="$t('caris-hsn-tsn.headline')"
      @hide="handleWhereToFindDialogHide"
    >
      <div class="where-to-find-dialog__text">
        {{ $t('caris-hsn-tsn.text') }}
      </div>
      <img
        class="where-to-find-dialog__image"
        :class="qaClassName + 'whereToFindDialogImage'"
        :title="$t('caris-hsn-tsn.headline')"
        :src="resolveFile('images/caris-hsn-tsn/hsn_tsn.jpg')"
      />
    </Dialog>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import qaClassNameMixin from 'Libs/mixins/qaClassNameMixin';

import InputBase from 'Components/01-atoms/form/input/InputBase.vue';
import Dialog from 'Components/02-molecules/dialog/Dialog.vue';
import Button from 'Components/01-atoms/button/Button.vue';

import './images/hsn_tsn.jpg';

export default {
  name: 'CarisHsnTsn',
  components: { InputBase, Dialog, Button },
  mixins: [globalMixin, qaClassNameMixin],
  props: {
    autoFocus: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    action: {
      type: Boolean,
      default: true,
    },
    parentForm: {
      type: Object,
      default: null,
    },
    validateForm: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      insideForm: {
        hsnValue: null,
        tsnValue: null,
      },
      isHsnInvalid: false,
      isTsnInvalid: false,
    };
  },
  computed: {
    form() {
      return this.parentForm || this.insideForm;
    },
    isFormInvalid() {
      return this.isHsnInvalid || this.isTsnInvalid;
    },
  },
  mounted() {
    if (this.autoFocus) {
      this.autoFocusToInput();
    }
  },
  methods: {
    handleInputChange(inputName, value) {
      this.form[`${inputName}Value`] = value;

      if (inputName === 'hsn' && value.length === 4) {
        requestAnimationFrame(() => this.$refs['tsn-input'].focus());
      }
    },
    handleWhereToFindDialogHide() {
      this.$emit('where-to-find-dialog-hide');
    },
    showWhereToFindDialog() {
      this.$refs.whereToFindDialog.show();
      this.$emit('where-to-find-dialog-show');
    },
    submitForm() {
      if (this.validateForm) {
        this.isHsnInvalid = this.form.hsnValue
          ? this.form.hsnValue.length !== 4
          : true;
        this.isTsnInvalid = this.form.tsnValue
          ? this.form.tsnValue.length !== 3
          : true;
        if (!this.isFormInvalid) {
          this.$emit('select');
        }
      } else {
        this.$emit('select');
      }
    },
    autoFocusToInput() {
      this.$el.querySelector('.form__field input').focus();
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.caris-hsn-tsn {
  --regular-font-size: var(--font-size-M);
  --regular-line-height: var(--font-size-ML);
  --headline-font-size: var(--font-size-M);
  --headline-line-height: var(--font-size-ML);
  --input-font-size: var(--font-size-SM);
  --input-line-height: var(--font-size-MML);
  --input-width: 125px;
  --input-height: 32px;
  --input-padding: #{var(--space-1--half)};
  --button-height: 40px;
  --button-margin: #{var(--space-1--half)};
  --button-font-size: var(--font-size-SM);
}

.caris-hsn-tsn__headline {
  width: 100%;
  font-size: var(--font-size-MML);
  line-height: var(--headline-line-height);
  font-weight: bold;
  color: var(--color-secondary);
  letter-spacing: -0.16px;
}

.caris-hsn-tsn__form.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .form__field {
    margin-top: var(--space-1);
    padding: 0;

    &--invalid {
      ::v-deep {
        input {
          border-color: var(--color-red);
        }
      }
    }

    &.input--filled {
      ::v-deep {
        input {
          color: var(--color-nero);
        }
      }
    }

    &.input--focused {
      ::v-deep {
        input {
          color: var(--color-nero);
          border-color: var(--color-secondary);
        }
      }
    }

    ::v-deep {
      label {
        font-size: var(--regular-font-size);
        line-height: var(--regular-font-size);
        margin-left: 12px;
        padding: 0;
      }

      input {
        width: var(--input-width);
        height: var(--input-height);
        border: none;
        padding: 0 0 0 var(--input-padding);
        font-size: var(--input-font-size);
        line-height: var(--input-line-height);
        transition: none;

        @include set-placeholder-color(var(--color-mad-hippo));
      }
    }
  }

  .form__where-to-find-button {
    font-size: var(--regular-font-size);
    line-height: var(--regular-line-height);
    color: var(--color-white);
    text-decoration: underline;
    margin-top: var(--space-1);
    margin-right: auto;
    cursor: pointer;
    padding-left: 0;
  }

  .form__select-car-button {
    margin-top: var(--space-1--half);
    width: 100%;
    min-height: var(--button-height);
    font-size: var(--font-size-S);
  }
}

.where-to-find-dialog__text {
  line-height: 20px;
  font-size: var(--font-size-SM);
}

.where-to-find-dialog__image {
  margin-top: var(--space-2);
  width: 100%;
  max-width: 440px;
}

@media (max-width: #{$_widthXXLFrom}) {
  .caris-hsn-tsn {
    --regular-font-size: var(--font-size-SM);
    --regular-line-height: var(--font-size-M);
    --headline-font-size: var(--font-size-SM);
    --headline-line-height: var(--font-size-M);
    --input-font-size: var(--font-size-SM);
    --input-line-height: var(--font-size-MML);
    --input-width: 96px;
    --input-padding: 4px;
    --button-height: 36px;
    --button-margin: 18px;
    --button-font-size: var(--font-size-S);
  }
}

@media #{$_mediaMDown} {
  .caris-hsn-tsn {
    --regular-font-size: var(--font-size-S);
    --regular-line-height: var(--font-size-SM);
    --headline-font-size: var(--font-size-SM);
    --headline-line-height: var(--font-size-SM);
    --input-width: 100%;
    --input-height: 40px;
    --input-padding: #{var(--space-1--half)};
    --input-font-size: var(--font-size-SM);
    --input-line-height: var(--font-size-MML);
    --button-height: 40px;
    --button-margin: #{var(--space-2)};
    --button-font-size: var(--font-size-M);
  }

  .caris-hsn-tsn__headline {
    font-weight: bold;
  }

  .caris-hsn-tsn__form.form {
    .form__field {
      flex-grow: 1;
      flex-basis: 45%;
      margin-top: var(--space-1--half);

      &:first-child {
        margin-right: var(--space-2);
      }
    }
  }
}
</style>
