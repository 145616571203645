
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="LoadingSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd" stroke-width="3" transform="translate(2 2)"><circle cx="14" cy="14" r="14" stroke-opacity=".3"/><path stroke-linecap="round" d="M14 28c7.7 0 14-6.3 14-14"/></g></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'LoadingSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
