<template>
  <div
    :class="[
      classes,
      {
        'raised-bottom': viewport === 'mobile' && isPdpPage,
      },
    ]"
    :data-preload="[$options.name]"
  >
    <div class="inner">
      <Toast />
    </div>
    <portal-target name="pdpStickyFooter" />

    <CookiesMain />
    <!-- Todo [Mina]: clean it up: https://kfzteile24.atlassian.net/wiki/spaces/IT/pages/edit-v2/4230217759
    <TwilioChat /> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import get from 'lodash/get';
import Toast from 'Components/02-molecules/toast/Toast';
import CookiesMain from 'Components/03-organisms/cookies-main/CookiesMain';
import globalMixin from 'Libs/mixins/globalMixin';
// import TwilioChat from 'Src/components/03-organisms/twilio-chat/TwilioChat.vue';

export default {
  name: 'ViewportBottom',
  components: {
    Toast,
    CookiesMain,
    // TwilioChat,
  },
  mixins: [globalMixin],
  data() {
    return {
      showCookiesBar: false,
    };
  },
  computed: {
    ...mapState('core', { viewport: ({ viewport: { range } }) => range }),
    ...mapState('productsList', {
      isPdpPage: (state) => state?.component ? state.component === 'ProductDetails' : false,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';
@import 'mixins';

.viewportBottom {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  z-index: 0;

  &.raised-bottom {
    bottom: 85px;

    ::v-deep .chat {
      bottom: 96px;
    }
  }
}

.inner {
  @include pageWidth;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--space-1);
  z-index: 9999;
}
</style>
