<template>
  <div
    :class="[classes, typeClasses, columnsClasses]"
    :data-preload="[$options.name]"
  >
    <div role="heading" aria-level="2" class="headline" @click="expandHandler">
      <img
        v-if="hasImage"
        class="image"
        :alt="image.alt"
        :src="imageUrl(image.src, { w: 80 })"
        loading="lazy"
      />
      <span class="title qa-LinksList__title">
        {{ title }}
      </span>

      <ChevronDownSvg
        v-if="collapsable"
        :class="['headlineIcon', { rotateArrow: expandList }]"
        width="24"
        height="24"
      />
    </div>

    <TransitionExpand
      :expand="expandList"
      :initial-expand-state="initialExpandState"
      :class="{ expanded: expandList }"
      max="1000"
    >
      <div
        class="items itemsInner qa-LinksList__itemsInner"
        :class="{ modlesInner: hasImage }"
      >
        <div
          v-for="(item, index) in items"
          :key="index"
          class="link-wrapper"
          :class="{ 'arrow-icon': !item.icon }"
        >
          <Icon
            v-if="item.icon"
            :name="item.icon"
            class="flagIcon"
            width="21"
            height="15"
            :original="true"
            :inline="false"
            :lazy="true"
          />
          <ChevronRightSvg
            v-if="image.src"
            class="arrow-icon"
            :class="{ arrow: hasImage }"
            width="21"
            height="15"
            :lazy="true"
          />
          <!-- [ozlui] v-text directive is used for eliminating unnecessarily generated spaces. -->
          <a
            class="itemLink"
            :class="{ model: hasImage }"
            :href="item.url"
            :target="item.target || null"
            :rel="item.target === '_blank' ? 'noreferrer noopener' : null"
            @click="item.method ? callMethod(item.method) : false"
          >
            {{ item.title
            }}<span
              v-if="item.titleBold"
              class="itemLinkBold"
              v-text="item.titleBold"
            />
            <Icon
              v-if="item.target === '_blank'"
              name="arrow-open-modal"
              width="12"
              height="12"
              :original="true"
              :inline="false"
              :lazy="true"
            />
          </a>
          <span
            v-if="hasSeparator && index < items.length - 1"
            class="itemSeparator"
            >{{ separator }}</span
          >
        </div>
        <!--div class="itemSlot item">
          <slot />
        </div-->
      </div>
    </TransitionExpand>
  </div>
</template>

<script>
import Icon from 'Components/00-generated/Icon';
import TransitionExpand from 'Components/01-atoms/transition-expand/TransitionExpand';
import globalMixin from 'Libs/mixins/globalMixin';
import imageMixin from 'Libs/mixins/imageMixin.js';
import ChevronRightSvg from 'Components/00-generated/ChevronRightSvg';
import ChevronDownSvg from 'Components/00-generated/ChevronDownSvg';
import { mapMutations } from 'vuex';

export default {
  name: 'LinksList',
  components: {
    TransitionExpand,
    Icon,
    ChevronRightSvg,
    ChevronDownSvg,
  },
  mixins: [globalMixin, imageMixin],
  props: {
    type: {
      type: String,
      default: '',
    },
    columns: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    image: {
      type: Object,
      default: () => ({}),
    },
    separator: {
      type: String,
      default: '',
    },
    collapsable: {
      type: Boolean,
      default: true,
    },
    initialExpandState: Boolean,
  },
  data: () => ({
    expandList: false,
  }),
  computed: {
    columnsClasses() {
      let classes;

      switch (this.$props.columns) {
        case 'five':
          classes = 'fiveColumns';
          break;
        case 'two':
          classes = 'twoColumns';
          break;
        case 'inline':
          classes = 'inline';
          break;
        default:
          classes = '';
          break;
      }

      return classes;
    },
    typeClasses() {
      let classes;

      switch (this.$props.type) {
        case 'channels':
          classes = 'channels';
          break;

        default:
          classes = '';
          break;
      }

      return classes;
    },
    hasImage() {
      return !!this.image.src;
    },
    hasSeparator() {
      return !!this.separator && this.separator.trim().length > 0;
    },
  },
  created() {
    this.$data.expandList = this.collapsable ? false : true;
  },
  methods: {
    ...mapMutations('cookiesMain', {
      openCookieBanner: 'OPEN_COOKIES_BANNER',
    }),
    expandHandler() {
      const mediumMax = window.matchMedia('(max-width: 1000px)').matches;
      if (mediumMax) {
        this.expandList = !this.expandList;
      }
    },
    showCookiesMainBanner() {
      this.openCookieBanner(true);
    },
    callMethod(methodToCall) {
      if (methodToCall && typeof this[methodToCall] === 'function') {
        this[methodToCall]();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.twoColumns {
  .itemsInner {
    column-count: 2;
    column-gap: 16px;
  }
}

.fiveColumns {
  .itemsInner {
    column-count: 5;
    column-gap: 16px;
  }
}

.inline {
  .itemsInner {
    display: flex;
    flex-wrap: wrap;
  }

  .itemsInner .link-wrapper {
    margin: var(--space-1) var(--space-1) 0 0;
  }
}

.headline {
  display: flex;
  align-items: center;
  margin: 0 0 var(--space-1);
  font-weight: bold;
  font-size: var(--font-size-MML);
  color: var(--color-rebranding-black-out);
  text-align: left;
}

.headlineIcon {
  margin-right: 8px;
  transform: rotate(0);
  transform-origin: center;
  transition: transform var(--time-S) ease-in-out;

  ::v-deep path {
    fill: var(--color-24-blue);
  }
}

.image {
  width: 55px;
  margin-right: 4px;
}

.arrow {
  width: 16px;
}

.rotateArrow {
  transform: rotate(-180deg);
}

.itemsInner {
  text-align: left;
}

.items {
  overflow: hidden;
  transition: height var(--time-S) ease-in-out;
}

.link-wrapper {
  display: flex;
  align-items: center;
  line-height: 18px;
  padding: 0;
  margin: var(--space-1--half) 0 0;

  &.arrow-icon {
    align-items: flex-start;

    .arrow-icon {
      margin-top: 2px;
      min-width: 22px;
    }
  }
}

::v-deep .itemLink {
  color: var(--color-rebranding-black-out);
  text-decoration: none;
  align-items: center;
  cursor: pointer;
}

::v-deep .itemSeparator {
  color: var(--color-rebranding-black-out);
  text-decoration: none;
  display: inline-block;
  margin: 0 0 0 var(--space-1);
}

.itemLinkBold {
  font-weight: bold;
}

.itemSlot:empty {
  display: none;
  margin-top: 0;
}

.model {
  font-size: var(--font-size-SM);
  cursor: pointer;
  font-weight: 400;
  min-width: 150px;
}

.model .itemTitle {
  flex-grow: 1;
}

.channels {
  .itemsInner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .link-wrapper {
      margin-left: 0;
      margin-right: var(--space-2);

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .link-wrapper {
    margin: 0 0 10px;
  }

  .item {
    padding-right: 20px;
    padding-bottom: 10px;
    margin-top: 0;
  }

  .flagIcon {
    height: 15px;
  }

  .expanded .flagIcon {
    transition-delay: 0.1ms;
    opacity: 1;
  }
}

.flagIcon {
  margin-right: var(--space-1);
}

@media #{$_mediaLDown} {
  .linksList {
    padding: var(--space-2);
    border-bottom: 1px solid var(--color-alto);
  }

  .channels {
    padding: 0 0 var(--space-4);
  }

  .twoColumns .itemsInner,
  .fiveColumns .itemsInner {
    column-count: 1;
  }

  .headline {
    cursor: pointer;
    font-size: var(--font-size-MML);
    justify-content: space-between;
    margin: 0;
  }

  .itemsInner {
    padding: 0;
  }

  .modlesInner {
    padding: 0 0 16px;
  }

  .arrow {
    width: 18px;
    display: inherit;
    margin: 5px 0;
  }

  ::v-deep .itemLink {
    margin: 0;
  }

  .channels .flagIcon {
    height: 20px;
  }

  .title {
    padding-left: 0;
    line-height: unset;
  }

  .model {
    width: auto;
    margin-left: 0;
    border-bottom: 1px solid var(--color-alto);
    flex-direction: row-reverse;
  }

  .model:last-of-type {
    border-bottom: none;
  }

  .inline {
    .itemsInner {
      display: block;
    }

    .itemsInner .link-wrapper {
      margin: var(--space-2) 0 0;
    }
  }

  .itemSeparator {
    display: none;
  }

  .link-wrapper {
    margin: var(--space-2) 0 0;
  }
}

@media #{$_mediaXLUp} {
  .headlineIcon {
    display: none;
  }

  .items {
    max-height: none;
  }
}
</style>
