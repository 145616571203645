import { axios } from 'Services/AjaxService';

export const updateSearchCompletionTerms = async (context, { url }) => {
  const { searchTerm: search, submitting } = context.state;

  if (!submitting) {
    try {
      const response = await axios.get(url, {
        params: { search }
      });

      if (response.data) {
        context.commit('SET_COMPLETION_TERMS', response.data.completions);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Axios request error:', error);
    }
  }
};

export const updateStaticSearchCompletionTerms = async (context) => {
  const salesChannelId = context.rootState.core.salesChannel.salesChannelId;
  //TODO: remove hardcoded data. (an endpoint should be created)
  const data = {
    'www-autoteile24-de': [
      {
        label: 'Bremsbeläge',
        value: 'Bremsbeläge',
        searchType: 'static',
        url: '/ersatzteile-verschleissteile/bremsanlage/bremsbelaege',
        genart: 402,
      },
      {
        label: 'Bremssattel',
        value: 'Bremssattel',
        searchType: 'static',
        url:
          '/ersatzteile-verschleissteile/bremsanlage/bremssattel/bremssattel-halter-traeger',
        genart: 82,
      },
      {
        label: 'Bremsscheiben',
        value: 'Bremsscheiben',
        searchType: 'static',
        url: '/ersatzteile-verschleissteile/bremsanlage/bremsscheiben',
        genart: 78,
      },
      {
        label: 'Lenker / Querlenker',
        value: 'Lenker / Querlenker',
        searchType: 'static',
        url:
          '/ersatzteile-verschleissteile/achsaufhaengung/lenker-querlenker/lenker-quer-laengs-schraeg',
        genart: 273,
      },
      {
        label: 'Luftfilter',
        value: 'Luftfilter',
        searchType: 'static',
        url: '/ersatzteile-verschleissteile/filter/luftfilter',
        genart: 8,
      },
      {
        label: 'Ölfilter',
        value: 'Ölfilter',
        searchType: 'static',
        url: '/ersatzteile-verschleissteile/filter/oelfilter',
        genart: 7,
      },
      {
        label: 'Radlagersatz',
        value: 'Radlagersatz',
        searchType: 'static',
        url:
          '/ersatzteile-verschleissteile/achsaufhaengung/radnabe-radlager/radlager',
        genart: 654,
      },
    ],
    'www-kfzteile-com': [
      {
        label: 'Bremsbeläge',
        value: 'Bremsbeläge',
        searchType: 'static',
        url:
          '/autoteile/bremsanlage/scheibenbremse/bremsbelag/-bremsbelagsatz-scheibenbremse',
        genart: 402,
      },
      {
        label: 'Bremssattel',
        value: 'Bremssattel',
        searchType: 'static',
        url:
          '/autoteile/bremsanlage/bremssattel/bremssattel-haltertraeger/-bremssattel',
        genart: 82,
      },
      {
        label: 'Bremsscheiben',
        value: 'Bremsscheiben',
        searchType: 'static',
        url: '/autoteile/bremsanlage/scheibenbremse/bremsscheibe/-bremsscheibe',
        genart: 78,
      },
      {
        label: 'Lenker / Querlenker',
        value: 'Lenker / Querlenker',
        searchType: 'static',
        url:
          '/autoteile/achsaufhaengungradfuehrungraeder/lenkerlenkerlagerung/lenker-quer-laengs-schraeg-/-lenker-radaufhaengung',
        genart: 273,
      },
      {
        label: 'Luftfilter',
        value: 'Luftfilter',
        searchType: 'static',
        url: '/autoteile/filter/luftfilter/-luftfilter',
        genart: 8,
      },
      {
        label: 'Ölfilter',
        value: 'Ölfilter',
        searchType: 'static',
        url: '/autoteile/filter/oelfilter/-oelfilter',
        genart: 7,
      },
      {
        label: 'Radlagersatz',
        value: 'Radlagersatz',
        searchType: 'static',
        url:
          '/autoteile/achsaufhaengungradfuehrungraeder/radnabe-lagerung/radlager/-radlagersatz',
        genart: 654,
      },
    ],
    'www-k24-at': [
      {
        label: 'Bremsbeläge',
        value: 'Bremsbeläge',
        searchType: 'static',
        url: '/kategorie/ersatzteile-verschleissteile/bremsanlage/bremsbelaege',
        genart: 402,
      },
      {
        label: 'Bremssattel',
        value: 'Bremssattel',
        searchType: 'static',
        url:
          '/kategorie/ersatzteile-verschleissteile/bremsanlage/bremssattel/bremssattel-halter-traeger',
        genart: 82,
      },
      {
        label: 'Bremsscheiben',
        value: 'Bremsscheiben',
        searchType: 'static',
        url:
          '/kategorie/ersatzteile-verschleissteile/bremsanlage/bremsscheiben',
        genart: 78,
      },
      {
        label: 'Lenker / Querlenker',
        value: 'Lenker / Querlenker',
        searchType: 'static',
        url:
          '/kategorie/ersatzteile-verschleissteile/achsaufhaengung/lenker-querlenker/lenker-quer-laengs-schraeg',
        genart: 273,
      },
      {
        label: 'Luftfilter',
        value: 'Luftfilter',
        searchType: 'static',
        url: '/kategorie/ersatzteile-verschleissteile/filter/luftfilter',
        genart: 8,
      },
      {
        label: 'Ölfilter',
        value: 'Ölfilter',
        searchType: 'static',
        url: '/kategorie/ersatzteile-verschleissteile/filter/oelfilter',
        genart: 7,
      },
      {
        label: 'Radlagersatz',
        value: 'Radlagersatz',
        searchType: 'static',
        url:
          '/kategorie/ersatzteile-verschleissteile/achsaufhaengung/radnabe-radlager/radlager',
        genart: 654,
      },
    ],
  };

  context.commit('SET_COMPLETION_TERMS', data[salesChannelId] || []);
};
