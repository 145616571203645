var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hazardous-good",class:[_vm.classes, `hazardous-good--${_vm.modifier}`],attrs:{"data-preload":[_vm.$options.name]}},[(!_vm.isMobile)?[_c('div',{staticClass:"hazardWarning"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"headline"},[_c('seo-hide',{attrs:{"text":_vm.$t('hazardous-good.hazard_warning')}})],1),_vm._v(" "),_c('div',{staticClass:"singleWord"},[_c('seo-hide',{attrs:{"text":_vm.information.signalWord}})],1)]),_vm._v(" "),_c('div',{staticClass:"hazardCriteria"},[_c('div',{staticClass:"iconsContainer"},_vm._l((_vm.information.icon),function(name,index){return _c('div',{key:index,class:['icons', { compact: _vm.isCompact }]},[_c('Icon',{staticClass:"icon",attrs:{"name":_vm.upperCamelCase(name)}}),_vm._v(" "),_c('p',{staticClass:"iconTitle"},[_c('seo-hide',{attrs:{"text":_vm.$t(`hazardous-good.${name}`)}})],1)],1)}),0)])]),_vm._v(" "),_c('div',{staticClass:"hazardStatements"},[_c('div',{staticClass:"statementsLabel"},[_c('seo-hide',{attrs:{"text":_vm.$t('hazardous-good.label')}})],1),_vm._v(" "),_vm._l((!!_vm.information.statements &&
        _vm.information.statements.hazard),function(statement,index){return _c('seo-hide',{key:index,staticClass:"statement",attrs:{"text":`${statement.label} `}})})],2),_vm._v(" "),(!!_vm.documents)?_c('div',{staticClass:"documents"},_vm._l((_vm.dangerousDocuments),function(document,index){return _c('div',{key:index,staticClass:"document",on:{"click":function($event){return _vm.handleDocumentClick(index, $event, 'desktop')}}},[_c('Icon',{staticClass:"icon",attrs:{"name":_vm.modifier !== 'secondary'
              ? _vm.upperCamelCase(document.icon)
              : 'download'}}),_vm._v(" "),_c('MaskedLink',{ref:"desktopDocumentLink",refInFor:true,staticClass:"document__link",attrs:{"rel":"noreferrer noopener","redirect-to":document.url,"text":document.title,"target":"_blank"}})],1)}),0):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.isMobile)?_c('Accordion',{staticClass:"hazardousMobile",attrs:{"max":"768"},on:{"clicked":_vm.onClickChild}},[_c('template',{slot:"header"},[_c('div',{staticClass:"mobileTitle"},[_c('seo-hide',{attrs:{"text":_vm.$t('hazardous-good.hazard_warning')}})],1),_vm._v(" "),_c('div',{staticClass:"iconsContainer"},_vm._l((_vm.information.icon),function(name,index){return _c('div',{key:index,class:[
            'icons',
            { 'icons--expanded': _vm.expanded },
            { compact: _vm.isCompact },
          ]},[_c('Icon',{staticClass:"icon",attrs:{"name":_vm.upperCamelCase(name)}}),_vm._v(" "),_c('seo-hide',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded),expression:"expanded"}],staticClass:"iconTitle",attrs:{"text":_vm.$t(`hazardous-good.${name}`)}})],1)}),0)]),_vm._v(" "),_c('template',{slot:"body"},[_c('div',{staticClass:"singleWord"},[_c('seo-hide',{attrs:{"text":_vm.information.signalWord}})],1),_vm._v(" "),_c('div',{staticClass:"statementsLabel"},[_c('seo-hide',{attrs:{"text":_vm.$t('hazardous-good.label')}})],1),_vm._v(" "),_vm._l((!!_vm.information.statements &&
        _vm.information.statements.hazard),function(statement,index){return _c('seo-hide',{key:index,staticClass:"statement",attrs:{"text":`${statement.label} `}})}),_vm._v(" "),(!!_vm.documents)?_c('div',{staticClass:"documents"},_vm._l((_vm.dangerousDocuments),function(document,index){return _c('div',{key:index,staticClass:"document",on:{"click":function($event){return _vm.handleDocumentClick(index, $event, 'mobile')}}},[_c('Icon',{staticClass:"icon",attrs:{"name":_vm.modifier !== 'secondary'
                ? _vm.upperCamelCase(document.icon)
                : 'download'}}),_vm._v(" "),_c('MaskedLink',{ref:"mobileDocumentLink",refInFor:true,staticClass:"document__link",attrs:{"rel":"noreferrer noopener","redirect-to":document.url,"text":document.title,"target":"_blank"}})],1)}),0):_vm._e()],2)],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }