<template>
  <div :class="classes" :data-preload="[$options.name]">
    {{ title }}
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'Subtitle',
  mixins: [globalMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.subtitle {
  font-weight: bold;
  color: var(--color-mine-shaft);
  font-size: var(--font-size-M);
  line-height: 18px;
}
</style>
