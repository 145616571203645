<template>
  <div
    :class="[
      classes,
      { 'cmsBanners--reverse': theme === 'kfzteile-com' },
      'qa-cmsBanners',
    ]"
    :data-preload="[$options.name]"
  >
    <div class="inner">
      <span class="headline">
        {{ headline }}
      </span>

      <div class="items">
        <div
          v-for="(banner, index) in banners"
          :key="index"
          class="item qa-bannerItem"
          @click="
            trackPromoClick({
              href: banner.link.href,
              position: banner.position,
              callback: () => redirectTo(banner.link.href),
            })
          "
        >
          <Button
            v-if="banner.callToAction"
            class="call-to-action"
            modifier="primary"
            :title="banner.callToAction"
          >
          </Button>
          <AdvacedImage
            :lazy="true"
            :src="banner.images.desktop.src"
            :alt="banner.images.desktop.alt || banner.images.mobile.alt || ''"
            reference-img-class="image"
            :config="{ q: 80 }"
            :sources="[
              {
                media: 'M_DOWN',
                src: banner.images.mobile.src,
              },
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';

import AdvacedImage from 'Components/01-atoms/advanced-image/AdvancedImage';
import Button from 'Components/01-atoms/button/Button';

export default {
  name: 'CmsBanners',
  components: {
    AdvacedImage,
    Button,
  },
  mixins: [globalMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
    updatedAt: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    banners: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('core', {
      theme: ({ salesChannel }) => salesChannel.theme,
    }),
  },
  methods: {
    ...mapActions('dataLayer', ['trackPromoClick']),
    redirectTo(url) {
      window.location.href = url;
    },
  },
  serverCacheKey: ({ id, updatedAt }) => {
    /* istanbul ignore next */
    return `${id}::${updatedAt}`;
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.cmsBanners {
  display: block;
  padding: var(--space-2) 0;
  width: 100%;
  background: var(--color-white);

  &--reverse {
    .headline {
      color: var(--color-primary);
    }
  }
}

.inner {
  @include pageWidth;
}

.headline {
  padding-bottom: var(--space-1);
  padding-left: var(--space-1);
  font-size: var(--font-size-ML);
  font-weight: bold;
  display: block;
  color: var(--color-secondary);
}

.items {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 -5px;
  width: calc(100% + 10px);
}

.item {
  flex-shrink: 0;
  padding: 5px;
  width: 100%;
  cursor: pointer;
  position: relative;

  @include activeState;
}

.call-to-action {
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: 14px;
  transform: translateX(-50%);
  text-transform: uppercase;
}

::v-deep {
  .image {
    display: block !important;
    width: 100% !important;
    height: auto !important;

    &--desktop {
      display: none !important;
    }
  }
}

@media #{$_mediaSUp} {
  .items {
    margin: 0 -10px;
    width: calc(100% + 20px);
  }

  .item {
    padding: 5px 10px;
  }
}

@media #{$_mediaMUp} {
  .headline {
    padding-top: 25px;
    padding-bottom: var(--space-1);
  }

  .items {
    display: flex;
    flex-direction: row;
  }

  .item {
    width: 50%;
    flex-shrink: 0;
  }
}

@media #{$_mediaLUp} {
  .item {
    width: 33.333%;
  }

  ::v-deep {
    .image {
      &--mobile {
        display: none;
      }
    }
  }
}
</style>
