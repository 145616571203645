var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.classes,
    {
      'sortingCategoriesDropdown--opened': _vm.isOpened,
    },
  ],attrs:{"data-preload":[_vm.$options.name]}},[_c('div',{staticClass:"header",on:{"click":_vm.toggleContainer}},[_c('div',{staticClass:"header__name"},[_vm._v("\n      "+_vm._s(_vm.isOpened
          ? _vm.$t('sorting-categories-dropdown.sort-by')
          : `${_vm.$t('sorting-categories-dropdown.sorting')}: ${_vm.activeTitle}`)+"\n    ")]),_vm._v(" "),_c('ChevronRightSvg',{class:['toggleIcon', 'qa_chevron-down', { 'opened-arrow': _vm.isOpened }]})],1),_vm._v(" "),(_vm.isOpened)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
      handler: _vm.handleClose,
      isActive: _vm.isOpened,
      events: ['click'],
    }),expression:"{\n      handler: handleClose,\n      isActive: isOpened,\n      events: ['click'],\n    }"}],staticClass:"container"},[_c('div',{staticClass:"container__body"},[_c('SortingCategoriesOptionList',{attrs:{"is-mobile":false,"options":_vm.sorting.options,"active-option":_vm.activeSorting,"name":_vm.sorting.alias},on:{"input":_vm.click}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }