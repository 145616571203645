import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import upperFirst from 'lodash/upperFirst';
import flow from 'lodash/flow';

/**
 * Object key case converter
 *
 * Deeply converts keys of an object from one case to another
 * @param {object} object to convert
 * @param {function} function to convert key.
 * @return converted object
 */
const convertKeyCase = (oldObject, converterFunction) => {
  let newObject;

  if (
    !oldObject ||
    typeof oldObject !== 'object' ||
    !Object.keys(oldObject).length
  ) {
    return oldObject;
  }

  if (Array.isArray(oldObject)) {
    newObject = oldObject.map((element) =>
      convertKeyCase(element, converterFunction)
    );
  } else {
    newObject = {};
    Object.keys(oldObject).forEach((oldKey) => {
      const newKey = converterFunction(oldKey);
      newObject[newKey] = convertKeyCase(oldObject[oldKey], converterFunction);
    });
  }

  return newObject;
};

export const toCamelCase = (obj) => convertKeyCase(obj, camelCase);
export const toSnakeCase = (obj) => convertKeyCase(obj, snakeCase);
export const toKebabCase = (obj) => convertKeyCase(obj, kebabCase);
export const toPascalCase = (obj) =>
  convertKeyCase(obj, flow(camelCase, upperFirst));

export default { toCamelCase, toSnakeCase, toKebabCase, toPascalCase };
