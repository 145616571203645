var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.classes,attrs:{"data-preload":[_vm.$options.name]}},[_c('div',{staticClass:"product-details-21__header-wrapper"},[_c('div',{staticClass:"product-details-21__header"},[_c('span',{staticClass:"pageTitle"},[_vm._v(_vm._s(_vm.product.brandName)+" "+_vm._s(_vm.product.name))]),_vm._v(" "),_c('div',{staticClass:"productId"},[_c('ProductAvailability',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEmployee),expression:"isEmployee"}],attrs:{"product-availability":_vm.product.productAvailability}}),_vm._v(" "),_c('div',{staticClass:"articleNumber"},[_vm._v("\n          "+_vm._s(_vm.$t('product-details-21.article_number', {
              '%artNr%': _vm.product.displaySku,
            }))+"\n        ")])],1)]),_vm._v(" "),_c('div',{staticClass:"product-details-21__brand-logo"},[(_vm.product.brandLogoUrl)?_c('AdvancedImage',{attrs:{"is-lazy-component":false,"src":_vm.product.brandLogoUrl || '',"alt":_vm.product.brandName,"config":_vm.brandConfig}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"information"},[_c('ProductImageSlider',{staticClass:"qa-productImageSlider",attrs:{"title":_vm.product.name,"images":_vm.product.images,"brand":{
        alt: _vm.product.brandName,
        src: _vm.product.brandLogoUrl || '',
      },"variant":"secondary"}}),_vm._v(" "),_c('PdpAttributes',{attrs:{"pdp-car-bar":_vm.pdpCarBar,"product":_vm.product,"related-items-link":_vm.relatedItemsLink}})],1),_vm._v(" "),(_vm.productTabs.length)?_c('div',{staticClass:"tabsController"},_vm._l((_vm.productTabs),function(tab){return _c('span',{key:tab,staticClass:"tab",class:[{ activeTab: _vm.activeTab === tab }, `qa-${tab}`],on:{"click":function($event){_vm.activeTab = tab}}},[_vm._v("\n      "+_vm._s(_vm.$t(`product-details-21.${tab}`))+"\n    ")])}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tabs"},[(_vm.productTabs.length)?_c('PdpTabs',{staticClass:"qa-tabInformation",attrs:{"active-tab":_vm.activeTab,"product-tabs":_vm.productTabs,"product":_vm.product,"restrictions":_vm.restrictions,"features":_vm.features}}):_vm._e(),_vm._v(" "),_c('PdpBadges',{class:{
        'pdpBadges--horizontal': !_vm.productTabs.length && _vm.viewport !== 'mobile',
      },attrs:{"is-horizontal":!_vm.productTabs.length,"variant":"secondary"}})],1),_vm._v(" "),(!!_vm.recommendations.length)?_c('CategoryRecommendations',{attrs:{"items":_vm.recommendations}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }