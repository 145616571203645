<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <InputBase
      v-show="optionsArray.length >= limit"
      v-model="searchTerm"
      :attr="{ autocomplete: false }"
      :hide-optional="true"
      :label="searchLabel || $t('catalog-filter-option-list.search')"
      class="searchInput"
      :icon1="true"
      modifier="iconLeft"
    >
      <template v-slot:icon1>
        <button class="searchInput__icon">
          <SearchSvg width="20" height="20" />
        </button>
      </template>
    </InputBase>

    <div class="container">
      <DynamicScroller
        :items="filteredOptionsArray"
        :min-item-size="modifier === 'withBorders' ? 45 : 28"
        class="scroller"
        key-field="alias"
      >
        <template v-slot="{ item, index, active }">
          <DynamicScrollerItem
            :active="active"
            :data-index="index"
            :item="item"
            :size-dependencies="[item.title]"
          >
            <div
              :key="item.alias"
              :title="item.title"
              class="container__option"
            >
              <CheckboxBase
                :hide-optional="true"
                :label="buildLabel(item)"
                :value="value.indexOf(item.alias) !== -1"
                @input="handleChange(item.alias, $event)"
              />
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </div>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import InputBase from 'Components/01-atoms/form/input/InputBase.vue';
import CheckboxBase from 'Components/01-atoms/form/checkbox/CheckboxBase.vue';
import SearchSvg from 'Components/00-generated/SearchSvg.vue';

export default {
  name: 'CatalogFilterOptionList',
  components: { InputBase, CheckboxBase, SearchSvg },
  mixins: [globalMixin],
  props: {
    limit: {
      type: Number,
      default: 7,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Array,
      default: () => [],
    },
    searchLabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchTerm: '',
    };
  },
  computed: {
    optionsArray() {
      return Object.values(this.options);
    },
    filteredOptionsArray() {
      const searchTerm = this.searchTerm.toLowerCase();
      return this.optionsArray.filter((item) => {
        return item.title && item.title.toLowerCase().includes(searchTerm);
      });
    },
  },
  methods: {
    handleChange(checkBoxValue, checked) {
      if (checked) {
        this.$emit('input', [...this.value, checkBoxValue]);
        return;
      }

      this.$emit(
        'input',
        this.value.filter((v) => v !== checkBoxValue)
      );
    },
    buildLabel({ title, count }) {
      if (count > 0) {
        return `${title} <span class="count">(${count})</span>`;
      }

      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'mixins';
@import '~Libs/../../node_modules/vue-virtual-scroller/dist/vue-virtual-scroller.css';

.scroller {
  height: 100%;

  scrollbar-color: var(--color-silver-chalice) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
    color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    // mozilla doesn't have support for border-radius yet
    border-radius: 2px;
    background-color: var(--color-silver-chalice);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.catalogFilterOptionList {
  height: 100%;
  display: flex;
  flex-direction: column;

  &--withBorders {
    .searchInput {
      padding: 12px 10px;
      border-bottom: 1px solid var(--color-concrete);
    }

    .container__option {
      padding: 12px 15px;
      border-bottom: 1px solid var(--color-concrete);
    }
  }
}

.searchInput {
  padding: 11px 11px 10px;
  flex-grow: 0;
  flex-shrink: 0;

  ::v-deep input {
    font-size: var(--font-size-M);
    height: 40px;
  }

  ::v-deep label {
    font-size: var(--font-size-SM);
  }

  ::v-deep &__icon {
    svg {
      color: var(--color-rolling-stone);
    }

    pointer-events: none;
    z-index: 0;
  }
}

.container {
  flex: 1;
  margin-right: 9px;
  overflow-y: hidden;

  &__option {
    padding: 4px 6px;

    ::v-deep .inputWrapper {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    ::v-deep label {
      cursor: pointer;
      vertical-align: top;
      font-size: var(--font-size-SM);
      line-height: 20px;
      padding: 0 8px 0 12px;

      & .label__value {
        white-space: pre-line;
        flex-shrink: 10;
      }

      & .count {
        color: var(--color-rolling-stone);
      }
    }
  }
}

@media #{$_mediaLUp} {
  .catalogFilterOptionList {
    font-size: var(--font-size-SM);
  }

  .container__option:hover {
    background-color: var(--color-concrete);
  }
}
</style>
