
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="DeleteSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 17"><path d="M5.5 2.5h4v-.8c0-.4-.3-.7-.8-.7H6.3c-.5 0-.8.3-.8.8v.7zm-1-.8C4.5.8 5.3 0 6.3 0h2.4c1 0 1.8.8 1.8 1.8v1.7h-6V1.7z"/><path d="M2.5 15h10V4h-10v11zM1 3.5c0-.5.4-1 1-1h11c.6 0 1 .4 1 1v12c0 .5-.4 1-1 1H2c-.6 0-1-.4-1-1v-12z"/><path d="M1 3.5h13c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H1c-.3 0-.5.2-.5.5s.2.5.5.5zm4.5 9v-6c0-.3-.2-.5-.5-.5s-.5.2-.5.5v6c0 .3.2.5.5.5s.5-.2.5-.5zm2.5 0v-6c0-.3-.2-.5-.5-.5s-.5.2-.5.5v6c0 .3.2.5.5.5s.5-.2.5-.5zm2.5 0v-6c0-.3-.2-.5-.5-.5s-.5.2-.5.5v6c0 .3.2.5.5.5s.5-.2.5-.5z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'DeleteSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
