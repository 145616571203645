<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    {{ prefixedSubtitle }}
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'PageSubtitle',
  mixins: [globalMixin],
  props: {
    subtitle: {
      type: String,
      default: '',
    },
    showPrefix: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    prefixedSubtitle() {
      const prefix = this.showPrefix ? this.$t('page-subtitle.prefix') : '';

      return `${prefix} ${this.subtitle}`.trim();
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.pageSubtitle {
  display: none;
}

@media #{$_mediaMUp} {
  .pageSubtitle {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: var(--font-size-SM);
    color: var(--color-nero);
  }
}
</style>
