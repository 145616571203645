<i18n src="./translations"></i18n>

<template>
  <div ref="filtersButton" :class="classes" :data-preload="[$options.name]">
    <Button
      ref="buttonTop"
      class="mobileFiltersButton__scrolledTopBtn"
      modifier="secondary"
      :icon="filterIcon"
      :on-click="handleClick"
    >
      <span class="title">{{ $t('mobile-filters-button.title') }}</span>
      <span v-if="count > 0" class="count">({{ count }})</span>
    </Button>

    <transition name="sticky">
      <Button
        ref="stickyButton"
        class="stickyButton"
        :modifiers="['secondary']"
        :icon="filterIcon"
        :has-focus-border="false"
        :on-click="handleClick"
        :style="stickyStyles"
      >
        <span class="title">{{ $t('mobile-filters-button.title') }}</span>
      </Button>
    </transition>
  </div>
</template>

<script>
import Button from 'Components/01-atoms/button/Button';
import globalMixin from 'Libs/mixins/globalMixin';
import debounce from 'lodash/debounce';

export default {
  name: 'MobileFiltersButton',
  components: {
    Button,
  },
  mixins: [globalMixin],
  props: {
    count: {
      type: Number,
      default: 0,
    },
    iconWidth: {
      type: Number,
      default: 23,
    },
    iconHeight: {
      type: Number,
      default: 16,
    },
  },
  data() {
    return {
      filterIcon: {
        name: 'FilterMobile',
        height: this.iconHeight,
        width: this.iconWidth,
      },
      isCookieDialogShown: false,
    };
  },
  computed: {
    cookieDialogHeight: {
      cache: false,
      get() {
        const $cookiesMain = this.$root.$el.querySelector('.cookies-main');
        const $cookiesBar = this.$root.$el.querySelector('.cookiesBar');
        const cookieMainHeight =
          $cookiesMain && $cookiesMain.getBoundingClientRect().height;
        const cookieBarHeight =
          $cookiesBar && $cookiesBar.getBoundingClientRect().height;

        return cookieMainHeight || cookieBarHeight || NaN;
      },
    },
    stickyStyles() {
      return this.isCookieDialogShown
        ? { bottom: `${this.cookieDialogHeight + 10}px` }
        : {};
    },
  },
  mounted() {
    this.handleScroll();

    window.addEventListener('scroll', this.handleScroll);
    this.$root.$on('closeCookieDialog', this.handleCloseCookieDialog);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    this.$root.$off('closeCookieDialog', this.handleCloseCookieDialog);
  },
  methods: {
    handleScroll: debounce(function() {
      if (!this.isVisible()) {
        if (this.cookieDialogHeight) {
          this.isCookieDialogShown = true;
        }
        this.addModifier('sticky');
      } else {
        this.removeModifier('sticky');
        this.isCookieDialogShown = false;
      }
    }, 100),
    handleCloseCookieDialog() {
      this.isCookieDialogShown = false;
    },
    isVisible() {
      const button = this.$refs.buttonTop.$el.getBoundingClientRect();

      return button.bottom >= 0;
    },
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.mobileFiltersButton {
  width: 100%;
  display: flex;

  &__scrolledTopBtn {
    width: 100%;

    ::v-deep .icon path {
      fill: var(--color-primary);
    }
  }

  .stickyButton {
    opacity: 0;
    display: none;
    position: fixed;
    bottom: 4%;
    min-width: 146px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 6;
    background: var(--color-white);
    border: 1px solid var(--color-alto);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

    .title {
      font-size: var(--font-size-M);
    }
  }

  .count {
    margin-left: 3px;
    font-weight: normal;

    color: var(--color-primary);
  }

  &--sticky {
    .stickyButton {
      opacity: 1;
      display: inline-block;

      ::v-deep .icon path {
        fill: var(--color-primary);
      }
    }
  }

  .sticky-enter-active {
    transition: all var(--time-XS) ease-in-out;
  }
}
</style>
