<template>
  <div :class="classes" :data-preload="[$options.name]">
    <LoadingSvg class="spin" :original="true" v-bind="dimensions" />
  </div>
</template>

<script>
import LoadingSvg from 'Components/00-generated/LoadingSvg.vue';

import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'LoadingSpinner',
  components: {
    LoadingSvg,
  },
  mixins: [globalMixin],
  props: {
    dimensions: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';
@import 'animations';

.loadingSpinner {
  @include themeColor(
    stroke,
    var(--color-primary),
    var(--color-primary),
    var(--color-picton-blue)
  );

  &--greyTrace {
    ::v-deep svg circle {
      stroke: var(--color-alto);
      stroke-opacity: 1;
    }
  }
}

.spin {
  animation: linearRotationKeyframes var(--time-L) ease-in-out infinite;
}
</style>
