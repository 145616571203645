import { toCamelCase } from '../libs/helpers/key-case-converter';

let redis;

if (typeof window === 'undefined') {
  const Redis = require('ioredis');

  redis = new Redis({
    host: process.env.REDIS_HOST,
    port: process.env.REDIS_PORT,
  });
} else {
  redis = {
    mget: () => new Promise((resolve) => resolve([])),
  };
}

class RedisService {
  static async fetchUrls({ salesChannelId, locale, urlKeys = [] }) {
    const urls = await redis.mget(
      ...urlKeys.map(
        (urlKey) => `front_facing_url.${salesChannelId}.${locale}.${urlKey}`
      )
    );

    const urlMappings = urls.reduce((result, url, index) => {
      return {
        ...result,
        [urlKeys[index]]: url,
      };
    }, {});

    return urlMappings;
  }

  static async getSalesChannelConfig(salesChannelId) {
    const salesChannelConfigString = await redis.get(
      `sales_channel.${salesChannelId}.1-0`
    );

    return toCamelCase(JSON.parse(salesChannelConfigString));
  }
}

export default RedisService;
