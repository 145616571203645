<template>
  <div
    :class="[
      classes,
      lazyClass,
      `advancedImage--${mode}`,
      {
        'advancedImage--failed-image': computedImageSource === noImageSVG,
        'advancedImage--static-component': !isLazyComponent,
      },
    ]"
    :data-preload="[$options.name]"
  >
    <picture>
      <Fragment v-for="(source, index) in transformedSources" :key="index">
        <source
          :media="source.media"
          :srcset="source.srcSetWebP"
          type="image/webp"
          :width="source.width"
          :height="source.height"
        />
        <source
          :media="source.media"
          :srcset="source.srcSetOriginal"
          :width="source.width"
          :height="source.height"
        />
      </Fragment>
      <img
        ref="img"
        :loading="isLazyComponent ? 'lazy' : 'eager'"
        :src="computedImageSource"
        :class="imgClasses"
        :alt="alt"
        :width="width"
        :height="height"
        :style="{
          objectFit: `${objectFit}`,
        }"
        @click="handleImageClick()"
        @load="onLoad"
        @error="handleError"
      />
    </picture>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import imageMixin from 'Libs/mixins/imageMixin.js';
import { mapState } from 'vuex';
import Fragment from 'Components/01-atoms/fragment/Fragment';
import './images/no-image.svg';

const QUERIES = {
  XS_UP: '(min-width: 0)',
  XS_DOWN: '(max-width: 320px)',
  XS: '(min-width: 0) and (max-width: 320px)',
  S_UP: '(min-width: 321px)',
  S_DOWN: '(max-width: 480px)',
  S: '(min-width: 321px) and (max-width: 480px)',
  M_UP: '(min-width: 481px)',
  M_DOWN: '(max-width: 768px)',
  M: '(min-width: 481px) and (max-width: 768px)',
  L_UP: '(min-width: 769px)',
  L_DOWN: '(max-width: 1000px)',
  L: '(min-width: 769px) and (max-width: 1000px)',
  XL_UP: '(min-width: 1001px)',
  XL_DOWN: '(min-width: 0)',
  XL: '(min-width: 1001px)',
};

export default {
  name: 'AdvancedImage',
  components: { Fragment },
  mixins: [globalMixin, imageMixin],
  props: {
    isLazyComponent: {
      type: Boolean,
      default: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    fallback: {
      type: String,
      default: null,
    },
    fallbackClass: {
      type: String,
      default: null,
    },
    src: {
      type: String,
      required: true,
    },
    sources: {
      type: Array,
      default: () => [],
    },
    alt: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    referenceImgClass: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    isBrand: {
      type: Boolean,
      default: false,
    },
    isProduct: {
      type: Boolean,
      default: false,
    },
    isCategory: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: null,
    },
    objectFit: {
      type: String,
      default: 'contain',
    },
  },
  data() {
    return {
      QUERIES,
      isBroken: false,
      baseImage:
        'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=',
      mode: '',
      attempt: 0,
      displayNoImage: false,
      useDefaultImage: false,
      failedImage: false,
    };
  },
  computed: {
    ...mapState('core', {
      staticUrl: ({ salesChannel: { staticUrl } }) => staticUrl,
      isStorybook: ({ environment }) => environment === 'storybook',
    }),
    noImageSVG() {
      let imagePath = `${this.staticUrl}/assets/rendering/shop/icons/no-image.svg`;
      if (this.isStorybook) {
        imagePath = this.resolveFile('images/advanced-image/no-image.svg');
      }
      return imagePath;
    },
    computedImageSource() {
      if (this.displayNoImage) return this.noImageSVG;
      if (this.useDefaultImage) return this.src;

      return this.imgSrc;
    },
    lazyClass() {
      return this.lazy ? 'advancedImage--lazy' : null;
    },
    transformedSources() {
      if (this.displayNoImage) return [];

      return this.sources.map((source) => {
        const imageSource = !!source.src ? source.src : this.src;
        const transformations = source.config || {};

        const media = Object.keys(this.QUERIES).includes(source.media)
          ? this.QUERIES[source.media]
          : source.media;

        const srcSetOriginal = this.imageUrl(imageSource, transformations);
        const srcSetWebP = this.imageUrl(imageSource, {
          ...transformations,
          f: 'webp',
        });

        return {
          ...source,
          imageSource,
          transformations,
          media,
          srcSetOriginal,
          srcSetWebP,
        };
      });
    },
    imgSrc() {
      return this.imageUrl(this.src, this.config);
    },
    imgClasses() {
      return [
        'img',
        {
          [this.referenceImgClass]: !!this.referenceImgClass,
        },
        {
          productImage: this.isProduct,
        },
        {
          brandImage: this.isBrand,
        },
      ];
    },
  },
  methods: {
    onLoad(event) {
      const { naturalHeight, naturalWidth } = event.currentTarget;
      this.mode = naturalHeight > naturalWidth ? 'portrait' : 'landscape';
    },
    handleError() {
      let currentImageSrc = this.$el.querySelector('img').src;
      let currentImage = this.$el.querySelector('img');
      let sourceElements = [...this.$el.querySelectorAll('source')];

      if (!this.isBroken && !this.isCategory) {
        if (currentImageSrc !== encodeURI(this.src) && this.attempt === 0) {
          this.attempt++;
          this.useDefaultImage = true;
        } else {
          this.displayNoImage = true;
          this.isBroken = true;
          currentImage.src = this.computedImageSource;
          sourceElements.map((_) => _.remove());
        }
      }

      if (this.attempt === 0 && this.isCategory) {
        this.attempt++;
        this.displayNoImage = true;
        currentImage.src = this.computedImageSource;
        sourceElements.map((_) => _.remove());
      }
    },
    handleImageClick() {
      this.$emit('image-click');
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.img {
  display: block;
  width: 100%;
  height: 100%;
  object-position: center;
  filter: none;
  transition:
    filter var(--time-S) ease-in-out,
    opacity var(--time-S) ease-in-out;
  opacity: 1;

  &--blur {
    filter: blur(1px);
  }

  &--hidden {
    opacity: 0;
  }
}

.productImage {
  max-height: 126px;
  max-width: 100%;
  position: absolute;
  margin-top: 50%;
  margin-bottom: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
}

.brandImage {
  max-width: 100px;
  max-height: 42px;
  width: auto;
  height: auto;
  margin: 0 auto;
}

.imageText {
  font-size: var(--space-2);
  text-transform: uppercase;
}

.advancedImage {
  height: 100%;
  width: 100%;
  position: relative;

  &--centered {
    .img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &.advancedImage--landscape {
      .img {
        height: 100%;
        width: 100%;
      }
    }

    &.advancedImage--portrait {
      .img {
        width: auto;
        height: 100%;
      }
    }
  }
}

@media #{$_mediaMDown} {
  .productImage {
    max-height: 120px;
  }
}

@media #{$_mediaSDown} {
  .productImage {
    max-height: 76px;
  }

  .brandImage {
    width: 100%;
  }
}
</style>
