<i18n src="./translations"></i18n>
<template>
  <div
    class="hazardous-good"
    :class="[classes, `hazardous-good--${modifier}`]"
    :data-preload="[$options.name]"
  >
    <template v-if="!isMobile">
      <div class="hazardWarning">
        <div class="title">
          <span class="headline">
            <seo-hide :text="$t('hazardous-good.hazard_warning')" />
          </span>
          <div class="singleWord">
            <seo-hide :text="information.signalWord" />
          </div>
        </div>
        <div class="hazardCriteria">
          <div class="iconsContainer">
            <div
              v-for="(name, index) in information.icon"
              :key="index"
              :class="['icons', { compact: isCompact }]"
            >
              <Icon class="icon" :name="upperCamelCase(name)" />
              <p class="iconTitle">
                <seo-hide :text="$t(`hazardous-good.${name}`)" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="hazardStatements">
        <div class="statementsLabel">
          <seo-hide :text="$t('hazardous-good.label')" />
        </div>

        <seo-hide
          v-for="(statement, index) in !!information.statements &&
          information.statements.hazard"
          :key="index"
          class="statement"
          :text="`${statement.label} `"
        />
      </div>

      <div v-if="!!documents" class="documents">
        <div
          v-for="(document, index) in dangerousDocuments"
          :key="index"
          class="document"
          @click="handleDocumentClick(index, $event, 'desktop')"
        >
          <Icon
            class="icon"
            :name="
              modifier !== 'secondary'
                ? upperCamelCase(document.icon)
                : 'download'
            "
          />
          <MaskedLink
            ref="desktopDocumentLink"
            rel="noreferrer noopener"
            class="document__link"
            :redirect-to="document.url"
            :text="document.title"
            target="_blank"
          />
        </div>
      </div>
    </template>

    <Accordion
      v-if="isMobile"
      max="768"
      class="hazardousMobile"
      @clicked="onClickChild"
    >
      <template slot="header">
        <div class="mobileTitle">
          <seo-hide :text="$t('hazardous-good.hazard_warning')" />
        </div>
        <div class="iconsContainer">
          <div
            v-for="(name, index) in information.icon"
            :key="index"
            :class="[
              'icons',
              { 'icons--expanded': expanded },
              { compact: isCompact },
            ]"
          >
            <Icon class="icon" :name="upperCamelCase(name)" />
            <seo-hide
              v-show="expanded"
              class="iconTitle"
              :text="$t(`hazardous-good.${name}`)"
            />
          </div>
        </div>
      </template>

      <template slot="body">
        <div class="singleWord">
          <seo-hide :text="information.signalWord" />
        </div>

        <div class="statementsLabel">
          <seo-hide :text="$t('hazardous-good.label')" />
        </div>

        <seo-hide
          v-for="(statement, index) in !!information.statements &&
          information.statements.hazard"
          :key="index"
          class="statement"
          :text="`${statement.label} `"
        />

        <div v-if="!!documents" class="documents">
          <div
            v-for="(document, index) in dangerousDocuments"
            :key="index"
            class="document"
            @click="handleDocumentClick(index, $event, 'mobile')"
          >
            <Icon
              class="icon"
              :name="
                modifier !== 'secondary'
                  ? upperCamelCase(document.icon)
                  : 'download'
              "
            />
            <MaskedLink
              ref="mobileDocumentLink"
              rel="noreferrer noopener"
              :redirect-to="document.url"
              :text="document.title"
              class="document__link"
              target="_blank"
            />
          </div>
        </div>
      </template>
    </Accordion>
  </div>
</template>

<script>
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import debounce from 'lodash/debounce';
import globalMixin from 'Libs/mixins/globalMixin';
import Icon from 'Components/00-generated/Icon';
import Accordion from 'Components/02-molecules/accordion/Accordion';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';
import SeoHide from 'Components/01-atoms/seo-hide/SeoHide';

export default {
  name: 'HazardousGood',
  components: { Icon, Accordion, MaskedLink, SeoHide },
  mixins: [globalMixin],
  props: {
    information: {
      type: Object,
      default: () => ({}),
    },
    documents: {
      type: Object,
      default: () => [],
    },
    isCollapsible: {
      type: Boolean,
      default: true,
    },
    modifier: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      viewportWidth: null,
      isMobile: false,
      expanded: false,
    };
  },
  computed: {
    dangerousDocuments() {
      return this.documents.items && !!this.documents.items.length
        ? this.documents.items.filter(
            (item) => item.title === 'Sicherheitsdatenblatt'
          )
        : [];
    },
    isCompact() {
      return this.information.icon.length >= 4;
    },
  },
  watch: {
    viewportWidth(newValue) {
      if (newValue <= 768 && this.isCollapsible) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    this.updateViewport();
    window.addEventListener('resize', debounce(this.updateViewport, 150));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateViewport);
  },
  methods: {
    upperCamelCase: (string) => upperFirst(camelCase(string)),
    updateViewport() {
      this.viewportWidth = window.innerWidth;
    },
    onClickChild(expanded) {
      this.expanded = expanded;
    },
    handleDocumentClick(index, { target }, type) {
      const documentLinkComponent = this.$refs[`${type}DocumentLink`][index];

      if (documentLinkComponent && documentLinkComponent.$el !== target) {
        documentLinkComponent.$el.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';
@import 'mixins';

.hazardousGood {
  border: 1px solid var(--color-alt);
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-SM);
}

.hazardous-good {
  &--secondary#{&} {
    @mixin default-typography {
      font-size: var(--font-size-M) !important;
      line-height: var(--font-size-MLL) !important;
    }

    border: 0;

    .hazardWarning {
      display: block;
      padding: 0;
    }

    .headline {
      color: var(--color-nero) !important;
      font-weight: bold;
      padding-bottom: var(--space-0--half);
      border-bottom: 1px solid var(--color-concrete);
      margin-bottom: var(--space-2);

      @include default-typography;
    }

    .title {
      margin-right: 0;
    }

    .singleWord {
      margin: 0;

      @include default-typography;
    }

    .hazardCriteria {
      margin: 0;
      width: 100%;
    }

    .iconsContainer {
      display: block;
    }

    .icons {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      min-width: 200px;
      height: 56px;
      border-radius: var(--border-radius-default);
      border: 1px solid var(--color-concrete);
      padding: var(--space-1);
      margin: var(--space-1) 0 0 0;

      &:not(:last-child) {
        margin-right: var(--space-1);
      }

      .icon {
        margin: 0 var(--space-1) 0 0;
      }

      .iconTitle {
        font-size: var(--font-size-SM);
        line-height: var(--font-size-MLL);
        flex-grow: 1;
        text-align: left;

        @include text-ellipsis;
      }
    }

    .hazardStatements {
      padding: 0;
      margin-top: var(--space-2);
    }

    .statementsLabel {
      @include default-typography;
    }

    .statement {
      margin-top: var(--space-0--half);

      @include default-typography;
    }

    .documents {
      margin: 0;
      padding: 0;
    }

    .document {
      display: inline-flex;
      flex-direction: row;
      align-content: center;
      margin: var(--space-2) 0 0 0;
      height: 22px;
      max-width: unset;

      &:not(:last-child) {
        margin-right: var(--space-3);
      }

      .icon {
        height: 22px;
        width: 22px;
      }

      .document__link {
        margin: 0 0 0 var(--space-1);
        text-decoration: underline;

        @include default-typography;
      }
    }

    @media #{$_mediaMDown} {
      .headline {
        padding-bottom: var(--space-1);
      }

      .icons {
        width: 100%;
      }

      .iconTitle {
        color: var(--color-nero);
      }
    }
  }
}

.hazardWarning {
  display: flex;
  width: 100%;
  padding: 16px;
}

.title {
  display: flex;
  flex-direction: column;
  margin-right: 24px;

  .headline {
    padding-bottom: 8px;
    font-weight: bold;
    font-size: var(--font-size-M);
    line-height: 17px;

    @include themeColor(
      color,
      var(--color-tory-blue),
      var(--color-endeavour),
      var(--color-chathamsblue)
    );
  }

  .singleWord {
    margin-top: 16px;
    font-weight: bold;
  }
}

.hazardCriteria {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 28px);
  margin-left: -14px;
  margin-right: -14px;
  margin-top: -8px;
}

.iconsContainer {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 0 2px;
  text-align: center;
  width: 66px;

  &--expanded {
    margin-top: 34px;
    height: 74px;
    justify-content: end;
  }

  .icon {
    height: 40px;
    width: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 0 14px;
    text-align: center;

    ::v-deep path:nth-of-type(1) {
      fill: var(--color-red);
    }
  }

  .iconTitle {
    margin-top: 4px;
    font-size: 11px;
    font-weight: 500;
    line-height: 10px;
    width: 66px;
    color: var(--color-mine-shaft);
    word-break: break-word;
    hyphens: auto;
  }
}

.hazardStatements {
  width: 100%;
  word-break: break-word;
  padding: 16px;
  padding-top: 6px;
}

.statementsLabel {
  font-weight: 600;
  padding-bottom: 2px;
}

.documents {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 28px);
  margin-left: -14px;
  margin-right: -14px;
  margin-top: 8px;
  padding: 0 16px;

  .icon {
    height: 32px;
  }
}

.document {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 14px 14px;
  max-width: calc(33.333% - 28px);
  cursor: pointer;
}

.document__link {
  margin-top: var(--space-1--half);
  text-align: center;
  font-size: 11px;
  line-height: 13px;
  color: var(--color-mine-shaft);
}

.mobileTitle {
  font-size: 13px;
  display: flex;
  flex-direction: column;

  .headline {
    padding-bottom: 8px;
    font-weight: bold;
    font-size: var(--font-size-M);
    line-height: 17px;

    @include themeColor(
      color,
      var(--color-tory-blue),
      var(--color-endeavour),
      var(--color-chathamsblue)
    );
  }
}

.hazardousMobile {
  ::v-deep {
    .body {
      position: relative;
      margin-top: 36px;
      overflow: unset;

      .singleWord {
        position: absolute;
        top: -32px;
        font-weight: bold;
      }

      .documents {
        padding: 0;
      }
    }
  }
}

@media screen and (min-width: 920px) and (max-width: 950px) {
  .title {
    margin-right: 4px;
  }

  .headline {
    font-size: 13px !important;
  }

  .icons {
    width: 40px;
    margin: 6px 16px;

    &--expanded {
      margin-top: 33px;
    }

    .iconTitle {
      font-size: 10px;
      margin-top: 0;
    }
  }
}

@media #{$_mediaMDown} {
  .hazardousGood {
    border-left: none;
    border-right: none;
  }
}

@media #{$_mediaSDown} {
  .headline {
    font-size: 13px !important;
  }

  .icons {
    width: 28px;
    margin: 6px 14px;

    &--expanded {
      margin-top: 40px;
    }

    .iconTitle {
      font-size: var(--font-size-XS);
      margin-top: 0;
      width: 50px;
    }
  }
}

@media (max-width: 400px) {
  .title {
    margin-right: 14px;
  }

  .mobileTitle {
    margin-right: 2px;
    font-size: var(--font-size-S);
  }

  .headline {
    font-size: 13px !important;
  }

  .iconsContainer {
    flex-wrap: no-wrap;
  }

  .icons {
    width: 32px;
    margin: 2px 8px 0;

    &--expanded {
      margin-top: 36px;
    }

    .icon {
      width: 30px;
    }

    .iconTitle {
      font-size: var(--font-size-XS);
      margin-top: 0;
      width: 40px;
    }
  }

  .document {
    margin: 0 14px 14px 26px;
  }
}

@media #{$_mediaXSDown} {
  .iconsContainer {
    .compact {
      width: 22px;

      .iconTitle {
        font-size: 6px;
      }
    }
  }
}
</style>
