import debounce from 'lodash/debounce';
import { mapMutations } from 'vuex';

export const updateViewportMixin = {
  mounted() {
    this.updateViewport();
    window.addEventListener('resize', debounce(this.updateViewport, 150));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateViewport);
  },
  methods: {
    ...mapMutations('core', ['setViewport']),
    matchMedia(min, max) {
      return window.matchMedia(
        `(min-width: ${min}px) and (max-width: ${max}px)`
      ).matches;
    },
    updateViewport() {
      if (this.matchMedia(0, 320)) {
        this.setViewport({ name: 'XS', range: 'mobile' });
      } else if (this.matchMedia(321, 480)) {
        this.setViewport({ name: 'S', range: 'mobile' });
      } else if (this.matchMedia(481, 768)) {
        this.setViewport({ name: 'M', range: 'mobile' });
      } else if (this.matchMedia(769, 1000)) {
        this.setViewport({ name: 'L', range: 'tablet' });
      } else if (this.matchMedia(1001, 9999)) {
        this.setViewport({ name: 'XL', range: 'desktop' });
      }
    },
  },
};
