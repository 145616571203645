<i18n src="./translations"></i18n>

<template>
  <Dialog
    ref="alternative-products-dialog"
    class="alternative-products-dialog"
    modifier="alternative-products-dialog"
    :title="$t('alternative-products-dialog.title')"
    :active="active"
    @hide="closeAction"
  >
    <div class="alternative-products-dialog-container">
      <div class="content">
        <div class="title">
          {{
            $t(
              `alternative-products-dialog.description${
                isParentAvailable() ? '' : '_unavailable'
              }`
            )
          }}
        </div>
        <div v-if="product" class="product-container">
          <div class="image-container">
            <AdvancedImage
              v-if="product.images && product.images.length > 0"
              class="alternative-products-dialog-product-image"
              :src="product.images[0].src"
              :alt="product.images[0].alt || product.name"
              :config="{ q: 80, w: 180 }"
            />
            <AdvancedImage
              v-if="product.brandLogoUrl"
              class="alternative-products-dialog-brand-image"
              :src="product.brandLogoUrl"
              :alt="product.brandName || ''"
              :config="{ q: 80, w: 180 }"
            />
          </div>
          <div class="description-container">
            <strong class="product-title" @click="openProduct()">{{
              product.name
            }}</strong>
            <ul>
              <li v-if="vehicleName">
                <Icon name="checkmark_green" />
                <span class="has-checkmark">
                  {{ $t('alternative-products-dialog.compatible_with')
                  }}{{ vehicleName }}</span
                >
              </li>
              <li>
                <Icon name="checkmark_green" />
                <span class="has-checkmark">
                  {{ $t('alternative-products-dialog.similar_price_range') }}
                </span>
              </li>
              <li>
                <Icon name="checkmark_green" />
                <span class="has-checkmark">
                  {{ $t('alternative-products-dialog.available_immediately') }}
                </span>
              </li>
            </ul>
          </div>
          <div class="price-container">
            <PriceInfo21 ref="priceInfo" modifier="slim" :product="product" />
          </div>
        </div>
      </div>
      <div class="footer">
        {{
          $t(
            `alternative-products-dialog.footer-text${
              isParentAvailable() ? '' : '_unavailable'
            }`
          )
        }}
        <div class="buttons-container">
          <Button
            :on-click="cancelAction"
            :title="
              $t(
                `alternative-products-dialog.cancel-title${
                  isParentAvailable() ? '' : '_unavailable'
                }`
              )
            "
            :modifiers="['secondary']"
          />
          <Button
            :on-click="confirmAction"
            :title="
              $t(
                `alternative-products-dialog.confirm-title${
                  isParentAvailable() ? '' : '_unavailable'
                }`
              )
            "
            :modifiers="['primary']"
          />
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import AdvancedImage from 'Components/01-atoms/advanced-image/AdvancedImage';
import Dialog from 'Components/02-molecules/dialog/Dialog';
import globalMixin from 'Libs/mixins/globalMixin';
import { mapActions, mapState } from 'vuex';
import { axios } from 'Services/AjaxService';
import Icon from 'Components/00-generated/Icon';
import PriceInfo21 from 'Components/02-molecules/price-info-21/PriceInfo21.vue';
import Button from 'Components/01-atoms/button/Button.vue';

export default {
  name: 'AlternativeProductsDialog',
  components: {
    Dialog,
    AdvancedImage,
    Icon,
    PriceInfo21,
    Button,
  },
  mixins: [globalMixin],
  props: {
    parentProduct: {
      type: Object,
      default: () => {},
    },
    handleConfirm: {
      type: Function,
      default: () => {},
    },
    handleCancel: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      product: null,
      active: false,
    };
  },
  computed: {
    ...mapState('core', {
      salesChannelId: ({ salesChannel }) => salesChannel.salesChannelId,
    }),
    ...mapState('carSelection', {
      carTypeNumber: ({ data: { vehicle = {} } }) => {
        const { ktypnr } = vehicle;
        return ktypnr ?? null;
      },
      vehicleName: ({ data: { vehicle = {} } }) => {
        const { manufacturer, model, type } = vehicle;
        return manufacturer && model && type
          ? `${manufacturer} ${model} ${type}`
          : null;
      },
    }),

    alternativeProductsAPIUrl() {
      return this.frontFacingPath('search.alternativeproducts.get');
    },
  },
  methods: {
    ...mapActions('dataLayer', [
      'trackAlternativeProductOpened',
      'trackAlternativeProductConfirm',
      'trackAlternativeProductCancel',
      'trackAlternativeProductDetailPageOpened',
    ]),
    async fetchAlternativeProduct(productId, quantity) {
      const payload = {
        product_id: productId,
        quantity: quantity,
        cartype_number: this.carTypeNumber,
      };

      let response = await axios.post(this.alternativeProductsAPIUrl, payload);

      if (
        response.success &&
        response.data.length > 0 &&
        response.data[0].searchResult
      ) {
        return response.data[0].searchResult;
      }

      return null;
    },
    showDialog(product) {
      this.product = product;
      this.active = true;
      this.$refs['alternative-products-dialog'].show();
      this.trackAlternativeProductOpened(this.parentProduct, this.product);
    },
    hideDialog() {
      this.active = false;
      this.$refs['alternative-products-dialog'].hide();
    },
    confirmAction() {
      if (this.active) {
        // Prevent multiple calls
        const productToReturn = {
          ...this.product.searchResult,
          ...this.product,
        };

        this.handleConfirm(productToReturn);
        this.hideDialog();
        this.trackAlternativeProductConfirm(this.parentProduct, this.product);
      }
    },
    cancelAction() {
      if (this.active) {
        // Prevent multiple calls
        this.handleCancel();
        this.hideDialog();
        this.trackAlternativeProductCancel(this.parentProduct, this.product);
      }
    },
    closeAction() {
      if (this.active) {
        // Prevent multiple calls
        this.active = false;
        this.handleCancel();
        this.trackAlternativeProductCancel(this.parentProduct, this.product);
      }
    },
    openProduct() {
      this.trackAlternativeProductDetailPageOpened(
        this.parentProduct,
        this.product
      ).finally(() => {
        if (this.product.productUrl) {
          window.location.href = this.product.productUrl;
        }
      });
    },
    isParentAvailable() {
      const availabilityStatus = [
        'DELAYED',
        'AVAILABLE_SOON',
        'AVAILABLE_ON_REQUEST',
        'OUT_OF_STOCK',
        'NOT_AVAILABLE',
        'NOTAVAILABLE',
        '2TO4WEEKS',
      ];

      return (
        this.parentProduct && //Exists
        this.parentProduct.prices.price > 0 && //Has a price
        !availabilityStatus.includes(
          (this.parentProduct.availability ?? '').toUpperCase()
        ) //Is available
      );
    },
  },
};
</script>

<style lang="scss">
@import 'variables/breakpoints.scss';

.dialog--alternative-products-dialog {
  .body {
    display: flex;
    overflow: hidden;
  }

  .header {
    font-weight: 700;
    font-size: var(--font-size-L);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .slotFrame {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
  }

  .buttons-container {
    justify-content: center;
    border: none;
    padding-top: var(--space-3);

    @media #{$_mediaSDown} {
      display: flex;
      flex-direction: column-reverse;
      gap: var(--space-2);
      align-items: center;
    }

    button {
      @media #{$_mediaSDown} {
        width: 80%;
      }
    }
  }

  .product-container {
    padding-top: var(--space-4);
    display: flex;

    @media #{$_mediaSDown} {
      flex-wrap: wrap;
    }

    > * {
      flex: 1;
    }

    > *:not(:first-child) {
      margin-left: var(--space-3);
    }

    .image-container {
      position: relative;

      @media #{$_mediaSDown} {
        flex: 1;
      }

      .alternative-products-dialog-brand-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: auto;
      }
    }

    .description-container {
      font-size: var(--font-size-MML);
      line-height: var(--font-size-MLL);

      .product-title {
        cursor: pointer;
      }

      @media #{$_mediaSDown} {
        flex: 2;
      }

      > strong {
        display: block;
        text-decoration: underline;
        font-weight: 700;
      }

      ul li {
        margin-top: calc(var(--space-4) - 2px);
        font-size: var(--font-size-M);
        font-weight: 700;

        @media #{$_mediaSDown} {
          margin-top: var(--space-2);
        }
      }

      ul li .has-checkmark {
        color: #37d19e;
      }

      ul li svg {
        display: inline-block;
        width: calc(var(--font-size-M) + 4px);
        height: calc(var(--font-size-M) + 4px);
        vertical-align: middle;
        margin-right: 2px;
        margin-top: -4px;
      }
    }

    .wishlistButton {
      display: none !important;
    }

    .mainPrice {
      color: var(--color-black);
      margin-bottom: var(--space-2);
    }

    .price-container {
      @media #{$_mediaSDown} {
        width: 100%;
        margin-left: 0;
        margin-top: var(--space-3);
        flex: auto;
      }
    }
  }

  .alternative-products-dialog-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;

    .content {
      flex: 1;
      padding: var(--space-4) var(--space-4) var(--space-2);
      overflow-y: auto;

      @media #{$_mediaSDown} {
        padding: var(--space-2) var(--space-2) var(--space-2);
      }

      .title {
        font-size: var(--font-size-MLL);
        line-height: var(--font-size-XL);

        @media #{$_mediaSDown} {
          font-size: var(--font-size-M);
          line-height: normal;
        }
      }
    }

    .footer {
      border-top: solid 1px var(--color-alto);
      font-size: var(--font-size-MLL);
      font-weight: 700;
      text-align: center;
      padding: var(--space-3) var(--space-2) 0;
      z-index: 2;
      background: var(--color-white);

      @media #{$_mediaSDown} {
        line-height: normal;
      }
    }
  }

  .frame {
    max-width: 948px;
  }
}
</style>
