<template>
  <button
    :class="'active-filter-item'"
    :data-preload="[$options.name]"
    @click="$emit('click')"
  >
    {{ label }}:&nbsp;<strong>{{ value }}</strong>
    <Icon name="close" :class="['icon-close']" />
  </button>
</template>

<script>
import Icon from 'Components/00-generated/Icon';

export default {
  name: 'ActiveFilterItem',
  components: {
    Icon,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.active-filter-item {
  background-color: var(--color-white);
  border: 1px solid var(--color-alt);
  border-radius: 2px;

  display: inline-flex;
  align-items: flex-start;

  font-size: 11px;
  max-height: 30px;
  padding: var(--space-1) var(--space-0--half);
  margin-right: 10px;

  transition: all var(--time-XS) ease-in-out;

  strong {
    font-weight: bold;
  }

  .icon-close {
    width: 12px;
    height: 12px;
    stroke: var(--color-rolling-stone);
    stroke-width: 2px;
    margin-left: var(--space-0--half);
  }

  &:hover {
    background-color: var(--color-secondary);

    color: var(--color-white);
    cursor: pointer;

    strong {
      color: var(--color-white);
    }

    .icon-close {
      stroke: var(--color-white);
    }
  }

  &:focus {
    outline: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

@media #{$_mediaLUp} {
  .active-filter-item {
    margin-bottom: 12px;
    align-items: center;
    font-size: var(--font-size-S);
  }
}
</style>
