import axios from 'axios';
import has from 'lodash/has';
import { toCamelCase } from '../libs/helpers/key-case-converter';
import TrackingHandler from '../libs/helpers/tracking';

class AjaxService {
  static get(...params) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(...params);
        if (response.data && response.data.tracking) {
          this._pushTrackingData(response.data.tracking);
        }
        resolve(this._handleResponse(response.data));
      } catch (error) {
        this._handleAjaxError({ error });
        reject(error);
      }
    });
  }

  static post(...params) {
    const promise = new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(...params);
        if (response.data && response.data.tracking) {
          this._pushTrackingData(response.data.tracking);
        }

        const options = params[2] || {};

        resolve(
          options.hideSuccess && !has(response, 'data.messages')
            ? response.data
            : this._handleResponse(response.data, options.hideSuccess)
        );
      } catch (error) {
        this._handleAjaxError({ error });
        reject(error);
      }
    });

    return promise;
  }

  static _handleResponse(data, hideSuccess = false) {
    let { messages = [] } = data;

    if (hideSuccess) {
      messages = messages.filter((message) => message.type === 'error');
    }

    if (window.store) {
      window.store.dispatch('messages/addMessages', messages);
    }

    return toCamelCase(data);
  }

  static _handleAjaxError({ error }) {
    // eslint-disable-next-line
    console.error(error);
  }

  static _pushTrackingData(data) {
    TrackingHandler.track({ data });
  }
}

export { AjaxService as axios };
export default AjaxService;
