<i18n src="./translations"></i18n>

<template>
  <div :class="classes">
    <span class="headline">
      {{ $t('category-recommendations.headline') }}
    </span>
    <div class="categories">
      <CategoryItem
        v-for="(item, index) in initial"
        :key="index"
        :title="item.title"
        :url="item.url"
        :image-url="item.imageUrl"
        :is-pdp="true"
      />
    </div>
    <Button
      v-if="moreItems"
      class="showMore"
      :modifiers="['full', 'link']"
      :title="$t('category-recommendations.button')"
      :on-click="showExtra"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';
import Button from 'Components/01-atoms/button/Button';
import CategoryItem from 'Components/01-atoms/category-item/CategoryItem';

export default {
  name: 'CategoryRecommendations',
  components: {
    Button,
    CategoryItem,
  },
  mixins: [globalMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isShowExtra: false,
      limit: 6,
    };
  },
  computed: {
    ...mapState('core', {
      viewport: ({ viewport }) => viewport.range,
    }),
    initial() {
      if (!this.items) {
        return [];
      }

      return this.items.slice(0, this.limit);
    },
    moreItems() {
      return this.items && this.items.length > this.limit;
    },
  },
  methods: {
    showExtra() {
      this.limit = Infinity;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.categoryRecommendations {
  width: 100%;
  padding: var(--space-2) 0;
  background: var(--color-wild-sandish);
}

.headline {
  font-weight: bold;
  font-size: var(--font-size-MLL);
  padding-left: var(--space-2);
  line-height: var(--font-size-LLXL);

  @media #{$_mediaMDown} {
    font-size: var(--font-size-ML);
    line-height: var(--font-size-L);
  }
}

.categories {
  display: flex;
  flex-wrap: wrap;
  padding: var(--space-2) var(--space-1);
  justify-content: flex-start;

  .categoryItem {
    width: 50%;
    padding: var(--space-0--half) var(--space-1);
  }

  @media #{$_mediaSDown} {
    .categoryItem {
      width: 100%;
    }
  }
}

.showMore {
  padding: var(--space-1);
  text-align: center;
}
</style>
