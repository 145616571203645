<i18n src="./translations"></i18n>

<template>
  <div
    class="breadcrumbs--section"
    :class="classes"
    :data-preload="[$options.name]"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div v-if="!isBrandPage" v-html="seoScript"></div>
    <!-- eslint-enable vue/no-v-html -->
    <template v-for="(item, index) in breadcrumbsItems">
      <span
        :key="`breadcrumbs-${index}`"
        class="item-divider"
        :class="{
          'item-divider-masked-link': isBrandPage,
          'item-divider--mobile':
            item.url && index === breadcrumbsItems.length - 2,
        }"
      >
        >
      </span>
      <!-- [ozlui] v-text directive is used for eliminating unnecessarily generated spaces. -->
      <component
        :is="item.url ? 'a' : 'span'"
        v-if="!isBrandPage"
        :key="`breadcurmb-text-${index}`"
        class="item"
        :class="{
          link: item.url,
          'link--clickable': item.url && !!onClickHandler,
          'link--leaf': item.url && index === breadcrumbsItems.length - 1,
          'link--mobile': item.url && index === breadcrumbsItems.length - 2,
          label: !item.url,
        }"
        :href="getHref(item)"
        :title="itemTitle(item)"
        @click="item.url && handleClick(item)"
        v-text="itemTitle(item)"
      />
      <MaskedLink
        v-else
        :key="`breadcrumb-text-${index}`"
        class="item masked"
        :class="{
          link: item.url,
          'link--clickable': item.url && !!onClickHandler,
          'link--leaf': item.url && index === breadcrumbsItems.length - 1,
          'link--mobile': item.url && index === breadcrumbsItems.length - 2,
          label: !item.url,
        }"
        :redirect-to="getHref(item)"
        :title="itemTitle(item)"
        :html="itemTitle(item)"
        @click-handler="item.url && clickHandlerMaskedLink(item)"
      />
    </template>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { mapState, mapActions } from 'vuex';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';
import get from 'lodash/get';

export default {
  name: 'Breadcrumbs',
  components: {
    MaskedLink,
  },
  mixins: [globalMixin],
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    onClickHandler: {
      type: Function,
      default: null,
    },
    previousItem: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('core', {
      baseUrl: ({ salesChannel }) => salesChannel.baseUrl,
      requestUrl: (state) => state.requestData.requestUrl,
      placeholders: (state) => state.placeholders,
    }),
    isBrandPage() {
      const pageName = get(this.placeholders, 'content.component');
      const brandPages = ['BrandCategories', 'BrandsLandingPage'];

      return brandPages.includes(pageName);
    },
    clickHandlerMaskedLink(item) {
      return function() {
        if (item.url) {
          this.handleClick(item);
        }
        return true;
      };
    },
    breadcrumbsItems() {
      const homepageTitle = this.$t('breadcrumbs.homepage');

      if (this.items.length) {
        return [
          {
            url: '/',
            title: homepageTitle,
          },
          ...this.items,
        ];
      }
      return [
        {
          url: '/',
          title: homepageTitle,
        },
        ...this.breadcrumbs,
      ].map((item) => {
        if (item.name && !item.title) {
          return {
            ...item,
            title: item.name,
          };
        }
        return item;
      });
    },
    generateSeoItemListElement() {
      const list = [];
      const breadCrumbsLength = this.breadcrumbsItems.length;

      const getId = (index) => {
        let result = this.baseUrl;

        if (index === 0) {
          result = this.baseUrl;
        } else if (index === breadCrumbsLength - 1) {
          result =
            this.baseUrl +
            this.requestUrl.replace(this.baseUrl, '').split('?')[0];
        } else {
          result = this.baseUrl + this.breadcrumbsItems[index].url;
        }

        return result;
      };

      const getName = (index) => {
        let result = '';
        const item = this.breadcrumbsItems[index];

        if (index === 0) {
          result = this.$t('breadcrumbs.homepage');
        } else {
          result = item.title;
        }

        if (!item.url) {
          try {
            result =
              this.placeholders.content.data.product.brandName +
              ' ' +
              item.title;
          } catch {
            result = item.title;
          }
        }

        return result;
      };

      for (let i = 0; i < breadCrumbsLength; i++) {
        list.push({
          '@type': 'ListItem',
          position: i + 1,
          item: {
            '@id': getId(i),
            name: getName(i),
          },
        });
      }

      return JSON.stringify(list);
    },
    seoScript() {
      return (
        `
        <script type="application/ld+json">
          {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": ${this.generateSeoItemListElement}
          }
        </sc` +
        `ript>
      `
      );
    },
  },
  methods: {
    ...mapActions('dataLayer', ['trackBreadcrumbClick']),
    itemTitle(item) {
      let title;

      if (item.url) {
        title = item.title;
      } else {
        try {
          title =
            this.placeholders.content.data.product.brandName + ' ' + item.title;
        } catch {
          title = item.title;
        }
      }

      return title.trim();
    },
    handleClick(item) {
      if (!!this.onClickHandler) {
        this.onClickHandler(item);
      }
      this.trackBreadcrumbClick({
        categoryKey: item.title,
        event: 'breadcrumb_click',
      });
    },
    getHref(item) {
      return item.url && !this.onClickHandler ? item.url : null;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.breadcrumbs {
  padding: 24px 16px 0;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  line-height: 17px;
  font-size: var(--font-size-SM);
  display: block;

  @media #{$_mediaMDown} {
    padding-left: 0;
  }

  &--section {
    color: var(--color-nero);
  }

  @media #{$_mediaMDown} {
    padding: var(--space-2);
  }
}

.item-divider {
  height: 16px;
  width: 16px;
  display: inline-flex;
  justify-content: center;
  margin: 0 0 0 var(--space-0--half);
}

div + .item-divider,
.item-divider-masked-link:first-child {
  display: none;
}

.link {
  text-decoration: underline;
  cursor: pointer;

  &--clickable {
    cursor: pointer;
  }

  &--leaf {
    text-decoration: none;
    cursor: default;
  }
}

.label {
  font-size: var(--font-size-SM);
}

@media #{$_mediaMDown} {
  .label.item {
    display: none;
  }

  .link.item {
    display: none;

    &.link--mobile {
      display: inline;
      font-size: var(--font-size-M);
      margin-right: var(--space-2);
    }
  }

  .item-divider {
    width: 5px;

    &:not(.item-divider--mobile) {
      display: none;
    }

    &.item-divider--mobile {
      display: inline-block;
      transform: rotate(180deg) translateY(-2px);
    }
  }
}
</style>
