import { axios } from 'Services/AjaxService';
import TrackingHandler from 'Libs/helpers/tracking';

export const submitNewsletter = async (_, { src, url, email }) => {
  const { data } = await axios.post(url, {
    subscription_source: src,
    email,
  });

  const isSubscribed = data.some((d) => d.status === 'success');

  if (isSubscribed) {
    TrackingHandler.track({
      data: {
        event: 'newsletter_subscription',
        newsletterSubscribed: {
          subscriberSource: src,
          email: email,
          set: isSubscribed,
        },
      },
    });
  }
};
