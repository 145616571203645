<i18n src="./translations"></i18n>

<template>
  <div class="deshop-social" :class="classes" :data-preload="[$options.name]">
    <div
      v-if="showAppLinks === true || showAppLinks !== false"
      class="deshop-social__app-links app-links"
    >
      <a
        href="https://app.adjust.com/gy92yvb"
        class="app-links__store-link"
        rel="noreferrer noopener"
        target="_blank"
        :title="$t('deshop-social.title.apple-store')"
      >
        <Icon name="apple_store" :inline="false" :lazy="true" />
      </a>
      <a
        href="https://app.adjust.com/gy92yvb"
        class="app-links__store-link"
        rel="noreferrer noopener"
        target="_blank"
        :title="$t('deshop-social.title.google-play-store')"
      >
        <Icon name="google_play_store" :inline="false" :lazy="true" />
      </a>
    </div>
    <div
      v-if="showSocials && socialMediaList.length"
      class="deshop-social__media-links media-links"
    >
      <a
        v-for="(social, index) in socialMediaList"
        :key="index"
        class="media-links__item qa-IconButton"
        rel="noreferrer noopener"
        target="_blank"
        :href="social.url"
        :title="social.title"
      >
        <Icon :name="`${social.icon}_deshop`" :inline="false" :lazy="true" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Icon from 'Components/00-generated/Icon';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'DeshopSocial',
  components: {
    Icon,
  },
  mixins: [globalMixin],
  props: {
    showAppLinks: {
      type: Boolean,
      required: false,
      default: true,
    },
    showSocials: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapState('core', {
      socialMediaList: ({ navigation }) => {
        return navigation.social;
      },
    }),
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.deshop-social {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding-bottom: 0;
}

.media-links__item {
  display: inline-flex;

  & + & {
    margin-left: var(--space-1);
  }

  &:hover {
    background: var(--color-alabaster);
  }
}

.deshop-social__media-links {
  display: inline-block;
}

.deshop-social__app-links {
  display: inline-block;
  margin-right: var(--space-3);
}

.app-links__store-link {
  display: inline-block;
  height: 35px;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  & + & {
    margin-left: var(--space-1);
  }

  img {
    transition: transform var(--time-XS) ease-out;
    height: 100%;
  }
}

@media #{$_mediaLDown} {
  .deshop-social {
    display: block;
  }

  .deshop-social__media-links {
    margin-top: var(--space-3);
  }

  .media-links__item {
    flex-grow: 0;
    -ms-flex-positive: 1;
  }
}
</style>
