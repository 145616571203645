<i18n src="./translations"></i18n>
<template>
  <div
    class="hazardous-good-21"
    :class="[classes, `hazardous-good-21--${modifier}`]"
    :data-preload="[$options.name]"
  >
    <div class="hazardous-good-21__statements-label">
      <seo-hide :text="$t('hazardous-good-21.label')" />
    </div>

    <div class="hazardous-good-21__hazard-warning">
      <div class="hazardous-good-21__single-word">
        <seo-hide :text="information.signalWord" />
      </div>

      <div class="hazardous-good-21__icons-container">
        <div
          v-for="(name, index) in information.icon"
          :key="index"
          :class="['hazardous-good-21__icons', { compact: isCompact }]"
        >
          <Icon class="hazardous-good-21__icon" :name="upperCamelCase(name)" />
          <p class="hazardous-good-21__icon-title">
            <seo-hide :text="$t(`hazardous-good-21.${name}`)" />
          </p>
        </div>
      </div>
    </div>

    <template
      v-if="
        information.statements &&
        information.statements.hazard &&
        information.statements.hazard.length > 0
      "
    >
      <seo-hide
        class="hazardous-good-21__headline"
        :text="`${$t('hazardous-good-21.hazard_warning')}:`"
      />

      <div class="hazardous-good-21__hazard-statements">
        <seo-hide
          v-for="(statement, index) in information.statements.hazard"
          :key="index"
          class="hazardous-good-21__statement"
          :text="`${statement.label} `"
        />
      </div>
    </template>
  </div>
</template>

<script>
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import debounce from 'lodash/debounce';
import globalMixin from 'Libs/mixins/globalMixin';
import Icon from 'Components/00-generated/Icon';
import SeoHide from 'Components/01-atoms/seo-hide/SeoHide';

export default {
  name: 'HazardousGood21',
  components: { Icon, SeoHide },
  mixins: [globalMixin],
  props: {
    information: {
      type: Object,
      default: () => ({}),
    },
    isCollapsible: {
      type: Boolean,
      default: true,
    },
    modifier: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      viewportWidth: null,
      isMobile: false,
      expanded: false,
    };
  },
  computed: {
    isCompact() {
      return this.information.icon.length >= 4;
    },
  },
  watch: {
    viewportWidth(newValue) {
      if (newValue <= 768 && this.isCollapsible) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    this.updateViewport();
    window.addEventListener('resize', debounce(this.updateViewport, 150));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateViewport);
  },
  methods: {
    upperCamelCase: (string) => upperFirst(camelCase(string)),
    updateViewport() {
      this.viewportWidth = window.innerWidth;
    },
    onClickChild(expanded) {
      this.expanded = expanded;
    },
    handleDocumentClick(index, { target }, type) {
      const documentLinkComponent = this.$refs[`${type}DocumentLink`][index];

      if (documentLinkComponent && documentLinkComponent.$el !== target) {
        documentLinkComponent.$el.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';
@import 'mixins';

@mixin default-typography {
  font-size: var(--font-size-M) !important;
  line-height: var(--font-size-MLL) !important;
  color: var(--color-nero);
}

.hazardous-good-21 {
  margin-bottom: var(--space-1--half);
}

.hazardous-good-21__hazard-warning {
  padding: 0;
  display: flex;
  width: 100%;
}

.hazardous-good-21__title {
  display: flex;
  flex-direction: column;
  margin-right: var(--space-3);

  .hazardous-good-21__headline {
    padding-bottom: 8px;
    font-weight: bold;
    font-size: var(--font-size-M);
    line-height: 17px;

    @include themeColor(
      color,
      var(--color-tory-blue),
      var(--color-endeavour),
      var(--color-chathamsblue)
    );
  }
}

.hazardous-good-21__hazard-criteria {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -14px;
  margin-right: -14px;
  margin-top: -8px;
}

.hazardous-good-21__icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 0 3px;
  text-align: center;
  width: 71px;

  &--expanded {
    margin-top: 34px;
    height: 74px;
    justify-content: end;
  }

  .hazardous-good-21__icon {
    height: 40px;
    width: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 0 14px;
    text-align: center;

    ::v-deep path:nth-of-type(1) {
      fill: var(--color-red);
    }
  }

  .hazardous-good-21__icon-title {
    margin-top: 1px;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    width: 72px;
    color: var(--color-mine-shaft);
    word-break: break-word;
    hyphens: auto;
  }
}

.hazardous-good-21__hazard-statements {
  width: 100%;
  word-break: break-word;
  font-size: 16px;
  line-height: 22px;
}

.hazardous-good-21__statements-label {
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 16px;
}

.hazardous-good-21__headline {
  color: var(--color-nero);
  font-weight: bold;
  display: block;

  @include default-typography;
}

.hazardous-good-21__single-word {
  @include default-typography;

  font-weight: bold;
  width: 50%;
  margin-top: 10px;
}

.hazardous-good-21__icons-container {
  display: flex;
  margin-left: auto;
}
</style>
