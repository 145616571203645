import { render, staticRenderFns } from "./RadioBase.vue?vue&type=template&id=479d2ab4&scoped=true"
import script from "./RadioBase.vue?vue&type=script&lang=js"
export * from "./RadioBase.vue?vue&type=script&lang=js"
import style0 from "./RadioBase.vue?vue&type=style&index=0&id=479d2ab4&prod&lang=scss"
import style1 from "./RadioBase.vue?vue&type=style&index=1&id=479d2ab4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479d2ab4",
  null
  
)

export default component.exports