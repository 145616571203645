<template>
  <section
    class="pdp-tab-base"
    :class="classes"
    :data-preload="[$options.name]"
  >
    <button
      class="pdp-tab-base__collapsible-button"
      type="button"
      @click="toggleContainer"
    >
      <span class="pdp-tab-base__collapsible-headline">
        {{ headline }}
      </span>
      <Icon
        class="pdp-tab-base__collapsible-icon"
        :class="
          expanded
            ? 'pdp-tab-base__collapsible-icon--expanded'
            : 'pdp-tab-base__collapsible-icon--collapsed'
        "
        name="chevron_right"
      />
    </button>
    <transition name="pdp-tab-base__container">
      <div
        v-show="expanded"
        class="pdp-tab-base__container"
        :class="{ 'pdp-tab-base__container--expanded': expanded }"
      >
        <slot />
      </div>
    </transition>
  </section>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import Icon from 'Components/00-generated/Icon';

export default {
  name: 'PdpTabBase',
  components: { Icon },
  mixins: [globalMixin],
  props: {
    headline: {
      type: String,
      default: '',
      required: true,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    expanded: false,
  }),
  mounted() {
    if (this.isExpanded) this.expanded = !this.expanded;
  },
  methods: {
    toggleContainer() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.pdp-tab-base__collapsible-button {
  min-height: 56px;
  padding: var(--space-2);
  width: 100%;
  display: none;
  border-top: 1px solid var(--color-concrete);
  border-bottom: 1px solid var(--color-concrete);
  cursor: pointer;
  transition: background-color var(--time-XS) ease-in;

  &:active {
    background-color: var(--color-secondary);

    .pdp-tab-base__collapsible-headline {
      color: var(--color-white);
    }
  }
}

.pdp-tab-base__collapsible-headline {
  font-size: var(--font-size-MML);
  line-height: var(--font-size-MLL);
  font-weight: bold;
  flex-grow: 1;
  text-align: left;
  transition: color var(--time-XS) ease-in;
}

.pdp-tab-base__collapsible-icon {
  height: 28px;
  width: 28px;
  min-height: 28px;
  min-width: 28px;
  fill: var(--color-secondary);

  &--expanded {
    transform: rotate(-90deg);
  }

  &--collapsed {
    transform: rotate(90deg);
  }
}

.pdp-tab-base__container {
  overflow: hidden;

  &-enter,
  &-leave-to {
    max-height: 0;
  }

  &-enter-to,
  &-leave {
    max-height: 100vh;
  }

  &-enter-active,
  &-leave-active {
    transition: max-height var(--time-S) ease-out;
  }
}

@media #{$_mediaLUp} {
  .pdp-tab-base__container {
    display: block !important;
  }
}

@media #{$_mediaMDown} {
  .pdp-tab-base__container.pdp-tab-base__container {
    padding-bottom: var(--space-1);

    &--expanded {
      display: block;
    }
  }

  .pdp-tab-base__collapsible-button {
    display: flex !important;
  }
}
</style>
