<i18n src="./translations"></i18n>

<template>
  <div class="caris-my-garage">
    <span class="caris-my-garage__headline">
      {{ $t('caris-my-garage.headline') }}
    </span>
    <template v-if="!isFetching && vehicles && vehicles.length > 0">
      <ul class="caris-my-garage__vehicle-list vehicle-list">
        <li
          v-for="vehicle in vehicles"
          :key="vehicle.ktypnr"
          class="vehicle-list__item"
          @click="() => handleVehicleClick(vehicle)"
        >
          <Icon
            class="caris-select__icon"
            name="chevron_right"
            width="12"
            height="12"
          />
          {{ vehicle.label }}
        </li>
      </ul>
    </template>
    <template v-if="!isFetching && vehicles && vehicles.length === 0">
      <p class="caris-my-garage__empty-garage-message">
        {{ $t('caris-my-garage.empty_garage') }}
      </p>
    </template>
    <MaskedLink
      class="caris-my-garage__vehicles-link"
      :redirect-to="frontFacingPath('customer.shop.vehicles')"
      :text="$t('caris-my-garage.to_my_vehicles')"
    />
    <LoadingSpinner
      v-if="isFetching"
      class="caris-my-garage__spinner"
      modifier="greyTrace"
    />
  </div>
</template>

<script>
import { ktypnrMixin } from 'Src/libs/mixins/ktypnrMixin';
import globalMixin from 'Libs/mixins/globalMixin';

import Icon from 'Components/00-generated/Icon';
import LoadingSpinner from 'Components/01-atoms/loading-spinner/LoadingSpinner';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';

export default {
  name: 'CarisMyGarage',
  components: { LoadingSpinner, Icon, MaskedLink },
  mixins: [globalMixin, ktypnrMixin],
  props: {
    vehicles: {
      type: Array,
      default: () => [],
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleVehicleClick({ ktypnr, id }) {
      this.setKtypnr(ktypnr || id);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'mixins';
@import 'variables/breakpoints';

.caris-my-garage {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  --regular-font-size: var(--font-size-M);
  --regular-line-height: var(--font-size-ML);
  --headline-font-size: 18px;
  --healine-line-height: 22px;
}

.caris-my-garage__headline {
  font-size: var(--headline-font-size);
  line-height: var(--headline-line-height);
  color: var(--color-secondary);
  font-weight: bold;
}

.caris-my-garage__empty-garage-message {
  color: var(--color-white);
  margin-top: 12px;
  font-size: var(--regular-font-size);
  line-height: var(--regular-line-height);
}

.caris-my-garage__vehicles-link {
  display: block;
  text-decoration: underline;
  color: var(--color-white);
  margin-top: var(--space-2);
  margin-bottom: var(--space-1);
  font-size: var(--regular-font-size);
  line-height: var(--regular-line-height);
}

.caris-my-garage__vehicle-list.vehicle-list {
  overflow: hidden;
  max-height: 66px;
  margin-top: var(--space-1);
}

.vehicle-list__item {
  font-size: var(--regular-font-size);
  line-height: var(--regular-line-height);
  color: var(--color-white);
  cursor: pointer;
  width: max-content;
  border-bottom: 1px solid transparent;

  &:not(:last-child) {
    margin-bottom: 1px;
  }

  &:hover {
    border-color: var(--color-white);
  }
}

.caris-my-garage__spinner {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: var(--color-black--decorative);

  ::v-deep {
    .loadingSvg {
      position: absolute;
      top: 35%;
      width: 100%;
      height: 30%;
    }
  }
}

@media #{$_mediaMDown} {
  .caris-my-garage {
    --headline-font-size: var(--font-size-SM);
    --healine-line-height: var(--font-size-SM);
    --regular-font-size: var(--font-size-S);
    --regular-line-height: var(--font-size-SM);
  }

  .caris-my-garage__vehicles-link {
    margin-top: var(--space-1);
  }

  .caris-my-garage__vehicle-list.vehicle-list {
    height: 51px;
  }
}
</style>
