export function reportError(err, info, handler) {
  if (typeof newrelic !== 'undefined') {
    newrelic.noticeError(err, { errorSource: info, handler: handler });
  } else {
    // eslint-disable-next-line no-console
    console.error('NewRelic is not defined - local or testing env', err, info);
  }
}

/* istanbul ignore next */
export function setupErrorHandling(Vue) {
  Vue.config.errorHandler = function(err, _vm, info) {
    reportError(err, info, 'errorHandler');
  };

  Vue.config.errorCaptured = function(err, _vm, info) {
    reportError(err, info, 'errorCaptured');
  };
}
