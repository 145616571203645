var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{class:[_vm.classes, 'form'],attrs:{"autocomplete":"off","data-preload":[_vm.$options.name]},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"caris-nat-code-header__label"},[_vm._v("\n    "+_vm._s(_vm.$t('caris-nat-code-header.nat_code.headline'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"caris-nat-code-header__mode"},[_c('div',{staticClass:"caris-nat-code-header__fields"},[_c('div',{staticClass:"caris-nat-code-header__field field--full"},[_c('InputBase',{ref:"nat",attrs:{"placeholder":_vm.$t('caris-nat-code-header.nat_label'),"full-name":"nat","value":_vm.form.natCodeValue,"errors":_vm.errors,"minlength":"2","max-length":6,"type":"text","send":true,"attr":{
            autocomplete: 'off',
            autocorrect: 'off',
            autocapitalize: 'off',
            spellcheck: 'false',
            pattern: '\\d*',
          }},on:{"input":_vm.handleChange}})],1)]),_vm._v(" "),_c('Dialog',{ref:"dialog",attrs:{"click-outside":true,"title":_vm.$t('caris-nat-code-header.nat_code.headline')}},[_c('div',{staticClass:"caris-nat-code-header__explanation-text"},[_vm._v("\n        "+_vm._s(_vm.$t('caris-nat-code-header.nat_code.text'))+"\n      ")]),_vm._v(" "),_c('img',{staticClass:"caris-nat-code-header__explanation-image",attrs:{"data-src":_vm.resolveFile('images/caris-nat-code-form/nat_code.jpg'),"src":_vm.resolveFile('images/caris-nat-code-form/nat_code.jpg'),"title":_vm.$t('caris-nat-code-header.nat_code.headline'),"loading":"lazy"}})])],1),_vm._v(" "),_c('div',{staticClass:"caris-nat-code-header__where-to-find-button qa-whereToFindButton",on:{"click":_vm.showDialog}},[_vm._v("\n    "+_vm._s(_vm.$t('caris-nat-code-header.where_to_find'))+"\n  ")]),_vm._v(" "),_c('Button',{directives:[{name:"show",rawName:"v-show",value:(_vm.action),expression:"action"}],staticClass:"caris-nat-code-header__form__select-car-button selectCarButton",attrs:{"title":_vm.$t('caris-nat-code-header.select_new_car'),"modifier":"tertiary","type":"submit"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }