var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isEnabled)?_c('div',{class:[_vm.classes],attrs:{"data-preload":[_vm.$options.name]},on:{"click":function($event){return _vm.loadBranchesAvailability()}}},[(_vm.hasLocationImage)?_c('img',{staticClass:"locationIcon",attrs:{"width":"16","height":"25","loading":"lazy","src":_vm.resolveFile('images/branches-availability-link/location.png'),"data-src":_vm.resolveFile('images/branches-availability-link/location.png')}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"link"},[_vm._v("\n    "+_vm._s(_vm.$t(
        _vm.labelType === 'primary'
          ? 'branches-availability-link.label'
          : 'branches-availability-link.secondary_label'
      ))+"\n  ")]),_vm._v(" "),_c('Dialog',{ref:"dialog",staticClass:"dialog",attrs:{"click-outside":true,"title":_vm.$t(`branches-availability-link.label`)}},[(!!_vm.branchesAvailability)?_c('div',{staticClass:"heading",domProps:{"innerHTML":_vm._s(
        _vm.$t('branches-availability-link.heading', {
          '%brandName%': _vm.brandName,
          '%productName%': _vm.productName,
        })
      )}}):_vm._e(),_vm._v(" "),_vm._l((_vm.branchesAvailability),function(branch){return _c('div',{key:branch.branchName,staticClass:"branch"},[_c('div',{staticClass:"branchAvailability"},[_c('span',{staticClass:"branchName"},[_vm._v(_vm._s(branch.branchName))]),_vm._v(" "),_c('span',{class:[
            'availability',
            _vm.availabilityClasses(branch.availabilityKey),
          ]},[_vm._v("\n          "+_vm._s(_vm.translations.labels[branch.availabilityKey])+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"branchInfo"},[_vm._l((branch.address),function(addressInfo,index){return _c('p',{key:index,staticClass:"address"},[_vm._v("\n          "+_vm._s(addressInfo)+"\n        ")])}),_vm._v(" "),_c('p',{staticClass:"openingWeekdays"},[_vm._v("\n          "+_vm._s(branch.openingWeekdays)+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"openingWeekend"},[_vm._v("\n          "+_vm._s(branch.openingWeekend)+"\n        ")])],2)])})],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }