import { render, staticRenderFns } from "./SideNavigation.vue?vue&type=template&id=f517b6a4&scoped=true"
import script from "./SideNavigation.vue?vue&type=script&lang=js"
export * from "./SideNavigation.vue?vue&type=script&lang=js"
import style0 from "./SideNavigation.vue?vue&type=style&index=0&id=f517b6a4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f517b6a4",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F03-organisms%2Fside-navigation%2FSideNavigation.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports