export const miniCartState = ({ data, component } = {}) => {
  if (!data) {
    return false;
  }

  let minicart = { products: [], paymentTotal: '' };

  return {
    data: {
      ...data,
      minicart: data.minicart ? data.minicart : minicart,
    },
    component,
  };
};
