import AjaxService from 'Services/AjaxService';
import { has } from 'lodash';

const ORDER_DETAIL_ENDPOINT_KEY = 'order.ajax.order';

export default {
  async getOrderDetail({ rootState, state, commit }, orderId) {
    const { fetchedUrls } = rootState.core;
    const order = state.orderMap[orderId];

    if (order && order.details) {
      return order.details;
    } else if (order) {
      let result;
      try {
        result = await AjaxService.get(
          fetchedUrls[ORDER_DETAIL_ENDPOINT_KEY].replace('{orderId}', orderId)
        );
      } catch (e) {
        result = null;
      }
      if (
        has(result, 'data.order.orderHeader') &&
        has(result, 'data.order.orderRows')
      ) {
        // noinspection JSObjectNullOrUndefined
        const { orderHeader, orderRows } = result.data.order;
        order.details = converOrderHeaderToOrderDetails(orderHeader, orderRows);
        commit('SET_ORDER_DETAIL', order.details);

        return order.details;
      }
    }

    return null;
  },
  setOrderMap({ commit }, orders) {
    let orderMap = orders;

    if (Array.isArray(orders)) {
      orderMap = orders.reduce(
        (mappedOrders, order) => ({ ...mappedOrders, [order.id]: order }),
        {}
      );
    }

    commit('SET_ORDER_MAP', orderMap);
  },
};
const converOrderHeaderToOrderDetails = (orderHeader, orderRows) => {
  return {
    ...orderHeader,
    amounts: orderHeader.totals,
    invoiceUrls: orderHeader.invoices,
    shippingAddressList: orderHeader.shippingAddresses,
    paymentList: orderHeader.payments,
    totalAmount: orderHeader.totals.paymentTotal,
    products: orderRows,
  };
};
