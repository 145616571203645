<i18n src="./translations"></i18n>

<template>
  <div
    class="car-manufacturer-header"
    :class="classes"
    :data-preload="[$options.name]"
  >
    <div class="car-manufacturer-header__header-wrapper">
      <div class="car-manufacturer-header__header-wrapper-left">
        <h1 class="car-manufacturer-header__header">
          {{ headlinePrimary }}
          <span
            v-if="shouldShowSecondaryHeader"
            class="car-manufacturer-header__title-secondary"
          >
            {{ $t('car-manufacturer-header.series-title-mobile') }}
          </span>
        </h1>
        <span
          v-if="productsCount > 0"
          class="car-manufacturer-header__products-count"
        >
          ({{ productsCount }} {{ $t('car-manufacturer-header.parts') }}):
        </span>
      </div>
      <div class="car-manufacturer-header__logo">
        <AdvancedImage
          class="car-manufacturer-header__image"
          :src="createImageURL(manufacturer.image || '')"
          :alt="manufacturer.title"
          :config="{ q: 80, w: 80 }"
          :is-category="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import AdvancedImage from 'Components/01-atoms/advanced-image/AdvancedImage';
import { mapState } from 'vuex';

export default {
  name: 'CarManufacturerHeader',
  components: {
    AdvancedImage,
  },
  mixins: [globalMixin],
  props: {
    manufacturer: {
      type: Object,
      default: () => ({}),
    },
    series: {
      type: Object,
      default: () => ({}),
    },
    productsCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('seoCarCategories', {
      seriesTitle: (state) => state.data?.series?.title || '',
      categoryTitle: (state) => state.data?.category?.title || '',
      manufacturerTitle: (state) => state.data?.manufacturer?.title || '',
    }),
    ...mapState('seoBrandCategories', {
      categoryBrandTitle: (state) => state.data?.category?.title || '',
    }),
    ...mapState('core', {
      placeholders: (state) => state.placeholders,
      staticUrl: ({ salesChannel: { staticUrl } }) => staticUrl,
    }),
    headlinePrimary() {
      if (this.isPage('CarManufacturerSeries')) {
        return `${this.manufacturer.title}`;
      }
      if (this.isPage('CarCategoriesOverview')) {
        return `${this.manufacturer.title} ${this.series.title}`;
      }
      if (this.isPage('CarCategories')) {
        return `${this.manufacturerTitle} ${this.seriesTitle} ${this.categoryTitle}`;
      }

      if (this.isPage('BrandCategories')) {
        return `${this.manufacturer.title} ${this.categoryBrandTitle}`;
      }

      return '';
    },
    shouldShowSecondaryHeader() {
      if (
        this.isPage('CarManufacturerSeries') ||
        this.isPage('CarCategoriesOverview')
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    isPage(pageName) {
      const pageType = this.placeholders?.content?.component;
      return pageName === pageType;
    },
    createImageURL(imageUrl) {
      let fullImageURL = imageUrl;
      const isAbsoluteUrl = imageUrl.startsWith('http');

      if (!isAbsoluteUrl) {
        fullImageURL = `${this.staticUrl}/media-library/${imageUrl}`;
      }
      return fullImageURL;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.car-manufacturer-header__header-wrapper {
  display: flex;
  margin-bottom: var(--space-3);
  align-items: center;

  .car-manufacturer-header__logo {
    height: 60px;
    width: 100px;
    margin-left: auto;
    margin-top: 2px;

    @media #{$_mediaMDown} {
      width: 60px;
      height: 40px;
    }
  }
}

.car-manufacturer-header__products-count {
  font-size: var(--font-size-M);
  font-weight: normal;
  margin-top: var(--space-1);
  display: inline-block;

  @media #{$_mediaMDown} {
    margin-left: 0;
    margin-top: var(--space-1);
    display: block;
  }
}

.car-manufacturer-header__header-wrapper-left {
  max-width: 750px;
  display: inline-block;
}

.car-manufacturer-header__header {
  font-size: var(--font-size-LXL);
  font-weight: 700;
  display: inline;
  max-width: 666px;
  align-items: center;
  color: var(--color-nero);

  @media #{$_mediaMDown} {
    line-height: var(--font-size-LXL);
    display: block;
  }

  .car-manufacturer-header__title-primary {
    display: block;
  }

  .car-manufacturer-header__title-secondary {
    display: inline-block;

    @media #{$_mediaMDown} {
      display: block;
    }
  }

  @media #{$_mediaMDown} {
    font-size: var(--font-size-L);
    display: block;
  }
}
</style>
