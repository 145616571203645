import { mapState } from 'vuex';
import UrlHelper from 'Libs/helpers/url';

export const ktypnrMixin = {
  props: {
    setIdentificationMethod: {
      type: String,
    },
  },
  data() {
    return {
      identificationMethod: this.setIdentificationMethod,
    };
  },
  computed: {
    ...mapState('carSelection', {
      ktypnr: ({ data: { vehicle } }) => vehicle && vehicle.ktypnr,
    }),
  },
  methods: {
    setKtypnr(ktypnr) {
      let hrefObj = {
        parameters: { ktypnr },
      };

      if (this.frontFacingPath('content.page.node_id.0')) {
        hrefObj.url = this.frontFacingPath('content.page.node_id.0');
      }

      if (this.identificationMethod) {
        const newUrl = UrlHelper.getCarSelectionURL(hrefObj);

        if (newUrl === window.location.href) {
          window.location.href += '#' + this.identificationMethod;
          window.location.reload();
        } else {
          window.location.href = newUrl + '#' + this.identificationMethod;
        }
      } else {
        window.location.href = UrlHelper.getCarSelectionURL(hrefObj);
      }
    },
    appendKtypnr(url) {
      return this.ktypnr && this.ktypnr !== 0
        ? `${url}?ktypnr=${this.ktypnr}`
        : url;
    },
  },
};
