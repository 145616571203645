<i18n src="./translations"></i18n>

<template>
  <Dialog
    ref="unavailable-products-dialog"
    modifier="unavailable-products-dialog"
    class="unavailable-products-dialog"
    :cancel-button="cancelButton"
    :confirm-button="confirmButton"
    :title="$t('unavailable-products-dialog.title')"
    :active="active"
    @hide="hideDialog"
  >
    <ProductTile
      v-for="product in updatedProducts"
      :key="product.sku"
      :class="{ 'product-tile': products.length > 1 }"
      :product="product"
      :readonly="true"
    />

    <!-- eslint-disable vue/no-v-html -->
    <div
      class="unavailable-products-dialog__description"
      v-html="$t('unavailable-products-dialog.description')"
    />
    <!-- eslint-enable vue/no-v-html -->
  </Dialog>
</template>

<script>
import { mapMutations } from 'vuex';
import Dialog from 'Components/02-molecules/dialog/Dialog';
import ProductTile from 'Components/02-molecules/product-tile/ProductTile';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'UnavailableProductsDialog',
  components: {
    Dialog,
    ProductTile,
  },
  mixins: [globalMixin],
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    cartId: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    confirmButton() {
      return {
        title: this.$t('unavailable-products-dialog.confirm-title'),
        onClick: this.handleConfirm,
      };
    },
    cancelButton() {
      return {
        title: this.$t('unavailable-products-dialog.cancel-title'),
        onClick: this.hideDialog,
      };
    },
    updatedProducts() {
      return this.products.map((product) => {
        return {
          productId: product.productId,
          image: product.item_information?.image_url || product.image,
          brand: product.item_information?.brand || product.brand,
          name: product.item_information?.invoice.name || product.name,
          displaySku: product.display_sku || product.sku,
          sku: product.sku,
        };
      });
    },
  },
  watch: {
    active(newValue) {
      if (newValue) {
        this.$refs['unavailable-products-dialog'].show();
      } else {
        this.$refs['unavailable-products-dialog'].hide();
      }
    },
  },
  methods: {
    ...mapMutations('header', {
      closeDialog: 'HIDE_UNAVAILABLE_PRODUCTS_DIALOG',
    }),
    handleConfirm() {
      location.assign(this.frontFacingPath('checkout.cart.details'));
    },
    hideDialog() {
      this.closeDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints.scss';

.unavailable-products-dialog__description {
  padding: var(--space-2);
}

.product-tile {
  border-bottom: 1px solid var(--color-black--decorative);
}
</style>
