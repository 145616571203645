
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="WarningSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path fill="#D80000" fill-rule="evenodd" d="M8.57.286c1.556 0 2.99.383 4.304 1.15a8.527 8.527 0 013.12 3.12 8.38 8.38 0 011.15 4.3c0 1.556-.384 2.99-1.15 4.304a8.55 8.55 0 01-3.12 3.12 8.4 8.4 0 01-4.303 1.15 8.39 8.39 0 01-4.3-1.15 8.534 8.534 0 01-3.12-3.12A8.374 8.374 0 010 8.857c0-1.555.383-2.99 1.15-4.302a8.527 8.527 0 013.12-3.12 8.381 8.381 0 014.3-1.15zM10 14.203v-2.12a.367.367 0 00-.1-.263.33.33 0 00-.246-.106H7.51a.353.353 0 00-.256.112.352.352 0 00-.11.257v2.12c0 .097.036.182.11.257.075.074.16.11.257.11h2.144c.097 0 .18-.034.246-.105.067-.07.1-.158.1-.262zm-.022-3.84l.2-6.93a.218.218 0 00-.11-.2.419.419 0 00-.27-.09H7.345a.413.413 0 00-.268.09.216.216 0 00-.112.2l.19 6.93c0 .075.037.14.112.196a.435.435 0 00.267.083h2.065a.407.407 0 00.262-.084.293.293 0 00.118-.196z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'WarningSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
