
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="SearchSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="currentColor" fill-rule="evenodd" d="M11 6.8c0-1.2-.3-2.2-1.2-3a4.1 4.1 0 00-3-1.3c-1.2 0-2.2.4-3 1.2-1 .9-1.3 1.9-1.3 3 0 1.3.4 2.3 1.2 3.1.9.9 1.9 1.3 3 1.3C8 11 9 10.7 9.9 9.8c.9-.8 1.3-1.8 1.3-3zm5 8c0 .3-.1.6-.4.8-.2.3-.5.4-.8.4-.4 0-.7-.1-.9-.4l-3.3-3.3a6.7 6.7 0 01-8.6-.8A6.8 6.8 0 01.5 4.2a6.8 6.8 0 1111.8 6.5l3.3 3.3c.3.2.4.5.4.9z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'SearchSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
