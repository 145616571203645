
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="ChevronDownSvg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25"><path fill="#184187" d="M11.993 17.432a.98.98 0 01-.728-.334L4.296 9.389A1.221 1.221 0 014 8.581c0-.302.108-.592.3-.807a.989.989 0 01.722-.342.983.983 0 01.73.32l6.244 6.898 6.251-6.899a.983.983 0 01.732-.319.989.989 0 01.722.343c.191.215.299.505.299.807 0 .302-.106.593-.297.808l-6.98 7.708a1.03 1.03 0 01-.335.248.94.94 0 01-.395.086z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'ChevronDownSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
