<i18n src="./translations"></i18n>

<template>
  <p
    class="pdp-vehicle-notification"
    :class="`pdp-vehicle-notification--${compatibilityClasses[vehicleCompatibility]}`"
    :data-preload="[$options.name]"
  >
    <button
      v-if="vehicleCompatibility === 'NOT_SELECTED'"
      :class="['pdp-vehicle-notification__link', gtmClassName + '-link']"
      type="button"
      @click="openWidget"
    >
      {{ $t('pdp-vehicle-notification.select-a-car') }}
    </button>

    {{ $t(compatibilityTranslations[vehicleCompatibility]) }}

    <template
      v-if="
        ['SELECTED_CAR_OK', 'SELECTED_CAR_NOT_OK'].includes(
          vehicleCompatibility
        ) && vehicleName
      "
    >
      <br />
      {{ vehicleName }}
    </template>

    <!--  TODO: [ozlui] find the destination for this link  -->
    <MaskedLink
      v-if="
        vehicleCompatibility === 'SELECTED_CAR_NOT_OK' &&
        relatedItemsLink !== '/' &&
        relatedItemsLink !== ''
      "
      class="pdp-vehicle-notification__link"
      :redirect-to="relatedItemsLink"
      :text="$t('pdp-vehicle-notification.to-fitting-items')"
    />
  </p>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { mapMutations } from 'vuex';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';

export default {
  name: 'PdpVehicleNotification',
  components: { MaskedLink },
  mixins: [globalMixin],
  props: {
    vehicleCompatibility: {
      default: 'NOT_SELECTED',
      type: String,
    },
    vehicleName: {
      default: '',
      type: String,
    },
    relatedItemsLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      compatibilityTranslations: {
        SELECTED_CAR_OK: 'pdp-vehicle-notification.item-fits-for',
        SELECTED_CAR_NOT_OK: 'pdp-vehicle-notification.item-does-not-fit-for',
        NOT_SELECTED: 'pdp-vehicle-notification.check-if-vehicle-fits',
        UNIVERSAL_PRODUCT: 'pdp-vehicle-notification.can-be-used-universally',
      },
      compatibilityClasses: {
        SELECTED_CAR_OK: 'car-ok qa-selectedCarOk',
        SELECTED_CAR_NOT_OK: 'car-not-ok qa-selectedCarNotOk',
        NOT_SELECTED: 'car-not-selected qa-carNotSelected',
        UNIVERSAL_PRODUCT: 'universal-product qa-universalProduct',
      },
    };
  },
  methods: {
    ...mapMutations('carSelection', {
      openWidget: 'CARIS_DIALOG__OPEN',
    }),
  },
};
</script>

<style scoped lang="scss">
@import 'mixins';
@import 'variables/breakpoints';

.pdp-vehicle-notification {
  position: relative;
  min-width: 100%;
  border-radius: 8px;
  padding: var(--space-1) var(--space-1--half);
  margin-top: var(--space-2);
  letter-spacing: -0.1px;

  @include typography-size(var(--font-size-SM), var(--font-size-MML));

  @media #{$_mediaMDown} {
    border-radius: 8px;
  }

  background-color: var(--color-amber-lighter);

  &--car-ok {
    background-color: var(--color-success-green);
  }

  &--car-not-ok {
    background-color: var(--color-invalid-red);
  }

  &--car-not-selected {
    background-color: var(--color-amber-lighter);
  }

  &--universal-product {
    background-color: var(--color-alto-light);
  }

  &__link {
    text-decoration: underline;
    cursor: pointer;
    display: block;
    padding: 0;
    color: var(--color-black);
  }
}
</style>
