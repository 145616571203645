
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="SparePartsSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.04 23"><g data-name="Ebene 2"><path d="M11.5 5.75A5.75 5.75 0 115.75 0a5.74 5.74 0 015.75 5.75zM9.09 5.1a.29.29 0 00-.28-.28H6.68V2.69a.29.29 0 00-.28-.28H5.1a.29.29 0 00-.28.28v2.13H2.69a.29.29 0 00-.28.28v1.3a.29.29 0 00.28.28h2.13v2.13a.29.29 0 00.28.28h1.3a.29.29 0 00.28-.28V6.68h2.13a.29.29 0 00.28-.28zm13.8 1.05a.42.42 0 00-.71-.15l-2.63 2.58-2.39-.4-.4-2.39 2.62-2.63a.42.42 0 00-.2-.71 5.06 5.06 0 00-6 6.54L8.35 13.8A4.64 4.64 0 007 13.6a4.7 4.7 0 104.7 4.7 4.88 4.88 0 00-.18-1.3l4.83-4.83a5.07 5.07 0 006.54-6zM7.7 20.92l-2.62-.7-.7-2.62 1.92-1.92 2.62.7.7 2.62z" data-name="Ebene 1"/></g></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'SparePartsSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
