const qaClassNameMixin = {
  props: {
    qaClassNamePrefix: {
      type: String,
      default: '',
    },
  },
  computed: {
    qaClassName() {
      return this.qaClassNamePrefix ? this.qaClassNamePrefix + '-' : 'qa-';
    },
  },
};

export default qaClassNameMixin;
