<i18n src="./translations"></i18n>

<template>
  <div
    :class="[
      classes,
      {
        opened: isOpened || isOptionSelected,
        'qa-catalogCategorySearchFilter': true,
      },
    ]"
    :data-preload="[$options.name]"
    @mousedown="toggleContainer"
  >
    <div class="header">
      <div class="name">
        {{ title }}
      </div>
      <div v-show="!isOptionSelected" class="label">
        {{ $t('catalog-category-search-dropdown.select') }}
      </div>
      <div v-show="isOptionSelected" class="label selected-option">
        {{ selectedOptionWithCount() }}
      </div>
      <ChevronRightSvg
        :class="['toggleIcon', 'qa_chevron-down', { 'opened-arrow': isOpened }]"
      />
    </div>

    <div
      v-click-outside="{
        handler: handleSubmit,
        isActive: isOpened,
        events: ['click'],
      }"
      class="container"
    >
      <select
        v-model="selected"
        class="select-options"
        @change="handleChange($event)"
        @click="isOpened = false"
      >
        <option :class="['disabled-option']" disabled value="">
          {{ $t('catalog-category-search-dropdown.select-one') }}
        </option>
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.alias"
        >
          {{ option.alias }} ({{ option.count }})
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import globalMixin from 'Libs/mixins/globalMixin';
import ChevronRightSvg from 'Components/00-generated/ChevronRightSvg';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'CatalogCategorySearchDropdown',
  components: { ChevronRightSvg },
  mixins: [globalMixin],
  props: {
    alias: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpened: false,
      selected: this.selectedOption(),
      localValue: cloneDeep(this.value),
    };
  },
  computed: {
    hasDirtyData() {
      return !isEqual(this.value, this.localValue);
    },
    labelOrSelected() {
      let selected = values(this.options).filter((opt) => opt.isActive);
      if (selected.length === 0) {
        return this.$t('catalog-category-search-dropdown.select');
      }
      return `<span class="selected-option">${selected[0].alias}</span>`;
    },
    isOptionSelected() {
      let selected = values(this.options).filter((opt) => opt.isActive);
      return selected.length > 0;
    },
  },
  mounted() {
    this.$watch('value', (newValue) => {
      this.localValue = cloneDeep(newValue);
    });
  },
  methods: {
    getSelectedOption() {
      let selected = values(this.options).filter((opt) => opt.isActive);
      if (selected.length === 0) {
        return null;
      }
      return selected;
    },
    selectedOptionWithCount() {
      let selected = this.getSelectedOption();
      if (!selected) {
        return null;
      }
      return selected[0].alias + ' (' + selected[0].count + ')';
    },
    selectedOption() {
      let selected = this.getSelectedOption();
      if (!selected) {
        return null;
      }
      return selected[0].alias;
    },
    handleChange(event) {
      this.$emit('input', [event.target.value]);
    },
    toggleContainer() {
      this.isOpened = !this.isOpened;
    },
    handleClose() {
      this.isOpened = false;
      this.localValue = this.value;
    },
    handleSubmit() {
      if (!isEqual(this.localValue, this.value)) {
        this.$emit('input', this.localValue);
      }
      this.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'mixins';

.catalogCategorySearchDropdown {
  position: relative;
  width: 202px;
  height: 40px;
  flex: 1 1 100%;
  padding: 0 var(--space-0--half);
  margin-top: var(--space-1--half);
  margin-bottom: 26px;
  margin-right: 7px;
  margin-left: var(--space-1);
  max-width: 210px;

  @media #{$_mediaLDown} {
    width: 234px;
  }

  .disabled-option {
    display: none;
  }

  @supports (-webkit-touch-callout: none) {
    .disabled-option {
      display: block;
    }
  }

  .select-options {
    height: 59px;
    position: relative;
    z-index: 2;
    opacity: 0;
    width: 202px;
  }

  &.opened,
  &.active {
    .header,
    .container {
      border-color: var(--color-secondary);
    }
  }

  ::v-deep .label__value {
    width: 180px;
    word-break: break-word;
    white-space: normal;
  }
}

.toggleIcon {
  display: flex;
  color: var(--color-white);
  transform: rotate(90deg);
  transform-origin: center;
  transition:
    opacity var(--time-S) ease-in-out,
    transform var(--time-S) ease-in-out;
  margin-top: 4px;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-right: -4px;

  fill: var(--color-secondary);

  &.opened-arrow {
    transform: rotate(270deg);
  }
}

.header {
  cursor: pointer;
  height: 100%;
  border: 1px solid var(--color-boulderish);
  border-radius: 3px;
  background: var(--color-white);
  width: 202px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--space-1--half) 0 16px;

  .name {
    color: var(--color-nero);
    font-size: var(--font-size-SM);
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -2px;
    padding-right: 5px;
    top: -24px;
    position: absolute;
    left: 6px;
    max-width: 200px;

    @media #{$_mediaLDown} {
      font-size: var(--font-size-S);
    }
  }

  .label {
    color: var(--color-silver);
    font-size: var(--font-size-SM);
    line-height: 24px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: -2px;
    padding-right: 5px;
    max-width: 200px;

    &.selected-option {
      color: var(--color-black);
    }
  }

  .icon {
    ::v-deep svg {
      color: var(--color-primary);
    }

    &--up {
      transform: rotateZ(180deg);
    }
  }
}

.container {
  width: 200px;

  display: flex;
  flex-direction: column;

  position: absolute;
  top: -20px;
  height: 62px;

  .body {
    flex: 1;
    overflow-y: auto;

    @media all and (-ms-high-contrast: none) {
      min-height: 200px;
    }
  }

  .footer {
    flex-grow: 0;
    flex-shrink: 0;

    padding: 10px 14px 14px;
  }
}
</style>
