export const formMixin = {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    vars() {
      const vars = (this.form && this.form.vars) || {};
      if (!vars.attr) {
        vars.attr = {};
      }
      return vars;
    },
  },
};

export default formMixin;
