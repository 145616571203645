<i18n src="./translations"></i18n>
<template>
  <div :class="classes" :data-preload="[$options.name]">
    <div
      v-for="sectionName in filterSectionsArray"
      :key="sectionName"
      class="section"
    >
      <div class="section__header">
        {{ $t('catalog-filter-mobile-sections.' + sectionName.toLowerCase()) }}
      </div>

      <button
        v-for="filterName in filterSections[sectionName]"
        :key="filterName"
        class="section__filter-row qa-catalogFilter"
        @click="$emit('click', filterName)"
      >
        <div>
          <div class="section__filter-name">
            {{ allFilters[filterName].title }}
          </div>
          <div class="section__selected-value">
            {{ buildActiveFilters(filterName) }}
          </div>
        </div>
        <div class="section__icon">
          <Icon class="chevron" name="chevron_right" width="24" height="24" />
        </div>
      </button>
    </div>
  </div>
</template>
<script>
import globalMixin from 'Libs/mixins/globalMixin';
import Icon from 'Components/00-generated/Icon';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

export default {
  name: 'CatalogFilterMobileSections',
  components: { Icon },
  mixins: [globalMixin],
  props: {
    filterSections: {
      type: Object,
      default: () => ({}),
    },
    allFilters: {
      type: Object,
      default: () => ({}),
    },
    activeFilters: {
      type: Object,
      default: () => ({}),
    },
    activeSorting: {
      type: Object,
      default: null,
    },
  },
  computed: {
    filterSectionsArray() {
      return sortBy(Object.keys(this.filterSections));
    },
  },
  methods: {
    buildActiveFilters(filterName) {
      if (filterName === 'sorting' && this.activeSorting) {
        return this.activeSorting.title;
      }

      if (!this.activeFilters || !isArray(this.activeFilters[filterName])) {
        return '';
      }

      const optionLabels = this.activeFilters[filterName].map((optionName) => {
        return get(
          this.allFilters,
          `${filterName}.options.${optionName}.title`,
          ''
        );
      });

      return optionLabels.join(', ');
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'mixins';

.section {
  &__header {
    padding: 23px 0 10px 12px;
    border-bottom: 1px solid var(--color-alt);

    color: var(--color-nero);
    font-size: var(--font-size-M);
    line-height: 21px;
    font-weight: bold;
  }

  &__filter-row {
    width: 100%;
    height: 50px;
    padding: 0 12px;
    border-bottom: 1px solid var(--color-alt);
    font-size: var(--font-size-M);
    text-align: initial;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > *:first-child {
      overflow: hidden;
    }

    &:focus {
      outline-color: var(--color-primary);
    }
  }

  &__filter-name {
    text-transform: capitalize;
    color: var(--color-nero);
    line-height: 22px;
  }

  &__selected-value {
    line-height: 14px;

    color: var(--color-primary);

    font-size: var(--font-size-S);

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__icon {
    color: var(--color-primary);

    font-weight: bold;
    display: inline-flex;
  }
}
</style>
