<template>
  <button
    class="headerButton--newHeader"
    :class="[
      classes,
      {
        'headerButton--label': !!label,
        'headerButton--clicked': isActive,
      },
    ]"
    :tabindex="!href ? 0 : false"
    :title="title || !!title"
    v-bind="attrs"
    :data-preload="[$options.name]"
    @click="handleClick"
  >
    <Icon v-if="!!iconConfig" v-bind="iconConfig" />

    <client-only>
      <div v-if="count" class="bubble">
        <div class="bubbleInner" :class="`qa-${icon.name}BubbleInner`">
          {{ count }}
        </div>
      </div>
    </client-only>

    <span v-if="!!label" class="label" :class="{ expanded: !toggle }">{{
      label
    }}</span>
    <ChevronRightSvg
      v-show="toggle"
      class="toggleIcon qa-toggleIcon chevron-down"
    />
  </button>
</template>

<script>
import noop from 'lodash/noop';
import Icon from 'Components/00-generated/Icon';
import ChevronRightSvg from 'Components/00-generated/ChevronRightSvg';
import ClientOnly from 'vue-client-only';
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'HeaderButton',
  components: {
    Icon,
    ClientOnly,
    ChevronRightSvg,
  },
  mixins: [globalMixin],
  props: {
    disabled: {
      type: Boolean,
    },
    onClick: {
      type: Function,
      default: noop,
    },
    count: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
    icon: {
      type: [String, Object],
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    toggle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      componentType: this.href ? 'a' : 'button',
    };
  },
  computed: {
    iconConfig() {
      if (!!this.icon) {
        if (this.icon instanceof String) {
          return { name: this.icon };
        } else if (this.icon instanceof Object) {
          return this.icon;
        }
      }
      return false;
    },
  },
  methods: {
    handleClick(event) {
      if (this.onClick) {
        this.onClick(event);
      }

      if (!event.defaultPrevented && this.href) {
        location.assign(this.href);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'animations';
@import 'mixins';

.headerButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background: transparent;
  border: 1px solid var(--color-white);
  border-radius: var(--border-radius-default);
  cursor: pointer;
  transition:
    background-color var(--time-XS) ease-in-out,
    border-color var(--time-XS) ease-in-out;

  &:active {
    outline: none;
  }

  // TODO: remove when sytnthetic click added
  outline: none;

  @include activeState;

  ::v-deep {
    svg {
      width: 100%;
      max-width: 21px;
      max-height: 24px;
      height: auto;
      flex-shrink: 0;
    }

    svg,
    g,
    path {
      fill: var(--color-white);
    }
  }

  &:hover {
    background: rgba(var(--color-white), 0.2);
  }

  &--active {
    .bubble {
      z-index: 6;
    }
  }

  &--newHeader {
    margin-left: var(--space-1);
    border: none;
    flex-direction: column;

    &:hover {
      background: none;
    }

    .label {
      margin-left: 0;
      font-size: var(--font-size-SM);
    }

    @media #{$_mediaM} {
      margin-left: 0;
    }

    @media #{$_mediaMDown} {
      .label {
        font-size: var(--font-size-S);
      }
    }

    ::v-deep {
      svg {
        margin-right: 0;
      }
    }

    .bubble {
      top: 6px;
      left: 12px;

      width: 20px;
      height: 20px;
    }
  }

  .toggleIcon {
    display: none;
  }
}

.label {
  color: var(--color-white);
  margin-left: var(--space-1);
  font-size: 10px;
  transform: translateY(10%);
}

.bubble {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(33%, -50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.25s ease-in 0s 1;
  color: var(--color-white);
  font-size: 11px;
  font-weight: bold;

  background: var(--color-secondary);
}

@media #{$_mediaSDown} {
  .headerButton {
    width: 32px;
    height: 32px;

    &--newHeader {
      .label {
        display: unset;
        font-size: var(--font-size-S);
      }
    }
  }

  .bubble {
    width: 20px;
    height: 20px;
    top: 0;
    right: 0;
    letter-spacing: -0.5px;
  }
}

@media (min-width: 900px) {
  .headerButton {
    flex-direction: row;

    &--label {
      width: 100%;
    }

    .toggleIcon {
      display: flex;
      color: var(--color-white);
      fill: var(--color-white);
      transform: rotate(90deg);
      transform-origin: center;
      transition:
        opacity var(--time-S) ease-in-out,
        transform var(--time-S) ease-in-out;
      margin-top: 4px;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }

    &--clicked .toggleIcon {
      transform: rotate(270deg);
    }

    &--newHeader {
      &--label {
        .label {
          font-size: var(--font-size-M);
        }

        .expanded {
          padding-right: 8px;
        }
      }

      ::v-deep {
        svg {
          max-width: 40px;
          max-height: 40px;
          margin-right: 6px;

          @media #{$_mediaMDown} {
            margin-right: 0;
          }
        }
      }
    }
  }

  .label {
    display: block;
  }
}

@media #{$_mediaL} {
  .headerButton {
    flex-direction: column;

    .toggleIcon {
      display: none;
    }

    &--newHeader {
      margin-left: 0;

      .label {
        font-size: var(--font-size-S);
      }

      ::v-deep {
        svg {
          max-width: 21px;
          max-height: 21px;
          margin-right: 0;
        }
      }

      .bubble {
        top: 6px;
        left: 0;
        margin-left: var(--space-2--half);
      }
    }
  }
}
</style>
