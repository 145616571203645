<template>
  <div
    class="productImageItem"
    :class='{secondary: isSecondary}'
  >
    <div class="productWrapper">
      <div class="product">
        <div class="productInner">
          <AdvancedImage
            :is-lazy-component="false"
            :src="product.src"
            :alt="product.alt"
            :config="productConfig"
            modifier="centered"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import AdvancedImage from 'Components/01-atoms/advanced-image/AdvancedImage';

export default {
  name: 'ProductImageItem',
  components: { AdvancedImage },
  mixins: [globalMixin],
  props: {
    product: {
      type: Object,
      default: null,
    },
    isSecondary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productConfig: {
        q: 80,
        w: 500,
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.productImageItem {
  width: 100%;
  height: 100%;
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary {
  padding: 0;
}

.productWrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}

.product {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productInner {
  height: 100%;
  width: 100%;
}
</style>
