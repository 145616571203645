
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="ArrowDropDownSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 7"><path fill="currentColor" d="M4.2 6.9L8 .4V0H.2L0 .1a.3.3 0 000 .3l3.8 6.5.2.1.2-.1z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'ArrowDropDownSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
