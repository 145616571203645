const MAX_ITEM_COUNT = 5;

const limitItemCount = (messages) => {
  const start = Math.max(messages.length - MAX_ITEM_COUNT, 0);

  return messages.slice(start);
};

export const addMessage = (state, message) => {
  state.items = limitItemCount([
    ...state.items.filter((item) => item.key !== message.key),
    message,
  ]);
};

export const removeMessage = (state, uniqueKey) => {
  state.items = state.items.filter(
    (message) => message.uniqueKey !== uniqueKey
  );
};

export const removeDelayedMessage = (state, delayedMessageToRemove) => {
  const { items } = state;

  return {
    ...state,
    items: items.filter(({ text }) => text !== delayedMessageToRemove),
  };
};

export const translateErrorMessage = (state, message) => {
  state.errorMessage = message;
};
