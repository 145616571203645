var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.classes, { isNewFiltersLayout: _vm.showPlpTitle }],attrs:{"data-preload":[_vm.$options.name]}},[(_vm.showNewTitle && _vm.searchTerm)?_c('div',{staticClass:"searchingTerm"},[_vm._v("\n    "+_vm._s(_vm.$t('page-title.searchResult'))+":\n  ")]):_vm._e(),_vm._v(" "),_c('h1',{class:[
      'title',
      'qa-PageTitle',
      { 'title--reverse': _vm.theme === 'kfzteile-com' },
      { 'title--short': !!_vm.searchTerm && _vm.searchTerm.length < 4 },
      { 'title--plp': _vm.showPlpTitle },
    ]},[_vm._v("\n    "+_vm._s(_vm.showNewTitle && _vm.searchTerm ? `„${_vm.searchTerm}”` : _vm.title)+"\n  ")]),_vm._v(" "),(_vm.showNewTitle && _vm.productsCount > 0)?_c('div',{class:['productsCount', { 'productsCount--plp': _vm.showPlpTitle }]},[_vm._v("\n    ("+_vm._s(_vm.productsCount)+"\n    "+_vm._s(_vm.showPlpTitle ? _vm.$t('page-title.parts') : _vm.$t('page-title.articles'))+"\n    "),(_vm.carShortName && _vm.showPlpTitle)?_c('span',[_vm._v("\n      "+_vm._s(_vm.$t('page-title.for'))+" "+_vm._s(_vm.carShortName))]):_vm._e(),_vm._v(")\n  ")]):_vm._e(),_vm._v(" "),(_vm.subtitle)?_c('div',{staticClass:"subtitle"},[_vm._v("\n    "+_vm._s(_vm.subtitle)+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }