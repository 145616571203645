var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.classes,attrs:{"data-preload":[_vm.$options.name]}},[_c('div',{staticClass:"bannersRow"},[(_vm.primaryImage)?_c('div',{staticClass:"primary qa-CmsPrimarySecondaryBanners__primaryLink",attrs:{"title":_vm.primaryImage.link.title},on:{"click":function($event){_vm.trackPromoClick({
          href: _vm.primaryImage.link.href,
          position: _vm.primaryImage.position,
          callback: () => _vm.redirectTo(_vm.primaryImage.link.href),
        })}}},[_c('AdvancedImage',_vm._b({},'AdvancedImage',_vm.primaryImageConfig(),false))],1):_vm._e(),_vm._v(" "),(_vm.secondaryImage && _vm.secondaryImage.link && _vm.secondaryImage.link.href)?_c('div',{staticClass:"secondary-image",class:{
        secondary: _vm.primaryImage,
        primary: !_vm.primaryImage,
        'qa-CmsPrimarySecondaryBanners__secondaryLink': true,
      },attrs:{"title":_vm.secondaryImage.link.title},on:{"click":function($event){_vm.trackPromoClick({
          href: _vm.secondaryImage.link.href,
          position: _vm.secondaryImage.position,
          callback: () => _vm.redirectTo(_vm.secondaryImage.link.href),
        })}}},[_c('AdvancedImage',_vm._b({},'AdvancedImage',_vm.secondaryImageConfig(),false)),_vm._v(" "),_c('Button',{attrs:{"title":_vm.$t('cms-primary-secondary-banners.show_more'),"modifiers":['primary', 'nowrap']}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }