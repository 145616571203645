<i18n src="./translations"></i18n>

<template>
  <div
    ref="pdpActions"
    class="pdp-actions"
    :class="classes"
    :data-preload="[$options.name]"
  >
    <QuantitySelector
      v-if="isPlpOrSearchPage || (product.isBuyable && viewport !== 'mobile')"
      class="pdp-actions__quantity"
      :has-label="false"
      :has-vertical-divider="false"
      :darker="true"
      :product="product"
      :value="product.quantityAmount"
      :choices="product.quantityOptions"
      @onQuantityChange="handleQuantityChange($event)"
    >
      <Button
        class="pdp-actions__in-the-cart"
        modifier="primary"
        :on-click="handleCartClick"
        v-bind="addToCartButtonProps"
      />
    </QuantitySelector>

    <Button
      class="pdp-actions__to-wishlist qa-addToWishlistButton"
      :class="{ 'pdp-actions__to-wishlist--single': !product.isBuyable }"
      modifier="secondary"
      icon="wishlist_heart"
      :title="$t('pdp-actions.to_wishlist')"
      :on-click="handleWishlistClick"
    />

    <portal
      v-if="viewport === 'mobile' && product.isBuyable && !isPlpOrSearchPage"
      to="product-cart-group"
    >
      <div class="cart-group-portal">
        <QuantitySelector
          class="pdp-actions__quantity pdp-actions__cart-group pdp-actions__cart-group--mobile"
          :has-label="false"
          :has-vertical-divider="false"
          :darker="true"
          :product="product"
          :choices="product.quantityOptions"
          :value="product.quantityAmount"
          @onQuantityChange="handleQuantityChange($event)"
        >
          <Button
            class="pdp-actions__in-the-cart"
            modifier="primary"
            :on-click="handleCartClick"
            v-bind="addToCartButtonProps"
          />
        </QuantitySelector>
      </div>
    </portal>
  </div>
</template>

<script>
import QuantitySelector from 'Components/01-atoms/quantity-selector/QuantitySelector';
import globalMixin from 'Libs/mixins/globalMixin';
import Button from 'Components/01-atoms/button/Button';
import { mapState } from 'vuex';
import get from 'lodash/get';

export default {
  name: 'PdpActions',
  components: { QuantitySelector, Button },
  mixins: [globalMixin],
  props: {
    product: {
      type: Object,
      default: () => ({
        sku: '',
        quantityAmount: 1,
        isBuyable: true,
      }),
      required: true,
    },
    addToCartButtonProps: {
      default: () => ({
        class: '',
        title: '',
        icon: { name: '', height: '', width: '' },
        disabled: false,
      }),
      type: Object,
    },
  },
  data() {
    return {
      quantity: '1',
    };
  },
  computed: {
    ...mapState('core', { viewport: ({ viewport: { range } }) => range }),
    ...mapState('productsList', {
      isPlpOrSearchPage: (state) => {
        const type = get(state, 'data.plp.pageType');
        return (
          ['productListing', 'search', 'brandProductListing'].indexOf(type) !==
          -1
        );
      },
    }),
  },
  mounted() {
    this.quantity = this.product.quantityAmount;
  },
  methods: {
    handleQuantityChange({ quantity }) {
      this.quantity = quantity;
      this.$emit('quantity-change', quantity);
    },
    handleCartClick() {
      this.$emit('cart-click', this.quantity);
    },
    handleWishlistClick() {
      this.$emit('wishlist-click');
    },
  },
};
</script>

<!-- Scoped Styling -->
<style scoped lang="scss">
@import 'mixins';
@import 'variables/breakpoints';

.pdp-actions {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  --row-height: 48px;
}

.pdp-actions__quantity {
  width: 100%;

  ::v-deep {
    .select {
      height: var(--row-height);
      padding-left: var(--space-2);
      border-color: var(--color-silver-lining) !important;
    }

    .icon {
      color: var(--color-secondary);
    }

    .input {
      height: var(--row-height);
      padding-top: 0;
    }
  }
}

::v-deep .button {
  .inner {
    display: flex;
  }

  .title {
    flex-basis: 100%;
    margin: 0;

    @include typography-size(var(--font-size-M), var(--font-size-MLL));
  }
}

.pdp-actions__in-the-cart {
  height: var(--row-height);
  margin-top: var(--space-1);
  flex-grow: 1;
}

.pdp-actions__to-wishlist {
  height: var(--row-height);
  flex-basis: 100%;
  color: var(--color-rhino-without-horn);
  border-color: var(--color-silver-lining);

  &--single {
    margin-top: 0;
  }

  ::v-deep {
    .wishlistSvg path {
      fill: var(--color-rhino-without-horn);
    }
  }
}

@media #{$_mediaMDown} {
  .pdp-actions__in-the-cart {
    ::v-deep {
      .inner {
        padding: 0 var(--space-1);
      }
    }
  }

  .cart-group-portal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
    padding: var(--space-1) var(--space-2);
    background-color: var(--color-wild-sandish);
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);

    --row-height: 48px;
  }
}
</style>
