
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="CheckCircleSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path fill="#028A00" fill-rule="evenodd" d="M14.33 7.05c0-.21-.067-.38-.2-.514L13.113 5.53a.693.693 0 00-.502-.21c-.194 0-.36.07-.503.21l-4.554 4.544L5.033 7.55a.686.686 0 00-.502-.21c-.192 0-.36.07-.5.21L3.012 8.557a.694.694 0 00-.2.513c0 .2.066.367.2.5l4.04 4.042c.142.14.31.212.503.212.2 0 .372-.07.513-.212l6.06-6.06a.688.688 0 00.2-.503zm2.813 1.807a8.38 8.38 0 01-1.15 4.303 8.534 8.534 0 01-3.12 3.12 8.394 8.394 0 01-4.302 1.15 8.39 8.39 0 01-4.3-1.15 8.534 8.534 0 01-3.12-3.12A8.374 8.374 0 010 8.857c0-1.555.383-2.99 1.15-4.302a8.527 8.527 0 013.12-3.12 8.381 8.381 0 014.3-1.15c1.556 0 2.99.384 4.304 1.15a8.542 8.542 0 013.12 3.12 8.387 8.387 0 011.15 4.302z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'CheckCircleSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
