import Vue from 'vue';
import Vuex from 'vuex';
import VueI18n from 'vue-i18n';
import vClickOutside from 'v-click-outside';
import PortalVue from 'portal-vue';
import VueVirtualScroller from 'vue-virtual-scroller';

import TranslationFormat from './TranslationFormat';
import { createStore } from '../store/store';
import { setupErrorHandling } from './helpers/error-handler';

const appFactory = (App, coreState) => {
  Vue.use(Vuex);
  Vue.use(VueI18n);
  Vue.use(vClickOutside);
  Vue.use(VueVirtualScroller);
  Vue.use(PortalVue);

  const { enableTranslations = true, salesChannel } = coreState;

  if (!enableTranslations) {
    VueI18n.prototype._t = (key) => key;
  }

  const i18n = new VueI18n({
    locale: salesChannel.locale,
    formatter: new TranslationFormat(),
  });

  setupErrorHandling(Vue);

  const store = createStore({ coreState });

  const app = new Vue({
    i18n,
    store,
    render: (createElement) => createElement(App),
  });

  return { app, store };
};

export default appFactory;
