
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="ArrowOpenModalSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="#174287" d="M3.557 16A3.56 3.56 0 010 12.445V3.556A3.561 3.561 0 013.557 0H4.84a.89.89 0 010 1.779H3.557a1.78 1.78 0 00-1.779 1.777v8.889a1.781 1.781 0 001.779 1.777h8.889a1.781 1.781 0 001.778-1.777v-1.284a.889.889 0 111.777 0v1.284A3.558 3.558 0 0112.446 16zm3.026-6.581a.887.887 0 010-1.257l6.382-6.384H9.334a.89.89 0 010-1.778h5.777A.889.889 0 0116 .89v5.778a.889.889 0 11-1.777 0V3.036L7.84 9.419a.891.891 0 01-1.257 0z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'ArrowOpenModalSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
