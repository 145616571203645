<template>
  <div class="totalColumns" :data-preload="$options.name">
    <div v-for="(item, index) in items" :key="index" class="items itemsInner">
      <div
        role="heading"
        aria-level="2"
        class="headline"
        @click="expandHandler(index)"
      >
        <span class="title">
          {{ item.headline }}
        </span>
        <ChevronRightSvg
          class="headlineIcon"
          :class="{ rotateArrow: expandList[index] }"
          width="30"
          height="30"
        />
      </div>

      <TransitionExpand
        :expand="expandList[index]"
        :initial-expand-state="initialExpandState"
        :class="{ expanded: expandList[index] }"
        max="768"
      >
        <div
          v-for="(link, linkIndex) in item.links"
          :key="linkIndex"
          class="link-wrapper"
        >
          <a
            class="itemLink"
            :href="link.pageUrl"
            :target="link.target || null"
            :rel="link.target === '_blank' ? 'noreferrer noopener' : null"
            v-text="link.urlText"
          />
        </div>
        <div class="itemSlot item">
          <slot />
        </div>
      </TransitionExpand>
    </div>
  </div>
</template>

<script>
import TransitionExpand from 'Components/01-atoms/transition-expand/TransitionExpand';
import globalMixin from 'Libs/mixins/globalMixin';
import ChevronRightSvg from 'Components/00-generated/ChevronRightSvg.vue';

export default {
  name: 'LinkBox',
  components: {
    TransitionExpand,
    ChevronRightSvg,
  },
  mixins: [globalMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    initialExpandState: Boolean,
  },
  data: () => ({
    expandList: [false, false, false, false, false],
  }),
  computed: {},
  methods: {
    expandHandler(index) {
      const mediumMax = window.matchMedia('(max-width: 768px)').matches;
      if (mediumMax) {
        this.$set(this.expandList, index, !this.expandList[index]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.totalColumns {
  @include pageWidth;
  @include pagePadding;

  padding-top: 24px;
  column-count: 5;
  column-gap: var(--space-LL);
  width: 100%;

  .itemsInner {
    break-after: column;
  }
}

.headline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  gap: 8px;
  font-weight: 700;
  font-size: var(--font-size-MML);
  color: var(--color-nero);
  line-height: 22px;
  margin: -1px 0;
}

.headlineIcon {
  margin-right: 8px;
  transform-origin: center;
  transform: rotate(-270deg);
  transition: transform var(--time-S) ease-in-out;
  width: 16px;
  color: var(--color-secondary);

  ::v-deep path {
    fill: var(--color-secondary);
  }
}

.rotateArrow {
  transform: rotate(-90deg);
}

.itemsInner {
  text-align: left;
}

.items {
  overflow: hidden;
  transition: height var(--time-S) ease-in-out;
}

.link-wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}

::v-deep .itemLink {
  color: var(--color-rebranding-black-out);
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  line-height: 22px;
  overflow-wrap: break-word;
  overflow: auto;
}

.itemSlot:empty {
  display: none;
  margin-top: 0;
}

@media #{$_mediaMDown} {
  .linkBox {
    padding: 0 8px;
  }

  .totalColumns {
    column-count: 1;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    border-top: 1px solid var(--color-alto-light);
  }

  .headline {
    cursor: pointer;
  }

  .itemsInner {
    break-after: avoid !important;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 1px solid var(--color-alto-light);
  }

  .arrow {
    width: 18px;
    display: inherit;
    margin: 5px 0;
  }

  ::v-deep .itemLink {
    line-height: 22px;
    margin: 0;
  }

  .title {
    padding-left: 0;
    line-height: unset;
  }
}

@media #{$_mediaLUp} {
  .headlineIcon {
    display: none;
  }

  .items {
    max-height: none;
  }
}
</style>
