<template>
  <button
    :class="'active-brand-filter-item'"
    :data-preload="[$options.name]"
    @click="$emit('click')"
  >
    <Icon name="close" :class="['icon-close']" />
    <span class="value">{{ value }}&nbsp;</span>&#40;
    <span class="count">{{ count }}</span
    >&#41;
  </button>
</template>

<script>
import Icon from 'Components/00-generated/Icon';

export default {
  name: 'ActiveBrandFilterItem',
  components: {
    Icon,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    count: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.active-brand-filter-item {
  background-color: var(--color-white);
  border: 1px solid var(--color-boulderish);
  text-transform: uppercase;
  border-radius: 3px;
  display: inline-flex;
  align-items: flex-start;
  font-size: 14px;
  max-height: 30px;
  padding: 5px 7px 7px;
  margin-right: 10px;
  transition: all var(--time-XS) ease-in-out;
  margin-bottom: 10px;

  strong {
    font-weight: bold;
  }

  .icon-close {
    width: 16px;
    height: 33px;
    stroke: var(--color-hippo-darker);
    stroke-width: 2px;
    opacity: 0.5;
    margin-right: var(--space-0--half);
  }

  &:hover {
    background-color: var(--color-secondary);

    color: var(--color-white);
    cursor: pointer;

    strong {
      color: var(--color-white);
    }

    .icon-close {
      stroke: var(--color-white);
    }
  }

  &:focus {
    outline: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

@media #{$_mediaLUp} {
  .active-brand-filter-item {
    margin-bottom: var(--space-1--half);
    align-items: center;
  }
}

@media #{$_mediaLDown} {
  .active-brand-filter-item {
    padding: 5px 10px;
    font-size: var(--font-size-S);
    line-height: 14px;

    .icon-close {
      height: 13px;
    }
  }
}
</style>
