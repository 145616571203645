var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.classes,
    {
      opened: _vm.isOpened || _vm.isOptionSelected,
      'qa-catalogCategorySearchFilter': true,
    },
  ],attrs:{"data-preload":[_vm.$options.name]},on:{"mousedown":_vm.toggleContainer}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"name"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isOptionSelected),expression:"!isOptionSelected"}],staticClass:"label"},[_vm._v("\n      "+_vm._s(_vm.$t('catalog-category-search-dropdown.select'))+"\n    ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOptionSelected),expression:"isOptionSelected"}],staticClass:"label selected-option"},[_vm._v("\n      "+_vm._s(_vm.selectedOptionWithCount())+"\n    ")]),_vm._v(" "),_c('ChevronRightSvg',{class:['toggleIcon', 'qa_chevron-down', { 'opened-arrow': _vm.isOpened }]})],1),_vm._v(" "),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
      handler: _vm.handleSubmit,
      isActive: _vm.isOpened,
      events: ['click'],
    }),expression:"{\n      handler: handleSubmit,\n      isActive: isOpened,\n      events: ['click'],\n    }"}],staticClass:"container"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"select-options",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.handleChange($event)}],"click":function($event){_vm.isOpened = false}}},[_c('option',{class:['disabled-option'],attrs:{"disabled":"","value":""}},[_vm._v("\n        "+_vm._s(_vm.$t('catalog-category-search-dropdown.select-one'))+"\n      ")]),_vm._v(" "),_vm._l((_vm.options),function(option,index){return _c('option',{key:index,domProps:{"value":option.alias}},[_vm._v("\n        "+_vm._s(option.alias)+" ("+_vm._s(option.count)+")\n      ")])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }