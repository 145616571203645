<i18n src="./translations"></i18n>

<template>
  <div class="price-discounted" :class="{ is21: is21 }">
    <div v-if="isU2D" class="price-discounted--discount">
      <span>{{ $t('price-discounted.action') }}</span>
      <span>{{ priceTexts.upToDiscountPercentage }}%</span>
    </div>

    <Price
      :label="label"
      :value="discountedPrice"
      is-net
      :is-kvi="isKvi"
      class="price-discounted--price mainPrice qa-PriceInfo__mainPrice"
      :modifiers="priceModifiers"
    />

    <div v-if="isU2D" class="price-discounted--instead-of">
      <span>{{ $t('price-discounted.instead_of') }}</span>
      <span class="price-discounted--instead-of_price">
        {{ originalPrice }}
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';

import Price from 'Components/01-atoms/price/Price';

export default {
  name: 'PriceDiscounted',
  components: { Price },
  mixins: [globalMixin],
  props: {
    priceTexts: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    unitPrices: {
      type: Array,
      required: true,
      default: () => [],
    },
    prices: {
      type: Object,
      required: true,
      default: () => {},
    },
    priceModifiers: {
      type: Array,
      default: () => [],
    },
    is21: {
      type: Boolean,
      default: false,
    },
    isKvi: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('core', ['isBusinessCustomer']),
    isU2D() {
      const upToDiscountPercentage = this.priceTexts.upToDiscountPercentage;
      return upToDiscountPercentage && upToDiscountPercentage > 0;
    },
    discountedPrice() {
      if (!this.isBusinessCustomer) {
        return this.priceTexts.discountedSalesValueGross;
      }
      /* TBD-[Mina]: should be replaced with discountedSalesValueNet when the BE addes it
       priceNet - (priceNet * upToDiscountPercentage ) */
      const priceNetLocalized = this.unitPrices[1]?.price;
      if (!priceNetLocalized) return '';

      const priceNet = this.prices.priceNet;
      const isComma = priceNetLocalized.includes(',');
      const currency = priceNetLocalized
        .split(/(\s+)/)
        .filter((e) => e.trim().length > 0)[1];
      const discountPercentage = this.priceTexts.upToDiscountPercentage / 100;
      // Calc discount
      let discountedSalesValueNet = (
        priceNet -
        priceNet * discountPercentage
      ).toFixed(2);
      // Localize it same as priceNetLocalized
      discountedSalesValueNet = isComma
        ? discountedSalesValueNet.toString().replace('.', ',')
        : discountedSalesValueNet;
      // Add currency same as priceNetLocalized
      discountedSalesValueNet = currency
        ? `${discountedSalesValueNet} ${currency}`
        : discountedSalesValueNet;

      return discountedSalesValueNet;
    },
    originalPrice() {
      return !this.isBusinessCustomer
        ? this.unitPrices[0]?.price
        : this.unitPrices[1]?.price;
    },
    label() {
      return this.unitPrices[0]?.label || '';
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.price-discounted {
  position: relative;

  &--discount {
    display: flex;
    justify-content: center;
    gap: 0 3px;
    font-size: var(--font-size-SM);
    font-weight: bold;
    max-width: 100px;
    text-align: center;
    color: var(--color-white);
    background: var(--color-monza-similar);
    border-radius: 2px;
    padding: 3px var(--space-1);
    margin-bottom: var(--space-0--half);

    &::after {
      content: '';
      position: absolute;
      top: var(--space-2--half);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid var(--color-monza-similar);
    }
  }

  &--price {
    font-weight: bold;
    color: var(--color-nero);
    font-size: var(--font-size-LXL);
    line-height: var(--font-size-XL);
  }

  &--business-price {
    color: var(--color-nero);
    font-size: var(--font-size-ML);
    font-weight: bold;
    margin-top: var(--space-1);

    &--label {
      font-size: var(--font-size-M);
      font-weight: normal;
    }
  }

  &--instead-of {
    margin-bottom: var(--space-1);

    &_price {
      text-decoration: line-through;
    }
  }

  &.is21 .price-discounted--price {
    &.price--highlighted,
    &.price--outdated,
    &.price--big {
      color: var(--color-nero);
      font-size: var(--font-size-LXL);
    }
  }
}
</style>
