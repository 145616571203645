<i18n src="./translations"></i18n>

<template>
  <div
    :class="[
      classes,
      { 'carisBar--cockpit': expanded, 'carisBar--selected': isCarSelected },
    ]"
    :data-preload="[$options.name]"
    data-prefetch="CarisFilter"
  >
    <div class="vehicle">
      <button
        type="button"
        class="inner inner--trigger qa-CarisBar__trigger"
        @click.prevent="toggleCaris"
        @mouseup="onMouseup"
      >
        <ChevronRightSvg class="toggleIcon qa-toggleIcon chevron-down" />
        <Icon name="car" class="car" widht="30" height="13" />
        <client-only>
          <div class="text qa-CarisBar__text">
            {{ carShortName || $t('caris-bar.select') }}
          </div>
        </client-only>
      </button>
      <client-only>
        <button
          v-if="isCarSelected"
          type="button"
          class="btn-unselect qa-CarisBar__deselect"
          :title="$t('caris-bar.unselect_dialog.title')"
          @click.prevent="showDialog"
        >
          <DeleteSvg :class="['unselectIcon', 'delete']" />
        </button>
      </client-only>
    </div>

    <client-only>
      <div :class="['inner', 'inner--holder']">
        <div class="cockpitContainer">
          <div class="cockpitInner">
            <CarisCockpit
              v-if="expanded"
              :vehicle-count="vehicleCount"
              :is-logged-in="isLoggedIn"
              :vehicle="vehicle"
              :car-display-name="carDisplayName"
              :is-car-saved="isCarSaved"
              :is-car-selected="isCarSelected"
              :toggle-filter-handler="toggleFilterHandler"
            />
          </div>
        </div>
      </div>
    </client-only>

    <portal to="carisFilter" :disabled="portalDisabled">
      <client-only>
        <div :class="['filtersContainer', { filters: filters }]">
          <div class="filtersInner">
            <CarisFilter
              v-if="filters"
              :toggle-filter-handler="toggleFilterHandler"
            />
          </div>
        </div>
      </client-only>
    </portal>
    <Dialog
      ref="dialog"
      v-bind="unselectDialogConfig"
      :title="$t('caris-bar.unselect_dialog.title')"
      :click-outside="true"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="$t('caris-bar.unselect_dialog.message')" />
    </Dialog>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import ClientOnly from 'vue-client-only';
import { mapActions, mapState } from 'vuex';
import scrollMixin from 'Libs/mixins/scrollMixin';
import { buttonMixin } from 'Libs/mixins/buttonMixin';
import Icon from 'Components/00-generated/Icon';
import ChevronRightSvg from 'Components/00-generated/ChevronRightSvg';
import DeleteSvg from 'Components/00-generated/DeleteSvg';
import globalMixin from 'Libs/mixins/globalMixin';
import Dialog from 'Components/02-molecules/dialog/Dialog';
import UrlHelper from 'Libs/helpers/url';
import get from 'lodash/get';

export default {
  name: 'CarisBar',
  components: {
    CarisCockpit: () =>
      import(
        'Components/03-organisms/caris-cockpit/CarisCockpit' /* webpackChunkName: "CarisCockpit" */
      ),
    CarisFilter: () =>
      import(
        'Components/02-molecules/caris-filter/CarisFilter' /* webpackChunkName: "CarisFilter" */
      ),
    ClientOnly,
    Icon,
    ChevronRightSvg,
    DeleteSvg,
    Dialog,
  },
  mixins: [globalMixin, scrollMixin, buttonMixin],
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    portalDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('carSelection', {
      carDisplayName: (state) => state.data.carDisplayName,
      carShortName: (state) => {
        let name =
          !!state.data.vehicle &&
          state.data.vehicle.manufacturer + ' ' + state.data.vehicle.model;
        if (!!state.data.vehicle && !!state.data.vehicle.generation) {
          name += ' ' + state.data.vehicle.generation;
        }
        return name;
      },
      isCarSelected: (state) => {
        const carName = state.data.carDisplayName;
        return !!carName;
      },
      vehicleCount: (state) => state.data.vehicleCount,
      isLoggedIn: (state) => state.data.isLoggedIn,
      vehicle: (state) => state.data.vehicle,
      isCarSaved: (state) =>
        !isEmpty(state.data.vehicle) && state.data.isCarSaved,
      filters: (state) => state.filters,
    }),
    ...mapState('core', {
      placeholders: (state) => state.placeholders,
    }),
    unselectDialogConfig() {
      return {
        'cancel-button': {
          title: this.$t('caris-bar.unselect_dialog.cancel'),
        },
        'confirm-button': {
          title: this.$t('caris-bar.unselect_dialog.reset'),
          onClick: this.onClickUnselect,
        },
      };
    },
  },
  mounted() {
    if (!isEmpty(this.vehicle)) {
      this.trackSelectedCar({
        vehicle: this.vehicle,
      });
    } else {
      const isMobile = window.matchMedia('(max-width: 768px)').matches;

      if (
        (!isMobile &&
          this.isPage('ProductDetails') &&
          this.productNeedsCarSelection()) ||
        (this.isPage('ProductList') && this.plpNeedsCarSelection())
      ) {
        this.showCaris();
      }
    }

    const url = window.location.href;
    if (url.indexOf('#')) {
      const searchType = url.substring(url.indexOf('#') + 1);
      const searchList = {
        keyno: 'identifyCar_completedKeyNo',
        plate: 'identifyCar_completedNumberplate',
        nat: 'identifyCar_completedNat',
        garage: 'identifyCar_accountSelection',
      };

      if (searchType in searchList) {
        this.trackCarSelection({
          vehicle: this.vehicle,
          event: searchList[searchType],
        });
        UrlHelper.clearLocationHash();
      }
    }

    if (this.expanded) {
      const isMobile = window.matchMedia('(max-width: 768px)').matches;

      if (isMobile) {
        this.toggleCaris();
      } else {
        this.trackAutoExpand();
      }
    }

    this.$root.$on('toggleCaris', this.toggleCaris);
  },
  beforeDestroy() {
    this.$root.$off('toggleCaris', this.toggleCaris);
  },
  methods: {
    ...mapActions('carSelection', [
      'toggleFilters',
      'toggleExpanded',
      'loadCarisData',
      'setAutoExpand',
      'unselectCar',
      'showCaris',
    ]),
    ...mapActions('dataLayer', [
      'trackCarSelection',
      'trackSelectedCar',
      'trackAutoExpand',
    ]),
    isPage(pageName) {
      const pageType = get(this.placeholders, 'content.component');
      return pageName === pageType;
    },
    productNeedsCarSelection() {
      try {
        return this.placeholders.content.data.pdpCarBar.key;
      } catch {
        return false;
      }
    },
    plpNeedsCarSelection() {
      try {
        return this.placeholders.content.data.plp.hasCarSpecificProducts;
      } catch {
        return false;
      }
    },
    toggleCaris() {
      if (window.matchMedia('(max-width: 768px)').matches) {
        this.toggleBodyScrolling(this.expanded);
      }

      this.setAutoExpand(!this.expanded);
      this.toggleExpanded();
    },
    toggleFilterHandler({ step }) {
      this.toggleBodyScrolling(this.filters);
      this.toggleFilters({ step });
      this.loadCarisData({ step: 0 });
    },
    showDialog() {
      this.$refs.dialog.show();
    },
    onClickUnselect() {
      this.unselectCar();
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';
@import 'animations';

.carisBar {
  display: block;
  position: relative;
  z-index: 3;

  .cockpitInner {
    position: relative;
    z-index: 1;
    width: 100vw;
    max-width: 325px;
    opacity: 0;

    transition: opacity var(--time-XS) ease-in-out;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      opacity: 0;
      pointer-events: none;

      transition: opacity var(--time-S) ease-in-out;
    }
  }

  &--cockpit {
    .inner {
      &.inner--trigger {
        z-index: 3;
        position: relative;
      }
    }

    .cockpitContainer {
      pointer-events: all;
      transform: translate(0, 100%);

      transition: transform var(--time-XS) ease-out var(--time-XS);
    }

    .cockpitInner {
      opacity: 1;
      transition: opacity var(--time-XS) ease-out var(--time-XS);
    }
  }

  &--selected {
    .text {
      font-size: var(--font-size-M);
      line-height: 18px;
      max-height: 28px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .toggleIcon {
    color: var(--color-white);
    fill: var(--color-white);
    transform: rotate(90deg);
    transform-origin: center;
    transition:
      opacity var(--time-S) ease-in-out,
      transform var(--time-S) ease-in-out;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  &--cockpit .toggleIcon {
    transform: rotate(-90deg);
  }

  .btn-unselect {
    outline: none;
    cursor: pointer;
    padding-right: 0;

    @include shrinkButtonToSVG();
  }

  .unselectIcon {
    color: var(--color-white);
    fill: var(--color-white);
    transition:
      opacity var(--time-S) ease-in-out,
      transform var(--time-S) ease-in-out;
    width: 17px;
    height: 19px;
    margin-left: auto;
    flex-shrink: 0;
  }
}

.inner {
  &.inner--trigger {
    position: relative;
    z-index: 3;
    padding: 0;
    display: flex;
    align-items: center;
    height: 54px;
    width: 100%;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  &.inner--holder {
    position: relative;
    z-index: 2;
  }
}

.vehicle {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  height: 100%;
  min-height: 54px;
  min-width: 320px;
  outline: none;
  cursor: pointer;
  padding: 0 16px 0 10px;
  transition: background-color var(--time-S) ease-in-out;

  background-color: var(--color-secondary);
}

.car {
  color: var(--color-white);
  flex-shrink: 0;
  margin-right: 14px;
  margin-left: var(--space-1);
  width: 30px;
}

.chevron {
  flex-shrink: 0;
}

.text {
  flex-grow: 1;
  font-weight: bold;
  color: var(--color-white);
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75%;
  line-height: 18px;
  text-align: left;
  animation: fadeIn 0.2s ease-in 0s 1;
}

.garage {
  position: relative;
  z-index: 1;
  height: 100%;
  padding: 0 14px;
  display: flex;
  align-items: center;

  @include themeColor(
    background-color,
    var(--color-gold-drop),
    var(--color-tory-blue) Dark,
    var(--color-chathamsblue)
  );

  svg {
    width: 28px;
  }
}

.cockpitContainer {
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  cursor: default;
  pointer-events: none;
  transform: translateY(100%) translateY(-50px);
  transition: transform var(--time-XS) ease-in-out var(--time-S);

  @media all and (-ms-high-contrast: none) {
    transition: none !important;
  }
}

@supports (-ms-ime-align: auto) {
  .cockpitContainer {
    transform: translateY(100%);
    transition: transform var(--time-XS) ease-in-out var(--time-S);
  }
}

.filters {
  &.filtersContainer {
    opacity: 1;
    pointer-events: all;
    transform: translate(0, 0);

    &::before {
      opacity: 1;
    }
  }

  .cockpitInner {
    &::before {
      opacity: 1;
    }
  }
}

.filtersContainer {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  height: 100vh;
  transform: translate(101%, 0);
  pointer-events: none;

  transition: transform var(--time-S) ease-in-out;

  &::before {
    content: '';
    width: 200vw;
    height: 110vh;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background: var(--color-black--secondary);
    opacity: 0;

    transition: opacity var(--time-S) ease-in-out;
  }
}

.filtersInner {
  position: relative;
  z-index: 2;
  background: var(--color-white);
  overflow: hidden;
  box-shadow: 0 0 6px 0 rgba(51, 51, 51, 0.3);
  width: 100vw;
  max-width: 420px;
}

@media #{$_mediaMDown} {
  .carisBar {
    z-index: 2;

    &--cockpit {
      .cockpitContainer {
        opacity: 1;
        pointer-events: all;
        transform: translate(0, 0);
      }
    }

    .cockpitInner {
      position: relative;
      z-index: 2;
      background: var(--color-white);
      box-shadow: 0 0 24px 0 rgba(51, 51, 51, 0.3);
      max-width: none;
    }
  }

  .filtersInner {
    max-width: none;
  }

  .cockpitContainer {
    position: fixed;
    z-index: 999;
    bottom: auto;
    height: 100vh;
    transform: translate(100%, 0);

    transition: transform var(--time-S) ease-in-out;
  }

  .filtersContainer {
    &::before {
      background: transparent;
    }
  }
}

@media #{$_mediaMUp} {
  .inner.inner--trigger {
    height: 54px;
  }
}

@media #{$_mediaLUp} {
  .carisBar {
    width: 320px;
    min-width: 320px;
  }

  .inner {
    position: relative;
  }

  .carisBar {
    .cockpitInner {
      max-width: 320px;
    }
  }
}
</style>
