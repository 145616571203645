<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <ul class="categories qa-CategoryButton__categories">
      <li
        v-for="category in menu.menuItems"
        :key="category.title"
        class="category qa-CategoryButton__category"
      >
        <CategoryItem
          :title="category.title"
          :url="appendKtypnr(category.url)"
          :image-url="createImageURL(category.imageUrl || '')"
          source="SeoCarPage"
        />
      </li>
      <li />
      <li />
    </ul>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { mapState } from 'vuex';
import CategoryItem from 'Components/01-atoms/category-item/CategoryItem';

export default {
  name: 'CategoriesRow',
  components: {
    CategoryItem,
  },
  mixins: [globalMixin],
  props: {
    menu: {
      type: Object,
      required: true,
    },
    source: {
      type: String,
      default: null,
    },
    showProdCount: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('carSelection', {
      ktypnr: ({ data }) => data && data.vehicle && data.vehicle.ktypnr,
    }),
    ...mapState('core', {
      staticUrl: ({ salesChannel: { staticUrl } }) => staticUrl,
    }),
  },
  methods: {
    appendKtypnr(url) {
      return this.ktypnr && this.ktypnr !== 0
        ? `${url}?ktypnr=${this.ktypnr}`
        : url;
    },
    createImageURL(imageUrl) {
      let fullImageURL = imageUrl;
      const isAbsoluteUrl = imageUrl.startsWith('http');
      if (!isAbsoluteUrl) {
        fullImageURL = `${this.staticUrl}/media-library/${imageUrl}`;
      }
      return fullImageURL;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.categoriesRow {
  background: var(--color-white);
  width: 100%;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media #{$_mediaMDown} {
    display: flex;
    flex-wrap: wrap;
  }
}

.category {
  width: calc(33.33% - 10px);
  margin: 0 8px 8px 0;

  @media #{$_mediaMDown} {
    width: 100%;
    max-width: none;
    height: 100px;
    margin-bottom: 0;
    position: relative;
  }
}

.products-count {
  font-size: 14px;

  @media #{$_mediaMDown} {
    position: absolute;
    right: calc(100% - 225px);
    bottom: 19px;
    width: 130px;
    font-size: 10px;
  }
}
</style>
