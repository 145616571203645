export default {
  overlay: {
    on: false,
    fading: false,
  },
  searchBar: {
    searchTerm: '',
    completions: [],
    open: false,
  },
  mobileSideNav: {
    open: false,
  },
  navigation: {
    open: false,
  },
  miniCart: {
    open: false,
  },
  latestAddedProductNotification: {
    open: false,
    productId: null,
  },
  accountWidget: {
    open: false,
  },
  discount: {
    show: false,
    fields: {
      headline: '',
      content: '',
      finePrint: '',
    },
  },
  unavailableProducts: {
    show: false,
    products: [],
    cartId: '',
  },
};
