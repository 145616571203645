import { mapState } from 'vuex';
import lowerFirst from 'lodash/lowerFirst';
import kebabCase from 'lodash/kebabCase';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';

/** Changed by rollup.package.js */
const isPackage = 'ROLLUP_ENV' === 'PACKAGE';

const globalMixin = {
  data() {
    return {
      urls: [],
      bundles: [],
      addKebabCaseClass: false,
      modifiersArray: [...this.modifiers, this.modifier],
    };
  },
  computed: {
    ...(isPackage
      ? {}
      : mapState('core', {
        staticUrl: (state) => state.salesChannel.staticUrl,
        publicPath: (state) => state.salesChannel.publicPath,
        isMobileSalesChannel: (state) =>
          state.salesChannel.isMobileSalesChannel,
        isDebug: ({ requestData: { queryParams } }) =>
          queryParams?.debug?.[0] === 'true',
      })),
    classes: {
      get() {
        const rootClass = lowerFirst(this.$options.name);
        const kebabClass = kebabCase(this.$options.name);
        const classes = [rootClass];

        if (this.addKebabCaseClass) {
          classes.push(kebabClass);
        }

        this.modifiersArray.forEach((modifier) => {
          if (modifier) {
            classes.push(
              `${this.addKebabCaseModifier ? kebabClass : rootClass
              }--${modifier}`
            );
          }
        });

        return classes;
      },
      set(modifiers) {
        this.modifiersArray = modifiers;
      },
    },
    attrs() {
      const { title } = this;

      return {
        title,
      };
    },
  },
  props: {
    addKebabCaseModifier: {
      type: Boolean,
      default: false,
    },
    modifier: {
      type: String,
    },
    modifiers: {
      type: Array,
      default: () => [],
    },
    gtmClassName: {
      type: String,
    },
    ...(isPackage
      ? { translations: { type: Object, default: () => ({}) } }
      : {}),
  },
  methods: {
    generateUID(prefix) {
      return uniqueId(prefix);
    },
    resolveFile(path) {
      return `${this.publicPath}${path}`;
    },
    addModifier(modifierName) {
      this.classes = [...this.modifiersArray, modifierName].filter(
        (element, index, array) => array.indexOf(element) === index
      );
    },
    removeModifier(modifierName) {
      this.classes = this.modifiersArray.filter((itemModifier) => {
        return itemModifier !== modifierName;
      });
    },
    ...(isPackage
      ? {
        $t(key) {
          return get(this.translations, key, key);
        },
      }
      : {
        fetchUrls(urlKeys) {
          return this.$store.dispatch('core/fetchUrls', { urlKeys });
        },
        frontFacingPath(urlKey) {
          const path = this.$store.getters['core/getUrl'](urlKey);

          if (!path) {
            const message = `URL key "${urlKey}" not found (used in component "${this.$options.name}")`;

            if (!this.isMobileSalesChannel && this.isDebug) {
              console.log(message); //eslint-disable-line no-console
            }

            if (
              typeof newrelic !== 'undefined' &&
              !this.isMobileSalesChannel &&
              this.isDebug
            ) {
              newrelic.noticeError(new Error(message));
            }
          }

          return path;
        },
        printDataUrls() {
          const urlKeys = this.$data.urls;

          return JSON.stringify(
            urlKeys.reduce((mapping, urlKey) => {
              return {
                ...mapping,
                [urlKey]: this.frontFacingPath(urlKey),
              };
            }, {})
          );
        },
      }),
  },
  ...(isPackage
    ? {}
    : {
      /* istanbul ignore next */
      async serverPrefetch() {
        try {
          return this.urls.length
            ? this.fetchUrls(
              this.urls.filter(
                (url, index, array) => array.indexOf(url) === index
              )
            )
            : undefined;
        } catch (e) {
          return undefined;
        }
      },
    }),
};

export default globalMixin;
