<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <div v-if="productsCount === 0">
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="catalogFilters__no-items"
        v-html="
          $t('catalog-filters.results_count', {
            '%count%': 0,
          })
        "
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>

    <div v-show="productsCount > 0">
      <div v-if="isSearchPage" class="catalogFilters__title">
        <PageTitle is-search-page :modifiers="['embedded', 'wrapping']" />
      </div>

      <div class="catalogFilters__desktop-container">
        <CatalogFilterDesktop
          v-if="shouldShowFilters"
          :filter-sections="sections"
          :all-filters="allFilters"
          :active-filters="storedActiveFilters"
          :active-sorting="storedActiveSorting"
          @applyFilters="handleApplyFilters"
        />
      </div>

      <div class="catalogFilters__mobile-btn">
        <MobileFiltersButton
          v-if="shouldShowFilters"
          :count="activeFiltersCount"
          @click="openMobileFilter"
        />
      </div>
    </div>

    <portal to="mobile-filter">
      <CatalogFilterMobile
        v-if="shouldShowFilters"
        :open="open"
        :sections="sections"
        :all-filters="allFilters"
        :stored-active-filters="storedActiveFilters"
        :stored-active-sorting="storedActiveSorting"
        @close="handleClose"
        @applyFilters="handleApplyFilters"
      />
    </portal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CatalogFilterMobile from 'Components/03-organisms/catalog-filter-mobile/CatalogFilterMobile';
import CatalogFilterDesktop from 'Components/03-organisms/catalog-filter-desktop/CatalogFilterDesktop';
import MobileFiltersButton from 'Components/01-atoms/mobile-filters-button/MobileFiltersButton';
import globalMixin from 'Libs/mixins/globalMixin';
import get from 'lodash/get';
import size from 'lodash/size';
import PageTitle from 'Components/03-organisms/page-title/PageTitle';

export default {
  name: 'CatalogFilters',
  components: {
    CatalogFilterMobile,
    CatalogFilterDesktop,
    MobileFiltersButton,
    PageTitle,
  },
  mixins: [globalMixin],
  computed: {
    ...mapState('core', {
      isSearchPage: (state) => {
        const type = get(state, 'placeholders.content.data.plp.pageType');
        return type === 'search';
      },
    }),
    ...mapState('plpFilters', {
      productsCount: (data) => data.count,
      open: (data) => data.openMobileFilter,
      sections: (data) => data.filterSections || {},
      allFilters(data) {
        if (!data.filters) {
          return {};
        }

        data.filters.sorting.title = this.$t('catalog-filters.sort');
        Object.values(data.filters.sorting.options).forEach((option) => {
          if (option.dir) {
            const directionLabel = this.$t('catalog-filters.' + option.dir);
            option.title = option.title.includes(directionLabel)
              ? option.title
              : `${directionLabel} ${option.title}`;
          } else {
            option.title = `${this.$t('catalog-filters.relevance')}`;
          }
        });

        return data.filters;
      },
      storedActiveFilters: (data) => data.activeFilters || {},
      storedActiveSorting: (data) => data.activeSorting,
    }),
    activeFiltersCount() {
      return Object.values(this.storedActiveFilters).reduce(
        (count, filters) => count + filters.length,
        0
      );
    },
    hasFilters() {
      return size(this.allFilters) > 0;
    },
    hasActiveFilters() {
      return size(this.storedActiveFilters) !== 0;
    },
    shouldShowFilters() {
      if (this.hasFilters) {
        if (this.productsCount < 2 && !this.hasActiveFilters) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('plpFilters', [
      'openMobileFilter',
      'closeMobileFilter',
      'submitFilters',
    ]),
    handleClose() {
      this.$emit('close');
      this.closeMobileFilter();
    },
    handleApplyFilters(dirtyActiveFilters, dirtySorting) {
      this.submitFilters({
        newFilters: dirtyActiveFilters,
        newSorting: dirtySorting,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';
@import 'mixins';

.catalogFilters {
  display: block;

  &__desktop-container {
    display: none;
  }

  &__mobile-btn {
    display: initial;
  }

  &__no-items {
    padding-top: 15px;
  }

  &__title {
    padding-bottom: 12px;

    @media #{$_mediaMUp} {
      padding-top: 6px;
    }

    @media #{$_mediaLUp} {
      padding-top: 10px;
      padding-bottom: 26px;
      flex-wrap: nowrap;
    }
  }

  @media #{$_mediaMUp} {
    margin-bottom: var(--space-2);
  }

  @media #{$_mediaLUp} {
    &__desktop-container {
      display: initial;
    }

    &__mobile-btn {
      display: none;
    }
  }
}
</style>
