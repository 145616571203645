<template>
  <div
    v-if="productAvailability.length > 0"
    ref="productAvailability"
    class="products-availability-wrapper qa-ProductAvailability_wrapper"
  >
    <span
      v-for="item in productAvailability"
      :key="item.index"
      class="products-availability-item qa-ProductAvailability_item"
    >
      <span
        v-for="subItem in item"
        :key="subItem.index"
        class="products-availability-subitem qa-ProductAvailability_subitem"
      >
        <span v-for="(value, propertyName) in subItem" :key="value">
          {{ propertyName }}:<span
            class="products-availability-value qa-ProductAvailability_key"
            >{{ value }}</span
          >
        </span>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ProductAvailability',
  props: {
    productAvailability: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
@import 'mixins';
@import 'variables/breakpoints';

.products-availability-wrapper {
  .products-availability-item {
    font-size: var(--font-size-SM);
    color: var(--color-rhino-without-horn);
    text-transform: uppercase;

    &::after {
      content: '';
      width: 1px;
      height: 12px;
      background: var(--color-black);
      display: inline-block;
      position: relative;
      margin-left: var(--space-0--half);
      margin-right: var(--space-0--half);
      top: 1px;
    }

    &:last-child::after {
      display: none;
    }

    .products-availability-value {
      font-weight: bold;
      display: inline-block;
    }
  }

  .products-availability-subitem {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
