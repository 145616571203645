export const SET_COMPLETION_TERMS = (state, results = []) => {
  state.completionTerms = results;
};

export const UPDATE_SEARCH_TERM = (state, value) => {
  state.searchTerm = value;
};

export const UPDATE_SUBMIT_STATUS = (state, value) => {
  state.submitting = value;
};

export const TOGGLE_MOBILE_SEARCH = (state, value = null) => {
  state.mobileOpen = value === null ? !state.mobileOpen : value;
};
