var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SimpleDialog',{staticClass:"caris-select-vehicle-dialog qa-carisDialog",attrs:{"active":_vm.isActive,"background":_vm.isBackground,"title":_vm.dialogTitle,"message":_vm.dialogMessage,"gtm-class-name":_vm.state === _vm.DIALOG_STATE.SELECTED_VEHICLE
      ? 'gtm--carisDialogFinalConfirmation-'
      : 'gtm--carisDialog-',"action":{
    enabled: true,
    title: _vm.dialogActionLabel,
    modifier: 'primary gtm--carisDialog-selectVehicleButton',
  },"is-portal-disabled":""},on:{"close":_vm.handleClose,"action-click":_vm.handleDialogActionClick}},[(_vm.state === _vm.DIALOG_STATE.TO_SELECT_VEHICLE)?_c('CarisVehicleSelector',{ref:"vehicle-selector",attrs:{"parent-form":_vm.parentForm,"parent-selections":_vm.parentSelections},on:{"vehicle-select":_vm.handleVehicleSelect}}):_vm._e(),_vm._v(" "),(_vm.state === _vm.DIALOG_STATE.SELECTED_VEHICLE)?[_c('CarisVehicleTable',{attrs:{"vehicles":_vm.selectableVehicles}}),_vm._v(" "),(_vm.viewport === 'mobile')?_c('div',{staticClass:"caris-select-vehicle-dialog__divider"}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"caris-select-vehicle-dialog__subheader"},[_vm._v("\n      "+_vm._s(_vm.$t('caris-select-vehicle-dialog.garage_selection.title'))+"\n    ")]),_vm._v(" "),_c('CheckboxBase',{staticClass:"caris-select-vehicle-dialog__garage-checkbox",attrs:{"label":_vm.$t('caris-select-vehicle-dialog.garage_selection.label'),"value":_vm.isAdditionToGarage,"hide-optional":true},on:{"input":_vm.handleGarageAdditionChange}})]:_vm._e(),_vm._v(" "),(_vm.state === _vm.DIALOG_STATE.GARAGE_ADDITION)?[_c('span',{staticClass:"caris-select-vehicle-dialog__subheader-prefix"},[_vm._v("\n      "+_vm._s(_vm.$t('caris-select-vehicle-dialog.garage_selection.input.asterisk'))+"\n    ")]),_vm._v(" "),_c('span',{staticClass:"caris-select-vehicle-dialog__subheader caris-select-vehicle-dialog__subheader--garage-addition"},[_vm._v("\n      "+_vm._s(_vm.$t('caris-select-vehicle-dialog.garage_selection.input.label'))+"\n    ")]),_vm._v(" "),_c('InputBase',{ref:"vehicle-name",staticClass:"caris-select-vehicle-dialog__vehicle-name-input",attrs:{"placeholder":_vm.$t('caris-select-vehicle-dialog.garage_selection.input.placeholder'),"value":_vm.garageAdditionName},on:{"input":_vm.handleVehicleNameChange}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }