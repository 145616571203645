<i18n src="./translations"></i18n>
<i18n src="Components/03-organisms/product-details-21/translations"></i18n>

<template>
  <div :class="classes" class="plp-product-21" :data-preload="[$options.name]">
    <div
      class="media"
      @mouseenter="titleHover(true)"
      @mouseleave="titleHover(false)"
    >
      <div class="plp-product-21__title" @click="productClick()">
        {{ product.brandName }} {{ product.name }}
      </div>

      <span class="productId">
        {{ $t('plp-product-21.article_number') }} {{ product.displaySku }}
      </span>

      <span v-if="isSkuDisplayed" class="productSku">
        | SKU:
        <input
          type="text"
          :value="product.sku"
          readonly
          @focus="$event.target.select()"
        />
      </span>
    </div>

    <div class="imageContainer qa-ProductCard__images">
      <BaseFlag
        v-if="showUniversalFlag && !product.pdpCarBar"
        class="universal-flag"
        :text="$t('plp-product-21.universal_flag')"
      />

      <div
        class="plp-product-21__images"
        :class="{ active: imageHover }"
        @click="!product.image.length && productClick()"
      >
        <AdvancedImage
          v-if="product.image.length"
          class="plp-product-21__product-image qa-ProductCard__image"
          :src="product.image"
          :alt="product.image.alt || ''"
          :config="{ q: 80, w: 180 }"
          @image-click="productClick()"
        />
        <NoImageSvg
          v-else
          class="plp-product-21__no-image"
          :lazy="true"
          :inline="false"
          @click="productClick()"
        />

        <AdvancedImage
          v-if="product.brandLogoUrl"
          class="plp-product-21__brand qa-ProductCard__logo"
          :src="product.brandLogoUrl"
          :alt="product.brandName"
          :config="{ q: 80, w: 100 }"
        />
      </div>
      <div
        v-if="product.documents.items.length"
        class="plp-product-21__documents"
      >
        <div
          v-for="(document, index) in product.documents.items"
          :key="index"
          class="plp-product-21__document"
          @click="handleDocumentClick(index, $event)"
        >
          <Icon
            class="plp-product-21__document-icon"
            :name="processIconName(document.icon)"
          />
          <MaskedLink
            ref="documentLink"
            class="plp-product-21__document-link"
            :redirect-to="document.url"
            :text="document.title"
            target="_blank"
            rel="noreferrer noopener"
          />
        </div>
      </div>
    </div>

    <div class="contentContainer">
      <div class="isDesktop">
        <div v-show="cleanResouceType" class="foundByIdentifier">
          <span class="icon">
            <Icon name="noted_outlined" />
          </span>
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="foundByText"></span>
          <!-- eslint-enable vue/no-v-html -->
        </div>

        <PdpInformation21
          v-if="
            isCarSelected &&
            (carAttributes.length || carAttributes.length === 0)
          "
          :headline="$t('plp-product-21.car_attributes')"
          :has-hazardous-good-enabled="true"
          :regular="carAttributes"
          :product="product"
          class="plp-product-21__car-attributes"
        />

        <PdpInformation21
          v-if="isCarSelected && information.length && carAttributes.length < 6"
          :headline="$t('plp-product-21.general_attributes')"
          :has-hazardous-good-enabled="true"
          :product="product"
          :quantity="quantity"
          :regular="information"
          :default-limit="6 - carAttributes.length"
        />

        <PdpInformation21
          v-if="
            !isCarSelected &&
            (information.length ||
              carAttributes.length ||
              carAttributes.length === 0)
          "
          :headline="$t('plp-product-21.general_attributes')"
          :has-hazardous-good-enabled="true"
          :product="product"
          :quantity="quantity"
          :regular="[...carAttributes, ...information]"
          :default-limit="3"
        />

        <PlpDescription21
          v-if="shouldShowDescription"
          :description="product.shortDescription"
        />

        <PdpInformation21
          :product-id="product.productId"
          :has-car-types="product.hasCarTypes"
          :has-oe-numbers="product.hasOeNumbers"
          :product="product"
        />

        <TyreLabels
          is-minimal
          :attributes="product.attributes"
          :images="product.images"
        ></TyreLabels>

        <ProductBundles v-if="productBundles.length" :items="productBundles" />
      </div>

      <div class="price isMobile">
        <PriceInfo21 ref="priceInfo" modifier="slim" :product="product" />
      </div>
    </div>

    <div class="plp-product-21__cta-container">
      <div class="price isDesktop">
        <PriceInfo21
          ref="priceInfo"
          modifier="slim"
          :product="product"
          :is-desktop="true"
        />
      </div>

      <div v-show="cleanResouceType" class="foundByIdentifier isMobile">
        <span class="icon">
          <Icon name="noted_outlined" />
        </span>
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="foundByText"></span>
        <!-- eslint-enable vue/no-v-html -->
      </div>

      <PdpVehicleNotification
        v-if="!product.isDropShipment"
        class="plp-product-21__vehicle-notification"
        :gtm-class-name="'gtm--plp-vehicle-notification'"
        :vehicle-compatibility="productVehicleCompatibility"
        :vehicle-name="vehicleName"
      />

      <PdpActions
        class="plp-product-21__actions"
        :product="product"
        :add-to-cart-button-props="addToCartButtonProps"
        @cart-click="handleAddToCart"
        @wishlist-click="handleAddToWishlist"
        @quantity-change="handleQuantityChange"
      />

      <Button
        class="plp-product-21__show-details"
        :class="detailsButtonClass"
        :modifiers="['full', 'iconRight']"
        :on-click="productClick"
        :title="this.$t('plp-product-21.details')"
      />

      <PlpDescription21
        v-if="shouldShowDescription"
        :description="product.shortDescription"
        class="isMobile"
      />

      <Dialog
        ref="unavailableProductDialog"
        :click-outside="true"
        :title="$t('plp-product-21.availability.delayed.title')"
      >
        <div>
          {{
            $t('plp-product-21.availability.delayed.message', {
              '%customerServicePhoneNumber%': customerServicePhoneNumber,
            })
          }}
        </div>
      </Dialog>
    </div>

    <ProductAvailability :product-availability="product.productAvailability" />

    <div class="further-details isMobile">
      <PdpInformation21
        v-if="isCarSelected && carAttributes.length"
        :headline="$t('product-details.car_usage')"
        :regular="carAttributes"
        :product="product"
        class="plp-product-21__car-attributes"
      />

      <PdpInformation21
        v-if="information"
        :has-hazardous-good-enabled="true"
        :headline="$t('plp-product-21.general_attributes')"
        :product="product"
        :quantity="quantity"
        :default-limit="3"
        :regular="information"
        :product-id="product.productId"
        :has-car-types="product.hasCarTypes"
        :has-oe-numbers="product.hasOeNumbers"
      />

      <TyreLabels
        is-minimal
        :attributes="product.attributes"
        :images="product.images"
      ></TyreLabels>
      <ProductBundles v-if="productBundles.length" :items="productBundles" />
    </div>

    <AlternativeProductsDialog
      ref="alternativeProductsDialog"
      :parent-product="product"
      :handle-cancel="handleAlternativeProductCancel"
      :handle-confirm="handleAlternativeProductConfirm"
    />
  </div>
</template>

<script>
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import { mapActions, mapState } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin.js';
import PdpVehicleNotification from 'Components/02-molecules/pdp-vehicle-notification/PdpVehicleNotification';
import AdvancedImage from 'Components/01-atoms/advanced-image/AdvancedImage.vue';
import PdpInformation21 from 'Components/02-molecules/pdp-information-21/PdpInformation21.vue';
import PdpActions from 'Components/02-molecules/pdp-actions/PdpActions';
import ProductAvailability from 'Components/02-molecules/product-availability/ProductAvailability';
import PriceInfo21 from 'Components/02-molecules/price-info-21/PriceInfo21.vue';
import Button from 'Components/01-atoms/button/Button.vue';
import NoImageSvg from 'Components/00-generated/NoImageSvg.vue';
import Dialog from 'Components/02-molecules/dialog/Dialog.vue';
import BaseFlag from 'Components/01-atoms/base-flag/BaseFlag';
import PlpDescription21 from 'Components/02-molecules/plp-description-21/PlpDescription21';
import Icon from 'Components/00-generated/Icon';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';
import ProductBundles from 'Components/02-molecules/product-bundles/ProductBundles';
import TyreLabels from 'Components/02-molecules/tyre-labels/TyreLabels';
import { isTypeLabel } from 'Components/02-molecules/tyre-labels/models/tyre-labels';
import AlternativeProductsDialog from 'Components/03-organisms/alternative-products-dialog/AlternativeProductsDialog';

export default {
  name: 'PlpProduct21',
  components: {
    AdvancedImage,
    PdpInformation21,
    PdpActions,
    ProductAvailability,
    PriceInfo21,
    Button,
    NoImageSvg,
    Dialog,
    BaseFlag,
    PlpDescription21,
    Icon,
    MaskedLink,
    PdpVehicleNotification,
    ProductBundles,
    TyreLabels,
    AlternativeProductsDialog,
  },
  mixins: [globalMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    position: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      imageHover: false,
      quantity: this.product.quantityAmount,
      showUniversalFlag: false,
      furtherDetailsExpanded: false,
      isAddToCartInProgress: false,
      selectedQuantity: this.product.quantityAmount,
      productIsReplaced: false,
    };
  },
  computed: {
    ...mapState('carSelection', {
      vehicleName: ({ data: { vehicle = {} } }) => {
        const { manufacturer, model, type, bodyCodes } = vehicle;
        const selectionName = `${manufacturer} ${model} ${type}`;
        if (bodyCodes && bodyCodes.length > 0) {
          return `${selectionName} (${[...bodyCodes].sort().join(', ')})`;
        }
        return selectionName;
      },
    }),
    ...mapState('renderingSalesChannelConfig', {
      isU2dAktionEnabled: ({ isU2dAktionEnabled }) => isU2dAktionEnabled,
    }),
    productBundles() {
      const setProductsItems = this.product.setProductsItems || [];
      const partsListItems = this.product.partsListItems || [];

      return setProductsItems.concat(partsListItems);
    },
    productVehicleCompatibility() {
      return this.product?.pdpCarBar?.key || 'UNIVERSAL_PRODUCT';
    },
    shouldShowDescription() {
      if (!this.product.shortDescription) {
        return false;
      }
      if (this.product.shortDescription.length === 0) {
        return false;
      }
      if (this.attributes.length > 0) {
        return false;
      }
      return true;
    },
    foundByText() {
      if (!this.cleanResouceType) {
        return null;
      }

      const reasonTranslationKey = this.$t(
        `plp-product-21.found_reason.${this.cleanResouceType}`
      );
      let reason = reasonTranslationKey;

      if (this.cleanResouceType === 'oe_number') {
        reason = reasonTranslationKey.replace(
          '%manufacturer%',
          this.product.resultSourceIdentifier
        );
      }

      return this.$t('plp-product-21.found_via').replace('%reason%', reason);
    },
    cleanResouceType() {
      // clean values that are treated the same
      if (this.product.resultSourceType === 'display_sku') {
        return 'sku';
      }
      if (this.product.resultSourceType === 'competitor_part_number') {
        return 'trade_number';
      }

      return this.product.resultSourceType;
    },
    isCarSelected() {
      return (
        this.product.pdpCarBar &&
        this.product.pdpCarBar.key &&
        this.product.pdpCarBar.key === 'SELECTED_CAR_OK'
      );
    },
    detailsButtonClass() {
      return ['button--secondary', 'qa-ProductCard__showDetails'];
    },
    ...mapState('core', {
      customerServicePhoneNumber: ({ salesChannel }) => {
        return salesChannel.config.customerServicePhoneNumber;
      },
      salesChannelId: ({ salesChannel }) => salesChannel.salesChannelId,
      isNewWishlist: ({ salesChannel }) =>
        salesChannel.config.isNewWishlist || false,
    }),
    ...mapState('wishlist', {
      isWished: (state) => state.data.isWished,
    }),
    attributes() {
      if (!this.product || !this.product.attributes) return [];

      return this.product.attributes
        .map((attribute) => ({
          label: attribute.title,
          info: attribute.value,
          isTop: attribute.isTop,
          id: attribute.id,
        }))
        .filter((attr) => !isTypeLabel(attr?.id));
    },
    carAttributes() {
      return this.attributes.filter(({ isTop }) => isTop);
    },
    information() {
      return this.attributes.filter(({ isTop }) => !isTop);
    },

    addToCartButtonProps() {
      if (this.isAddToCartInProgress) {
        return {
          class: 'button--japaneseLaurel',
          title: this.$t('plp-product-21.cart.added'),
          icon: 'added_to_cart_check',
          disabled: true,
        };
      }

      return {
        class: [
          this.viewport && this.viewport !== 'desktop' && 'button--noHover',
          'qa-addToCartButton',
        ],
        icon: 'cart',
        title: this.$t('plp-product-21.cart.add'),
      };
    },
    isSkuDisplayed() {
      // Only displayed internally for CustomerCar (same as when productAvailability is Displayed)
      return this.product?.productAvailability?.length > 0;
    },
  },
  methods: {
    upperCamelCase: (string) => upperFirst(camelCase(string)),
    ...mapActions('miniCart', ['addToCart']),
    ...mapActions('dataLayer', ['trackProductClick']),
    async handleAddToCart(
      quantity,
      skipAlternativeProductsCheck = false,
      productToAdd = null
    ) {
      if (!productToAdd) {
        productToAdd = this.product;
      }

      //Check if it's not instantly available
      //Check if the product has cross references.
      if (
        !skipAlternativeProductsCheck &&
        productToAdd.availability.toUpperCase() !== 'INSTANT' &&
        productToAdd.hasCrossReferences
      ) {
        const alternativeProduct = await this.$refs.alternativeProductsDialog.fetchAlternativeProduct(
          productToAdd.productId,
          this.selectedQuantity
        );
        if (alternativeProduct) {
          //Pop the alternative product dialog if there is an alternative.
          this.$refs.alternativeProductsDialog.showDialog(alternativeProduct);
          return false;
        }
      }

      const availabilityStatus = [
        'DELAYED',
        'AVAILABLE_SOON',
        'AVAILABLE_ON_REQUEST',
        'OUT_OF_STOCK',
        'NOT_AVAILABLE',
        'NOTAVAILABLE',
        '2TO4WEEKS',
      ];

      if (
        productToAdd.prices.price === 0 ||
        availabilityStatus.includes(productToAdd.availability) ||
        availabilityStatus.includes(productToAdd.availability.toUpperCase())
      ) {
        if (!skipAlternativeProductsCheck) {
          //Exit if the alternative product dialog has been shown. this flag will only be true if alternative product dialog has been shown.
          this.$refs.unavailableProductDialog.show();
        }
        return false;
      }

      this.isAddToCartInProgress = true;
      try {
        await this.addToCart({
          product: productToAdd,
          quantity: this.selectedQuantity,
        });
      } finally {
        setTimeout(() => {
          this.isAddToCartInProgress = false;
          this.productIsReplaced = false;
        }, 2000);
      }

      return true;
    },
    handleAlternativeProductConfirm(alternativeProduct) {
      this.productIsReplaced = true;
      this.handleAddToCart(this.selectedQuantity, true, alternativeProduct);
    },
    handleAlternativeProductCancel() {
      if (this.productIsReplaced) {
        return;
      }

      this.handleAddToCart(this.selectedQuantity, true);
    },
    handleAddToWishlist() {
      this.isNewWishlist
        ? this.$emit('wish-click', true)
        : this.addToWishlist({
            product: this.product,
            quantity: this.selectedQuantity,
            list: 'Product Listing Page',
          });
    },
    handleQuantityChange(quantity) {
      this.selectedQuantity = quantity;
    },
    titleHover(active) {
      this.imageHover = active;
    },
    handleDocumentClick(index, { target }) {
      const documentLinkComponent = this.$refs.documentLink[index];

      if (documentLinkComponent && documentLinkComponent.$el !== target) {
        documentLinkComponent.$el.click();
      }
    },
    productClick() {
      this.trackProductClick({
        product: this.product,
        position: this.position,
      }).finally(() => {
        if (this.product.productUrl) {
          window.location.href = this.product.productUrl;
        }
      });
    },
    ...mapActions('wishlist', ['addToWishlist']),
    toggleFurtherDetails(expanded) {
      this.furtherDetailsExpanded = expanded;
    },
    processIconName(icon) {
      icon = this.upperCamelCase(icon);
      return icon.includes('Pdf') ? 'NewDocumentPdf' : icon;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';
@import 'mixins';

.plp-product-21 {
  border: 1px solid var(--color-alto-sec);
  display: flex;
  flex-wrap: wrap;
  min-height: 225px;
  border-radius: 8px;
  padding: var(--space-2--half) var(--space-2) 0;

  @media #{$_mediaMDown} {
    border: 0;
    margin-left: calc(var(--space-2) * -1);
    margin-right: calc(var(--space-2) * -1);
    width: calc(100% + var(--space-2) * 2) !important;
  }

  &__actions {
    max-width: 267px;

    @media #{$_mediaLDown} {
      max-width: 100%;
    }

    ::v-deep {
      .wishlistSvg {
        margin-top: -3px;
        height: 24px !important;
      }

      .pdp-actions__quantity .select {
        border-color: var(--color-silver-lining) !important;
      }

      .pdp-actions__in-the-cart {
        width: 100%;
        margin-top: var(--space-1);
        text-transform: uppercase;

        @media #{$_mediaLDown} {
          width: 186px;
        }

        .cartSvg {
          @media #{$_mediaLDown} {
            display: none;
          }
        }
      }

      .inner .icon + .plp-product-21__title {
        margin-left: 0;
      }
    }
  }

  &__vehicle-notification {
    @media #{$_mediaLDown} {
      margin: var(--space-1--half) 0;
      border-radius: var(--space-1);
    }

    @media #{$_mediaMDown} {
      border-radius: var(--space-1);
      margin: 0;
    }
  }
}

.plp-product-21__car-attributes {
  ::v-deep {
    .pdp-information-21__headline {
      border: 0;
      margin-bottom: var(--space-0--half);
      padding-left: 0;
    }

    .pdp-information-21__wrapper {
      background: var(--color-alto-light);
      border: 0;
      margin-bottom: var(--space-3);
      padding: 6px var(--space-2);
      margin-top: 0;

      .pdp-information-21__table {
        margin-left: 0;
      }
    }

    .pdp-information-21__row--regular {
      border-bottom: 1px solid var(--color-silver-lining);

      &:last-child {
        border: 0;
      }
    }
  }
}

.foundByIdentifier {
  padding: 12px;
  background: var(--color-concrete);
  margin-bottom: 12px;
  vertical-align: middle;
  display: flex;
  line-height: 1.25;

  .icon {
    height: 22px;
    width: 21px;
    margin-right: var(--space-1);
  }

  &.isMobile {
    margin: var(--space-1--half) 0;
    border-radius: var(--space-1);
  }

  ::v-deep strong {
    font-weight: bold;
  }

  &__info {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: -7px;
    margin-top: -2px;
    flex: 0 0 auto;
  }
}

.imageContainer,
.actions {
  padding: 12px;
}

.pdpInformation + .pdpInformation {
  margin-top: 10px;
}

.plp-product-21__show-more {
  text-decoration: underline;
  cursor: pointer;
}

.imageContainer {
  position: relative;
  width: 25%;
}

.plp-product-21__no-image {
  max-height: 126px;
}

.contentContainer {
  padding: 0;
  text-align: left;
}

.media {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: var(--space-3);

  @media #{$_mediaLDown} {
    margin-bottom: 22px;
  }
}

.plp-product-21__cta-container {
  display: flex;
  flex-direction: column;

  @media #{$_mediaLDown} {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.products-availability-wrapper {
  padding: var(--space-XXS) var(--space-2);
  width: 100%;
  border: 1px solid var(--color-alt-gray);
  background: white;
  margin-top: -2px;
  z-index: 2;
  position: relative;
}

div.price {
  padding-bottom: 0;
}

.plp-product-21__title {
  color: var(--color-black);
  display: inline;
  cursor: pointer;
  margin-bottom: var(--space-1);
  font-weight: bold;
  font-size: var(--font-size-MLL);

  &:hover {
    text-decoration: underline;
  }

  @media #{$_mediaLDown} {
    display: block;
    font-size: var(--font-size-MML);
    margin-bottom: var(--space-1);
  }
}

.productId,
.productSku {
  cursor: text;
  font-size: var(--font-size-M);
  line-height: 17px;
  display: inline;
  position: relative;
  bottom: 1px;
  color: var(--color-black);
  margin-left: var(--space-1);

  @media #{$_mediaLDown} {
    font-size: var(--font-size-SM);
    margin-left: 0;
  }
}

.dangerous {
  border-right: none;
}

.further-details {
  ::v-deep {
    .pdp-information-21__headline {
      cursor: default;
    }
  }
}

.price {
  padding-bottom: 10px;
}

.further-details {
  .hazardousGood {
    border-right: none !important;
  }
}

.actions {
  padding: var(--space-1) var(--space-1--half);
  width: 100%;
  background: var(--color-wild-sand);
  border-top: 1px solid var(--color-alt);
  order: 2;
}

.flexRow {
  display: flex;
}

.plp-product-21__show-details {
  margin-top: var(--space-1);
  margin-bottom: var(--space-3);
  height: 48px;
  max-width: 267px;
  color: var(--color-rhino-without-horn);
  border-color: var(--color-mad-hippo);

  @media #{$_mediaLDown} {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.plp-product-21__product-image {
  max-height: 140px;
  max-width: 100%;
  cursor: pointer;
  height: auto;
  width: auto;

  ::v-deep .img {
    transition: transform var(--time-S) ease-in-out;
    height: 140px;
    width: 180px;

    @media #{$_mediaLDown} {
      height: 80px;
      max-width: 100%;
      width: auto;
    }
  }
}

.plp-product-21__documents {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: left;
  max-width: calc(100% + var(--space-3--half));
  margin-top: var(--space-3);
}

.plp-product-21__document {
  display: flex;
  flex-direction: column;
  margin: 0 14px var(--space-2) 14px;
  cursor: pointer;
  word-break: break-word;

  @media #{$_mediaMDown} {
    max-width: 70px;
  }
}

.plp-product-21__document-icon {
  height: 35px;

  @media #{$_mediaMDown} {
    height: 32px;
  }
}

.plp-product-21__document-link {
  width: 100%;
  margin-top: var(--space-1);
  font-size: 14px;
  line-height: 13px;
  color: var(--color-mine-shaft);
  text-align: center;
}

.plp-product-21__brand {
  width: 40% !important;
  min-width: 50px;
  max-width: 100%;
  margin-top: 6%;
  height: min-content;

  @media #{$_mediaSDown} {
    margin-top: 10%;
  }
}

.plp-product-21__images {
  display: flex;
  margin-top: 3px;
  flex-direction: column;
  align-items: center;
}

.pdpInformation21 {
  padding-left: 3px;

  @media #{$_mediaLDown} {
    padding-left: 0;
  }
}

.universal-flag {
  display: none;
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 1;

  @media #{$_mediaLUp} {
    display: block;
  }
}

@media #{$_mediaSUp} {
  .imageContainer {
    right: 3px;
  }
}

@media #{$_mediaMUp} {
  .plp-product-21__product-image {
    &:hover,
    &.active {
      ::v-deep .img {
        transform: scale(1.06);
      }
    }
  }

  .pdpInformation21 {
    padding-left: var(--space-1--half);
  }

  .tyre-labels {
    padding-left: var(--space-1--half);
  }
}

@media #{$_mediaXLUp} {
  .pdpInformation21 {
    padding-left: 0;
  }

  .plp-product-21__cta-container {
    width: 268px;
    box-sizing: content-box;
    flex-shrink: 0;
    padding: 0 0 0 var(--space-3);
    margin-top: -3px;
    align-items: flex-end;
  }

  .imageContainer {
    max-width: 180px;
    flex-shrink: 0;
    padding: 0 0 var(--space-2) 0;
    margin-right: 15px;
  }

  .contentContainer {
    width: 408px;
  }

  .media {
    align-items: center;
    flex-direction: row;
  }

  .media__icon {
    display: none;
  }

  .price {
    margin: 0;
    width: 268px;
    padding-left: 0;
  }

  .actions {
    padding: 0;
    border-top: none;
  }
}

@media #{$_mediaMDown} {
  .dangerous,
  .further-details {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
  }
}

@media #{$_mediaMUp} {
  .plp-product-21__product-image {
    &:hover,
    &.active {
      ::v-deep .img {
        transform: scale(1.06);
      }
    }
  }
}

@media #{$_mediaLDown} {
  .isDesktop {
    display: none;
  }

  .plp-product-21__product-image {
    display: flex;
    justify-content: center;
    max-width: 150px;
  }
}

@media #{$_mediaXLUp} {
  .isMobile {
    display: none;
  }
}

@media #{$_mediaSDown} {
  .imageContainer {
    width: 40%;
    padding: 0;
  }

  .contentContainer {
    padding: 0;
  }

  .contentContainer {
    width: 60%;
  }

  .price {
    width: 100%;
  }

  .brand {
    margin: 11px auto;
  }

  .productId,
  .plp-product-21__title,
  .price {
    padding-left: 3px;
  }
}

@media #{$_mediaLDown} {
  .wishlistButton {
    display: none;
  }

  .products-availability-wrapper {
    display: none;
  }
}

@media all and (min-width: 1001px) and (max-width: 1300px) {
  .contentContainer {
    width: 228px;
  }
}

@media screen and (min-width: 920px) and (max-width: 1000px) {
  .dangerous {
    display: none;
  }
}
</style>
