<template>
  <div
    :class="[
      classes,
      {
        'sortingDropdown--opened': isOpened,
      },
    ]"
    :data-preload="[$options.name]"
  >
    <div class="header" @click="toggleContainer">
      <div class="header__name">
        {{ activeTitle }}
      </div>
      <div :class="['header__icon', { 'header__icon--up': isOpened }]">
        <Icon name="arrow_drop_down" width="9" height="9" />
      </div>
    </div>

    <div
      v-if="isOpened"
      v-click-outside="{
        handler: handleClose,
        isActive: isOpened,
        events: ['click'],
      }"
      class="container"
    >
      <div class="container__body">
        <SortingOptionList
          :is-mobile="false"
          :options="sorting.options"
          :active-option="activeSorting"
          :name="sorting.alias"
          @input="click"
        />
      </div>
    </div>
  </div>
</template>
<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { formBaseMixin } from 'Libs/mixins/formBaseMixin.js';
import SortingOptionList from 'Components/01-atoms/sorting-option-list/SortingOptionList';
import Icon from 'Components/00-generated/Icon';

export default {
  name: 'SortingDropdown',
  components: { Icon, SortingOptionList },
  mixins: [globalMixin, formBaseMixin],
  props: {
    sorting: {
      type: Object,
      default: () => ({}),
    },
    activeSorting: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpened: false,
      newActiveTitle: '',
    };
  },
  computed: {
    activeTitle() {
      return this.newActiveTitle
        ? this.newActiveTitle
        : this.activeSorting.title;
    },
  },
  methods: {
    toggleContainer() {
      this.isOpened = !this.isOpened;
      if (!this.isOpened) {
        this.handleClose();
      }
    },
    handleClose() {
      this.isOpened = false;
    },
    click(value) {
      this.$emit('input', value);
      this.newActiveTitle = value.title;
      this.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';
@import 'mixins';

.sortingDropdown {
  position: relative;
  height: 40px;

  &--opened {
    .header,
    .container {
      border-color: var(--color-chathamsblue);
    }
  }
}

.header {
  cursor: pointer;
  height: 100%;
  border: 1px solid var(--color-alto);
  border-radius: 3px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 16px;

  &__name {
    text-transform: capitalize;
    color: var(--color-nero);
    font-size: var(--font-size-SM);
    line-height: 24px;
  }

  &__icon {
    ::v-deep svg {
      color: var(--color-secondary);
    }

    &--up {
      transform: rotateZ(180deg);
    }
  }
}

.container {
  width: 100%;
  z-index: 1;

  border: 1px solid var(--color-alto);
  background: var(--color-white);

  display: flex;

  position: absolute;
  top: 38px;

  &__body {
    flex: 1;
    overflow-y: auto;
    overflow: hidden;
  }
}
</style>
