import LocalStorage from '../../../libs/helpers/local-storage';

const LOCAL_STORAGE_KEYS = {
  SHOULD_DELAY_TOAST: 'SHOULD_DELAY_TOAST',
  TOAST_MESSAGE_TO_DELAY: 'TOAST_MESSAGE_TO_DELAY',
  TRUE: 'true',
  FALSE: 'false',
};

export default () => {
  const localStorage = new LocalStorage();
  let delayedMessages = [];

  if (
    localStorage &&
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SHOULD_DELAY_TOAST)) ===
      LOCAL_STORAGE_KEYS.TRUE &&
    localStorage.getItem(LOCAL_STORAGE_KEYS.TOAST_MESSAGE_TO_DELAY)
  ) {
    delayedMessages = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.TOAST_MESSAGE_TO_DELAY)
    );

    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOAST_MESSAGE_TO_DELAY);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.SHOULD_DELAY_TOAST);
  }

  return {
    items: [],
    delayedMessages,
    errorMessage: '',
  };
};
