import once from 'lodash/once';

const TRACKING_TIMEOUT = 1000;
export default class TrackingHandler {
  static track({ event, data }) {
    const hasCallback = typeof data.eventCallback === 'function';

    if (hasCallback) {
      data.eventCallback = once(data.eventCallback);
      setTimeout(data.eventCallback, TRACKING_TIMEOUT);
    }

    if (event) {
      event.preventDefault();
    }

    if (window.dataLayer && window.dataLayer.push) {
      window.dataLayer.push(data);
    } else {
      if (hasCallback) {
        data.eventCallback();
      }
    }

    return this;
  }

  static trackAsync({ event, data }) {
    return new Promise((resolve) => {
      data.eventCallback = () => resolve();
      this.track({ event, data });
    });
  }

  static trackDefined({ event, data }) {
    data = TrackingHandler.recursivePurgeUndefined(data);
    this.track({ event, data });
  }

  static trackDefinedAsync({ event, data }) {
    return new Promise((resolve) => {
      data.eventCallback = () => resolve();
      this.trackDefined({ event, data });
    });
  }

  static recursivePurgeUndefined(data) {
    Object.keys(data).forEach((key) => {
      data[key] === undefined && delete data[key];
      if (typeof data[key] === 'object') {
        data[key] = this.recursivePurgeUndefined(data[key]);
      }
    });
    return data;
  }

  static assignToDataLayerPage(data) {
    Object.assign(window.dataLayer[0].page, data);
  }
}
