<template>
  <!-- eslint-disable vue/no-v-html -->
  <component
    :is="config.component"
    v-if="config.component"
    v-bind="config.data || {}"
    :class="[classes]"
  />
  <div
    v-else-if="config.html"
    :class="[classes, 'html']"
    v-html="config.html"
  />
</template>

<script>
import Fragment from 'Components/01-atoms/fragment/Fragment';
import CarisBar from 'Components/03-organisms/caris-bar/CarisBar';
import CarisCombined from 'Components/03-organisms/caris-combined/CarisCombined';
import HeaderCartButton from 'Components/03-organisms/header-cart-button/HeaderCartButton';
import HeaderAccountButton from 'Components/03-organisms/header-account-button/HeaderAccountButton';
import HeaderWishlistButton from 'Components/03-organisms/header-wishlist-button/HeaderWishlistButton';
import Navigation from 'Components/03-organisms/navigation/Navigation';
import SideNavigation from 'Components/03-organisms/side-navigation/SideNavigation';
import CmsBanners from 'Components/03-organisms/cms-banners/CmsBanners';
import CmsContentText from 'Components/03-organisms/cms-content-text/CmsContentText';
import CmsPrimarySecondaryBanners from 'Components/03-organisms/cms-primary-secondary-banners/CmsPrimarySecondaryBanners';
import CmsUsp from 'Components/03-organisms/cms-usp/CmsUsp';
import PageSubtitle from 'Components/01-atoms/page-subtitle/PageSubtitle';
import PageTitle from 'Components/03-organisms/page-title/PageTitle';
import ProductDetails from 'Components/03-organisms/product-details-21/ProductDetails21'; // Same as ProductDetails21, Kept for safety in case the component name from BE does not contain the 21
import ProductDetails21 from 'Components/03-organisms/product-details-21/ProductDetails21';
import ProductList from 'Components/03-organisms/plp-products-21/PlpProducts21'; // Same as PlpProducts21, Kept for safety in case the component name from BE does not contain the 21
import ProductList21 from 'Components/03-organisms/plp-products-21/PlpProducts21';
import CatalogFilters from 'Components/03-organisms/catalog-filters/CatalogFilters';
import CatalogCategorySearchFilters from 'Components/03-organisms/catalog-category-filters-wrapper/CatalogCategoryFiltersWrapper';
import Breadcrumbs from 'Components/02-molecules/breadcrumbs/Breadcrumbs';
import CategoriesRow from 'Components/02-molecules/categories-row/CategoriesRow';
import CarCategoriesOverview from 'Components/03-organisms/car-categories-overview/CarCategoriesOverview.vue';
import BrandCategories from 'Components/03-organisms/brand-category/BrandCategory.vue';
import CarCategories from 'Components/03-organisms/car-category/CarCategory.vue';
import globalMixin from 'Libs/mixins/globalMixin';

function placeholderUpdated() {
  const event = new CustomEvent('VUE_PLACEHOLDER__UPDATED');
  window.dispatchEvent(event);
}

export default {
  name: 'Placeholder',
  components: {
    Fragment,
    CarisBar,
    CarisCombined,
    HeaderCartButton,
    HeaderAccountButton,
    HeaderWishlistButton,
    Navigation,
    SideNavigation,
    CmsBanners,
    CmsContentText,
    CmsNavigationTree: () =>
      import(
        'Components/03-organisms/cms-navigation-tree/CmsNavigationTree' /* webpackChunkName: "CmsNavigationTree" */
      ),
    CmsPopularCategories: () =>
      import(
        'Components/03-organisms/cms-popular-categories/CmsPopularCategories' /* webpackChunkName: "CmsPopularCategories" */
      ),
    CmsPrimarySecondaryBanners,
    CmsProductSlider: () =>
      import(
        'Components/03-organisms/cms-product-slider/CmsProductSlider' /* webpackChunkName: "CmsProductSlider" */
      ),
    CmsUsp,
    CmsContact: () =>
      import(
        'Components/03-organisms/cms-contact/CmsContact' /* webpackChunkName: "CmsContact" */
      ),
    CmsHelpCenter: () =>
      import(
        'Components/03-organisms/cms-help-center/CmsHelpCenter' /* webpackChunkName: "CmsHelpCenter" */
      ),
    CmsReturnsAndGuarantee: () =>
      import(
        'Components/03-organisms/cms-returns-and-guarantee/CmsReturnsAndGuarantee' /* webpackChunkName: "CmsReturnsAndGuarantee" */
      ),
    Components: () =>
      import(
        'Components/03-organisms/components/Components' /* webpackChunkName: "Components" */
      ),
    Dummy: () =>
      import('Components/01-atoms/dummy/Dummy' /* webpackChunkName: "Dummy"*/),
    PageSubtitle,
    ErrorNotice: () =>
      import(
        'Components/03-organisms/error-notice/ErrorNotice' /* webpackChunkName: "ErrorNotice" */
      ),
    PageTitle,
    ProductDetails,
    ProductDetails21,
    ProductList,
    ProductList21,
    CategorySearch: () =>
      import(
        'Components/02-molecules/search-results/SearchResults' /* webpackChunkName: "SearchResults" */
      ),
    CatalogFilters,
    CatalogCategorySearchFilters,
    Breadcrumbs,
    CategoriesRow,
    CarManufacturers: () =>
      import(
        'Components/03-organisms/car-manufacturers/CarManufacturers' /* webpackChunkName: "CarManufacturers" */
      ),
    CarManufacturerSeries: () =>
      import(
        'Components/03-organisms/car-manufacturer-series/CarManufacturerSeries.vue' /* webpackChunkName: "CarManufacturerSeries" */
      ),
    CarCategoriesOverview,
    BrandCategories,
    CarCategories,
    BrandsLandingPage: () =>
      import(
        'Components/03-organisms/brands-landing-page/BrandsLandingPage.vue' /* webpackChunkName: "BrandsLandingPage" */
      ),
    OrderList: () =>
      import(
        'Components/03-organisms/order-list/OrderList.vue' /* webpackChunkName: "OrderList" */
      ),
    WishlistOld: () =>
      import(
        'Components/03-organisms/wishlist-old/WishlistOld.vue' /* webpackChunkName: "WishlistOld" */
      ),
    Wishlist: () =>
      import(
        'Components/03-organisms/wishlist/Wishlist.vue' /* webpackChunkName: "Wishlist" */
      ),
  },
  mixins: [globalMixin],
  props: {
    config: {
      type: Object,
      default: () => ({}),
      validator(value) {
        return value.component || value.html;
      },
    },
  },
  updated() {
    if (this.config) {
      this.$nextTick(placeholderUpdated);
    }
  },
  mounted() {
    if (this.config) {
      this.$nextTick(placeholderUpdated);
    }
  },
};
</script>

<style scoped lang="scss">
.placeholder {
  &--flex {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.html {
  width: 100%;
}
</style>
