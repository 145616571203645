<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <div
      v-if="priceTexts.isExchangePart && priceTexts.exchangePartValue !== 0"
      class="priceText qa-PriceInfo__exchangePart"
    >
      {{
        $t('price-texts.exchange_part', {
          '%gross_exchange_part_value%': priceTexts.exchangePartValue,
        })
      }}
    </div>

    <div
      v-if="priceTexts.description"
      class="priceText qa-PriceInfo__description"
    >
      {{ priceTexts.description }}
    </div>

    <div v-if="showBulkyPrice" class="priceText qa-PriceInfo__bulkyPrice">
      {{
        $t('price-texts.bulky_price', {
          '%formattedBulkyPrice%': priceTexts.bulkyPrice,
        })
      }}
    </div>

    <div v-if="showRiskyPrice" class="priceText qa-PriceInfo__bulkyPrice">
      {{
        $t('price-texts.risky_price', {
          '%formattedRiskyPrice%': priceTexts.riskyPrice,
        })
      }}
    </div>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'PriceTexts',
  mixins: [globalMixin],
  props: {
    priceTexts: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('core', ['isBusinessCustomer', 'getCompanyInformation']),
    isDirectDelivery() {
      return (
        (this.isBusinessCustomer &&
          this.getCompanyInformation?.isDirectDelivery) ||
        false
      );
    },
    showBulkyPrice() {
      return !this.isDirectDelivery && this.priceTexts?.bulkyPrice;
    },
    showRiskyPrice() {
      return !this.isDirectDelivery && this.priceTexts?.riskyPrice;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

@media #{$_mediaSDown} {
  .priceTexts {
    &--slim {
      .priceText {
        font-size: 11px;
        line-height: 1.3em;
      }
    }
  }
}

.priceText {
  font-size: var(--font-size-S);
  line-height: 1.3em;
  color: var(--color-rolling-stone);
  margin: 6px 0 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

@media #{$_mediaMDown} {
  .priceText {
    margin: 4px 0;
  }
}
</style>
