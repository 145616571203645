
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="LeftnaviDotSvg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22"><circle cx="11" cy="11" r="2"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'LeftnaviDotSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
