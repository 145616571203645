
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="CustomerServiceSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path fill="#707C80" fill-rule="evenodd" d="M16.984 10.257c-.456-.459-1.137-.712-1.916-.712-1.637 0-2.696 1.156-2.696 2.943h1.51c0-1.106.623-1.499 1.156-1.499.476 0 .98.314 1.024.913.048.63-.292.95-.722 1.357-1.06 1-1.081 1.485-1.076 2.584h1.506c-.009-.495.023-.897.703-1.623.51-.544 1.14-1.22 1.155-2.252.006-.689-.215-1.28-.644-1.71m-1.047 7.447a.934.934 0 01-.937.931.936.936 0 01-.94-.931c0-.513.422-.93.94-.93a.932.932 0 01.937.93M15 5C9.19 5 4 8.842 4 14.097a7.97 7.97 0 001.876 5.114c.05 1.664-.937 4.05-1.827 5.789 2.386-.427 5.776-1.37 7.313-2.305 1.3.313 2.544.457 3.721.457 6.493 0 10.917-4.397 10.917-9.056C26 8.814 20.773 5 15 5m0 1.818c5.054 0 9.166 3.266 9.166 7.28 0 4.47-4.716 7.237-9.084 7.237-1.775 0-3.1-.361-4.028-.586-.916.557-1.462.943-3.915 1.655.49-1.249.662-2.499.551-3.879-.768-.909-1.856-2.181-1.856-4.429 0-4.012 4.111-7.278 9.166-7.278"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'CustomerServiceSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
