<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <div v-if="isMobile" class="wrapper">
      <template v-for="option in optionsList">
        <label :key="option.alias" class="mobileOptions">
          {{ option.title }}
          <input
            :id="option.identifier"
            type="radio"
            :name="name"
            :value="option.alias"
            :checked="option.alias === activeOption.alias"
            @change="handleChange(option)"
          />
          <span class="radio" />
        </label>
      </template>
    </div>
    <div v-if="!isMobile" class="wrapper">
      <template v-for="option in optionsList">
        <label
          :key="option.alias"
          :class="[
            'desktopOptions',
            {
              active: option.alias === activeOption.alias,
            },
          ]"
        >
          {{ option.title }}
          <input
            :id="option.identifier"
            type="radio"
            :name="name"
            :value="option.alias"
            v-bind="value"
            :checked="option.alias === activeOption.alias"
            @change="handleChange(option)"
          />
        </label>
      </template>
    </div>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import { formBaseMixin } from 'Libs/mixins/formBaseMixin.js';

export default {
  name: 'SortingOptionList',
  mixins: [globalMixin, formBaseMixin],
  props: {
    name: {
      type: String,
      default: 'sorting',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    activeOption: {
      type: Object,
      default: () => ({}),
    },
    isMobile: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    optionsList() {
      return Object.values(this.options);
    },
  },
  methods: {
    handleChange(option) {
      this.$emit('input', option);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.sortingOptionList {
  display: flex;

  .wrapper {
    padding: 12px 0;
    width: 100%;
  }

  .desktopOptions {
    display: block;
    width: 100%;
    padding: 7px 15px;
    font-size: var(--font-size-SM);
    cursor: pointer;

    &:hover {
      background-color: var(--color-concrete);
    }
  }

  .active {
    font-weight: bold;
    cursor: default;
  }

  .mobileOptions {
    display: block;
    width: 100%;
    position: relative;
    padding: 12px 15px;
    line-height: 20px;
    padding-left: 35px;
    border-bottom: 1px solid var(--color-concrete);
    cursor: pointer;
    font-size: 100%;

    .radio {
      position: absolute;
      left: 10px;
      height: 20px;
      width: 20px;
      border: 2px solid var(--color-rolling-stone);
      border-radius: 50%;
    }

    .radio::after {
      content: '';
      position: absolute;
      display: none;
    }
  }
}

.mobileOptions input,
.desktopOptions input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.mobileOptions input:checked ~ .radio {
  @include themeColor(
    border-color,
    var(--color-gold-drop),
    var(--color-tory-blue),
    var(--color-picton-blue)
  );

  &::before {
    @include themeColor(
      background-color,
      var(--color-gold-drop),
      var(--color-tory-blue),
      var(--color-picton-blue)
    );
  }
}

.mobileOptions input:checked ~ .radio::after {
  display: block;
}

.mobileOptions .radio::after {
  @include themeColor(
    background-color,
    var(--color-gold-drop),
    var(--color-tory-blue),
    var(--color-picton-blue)
  );

  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
</style>
