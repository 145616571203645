import { buildFiltersState } from './state';
import * as mutations from './mutations';
import * as actions from './actions';

export default (placeholders) => ({
  namespaced: true,
  state: buildFiltersState(placeholders),
  mutations,
  actions,
});
