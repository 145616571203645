<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <div
      v-if="isActive"
      class="overlay"
      @click="closeMiniCartAndNotification()"
    ></div>

    <MaskedLink
      class="header"
      :redirect-to="frontFacingPath('checkout.cart.details')"
      :text="`${$t('mini-cart.total')} ${paymentTotal}`"
    />

    <div class="container">
      <div class="productsContainer">
        <ProductTile
          v-for="(product, index) in products"
          :key="index"
          :product="product"
          context="cart"
          type="miniCart"
          :readonly="false"
          :delete-product="deleteProduct"
          :actions="actions(product)"
          @onQuantityChange="
            changeQuantity({ productId: product.productId, quantity: $event })
          "
        />
      </div>

      <div class="footer">
        <div class="cartButton">
          <Button
            class="qa-MiniCart__goToCheckout"
            :href="frontFacingPath('checkout.checkout.details')"
            :modifiers="['primary', 'full']"
            :title="$t('mini-cart.checkout')"
            :on-click="checkUnavailableProducts"
          />
        </div>

        <Button
          class="qa-MiniCart__goToCart gtm--minicart-goToCart"
          type="button"
          :href="frontFacingPath('checkout.cart.details')"
          :modifiers="['full', 'link']"
          :title="`${this.$t('mini-cart.cart')} >`"
        />
      </div>
    </div>

    <Dialog
      ref="unavailableDialog"
      :click-outside="true"
      v-bind="unavailableDialogConfiguration"
    >
      {{ $t(`mini-cart.${dialogType}.message`) }}
    </Dialog>

    <Dialog
      ref="deleteProductDialog"
      :title="$t('mini-cart.confirm.title')"
      v-bind="deleteDialogConfiguration"
    >
      {{ $t('mini-cart.confirm.cart.message') }}
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Button from 'Components/01-atoms/button/Button';
import ProductTile from 'Components/02-molecules/product-tile/ProductTile';
import Dialog from 'Components/02-molecules/dialog/Dialog.vue';
import globalMixin from 'Libs/mixins/globalMixin';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';

export default {
  name: 'MiniCart',
  components: {
    Button,
    ProductTile,
    Dialog,
    MaskedLink,
  },
  mixins: [globalMixin],
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    paymentTotal: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productToDeleteSetup: {},
    };
  },
  computed: {
    ...mapState('miniCart', {
      unavailableProductCount: (state) =>
        state.data.minicart.unavailableProductCount,
      productCount: (state) => state.data.minicart.products.length,
    }),
    ...mapState('core', {
      isNewWishlist: ({ salesChannel }) =>
        salesChannel.config.isNewWishlist || false,
    }),
    dialogType() {
      return this.unavailableProductCount === this.productCount
        ? 'unavailable_dialog'
        : 'partly_unavailable_dialog';
    },
    unavailableDialogConfiguration() {
      let dialogConfig = {
        title: this.$t(`mini-cart.${this.dialogType}.title`),
      };

      if (this.dialogType === 'partly_unavailable_dialog') {
        dialogConfig = {
          ...dialogConfig,
          'cancel-button': {
            title: this.$t('mini-cart.partly_unavailable_dialog.cancel'),
          },
          'confirm-button': {
            title: this.$t('mini-cart.partly_unavailable_dialog.accept'),
            onClick: () => {
              window.location.assign(
                this.frontFacingPath('checkout.checkout.details')
              );
            },
          },
        };
      }
      return dialogConfig;
    },
    deleteDialogConfiguration() {
      return {
        'cancel-button': {
          title: this.$t('mini-cart.confirm.cancel'),
        },
        'confirm-button': {
          title: this.$t('mini-cart.confirm.accept'),
          onClick: () => {
            this.changeQuantity(this.productToDelete);
          },
        },
      };
    },
    productToDelete: {
      get() {
        return this.productToDeleteSetup;
      },
      set(newSetup) {
        this.productToDeleteSetup = newSetup;
      },
    },
  },
  methods: {
    ...mapActions('header', ['closeMiniCartAndNotification']),
    ...mapActions('miniCart', ['updateQuantity']),
    ...mapActions('dataLayer', ['trackMiniCart2Checkout']),
    changeQuantity(product) {
      this.updateQuantity(product);
    },
    redirectTo(url) {
      window.location.href = url;
    },
    actions(product) {
      const isInWishlist = product.inWishlist;

      let wishlistIcon;

      if (this.isNewWishlist) {
        wishlistIcon = isInWishlist
          ? 'wishlist_filled_heart'
          : 'wishlist_heart';
      } else {
        wishlistIcon = isInWishlist
          ? 'wishlist_filled_heart'
          : 'wishlist_heart';
      }

      return {
        product: product,
        actions: [
          {
            type: 'wishlist',
            icon: wishlistIcon,
            title: this.$t(
              `mini-cart.wishlist.${!isInWishlist ? 'add' : 'remove'}`
            ),
            class: 'qa-ProductTile__wishlist ga-miniCart-wishlist',
            tabindex: '0',
          },
          {
            type: 'delete',
            icon: 'delete',
            title: this.$t('mini-cart.remove'),
            class: 'qa-ProductTile__delete ga-miniCart-delete',
            tabindex: '1',
            listOfOrigin: 'Shopping Cart Mini',
          },
        ],
      };
    },
    checkUnavailableProducts(e) {
      e.preventDefault();

      if (this.unavailableProductCount && this.unavailableProductCount > 0) {
        this.$refs.unavailableDialog.show();
      } else {
        const url = this.frontFacingPath('checkout.checkout.details');
        this.trackMiniCart2Checkout().finally(() => this.redirectTo(url));
      }
    },
    deleteProduct({ productId, listOfOrigin }) {
      this.productToDelete = {
        productId,
        listOfOrigin,
      };
      this.$refs.deleteProductDialog.show();
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

.miniCart {
  @include dropShadow;

  background: var(--color-white);
  position: absolute;
  z-index: 6;
  top: 48px;
  right: 0;
  min-height: 40px;
  max-height: 40px;
  width: 320px;

  &::before {
    content: '';
    display: block;
    background: var(--color-white);
    position: absolute;
    top: -12px;
    right: 0;
    height: 12px;
    width: 128px;
    overflow: hidden;
  }

  .overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
}

.container {
  @include dropShadow;

  background-color: var(--color-white);
}

.header {
  display: block;
  height: 40px;
  position: relative;
  line-height: 40px;
  text-align: center;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-alto);

  font-size: var(--font-size-M);
  font-weight: bold;
  text-transform: uppercase;

  color: var(--color-primary);

  svg {
    margin-right: var(--space-1);
    margin-left: var(--space-1);

    fill: var(--color-primary);
  }
}

.productsContainer {
  width: 320px;
  max-height: 400px;

  @include fancyScroll;
}

.footer {
  padding: 15px;
  border-top: 1px solid var(--color-alto);
}

.cartButton {
  margin-bottom: 10px;
}

@media (max-width: 899px) {
  .miniCart {
    display: none;

    &::before {
      content: none;
    }
  }
}
</style>
