const scrollMixin = {
  methods: {
    toggleBodyScrolling(value) {
      if (value) {
        this.enableBodyScrolling();
      } else {
        this.disableBodyScrolling();
      }
    },
    disableBodyScrolling() {
      document.body.style.overflowY = 'hidden';
      document.documentElement.style.overflowY = 'hidden';
    },
    enableBodyScrolling() {
      document.body.style.overflowY = '';
      document.documentElement.style.overflowY = '';
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
};

export default scrollMixin;
