export const setNavigationState = ({ data, component } = {}) => {
  if (!data) {
    return false;
  }

  const { seoMenu } = data;

  const { shortcuts } = data.menu;

  // TODO: after we make sure the new structure of the menu items is ok from a backend perspective we can remove this and leave an empty array by default
  let menuItems;
  try {
    menuItems = [data.menu.topCategories, ...data.menu.menuItems].map(
      (category, index) => ({
        ...category,
        topCategories: index === 0,
        selected: index === 0,
        fetching: false,
      })
    );
  } catch {
    menuItems = [];
  }

  // TODO: set final data structure during mobile development
  return {
    data: {
      shortcuts,
      menuItems,
    },
    seoMenu,
    component,
    activeMenuItem: null,
    isMobileOpen: false,
    isDesktopOpen: false,
    rootMenuFetching: false,
  };
};
