var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.classes,attrs:{"data-preload":[_vm.$options.name]}},[_c('div',{staticClass:"wrapper"},[(_vm.showSelector)?_c('div',{staticClass:"selectWrapper",class:{
        'selectWrapper--focused': _vm.selected,
        'selectWrapper--no-divider': !_vm.hasVerticalDivider,
        'selectWrapper--darker': _vm.darker,
        'input-error': _vm.isMaxQuantityReached,
      }},[(_vm.hasLabel)?_c('label',{staticClass:"label",attrs:{"for":_vm.id}},[_vm._v("\n        "+_vm._s(_vm.label)+"\n      ")]):_vm._e(),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedValue),expression:"selectedValue"}],staticClass:"select qa-select",class:{
          'select--focused': _vm.selected,
          'select--no-label': !_vm.hasLabel,
        },attrs:{"id":_vm.id},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedValue=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.onSelectChange()}],"focus":_vm.onFocus,"blur":_vm.onBlur}},[_vm._l((_vm.choices),function(choice,index){return _c('option',{directives:[{name:"show",rawName:"v-show",value:(parseInt(choice.value) <= _vm.maxQuantity),expression:"parseInt(choice.value) <= maxQuantity"}],key:index,domProps:{"value":choice.value,"selected":_vm.value === choice.value}},[_vm._v("\n          "+_vm._s(choice.label)+"\n        ")])}),_vm._v(" "),(_vm.threshold && _vm.maxQuantity >= _vm.threshold)?_c('option',{domProps:{"value":_vm.threshold}},[_vm._v("\n          "+_vm._s(_vm.threshold)),(_vm.maxQuantity !== _vm.threshold)?_c('span',[_vm._v("+")]):_vm._e()]):_vm._e()],2),_vm._v(" "),_c('ArrowDropDownSvg',{staticClass:"icon",attrs:{"original":true,"height":"7","width":"8"}})],1):_c('div',{staticClass:"inputWrapper",class:{
        'inputWrapper--focused': _vm.selected,
        'input-error': _vm.isMaxQuantityReached,
      }},[_c('label',{staticClass:"label",attrs:{"for":_vm.id}},[_vm._v("\n        "+_vm._s(_vm.label)+"\n      ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.inputValue),expression:"inputValue",modifiers:{"number":true}}],staticClass:"input",class:{ 'input--focused': _vm.selected },attrs:{"id":_vm.id,"pattern":"\\d*","maxLength":"3","type":"number"},domProps:{"value":(_vm.inputValue)},on:{"change":function($event){return _vm.onInputChange()},"focus":_vm.onFocus,"blur":[_vm.onBlur,function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.inputValue=_vm._n($event.target.value)}}}),_vm._v(" "),_c('ReloadSvg',{staticClass:"icon",attrs:{"original":true,"height":"30","width":"25"}})],1),_vm._v(" "),_vm._t("default")],2),_vm._v(" "),(_vm.isMaxQuantityReached)?_c('div',{staticClass:"max-quantity-error"},[_vm._v("\n    "+_vm._s(_vm.$t('quantity-selector.max-quantity-error'))+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }